import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import {
  CourseModes,
  CourseTypes,
  DurationUnits,
  emailRegex,
  RequestFormRoutes,
  SubsidyFormType,
} from '../../../Common/constants';
import { RMS_UPDATE_GROUP_GROSS_FEE } from '../../../redux/actionTypes/groupRequestTypes';
import RMSPageTitle from '../../CommonComponents/RMSPageTitle';
import useConvertDate from '../../../hooks/useConvertDate';

const TrainingFormCourseDetails = ({
  onSave,
  onBack,
  match,
  isGroup = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { combineDateIsoStringAndTime } = useConvertDate();

  const getFormattedDate = dateStr => {
    if (dateStr && moment(dateStr).isValid()) {
      return moment(dateStr).format('YYYY-MM-DD');
    } else {
      return '';
    }
  };

  const [errorMessage, setErrorMessage] = useState('');

  //Form Field States
  const [courseType, setCourseType] = useState();
  const [courseTitle, setCourseTitle] = useState('');
  const [courseMode, setCourseMode] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState('');
  const [duration, setDuration] = useState('');
  const [sessions, setSessions] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isCourseSubsidized, setIsCourseSubsidized] = useState();
  const [grossFee, setGrossFee] = useState('');
  const [grossFeeWithGST, setGrossFeeWithGST] = useState('');
  const [netFeeAfterSubsidy, setNetFeeAfterSubsidy] = useState('');
  const [grantRef, setGrantRef] = useState();
  const [subsidyForm, setSubsidyForm] = useState();
  const [subsidyFormOtherText, setSubsidyFormOtherText] = useState('');
  const [institution, setInstitution] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [durationType, setDurationType] = useState(DurationUnits.HOURS);

  const validateAndSubmit = () => {
    setErrorMessage('');

    //check required fields
    if (
      courseTitle &&
      startDate &&
      endDate &&
      duration &&
      durationType &&
      sessions &&
      grossFee &&
      grossFeeWithGST &&
      institution &&
      contactPerson &&
      email &&
      phoneNumber &&
      courseMode &&
      courseType &&
      startTime &&
      endTime
    ) {
      //check email validity
      if (!email.match(emailRegex)) {
        //not valid
        setErrorMessage(t('Please enter a valid email'));
        return;
      }
      //check date validity
      if (Date.parse(startDate) > Date.parse(endDate)) {
        //not valid
        setErrorMessage(t('Invalid course start/end date'));
        return;
      }

      //check subsidized mandatory fields
      if (isCourseSubsidized === 'yes') {
        if (netFeeAfterSubsidy && subsidyForm) {
          if (subsidyForm === SubsidyFormType.OTHERS) {
            if (!subsidyFormOtherText) {
              //not valid
              setErrorMessage(t('Please fill all the required fields'));
              return;
            }
          }
        } else {
          //not valid
          setErrorMessage(t('Please fill all the required fields'));
          return;
        }
      }

      let formattedData = {
        course: {
          title: courseTitle,
          courseType,
          courseModeList:
            courseMode === CourseModes.BOTH
              ? [CourseModes.ONLINE, CourseModes.PHYSICAL]
              : [courseMode],
          startDate: combineDateIsoStringAndTime(
            startDate.toISOString(),
            startTime
          ),
          endDate: combineDateIsoStringAndTime(endDate.toISOString(), endTime),
          duration: duration,
          durationUnit: durationType,
          sessions: sessions,
          isSubsidized: isCourseSubsidized === 'yes',
          grossFee: parseFloat(grossFee),
          grossFeeWithGst: parseFloat(grossFeeWithGST),

          grantRef,
          institutionName: institution,
          contactPerson,
          email,
          phoneNumber,
        },
      };
      if (isCourseSubsidized === 'yes') {
        formattedData = {
          ...formattedData,
          course: {
            ...formattedData.course,
            netFee: parseFloat(netFeeAfterSubsidy),
            subsidyForm,
          },
        };
      }
      if (
        isCourseSubsidized === 'yes' &&
        subsidyForm === SubsidyFormType.OTHERS
      ) {
        formattedData = {
          ...formattedData,
          course: {
            ...formattedData.course,
            subsidyText: subsidyFormOtherText,
          },
        };
      }
      dispatch({
        type: RMS_UPDATE_GROUP_GROSS_FEE,
        payload: grossFee,
      });
      onSave(RequestFormRoutes.Other, formattedData);
    } else {
      //not valid
      setErrorMessage(t('Please fill all the required fields'));
      return;
    }
  };

  const getCourseType = useMemo(() => {
    let courses = [{ label: t('General'), value: CourseTypes.GENERAL }];

    if (!isGroup) {
      courses.push({
        label: t('Professional'),
        value: CourseTypes.PROFESSIONAL,
      });
    }
    return courses;
  }, [isGroup]);

  if (!match) {
    return null;
  }
  return (
    <div>
      <div className="RMS__page trainingForm form2">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Training Form" />
            <div className="RMS__page--content">
              <div className="RMS__page--header">
                <div className="RMS__page--header-row">
                  <div className="RMS__page--header-left">
                    <p>{t('Course Details')}</p>
                  </div>
                  <div className="RMS__page--header-right required">
                    <p>
                      {t('Course Type')}
                      <span>*</span>
                    </p>
                    <ReactDropdown
                      options={getCourseType}
                      onChange={option => setCourseType(option.value)}
                      value={courseType}
                      placeholder={t('Select')}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body">
                <div className="RMS__page--body-row fourColumn">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Course Title')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Enter Course Title')}
                          value={courseTitle}
                          onChange={e => setCourseTitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Course Mode')}
                        <span>*</span>
                      </p>
                      <ReactDropdown
                        options={[
                          { label: t('Physical'), value: CourseModes.PHYSICAL },
                          {
                            label: t('Online'),
                            value: CourseModes.ONLINE,
                          },
                          {
                            label: t('Both'),
                            value: CourseModes.BOTH,
                          },
                        ]}
                        value={courseMode}
                        onChange={option => setCourseMode(option.value)}
                        placeholder={t('Select')}
                      />
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Start Date')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <DatePicker
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={setStartDate}
                          showMonthDropdown
                          minDate={
                            !isGroup &&
                            new Date().setDate(new Date().getDate() + 14)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('End Date')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <DatePicker
                          selected={endDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={setEndDate}
                          showMonthDropdown
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-row fourColumn">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Duration')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input withDropdown">
                        <input
                          type="text"
                          placeholder={t('Enter Duration')}
                          value={duration}
                          onChange={e =>
                            e.target.validity.valid &&
                            setDuration(e.target.value)
                          }
                          pattern="^[0-9]\d{0,6}(\.\d{0,1})?$"
                        />
                        <span className="typeLabel">{durationType}</span>
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Sessions')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Enter Sessions')}
                          value={sessions}
                          onChange={e =>
                            e.target.validity.valid &&
                            setSessions(e.target.value)
                          }
                          pattern="^[0-9]\d{0,6}(\.\d{0,1})?$"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Start Time')} <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="time"
                          placeholder={t('Pick Time')}
                          value={startTime}
                          onChange={e => setStartTime(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('End Time')} <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="time"
                          placeholder={t('Pick Time')}
                          value={endTime}
                          onChange={e => setEndTime(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RMS__page--divider"></div>
              <div className="RMS__page--body">
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field row">
                      <p className="form--field-title required">
                        {t('Is This Course Subsidized?')}
                        <span>*</span>
                      </p>
                      <ReactDropdown
                        options={[
                          { label: t('Yes'), value: 'yes' },
                          { label: t('No'), value: 'no' },
                        ]}
                        value={isCourseSubsidized}
                        onChange={option => setIsCourseSubsidized(option.value)}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Gross Fee')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={grossFee}
                          onChange={e =>
                            e.target.validity.valid &&
                            setGrossFee(e.target.value)
                          }
                          pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Gross Fee(with GST)')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={grossFeeWithGST}
                          onChange={e =>
                            e.target.validity.valid &&
                            setGrossFeeWithGST(e.target.value)
                          }
                          pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    {isCourseSubsidized === 'yes' && (
                      <div className="form--field twoRows">
                        <p className="form--field-title required">
                          {t('Net Fee(after Subsidy)')}
                          <span>*</span>
                        </p>
                        <div className="form--field-input">
                          <input
                            type="text"
                            placeholder={t('Type Here')}
                            value={netFeeAfterSubsidy}
                            onChange={e =>
                              e.target.validity.valid &&
                              setNetFeeAfterSubsidy(e.target.value)
                            }
                            pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Grant Ref')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={grantRef}
                          onChange={e => setGrantRef(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {isCourseSubsidized === 'yes' && (
                    <div className="RMS__page--body-column">
                      <div className="form--field twoRows">
                        <p className="form--field-title required">
                          {t('Subsidy Form')}
                          <span>*</span>
                        </p>
                        <ReactDropdown
                          options={[
                            { label: t('SDF'), value: SubsidyFormType.SDF },
                            { label: t('VCF'), value: SubsidyFormType.VCF },
                            {
                              label: t('Others'),
                              value: SubsidyFormType.OTHERS,
                            },
                          ]}
                          value={subsidyForm}
                          onChange={option => setSubsidyForm(option.value)}
                          placeholder={t('Select')}
                        />
                      </div>
                    </div>
                  )}
                  <div className="RMS__page--body-column">
                    {subsidyForm === SubsidyFormType.OTHERS &&
                      isCourseSubsidized === 'yes' && (
                        <div className="form--field twoRows">
                          <p className="form--field-title required">
                            {t('Others')}
                            <span>*</span>
                          </p>
                          <div className="form--field-input">
                            <input
                              type="text"
                              placeholder={t('Type Here')}
                              value={subsidyFormOtherText}
                              onChange={e =>
                                setSubsidyFormOtherText(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="RMS__page--divider"></div>
              <div className="RMS__page--body">
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field row">
                      <p className="form--field-title required">
                        {t('Institution')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={institution}
                          onChange={e => setInstitution(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Contact Person')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={contactPerson}
                          onChange={e => setContactPerson(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Email')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title required">
                        {t('Phone Number')}
                        <span>*</span>
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Type Here')}
                          value={phoneNumber}
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="RMS__page--submit overall">
              <div className="RMS__page--error">
                <div className="RMS__page--error-content"> {errorMessage}</div>
              </div>

              <button
                className="btn btn--secondary btn--marginRight"
                onClick={onBack}
              >
                {t('Back')}
              </button>
              <button className="btn btn--primary" onClick={validateAndSubmit}>
                {t('Next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingFormCourseDetails;
