import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddBudgetModal = ({ onHandleClose, header, setBudgetValues }) => {
  const { t } = useTranslation();
  const [budgetValue, setBudgetValue] = useState('');
  return (
    <div className="reel-view--popup RMS__popUp addUser">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{header}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose()}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <input
              type="text"
              placeholder="0.00"
              value={budgetValue}
              onChange={e =>
                e.target.validity.valid && setBudgetValue(e.target.value)
              }
              pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
            />
          </div>
          <div className="reel-view--popup-row">
            <button
              className="btn btn--primary"
              onClick={() => {
                setBudgetValues(budgetValue);
                onHandleClose();
              }}
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBudgetModal;
