import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { FilterBy, SearchBy } from '../../Common/constants';
import DeleteUserModal from '../../Components/Modal/DeleteUserModal';
import WarningModal from '../../Components/Modal/WarningModal';
import UserManagement from '../../Components/UserManagement/UserManagement';
import usePrevious from '../../hooks/usePrevious';
import {
  GET_RMS_CENTER_DETAILS_FAILED,
  GET_RMS_CENTER_DETAILS_REQUEST,
  GET_RMS_CENTER_DETAILS_SUCCESS,
  GET_RMS_CENTER_FAILED,
  GET_RMS_CENTER_REQUEST,
  GET_RMS_CENTER_SUCCESS,
  GET_RMS_DEPARTMENT_DETAILS_FAILED,
  GET_RMS_DEPARTMENT_DETAILS_REQUEST,
  GET_RMS_DEPARTMENT_DETAILS_SUCCESS,
  GET_RMS_DEPARTMENT_FAILED,
  GET_RMS_DEPARTMENT_REQUEST,
  GET_RMS_DEPARTMENT_SUCCESS,
  GET_RMS_DESIGNATION_DETAILS_FAILED,
  GET_RMS_DESIGNATION_DETAILS_REQUEST,
  GET_RMS_DESIGNATION_DETAILS_SUCCESS,
  GET_RMS_DESIGNATION_FAILED,
  GET_RMS_DESIGNATION_REQUEST,
  GET_RMS_DESIGNATION_SUCCESS,
  GET_RMS_QUALIFICATION_FAILED,
  GET_RMS_QUALIFICATION_REQUEST,
  GET_RMS_QUALIFICATION_SUCCESS,
  GET_RMS_USER_QUALIFICATIONS_FAILED,
  GET_RMS_USER_QUALIFICATIONS_REQUEST,
  GET_RMS_USER_QUALIFICATIONS_SUCCESS,
  RMS_DELETE_USER_FAILED,
  RMS_DELETE_USER_REQUEST,
  RMS_DELETE_USER_SUCCESS,
  RMS_RESET_USER_MANAGEMENT,
  SEARCH_RMS_USERS_FAILED,
  SEARCH_RMS_USERS_REQUEST,
  SEARCH_RMS_USERS_RESET,
  SEARCH_RMS_USERS_SUCCESS,
  GET_RMS_COMPANY_REQUEST,
  GET_RMS_COMPANY_SUCCESS,
  GET_RMS_COMPANY_FAILED,
  GET_RMS_COMPANY_DETAILS_REQUEST,
  GET_RMS_COMPANY_DETAILS_SUCCESS,
  GET_RMS_COMPANY_DETAILS_FAILED,
  RMS_CENTER_RESET,
  RMS_DEPARTMENT_RESET,
  RMS_DESIGNATION_RESET,
} from '../../redux/actionTypes/userManagementTypes';
import EditUserContainer from './EditUserContainer';

const UserManagementContainer = ({ history }) => {
  const [getAllUsersApi] = useApi();
  const [getUserQualificationsApi] = useApi();
  const [getCenterDetailsApi] = useApi();
  const [getAllCentersApi] = useApi();
  const [getAllDepartmentsApi] = useApi();
  const [getAllDesignationsApi] = useApi();
  const [getAllQualificationsApi] = useApi();
  const [getDepartmentDetailsApi] = useApi();
  const [getDesignationDetailsApi] = useApi();
  const [deleteUserApi] = useApi();
  const [getAllCompaniesApi] = useApi();
  const [getCompanyDetailsApi] = useApi();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [selectedSearchByMethod, setSelectedSearchByMethod] = useState(
    SearchBy.EMAIL
  );

  const [selectedFilter, setSelectedFilter] = useState();
  const [optionsList, setOptionsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [showUserEditPopup, setShowUserEditPopup] = useState(false);
  const [showUserDeletePopup, setShowUserDeletePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    centers = {},
    allCenters = [],
    allDepartments = [],
    allDesignations = [],
    allQualifications = [],
    departments = {},
    designations = {},
    qualifications = {},
    users = [],
    usersPage,
    usersTotalRecords,
    deleteUserLoading,
    deleteUserError,
    deleteUserErrorMessage,
    deleteUserSuccess,
    allCompanies,
    companies,
  } = useSelector(state => state.rmsUserManagement);

  const resetUserList = () => {
    dispatch({
      type: SEARCH_RMS_USERS_RESET,
    });
  };

  useEffect(() => {
    refreshData();
  }, [selectedSearchByMethod, selectedOption]);

  const refreshData = () => {
    resetUserList();
    getAllUsers(1);
  };

  let searchTimeout;

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      resetUserList();
      getAllUsers(1);
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);

  const loadNextUserPage = () => {
    const maxPage = Math.ceil(usersTotalRecords / 10);
    const nextPage = usersPage + 1;

    nextPage <= maxPage && getAllUsers(usersPage + 1);
  };

  const getAllUsers = page => {
    getAllUsersApi(
      `/api/v1/users?page=${page}&size=10${
        searchText ? `&searchText=${searchText}` : ''
      }${searchText ? `&searchInFields=${selectedSearchByMethod}` : ''}${
        selectedOption && selectedFilter === FilterBy.CENTER
          ? `&filteringCenterId=${selectedOption}`
          : ''
      }${
        selectedOption && selectedFilter === FilterBy.DEPARTMENT
          ? `&filteringDepartmentId=${selectedOption}`
          : ''
      }${
        selectedOption && selectedFilter === FilterBy.DESIGNATION
          ? `&filteringDesignationId=${selectedOption}`
          : ''
      }&orderBy=joinedDate&isProfileCompleted=${
        selectedFilter !== FilterBy.INCOMPLETE_PROFILES
      }`,
      SEARCH_RMS_USERS_REQUEST,
      SEARCH_RMS_USERS_SUCCESS,
      SEARCH_RMS_USERS_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getQualificationDetails = qualificationIds => {
    qualificationIds?.length &&
      getUserQualificationsApi(
        `/api/v1/qualifications?qualificationIds=${qualificationIds}&page=1&size=${qualificationIds.length}`,
        GET_RMS_USER_QUALIFICATIONS_REQUEST,
        GET_RMS_USER_QUALIFICATIONS_SUCCESS,
        GET_RMS_USER_QUALIFICATIONS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getCenterDetails = centerIds => {
    centerIds?.length &&
      getCenterDetailsApi(
        `/api/v1/centers?centerIds=${centerIds}&page=1&size=${centerIds.length}`,
        GET_RMS_CENTER_DETAILS_REQUEST,
        GET_RMS_CENTER_DETAILS_SUCCESS,
        GET_RMS_CENTER_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDepartmentDetails = departmentIds => {
    departmentIds?.length &&
      getDepartmentDetailsApi(
        `/api/v1/departments?departmentIds=${departmentIds}&page=1&size=${departmentIds.length}`,
        GET_RMS_DEPARTMENT_DETAILS_REQUEST,
        GET_RMS_DEPARTMENT_DETAILS_SUCCESS,
        GET_RMS_DEPARTMENT_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDesignationDetails = designationIds => {
    designationIds?.length &&
      getDesignationDetailsApi(
        `/api/v1/designations?designationIds=${designationIds}&page=1&size=${designationIds.length}`,
        GET_RMS_DESIGNATION_DETAILS_REQUEST,
        GET_RMS_DESIGNATION_DETAILS_SUCCESS,
        GET_RMS_DESIGNATION_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };

  const getCompanyDetails = companyIds => {
    companyIds?.length &&
      getCompanyDetailsApi(
        `/api/v1/companies?companyIds=${companyIds}&page=1&size=${companyIds.length}`,
        GET_RMS_COMPANY_DETAILS_REQUEST,
        GET_RMS_COMPANY_DETAILS_SUCCESS,
        GET_RMS_COMPANY_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };

  useEffect(() => {
    getAllUsers(1);

    return () => {
      dispatch({ type: RMS_RESET_USER_MANAGEMENT });
    };
  }, []);

  useEffect(() => {
    if (users?.length) {
      const qualificationIds =
        users
          ?.filter(user => user?.qualificationId)
          ?.map(user => user?.qualificationId) || [];

      const uniqueQualificationIds = [...new Set(qualificationIds)];

      const centerIds =
        users?.filter(user => user?.centerId)?.map(user => user?.centerId) ||
        [];
      const uniqueCenterIds = [...new Set(centerIds)];

      const departmentIds =
        users
          ?.filter(user => user?.departmentId)
          ?.map(user => user?.departmentId) || [];
      const uniqueDepartmentIds = [...new Set(departmentIds)];

      const designationIds =
        users
          ?.filter(user => user?.designationId)
          ?.map(user => user?.designationId) || [];
      const uniqueDesignationIds = [...new Set(designationIds)];

      const companyIds =
        users?.filter(user => user?.companyId)?.map(user => user?.companyId) ||
        [];
      const uniqueCompanyId = [...new Set(companyIds)];

      getQualificationDetails(uniqueQualificationIds);
      getCenterDetails(uniqueCenterIds);
      getDepartmentDetails(uniqueDepartmentIds);
      getDesignationDetails(uniqueDesignationIds);
      getCompanyDetails(uniqueCompanyId);
    }
  }, [users]);

  const previousFilter = usePrevious(selectedFilter);

  useEffect(() => {
    if (selectedFilter) {
      setOptionsList([]);
      setSelectedOption();

      switch (selectedFilter) {
        case FilterBy.CENTER:
          dispatch({
            type: RMS_CENTER_RESET,
          });
          getAllCenters();
          break;
        case FilterBy.DEPARTMENT:
          dispatch({
            type: RMS_DEPARTMENT_RESET,
          });
          getAllDepartments();
          break;
        case FilterBy.DESIGNATION:
          dispatch({
            type: RMS_DESIGNATION_RESET,
          });
          getAllDesignations();
          break;
        case FilterBy.INCOMPLETE_PROFILES:
          refreshData();
          setSelectedSearchByMethod(SearchBy.EMAIL);
          setSearchText('');
          break;
        default:
          break;
      }

      if (
        selectedFilter !== FilterBy.INCOMPLETE_PROFILES &&
        previousFilter === FilterBy.INCOMPLETE_PROFILES
      ) {
        refreshData();
        setSearchText('');
      }
    }
  }, [selectedFilter]);

  const getAllCenters = (page = 1, searchText = '') => {
    getAllCentersApi(
      `/api/v1/centers?page=${page}&size=200${
        searchText ? `&searchNameLike=${searchText}` : ''
      }`,
      GET_RMS_CENTER_REQUEST,
      GET_RMS_CENTER_SUCCESS,
      GET_RMS_CENTER_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllDepartments = (page = 1, searchText = '') => {
    getAllDepartmentsApi(
      `/api/v1/departments?page=${page}&size=200${
        searchText ? `&searchNameLike=${searchText}` : ''
      }`,
      GET_RMS_DEPARTMENT_REQUEST,
      GET_RMS_DEPARTMENT_SUCCESS,
      GET_RMS_DEPARTMENT_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllCompanies = () => {
    getAllCompaniesApi(
      `/api/v1/companies?page=1&size=200`,
      GET_RMS_COMPANY_REQUEST,
      GET_RMS_COMPANY_SUCCESS,
      GET_RMS_COMPANY_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllDesignations = (page = 1, searchText = '') => {
    getAllDesignationsApi(
      `/api/v1/designations?page=${page}&size=200${
        searchText ? `&searchNameLike=${searchText}` : ''
      }`,
      GET_RMS_DESIGNATION_REQUEST,
      GET_RMS_DESIGNATION_SUCCESS,
      GET_RMS_DESIGNATION_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };
  const getAllQualifications = (page = 1, searchText = '') => {
    getAllQualificationsApi(
      `/api/v1/qualifications?page=${page}&size=200${
        searchText ? `&searchNameLike=${searchText}` : ''
      }`,
      GET_RMS_QUALIFICATION_REQUEST,
      GET_RMS_QUALIFICATION_SUCCESS,
      GET_RMS_QUALIFICATION_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  useEffect(() => {
    if (selectedFilter === FilterBy.CENTER && allCenters?.length) {
      createOptionsList(allCenters);
    } else if (
      selectedFilter === FilterBy.DEPARTMENT &&
      allDepartments?.length
    ) {
      createOptionsList(allDepartments);
    } else if (
      selectedFilter === FilterBy.DESIGNATION &&
      allDesignations?.length
    ) {
      createOptionsList(allDesignations);
    }
  }, [allCenters, allDepartments, allDesignations]);

  const createOptionsList = options => {
    setOptionsList(
      options?.map(options => {
        return { label: options.name, value: options._id };
      })
    );
  };

  const showEditUser = userId => {
    
    if (!userId) return;
    const user = users?.find(u => u._id === userId);
    setSelectedUser(user);
    setShowUserEditPopup(true);

    //fetch dropdown data
    getAllCenters();
    getAllDepartments();
    getAllDesignations();
    getAllQualifications();
    getAllCompanies();
  };
  const showDeleteUser = userId => {
    if (!userId) return;
    const user = users?.find(u => u._id === userId);
    setSelectedUser(user);
    setShowUserDeletePopup(true);
  };

  const deleteUser = () => {
    deleteUserById(selectedUser?._id);
  };

  const deleteUserById = userId => {
    setErrorMessage('');
    userId &&
      deleteUserApi(
        `/api/v1/users/${userId}`,
        RMS_DELETE_USER_REQUEST,
        RMS_DELETE_USER_SUCCESS,
        RMS_DELETE_USER_FAILED,
        {},
        {},
        'DELETE',
        false,
        'isRmsService'
      );
  };

  useEffect(() => {
    if (!deleteUserLoading) {
      if (deleteUserSuccess) {
        refreshData();
        setShowUserDeletePopup(false);
      } else if (deleteUserError) {
        if (deleteUserErrorMessage) {
          switch (deleteUserErrorMessage) {
            case 'USER_IS_PART_OF_AN_ONGOING_REQUEST':
              setShowUserDeletePopup(false);
              setErrorMessage(
                t(
                  'This user has an ongoing request, hence user cannot be removed.'
                )
              );
              setShowWarningPopup(true);
              break;
            case 'DEFAULT_SUPER_ADMIN_CANNOT_BE_REMOVED':
              setShowUserDeletePopup(false);
              setErrorMessage(t('Default super admin cannot be removed'));
              setShowWarningPopup(true);
              break;

            default:
              setErrorMessage(
                t('Error while deleting the user. Please try again.')
              );
              break;
          }
        } else {
          setErrorMessage(
            t('Error while deleting the user. Please try again.')
          );
        }
      }
    }
  }, [deleteUserLoading, deleteUserError, deleteUserSuccess]);

  return (
    <>
      <UserManagement
        users={users}
        qualifications={qualifications}
        centers={centers}
        departments={departments}
        designations={designations}
        searchText={searchText}
        setSearchText={setSearchText}
        setSelectedSearchByMethod={setSelectedSearchByMethod}
        selectedSearchByMethod={selectedSearchByMethod}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        optionsList={optionsList}
        loadNextUserPage={loadNextUserPage}
        refreshData={refreshData}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        showEditUser={showEditUser}
        showDeleteUser={showDeleteUser}
        companies={companies}
      />
      {showUserEditPopup && (
        <EditUserContainer
          closePopup={() => setShowUserEditPopup(false)}
          selectedUser={selectedUser}
          refreshData={refreshData}
          getAllDesignations={getAllDesignations}
          getAllQualifications={getAllQualifications}
          getAllCenters={getAllCenters}
          getAllDepartments={getAllDepartments}
          userAssignedDesignations={designations}
          userAssignedQualification={qualifications}
          userAssignedCenters={centers}
          userAssignedDepartment={departments}
          userAssignedCompanies={companies}
        />
      )}
      {showUserDeletePopup && (
        <DeleteUserModal
          onHandleClose={() => {
            setShowUserDeletePopup(false);
            setErrorMessage('');
          }}
          header={t('Delete a user')}
          msgPartOne={t('Are you sure you want to delete this user?,')}
          msgPartTwo={t('is part of active templates!')}
          deleteUserData={selectedUser}
          removeSelectedUsers={deleteUser}
          errorMessage={errorMessage}
          loading={deleteUserLoading}
        />
      )}
      {showWarningPopup && (
        <WarningModal
          message={errorMessage}
          onClose={() => {
            setShowWarningPopup(false);
            setErrorMessage('');
          }}
          onOkClick={() => {
            setShowWarningPopup(false);
            setErrorMessage('');
          }}
          title={t('Warning')}
          userName={selectedUser?.name}
        />
      )}
    </>
  );
};

export default UserManagementContainer;
