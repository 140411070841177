import { avoidedDuplicationData, setErrorMessage } from '../../Common/helper';
import {
  RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST,
  RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS,
  RMS_RESET_MANAGE_REQUEST_PAGE_DATA,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS,
  RMS_ADMIN_UPDATE_REQUEST_REQUEST,
  RMS_ADMIN_UPDATE_REQUEST_SUCCESS,
  RMS_ADMIN_UPDATE_REQUEST_FAILED,
  RMS_ADMIN_UPDATE_REQUEST_RESET,
  RESET_RMS_REQUEST_DETAILS_FORM_DATA,
  RESET_RMS_SELECTED_TEMPLATE_REQUESTS,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
} from '../actionTypes/adminManageRequests';

const INITIAL_STATE = {
  allTemplateRequests: [],
  selectedTemplateRequests: [],
  requestDetailsById: {},
  applicantDetails: [],
  loading: false,
  lastEditedUserRole: [],
  requestTotalRecords: 0,
  requestPage: 0,
  updateRequestLoading: false,
  updateRequestSuccess: false,
  updateRequestFailed: false,
  approvalActionSuccess: false,
  approvalActionError: false,
  approvalActionLoading: false,
  approvalActionErrorMessage: '',
  updateRequestFailedMessage: '',
};

const adminManageRequestReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTemplateRequests: data,
      };
    case RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        selectedTemplateRequests:
          customResponse?.page === 1 ? [] : state.selectedTemplateRequests,
      };
    case RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        selectedTemplateRequests:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(
                state.selectedTemplateRequests,
                data || [],
                '_id'
              ),
        loading: false,
        requestPage: page || state.usersPage,
        requestTotalRecords: totalRecords || state.totalRecords,
      };
    case RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        requestDetailsById: action.payload || {},
        loading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS:
      return {
        ...state,
        applicantDetails: action.payload || [],
        loading: false,
      };

    case RMS_ADMIN_UPDATE_REQUEST_REQUEST:
      return {
        ...state,
        updateRequestLoading: true,
        updateRequestSuccess: false,
        updateRequestFailed: false,
        updateRequestFailedMessage: '',
      };
    case RMS_ADMIN_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestSuccess: true,
      };
    case RMS_ADMIN_UPDATE_REQUEST_FAILED:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestFailed: true,
        updateRequestFailedMessage: setErrorMessage(action?.payload),
      };

    case RMS_ADMIN_UPDATE_REQUEST_RESET:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestSuccess: false,
        updateRequestFailed: false,
        updateRequestFailedMessage: '',
      };

    case RMS_RESET_MANAGE_REQUEST_PAGE_DATA:
      return {
        ...state,
        requestTotalRecords: 0,
        requestPage: 0,
      };

    case RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS:
      return {
        ...state,
        lastEditedUserRole: data?.[0]?.roles || [],
      };
    case RESET_RMS_REQUEST_DETAILS_FORM_DATA:
      return {
        ...state,
        lastEditedUserRole: [],
        requestDetailsById: {},
      };

    case RESET_RMS_SELECTED_TEMPLATE_REQUESTS:
      return {
        ...state,
        selectedTemplateRequests: [],
        requestTotalRecords: 0,
        requestPage: 0,
      };

    case RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST:
      return {
        ...state,
        approvalActionLoading: true,
        approvalActionError: false,
        approvalActionSuccess: false,
        loading: true,
        approvalActionErrorMessage: '',
      };
    case RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        approvalActionLoading: false,
        approvalActionSuccess: true,
      };
    case RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED:
      return {
        ...state,
        approvalActionLoading: false,
        approvalActionError: true,
        loading: false,
        approvalActionErrorMessage: setErrorMessage(action?.payload || ''),
      };

    default:
      return state;
  }
};

export default adminManageRequestReducer;
