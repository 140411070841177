export const SearchBy = {
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  EMPLOYEE_ID: 'EMPLOYEE_ID',
};

export const privilegesDropDown = {
  superAdmin: 'superAdmin',
  adminApplicant: 'adminApplicant',
};
export const privileges = {
  RMS_DEFAULT_SUPER_ADMIN: 'RMS_DEFAULT_SUPER_ADMIN',
  RMS_ADMIN: 'RMS_ADMIN',
  RMS_APPLICANT: 'RMS_APPLICANT',
  RMS_SUPER_ADMIN: 'RMS_SUPER_ADMIN',
};

export const FilterBy = {
  CENTER: 'CENTER',
  DEPARTMENT: 'DEPARTMENT',
  DESIGNATION: 'DESIGNATION',
  INCOMPLETE_PROFILES: 'INCOMPLETE_PROFILES',
};

export const RequestSearchFilterBy = {
  REQUEST_ID: 'REQUEST_ID',
  NAME: 'NAME',
  COURSE_NAME: 'COURSE_NAME',
};

export const addUserDropDown = {
  searchByEmail: 'EMAIL',
  searchByName: 'NAME',
};

export const SearchFieldOptions = {
  email: 'EMAIL',
  employeeId: 'EMPLOYEE_ID',
  name: 'NAME',
};
export const BudgetTypes = {
  generalBudget: 'generalBudget',
  proffBudget: 'proffBudget',
};

export const ServiceHealth = {
  serviceUp: 'UP',
};

export const editUserFields = {
  QUALIFICATION: 'QUALIFICATION',
  CENTER: 'CENTER',
  DEPARTMENT: 'DEPARTMENT',
  DESIGNATION: 'DESIGNATION',
  EMPLOYEE_ID: 'EMPLOYEE_ID',
  DOB: 'DOB',
  DATE_JOINED: 'DATE_JOINED',
  NRIC_NO: 'NRIC_NO',
  COMPANY: 'COMPANY',
};

export const customErrorMessage = {
  INVALID_EMPLOYEE_ID: 'Invalid Employee Id',
  INVALID_NRIC_NO: 'Invalid NRICNo',
};

export const Services = {
  isUserService: 'isUserService',
  isGroupService: 'isGroupService',
  isReelService: 'isReelService',
  isNotificationService: 'isNotificationService',
  isWebsocketService: 'isWebsocketService',
  isRmsService: 'isRmsService',
};

export const TemplateTypes = {
  NORMAL_TEMPLATE: 'NORMAL_TEMPLATE',
  GROUP_TEMPLATE: 'GROUP_TEMPLATE',
};

export const AccessControlTabs = {
  SUPER_ADMIN: 'Super Admin',
  TEMPLATES: 'Templates',
};
export const RequestFormRoutes = {
  Personal: 'personal',
  Course: 'course',
  Other: 'other',
};

export const CourseTypes = {
  GENERAL: 'GENERAL_COURSE',
  PROFESSIONAL: 'PROFESSIONAL_COURSE',
};

export const CourseModes = {
  PHYSICAL: 'PHYSICAL',
  ONLINE: 'ONLINE',
  BOTH: 'BOTH',
};

export const SubsidyFormType = {
  SDF: 'SDF',
  VCF: 'VCF',
  OTHERS: 'OTHERS',
};

export const AssessmentTypes = {
  EXAMINATION: 'EXAMINATION',
  ASSIGNMENT: 'ASSIGNMENT',
  ASSESSMENT: 'ASSESSMENT',
};
export const bondArrayKeys = {
  minGrossAmount: 'minGrossAmount',
  maxGrossAmount: 'maxGrossAmount',
  duration: 'duration',
  durationUnit: 'durationUnit',
};
export const approvalBracketsAttributes = {
  minApproval: 'minApproval',
  grossStartAmount: 'grossStartAmount',
  grossEndAmount: 'grossEndAmount',
};
export const approveBracketsDropDownAttributes = {
  approverIds: 'approverIds',
  notifierIds: 'notifierIds',
};
export const UserModes = {
  USER: 'User',
  ADMIN: 'Admin',
};

export const RequestStatus = {
  APPROVED: 'APPROVED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const DurationUnits = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};
export const ActionMode = {
  APPROVALS: 'APPROVALS',
  SUBMITTED: 'SUBMITTED',
  PAST_REQUESTS: 'PAST_REQUESTS',
};

export const SuperAdminActionMode = {
  APPROVAL: 'APPROVAL',
  PAST_REQUEST: 'PAST_REQUEST',
  ALL_REQUEST: 'ALL_REQUEST',
};

export const SuperAdminRequestFilterOptions = {
  NAME: 'name',
  EMAIL: 'email',
  REQUEST_ID: 'requestId',
  DATE_RANGE: 'dateRange',
};

export const RequestType = {
  NORMAL_REQUEST: 'NORMAL_REQUEST',
  GROUP_REQUEST: 'GROUP_REQUEST',
};

export const bondsFilterByOptions = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETED: 'COMPLETED',
  ALL: 'ALL',
};

export const BondsSearchDropdownOptions = {
  REQUESTID: 'requestID',
  NAME: 'name',
  EMAIL: 'email',
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const RequestSubmissionErrors = {
  INSUFFICIENT_BUDGET: 'INSUFFICIENT_BUDGET',
  PRICE_BRACKET_NOT_DEFINED: 'PRICE_BRACKET_NOT_DEFINED',
};

export const multipleQuestionAnswers = {
  STRONGLY_AGREED: 'STRONGLY_AGREED',
  AGREED: 'AGREED',
  NEUTRAL: 'NEUTRAL',
  DISAGREED: 'DISAGREED',
  STRONGLY_DISAGREED: 'STRONGLY_DISAGREED',
};

export const feedBackQuestionPriorityOrders = {
  SINGLE_ANSWER_MULTIPLE_CHOICE_QUESTION:
    'SINGLE_ANSWER_MULTIPLE_CHOICE_QUESTION',
  BASIC_QUESTION: 'BASIC_QUESTION',
};

export const ApplicantBondSearchMode = {
  REQUEST_ID: 'REQUEST_ID',
  COURSE_NAME: 'COURSE_NAME',
};

export const adminDashboardMode = {
  OVERALL: 'overall',
  TEMPLATES: 'templates',
  NORMAL: 'normal',
  GROUP: 'group',
};

export const userStatsFilterDropDown = {
  searchByEmail: 'EMAIL',
  searchByName: 'NAME',
  searchByDesignation: 'DESIGNATION',
  searchByBonded: 'BONDED',
};

export const userStatsSearchDropDown = {
  searchByYes: 'YES',
  searchByNo: 'NO',
};

export const fileUploadPath = {
  REQUEST_FORM: 'request-form',
  FEEDBACK_FORM: 'feedback-form',
};
