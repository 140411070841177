import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import { RequestStatus, SuperAdminActionMode } from '../../Common/constants';
import EditAndDeleteIconBtn from './EditAndDeleteIconBtn';
import FeedbackContainer from '../../Container/FeedBackContainer/FeedBackContainer';
import useConvertDate from '../../hooks/useConvertDate';

const TableGrid = ({
  requests,
  loading,
  feedbackRequest,
  acceptOrRejectRequest,
  filterMode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { convertDateISOToString } = useConvertDate();

  const [isFeedback, setIsFeedback] = useState(false);
  const [requestIdForFeedBack, setRequestIdForFeedBack] = useState();
  const [applicantId, setApplicantId] = useState();
  const [isViewFeedback, setIsViewFeedback] = useState(false);

  const viewRequestForm = (trainingRequestId, isEdit = false) => {
    history.push(
      `/rms/admin-manage-requests/requestform/${trainingRequestId}`,
      { isEdit }
    );
  };

  const isStatusApprovedOrCompleted = (status = '') => {
    const currentStatus = status.toLowerCase();
    const approvedOrCompleted = [
      RequestStatus.APPROVED,
      RequestStatus.COMPLETED,
    ].map(status => status.toLowerCase());

    return approvedOrCompleted.includes(currentStatus);
  };

  const isPending = (status = '') => {
    return status.toLowerCase().includes('pending');
  };

  const viewFeedbackCheck = id => {
    const isView = feedbackRequest?.find(data => {
      return data.trainingRequestId === id;
    });
    if (isView) {
      return true;
    } else {
      return false;
    }
  };

  const clickOnViewFeedBack = (id, applicantId) => {
    setIsViewFeedback(true);
    setIsFeedback(true);
    setRequestIdForFeedBack(id);
    setApplicantId(applicantId);
  };

  const [tableColumClass, setTableColumClass] = useState('');

  const renderClassForButtonColumn = (requestStatus, _id) => {
    if (isStatusApprovedOrCompleted(requestStatus)) {
      if (viewFeedbackCheck(_id)) {
        return 1;
      } else {
        return 2;
      }
    } else {
      if (
        isPending(requestStatus) &&
        filterMode !== SuperAdminActionMode.PAST_REQUEST
      ) {
        return 3;
      }
    }
  };

  useEffect(() => {
    let classPriority = 0;

    if (requests?.length) {
      requests.forEach(({ requestStatus, _id }) => {
        if (renderClassForButtonColumn(requestStatus, _id) > classPriority) {
          classPriority = renderClassForButtonColumn(requestStatus, _id);
        }
      });
    }

    switch (classPriority) {
      case 1:
        setTableColumClass('iconOnly');
        break;
      case 2:
        setTableColumClass('iconButton');
        break;
      case 3:
        setTableColumClass('twoButtons');
        break;

      default:
        setTableColumClass('');
        break;
    }
  }, [requests]);

  return (
    <>
      <div className="RMS__page--body">
        <div className="RMS__page--table">
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Name')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Email')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Course name')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Institution')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Gross Fee')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Date Submitted')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Training Start Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Status')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Approval/Rejected Date')}</h4>
            </div>

            <div className={`RMS__page--table-column ${tableColumClass}`}></div>
          </div>

          <div className="RMS__page--table-body">
            {requests.length ? (
              requests?.map(
                ({
                  trainingRequestId,
                  applicantName,
                  applicantEmail,
                  trainingRequestSubmittedDate,
                  trainingRequestStartDate,
                  customRequestStatus,
                  approvedOrRejectedAt,
                  requestStatus,
                  _id,
                  pendingApprovalLevelId,
                  courseName,
                  institution,
                  grossFee,
                }) => (
                  <div
                    key={_id}
                    className="RMS__page--table-row clickable-row"
                    onClick={() => viewRequestForm(_id)}
                  >
                    <div className="RMS__page--table-column">
                      <p>{trainingRequestId || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{applicantName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{applicantEmail || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{courseName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{institution || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{grossFee?.toString() || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {convertDateISOToString(trainingRequestSubmittedDate) ||
                          '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {convertDateISOToString(trainingRequestStartDate) ||
                          '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{customRequestStatus || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {convertDateISOToString(approvedOrRejectedAt) || '-'}
                      </p>
                    </div>
                    <div
                      className={`RMS__page--table-column ${tableColumClass}`}
                    >
                      {isStatusApprovedOrCompleted(requestStatus) ? (
                        <>
                          <EditAndDeleteIconBtn
                            editHandler={() => viewRequestForm(_id, true)}
                          />
                          {viewFeedbackCheck(_id) && (
                            <button
                              className="btn btn--secondary btn--thinButton btn--tableButton"
                              onClick={e => {
                                e.stopPropagation();
                                clickOnViewFeedBack(_id, applicantId);
                              }}
                            >
                              {t('View Feedback')}
                            </button>
                          )}
                        </>
                      ) : (
                        isPending(requestStatus) &&
                        filterMode !== SuperAdminActionMode.PAST_REQUEST && (
                          <>
                            <button
                              className="btn btn--primary btn--thinButton btn--whiteText btn--marginRight"
                              onClick={e => {
                                e.stopPropagation();
                                acceptOrRejectRequest(
                                  true,
                                  _id,
                                  pendingApprovalLevelId
                                );
                              }}
                            >
                              {t('Accept')}
                            </button>
                            <button
                              className="btn btn--secondary btn--thinButton btn--whiteText"
                              onClick={e => {
                                e.stopPropagation();
                                acceptOrRejectRequest(
                                  false,
                                  _id,
                                  pendingApprovalLevelId
                                );
                              }}
                            >
                              {t('Reject')}
                            </button>
                          </>
                        )
                      )}
                    </div>
                  </div>
                )
              )
            ) : loading ? (
              <Loader />
            ) : (
              <NoContent message={t('Requests not available')} />
            )}
          </div>
        </div>
        {isFeedback && (
          <FeedbackContainer
            onHandleClose={() => setIsFeedback(false)}
            requestId={requestIdForFeedBack}
            applicantId={applicantId}
            isViewFeedback={isViewFeedback}
          />
        )}
      </div>
    </>
  );
};

export default TableGrid;
