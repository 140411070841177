import React, { useEffect, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import { editUserFields, customErrorMessage } from '../../Common/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useConvertDate from '../../hooks/useConvertDate';
import DropdownWithPagination from '../CommonComponents/DropdownWithPagination';
const EditUser = ({
  loading,
  closePopup,
  centerList = [],
  departmentList = [],
  designationList = [],
  qualificationList = [],
  updateUser,
  user = {},
  saveUser,
  errorMessage = '',
  updateUserErrorMsg,
  companiesList,
  getDropDownData,
  setDesignationSearchText,
  setQualificationSearchText,
  setCenterSearchText,
  setIsCenterSearch,
  setIsDesignationSearch,
  setIsQualificationSearch,
  setDepartmentSearchText,
  setIsDepartmentSearch,
}) => {
  const { t } = useTranslation();
  const { getDateObjectFromISOString, convertDateObjectToJSDate } =
    useConvertDate();

  const {
    qualificationId,
    designationId,
    centerId,
    departmentId,
    employeeId,
    email,
    name,
    nricNo,
    dob,
    joinedDate,
    companyId,
  } = user;

  const updateUserPopUpId = 'updateUserPopUpId';
  const [dropdownElements, setDropdownElements] = useState({});

  useEffect(() => {
    const element = document.getElementById(updateUserPopUpId);
    const propertyValues = Object.values(dropdownElements);
    if (propertyValues.filter(item => item)?.length) {
      document.body.classList.add('disable-scroll');
      element.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
      element.classList.remove('disable-scroll');
    }
  }, [JSON.stringify(dropdownElements)]);

  const disableScroll = (elementName, open) => {
    dropdownElements[elementName] = open;
    setDropdownElements({ ...dropdownElements });
  };

  return (
    <div className="reel-view--popup RMS__popUp editUser">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Edit User')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={closePopup}
          >
            &times;
          </a>
        </div>
        <div id={updateUserPopUpId} className="reel-view--popup-body">
          <div className="reel-view--popup-body__column">
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Employee Id')} <MandatoryMark />
              </label>
              <input
                type="text"
                value={employeeId}
                onChange={e =>
                  updateUser(editUserFields.EMPLOYEE_ID, e.target.value)
                }
              />
            </div>
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Email')}
                <MandatoryMark />
              </label>
              <input type="text" readOnly value={email} />
            </div>
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Date Joined')}
                <MandatoryMark />
              </label>
              <DatePicker
                selected={convertDateObjectToJSDate(joinedDate)}
                dateFormat="dd/MM/yyyy"
                onChange={date =>
                  updateUser(
                    editUserFields.DATE_JOINED,
                    getDateObjectFromISOString(date.toISOString())
                  )
                }
                showYearDropdown
                showMonthDropdown
                maxDate={new Date()}
              />
            </div>
            <div className="form">
              <DropdownWithPagination
                label={t('Highest Qualification')}
                required={true}
                name="qualificationId"
                value={qualificationId}
                rules={{ required: false }}
                errorMessage={null}
                placeholder={t('Select')}
                options={qualificationList ? qualificationList : []}
                customOnchangeAction={value => {
                  updateUser(editUserFields.QUALIFICATION, value);
                }}
                scrollCallBack={() =>
                  getDropDownData(editUserFields.QUALIFICATION)
                }
                onHandleSearch={value => {
                  setQualificationSearchText(value);
                  setIsQualificationSearch(true);
                }}
                onDropdownVisibleChange={open =>
                  disableScroll('qualificationId', open)
                }
              />
            </div>
            <div className="form">
              <DropdownWithPagination
                label={t('Designation')}
                required={true}
                name="designationId"
                value={designationId}
                rules={{ required: false }}
                errorMessage={null}
                placeholder={t('Select')}
                options={designationList ? designationList : []}
                customOnchangeAction={value => {
                  updateUser(editUserFields.DESIGNATION, value);
                }}
                scrollCallBack={() =>
                  getDropDownData(editUserFields.DESIGNATION)
                }
                onHandleSearch={value => {
                  setDesignationSearchText(value);
                  setIsDesignationSearch(true);
                }}
                onDropdownVisibleChange={open =>
                  disableScroll('designationId', open)
                }
              />
            </div>
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Company')}
                <MandatoryMark />
              </label>
              <ReactDropdown
                options={companiesList ? companiesList : []}
                value={companyId}
                onChange={option =>
                  updateUser(editUserFields.COMPANY, option.value)
                }
                placeholder={t('Select')}
              />
            </div>
          </div>
          <div className="reel-view--popup-body__column">
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Name')}
                <MandatoryMark />
              </label>
              <input type="text" readOnly value={name} />
            </div>
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('Date of Birth')}
                <MandatoryMark />
              </label>
              <DatePicker
                selected={convertDateObjectToJSDate(dob)}
                dateFormat="dd/MM/yyyy"
                onChange={date =>
                  updateUser(
                    editUserFields.DOB,
                    getDateObjectFromISOString(date.toISOString())
                  )
                }
                showYearDropdown
                showMonthDropdown
              />
            </div>
            <div className="reel-view--popup-body__row">
              <label htmlFor="">
                {t('NRIC No')}
                <MandatoryMark />
              </label>
              <input
                type="text"
                value={nricNo}
                onChange={e =>
                  updateUser(editUserFields.NRIC_NO, e.target.value)
                }
              />
            </div>
            <div className="form">
              <DropdownWithPagination
                label={t('Section Code')}
                required={true}
                name="centerId"
                value={centerId}
                rules={{ required: false }}
                errorMessage={null}
                placeholder={t('Select')}
                options={centerList ? centerList : []}
                customOnchangeAction={value => {
                  updateUser(editUserFields.CENTER, value);
                }}
                scrollCallBack={() => getDropDownData(editUserFields.CENTER)}
                onHandleSearch={value => {
                  setCenterSearchText(value);
                  setIsCenterSearch(true);
                }}
                onDropdownVisibleChange={open =>
                  disableScroll('centerId', open)
                }
              />
            </div>
            <div className="form">
              <DropdownWithPagination
                label={t('Department Id')}
                required={true}
                name="departmentId"
                value={departmentId}
                rules={{ required: false }}
                errorMessage={null}
                placeholder={'Select'}
                options={departmentList ? departmentList : []}
                customOnchangeAction={value => {
                  updateUser(editUserFields.DEPARTMENT, value);
                }}
                scrollCallBack={() =>
                  getDropDownData(editUserFields.DEPARTMENT)
                }
                onHandleSearch={value => {
                  setDepartmentSearchText(value);
                  setIsDepartmentSearch(true);
                }}
                onDropdownVisibleChange={open =>
                  disableScroll('departmentId', open)
                }
              />
            </div>
          </div>
          <div className="reel-view--popup-row-left">
            <span className="error-message">
              {updateUserErrorMsg === customErrorMessage.INVALID_EMPLOYEE_ID
                ? t('Employee Id already exists')
                : updateUserErrorMsg === customErrorMessage.INVALID_NRIC_NO
                ? t('NRIC No Already Exists')
                : errorMessage}
            </span>
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
                 btn btn--primary
                 ${loading ? 'btn--loader' : ''}
               `}
              onClick={saveUser}
            >
              {!loading && t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
