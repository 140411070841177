import React, { useState, useEffect } from 'react';
import CourseDetails from './Sub-Components/CourseDetails';
import CourseFee from './Sub-Components/CourseFee';
import InstitutionDetails from './Sub-Components/InstitutionDetails';
import PageSubmit from './Sub-Components/PageSubmit';
import RmsPageWrapper from './Sub-Components/RmsPageWrapper';
import RmsPageContentWrapper from './Sub-Components/RmsPageContentWrapper';
import Assessments from './Sub-Components/Assessments';
import ObjectivesAndRemarks from './Sub-Components/ObjectivesAndRemarks';
import EmployeeDetails from './Sub-Components/EmployeeDetails';
import IdDetails from './Sub-Components/IdDetails';
import BrochureAndCourseLink from './Sub-Components/BrochureAndCourseLink';
import useConvertId from '../../../hooks/useConvertId';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../Components/Loader/loader';
import { useTranslation } from 'react-i18next';
import ViewApprovalHistoryModal from '../../Modal/ViewApprovalHistoryModal';
import { privileges } from '../../../Common/constants';
import { useLocation } from 'react-router';
import RMSPageTitle from '../../CommonComponents/RMSPageTitle';
import { RESET_RMS_REQUEST_DETAILS_FORM_DATA } from '../../../redux/actionTypes/adminManageRequests';
import useConvertDate from '../../../hooks/useConvertDate';

const ManageRequestForm = ({
  match,
  formData,
  loading,
  designations,
  centers,
  qualifications,
  departments,
  ApprovalLevels,
  setSelectedApprovalLevel,
  approvalInfo,
  getApplicantNames,
  approverNames,
  approvalHistoryLoading,
  selectedApprovalLevel,
  lastEditedUserRole,
  updateCourseData,
  onRequestUpdate,
  btnLoading,
  getApprovalLevels,
  getApprovalLevelInfo,
  errorMessage,
}) => {
  const { convertDateISOToString } = useConvertDate();
  const { getFormattedDate } = useConvertId();
  const { applicantDetails } = useSelector(
    state => state.rmsAdminManageRequest
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isViewHistoryPopUp, setIsViewHistoryPopUp] = useState(false);
  const [isLastEditedBySuperAdmin, setIsLastEditedBySuperAdmin] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const resetFormData = () => {
    dispatch({ type: RESET_RMS_REQUEST_DETAILS_FORM_DATA });
    setIsLastEditedBySuperAdmin(false);
  };

  const handleClose = () => {
    setIsViewHistoryPopUp(false);
  };

  //check if SuperAdmin edited request based on lastEditedUserRole
  useEffect(() => {
    if (lastEditedUserRole.length) {
      lastEditedUserRole?.includes(privileges.RMS_DEFAULT_SUPER_ADMIN) &&
        setIsLastEditedBySuperAdmin(true);
    }
  }, [lastEditedUserRole]);

  useEffect(() => {
    if (state?.isEdit) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [state]);

  //clean up function
  useEffect(() => {
    return () => {
      resetFormData();
    };
  }, []);

  //reset formdata when routes change since this component is being re-used
  useEffect(() => {
    if (!match) {
      resetFormData();
    }
  }, [match]);

  if (!match) {
    return null;
  }

  return (
    <>
      <RmsPageWrapper>
        <RMSPageTitle
          titleText={isEditMode ? 'Edit Request' : 'Manage Requests'}
        />
        <RmsPageContentWrapper>
          <div
            className={`RMS__page--header noMargin ${
              isEditMode ? 'editRequest' : 'manageRequests'
            }`}
          >
            <div className="RMS__page--header-row">
              <div className="RMS__page--header-left">
                <p>{t('Request Details')}</p>
              </div>
              <div className="RMS__page--header-right">
                {isLastEditedBySuperAdmin && (
                  <p>
                    {t(`Last Updated on`)}{' '}
                    <span>
                      {convertDateISOToString(formData?.lastUpdatedAt) || '-'}
                    </span>{' '}
                    {t(`by `)} <span>Super Admin</span>
                  </p>
                )}
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setIsViewHistoryPopUp(true)}
                >
                  {t('View Approval History')}
                </button>
              </div>
              {isViewHistoryPopUp && (
                <ViewApprovalHistoryModal
                  onHandleClose={handleClose}
                  ApprovalLevels={ApprovalLevels}
                  setSelectedApprovalLevel={setSelectedApprovalLevel}
                  selectedApprovalLevel={selectedApprovalLevel}
                  approvalInfo={approvalInfo}
                  getApplicantNames={getApplicantNames}
                  approverNames={approverNames}
                  approvalHistoryLoading={approvalHistoryLoading}
                  requestDetailsById={formData}
                  getApprovalLevels={getApprovalLevels}
                  getApprovalLevelInfo={getApprovalLevelInfo}
                />
              )}
            </div>
          </div>
          <IdDetails data={formData} />
          <EmployeeDetails
            data={formData}
            applicantDetails={applicantDetails}
            designations={designations}
            centers={centers}
            qualifications={qualifications}
            departments={departments}
          />
          <CourseDetails
            data={formData}
            isEditMode={isEditMode}
            updateCourseData={updateCourseData}
          />
          <CourseFee
            data={formData}
            isEditMode={isEditMode}
            updateCourseData={updateCourseData}
          />
          <InstitutionDetails data={formData} />
          <Assessments data={formData} />
          <ObjectivesAndRemarks data={formData} />
          <BrochureAndCourseLink data={formData} />
        </RmsPageContentWrapper>
        <PageSubmit
          onSubmitClick={isEditMode ? onRequestUpdate : null}
          loading={btnLoading}
          errorMessage={errorMessage}
        />
        {loading && <Loader />}
      </RmsPageWrapper>
    </>
  );
};

export default ManageRequestForm;
