import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RmsPageContentWrapper = ({ children }) => {

  return (
    <>
      <div className="RMS__page--content">{children}</div>
    </>
  );
};

export default RmsPageContentWrapper;
