import rmsLandingData from '../reducers/landingReducer';
import rmsUserManagement from '../reducers/userManagementReducer';
import templateManagement from '../reducers/templateReducer';
import rmsAccessControl from '../reducers/accessControlReducer';
import rmsNormalRequest from '../reducers/normalRequestReducer';
import rmsGroupRequest from '../reducers/groupRequestReducer';
import rmsConvertId from '../reducers/convertIdReducer';
import rmsAdminManageRequest from './adminManageRequestReducer';
import rmsManageRequest from './manageRequestReducer';
import rmsManageBonds from './manageBondsReducer';
import rmsUserFeedBack from './userFeedbackReducer';
import rmsApplicantBonds from './applicantBondsReducer';
import rmsSuperAdminDashboard from './superAdminDashboardReducer';
import rmsRequestsReducer from './requestsReducer';
import commonData from '../reducers/commonReducer';
import rmsArchivalTrainingRequestData from './ArchivalTrainingRequestReducer';

const rmsReducers = {
  rmsLandingData,
  rmsUserManagement,
  templateManagement,
  rmsAccessControl,
  rmsNormalRequest,
  rmsGroupRequest,
  rmsConvertId,
  rmsAdminManageRequest,
  rmsManageRequest,
  rmsManageBonds,
  rmsUserFeedBack,
  rmsApplicantBonds,
  rmsSuperAdminDashboard,
  rmsRequestsReducer,
  commonData,
  rmsArchivalTrainingRequestData,
};

export default rmsReducers;
