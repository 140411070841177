import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import iconDelete from '../../assets/images/svg-images/icon-delete.svg';
import iconEdit from '../../assets/images/svg-images/icon-edit.svg';
import DeleteTemplateModal from '../Modal/DeleteTemplateModal';
import { TemplateTypes } from '../../Common/constants';
import GeneralErrorMsgModal from '../Modal/GeneralErrorMsgModal';
import { useDispatch } from 'react-redux';
import { CLOSE_DELETE_TEMPLATE_ERROR_MSG } from '../../redux/actionTypes/templateTypes';

const ManageTemplate = ({
  selectedTemplate,
  templateData,
  templateLoading,
  loadMoreData,
  isBondView = false,
  setIsBondView,
  bondTemplates,
  selectedBond,
  bondLoading,
  deleteTemplate,
  deleteTemplateLoading,
  deleteTemplateSuccess,
  isDeleteTemplateFail,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isTemplateDeleteModalOpen, setIsTemplateDeleteModalOpen] =
    useState(false);

  const [deleteTemplateData, setDeleteTemplateData] = useState();

  const [isDeleteTemplateConfirmed, setIsDeleteTemplateConfirmed] =
    useState(false);

  const templateDropDown = useMemo(() => {
    if (!isBondView) {
      return [
        {
          label: t('Normal Templates'),
          value: TemplateTypes.NORMAL_TEMPLATE,
        },
        {
          label: t('Group Templates'),
          value: TemplateTypes.GROUP_TEMPLATE,
        },
      ];
    } else {
      return (
        bondTemplates?.map(({ _id, name }) => ({
          label: name,
          value: _id,
        })) || []
      );
    }
  }, [isBondView, bondTemplates]);

  const [templateFilter, setTemplateFilter] = useState();

  useEffect(() => {
    templateDropDown?.length && setTemplateFilter(templateDropDown[0].value);
  }, [templateDropDown]);

  useEffect(() => {
    selectedTemplate(templateFilter);
  }, [templateFilter]);

  useEffect(() => {
    if (!deleteTemplateLoading && deleteTemplateSuccess) {
      setIsTemplateDeleteModalOpen(false);
      setDeleteTemplateData();
    }
  }, [deleteTemplateLoading, deleteTemplateSuccess]);

  useEffect(() => {
    isDeleteTemplateFail && setIsTemplateDeleteModalOpen(false);
  }, [isDeleteTemplateFail]);

  const handleCloseErrorMsg = () => {
    dispatch({ type: CLOSE_DELETE_TEMPLATE_ERROR_MSG });
    setIsTemplateDeleteModalOpen(false);
  };

  return (
    <div className="RMS__page manageTemplates">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>{t('Templates')}</h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <button
                    className={`btn ${
                      isBondView ? 'btn--secondary' : 'btn--orange'
                    } btn--marginRight`}
                    type="button"
                    onClick={() => setIsBondView(false)}
                  >
                    {t('Name/Group')}
                  </button>
                  <button
                    className={`btn ${
                      isBondView ? 'btn--orange' : 'btn--secondary'
                    }`}
                    type="button"
                    onClick={() => setIsBondView(true)}
                  >
                    {t('Bonds')}
                  </button>
                </div>
                <div className="RMS__page--header-right">
                  <Dropdown
                    disabled={!isBondView && templateLoading}
                    title="selectTemplate"
                    value={templateFilter}
                    options={templateDropDown}
                    onChange={({ value }) => {
                      setTemplateFilter(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--table mangTemps">
              <div className="RMS__page--table-header">
                <div className="RMS__page--table-column">
                  <h4>{t('Name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Action')}</h4>
                </div>
              </div>
              {isBondView ? (
                <div className="RMS__page--table-body">
                  {bondLoading ? (
                    <Loader />
                  ) : selectedBond ? (
                    <div className="RMS__page--table-row">
                      <div className="RMS__page--table-column">
                        <p>{selectedBond?.name}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <img
                          src={iconEdit}
                          alt="edit-data"
                          onClick={() => {
                            isBondView &&
                              history.push(`/rms/edit-bond-template`, {
                                selectedTemplate: templateFilter,
                              });
                          }}
                        />
                        <img
                          src={iconDelete}
                          alt="delete-data"
                          onClick={() => {
                            setDeleteTemplateData(selectedBond);
                            setIsTemplateDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoContent />
                  )}
                </div>
              ) : (
                <div className="RMS__page--table-body">
                  {!templateData?.length && templateLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {templateData?.length ? (
                        templateData.map(data => {
                          return (
                            <div
                              className="RMS__page--table-row"
                              key={data._id}
                            >
                              <div className="RMS__page--table-column">
                                <p>{data?.name}</p>
                              </div>
                              <div className="RMS__page--table-column">
                                <img
                                  src={iconEdit}
                                  alt="edit-data"
                                  onClick={() =>
                                    TemplateTypes.NORMAL_TEMPLATE ===
                                    templateFilter
                                      ? history.push({
                                          pathname: `/rms/create-template`,
                                          state: { templateData: data },
                                        })
                                      : history.push({
                                          pathname: `/rms/edit-group-template`,
                                          state: { templateData: data },
                                        })
                                  }
                                />
                                <img
                                  src={iconDelete}
                                  alt="delete-data"
                                  onClick={() => {
                                    setDeleteTemplateData(data);
                                    setIsTemplateDeleteModalOpen(true);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <NoContent />
                      )}
                      <ReactVisibilitySensor
                        partialVisibility
                        onChange={isVisible => {
                          if (isVisible) {
                            loadMoreData();
                          }
                        }}
                      >
                        <div>&nbsp;</div>
                      </ReactVisibilitySensor>
                    </>
                  )}
                </div>
              )}
              {isTemplateDeleteModalOpen && (
                <DeleteTemplateModal
                  onHandleClose={() => {
                    setIsTemplateDeleteModalOpen(false);
                    setDeleteTemplateData();
                  }}
                  deleteTemplateData={deleteTemplateData}
                  deleteTemplate={templateData => {
                    deleteTemplate(templateData);
                  }}
                  deleteTemplateLoading={deleteTemplateLoading}
                />
              )}
            </div>
            <div className="RMS__page--submit">
              {isBondView && !selectedBond ? (
                <button
                  className="btn btn--add"
                  onClick={() =>
                    history.push({
                      pathname: `/rms/create-bond-template`,
                      state: { templateId: templateFilter },
                    })
                  }
                >
                  <img src={iconAddButton} alt="add-data" />
                </button>
              ) : null}
              {!isBondView && (
                <button
                  className="btn btn--add"
                  onClick={() => {
                    history.push({
                      pathname:
                        TemplateTypes.NORMAL_TEMPLATE === templateFilter
                          ? `/rms/create-template`
                          : `/rms/create-group-template`,
                    });
                  }}
                >
                  <img src={iconAddButton} alt="add-data" />
                </button>
              )}
            </div>
            {isDeleteTemplateFail && (
              <GeneralErrorMsgModal handleSubmit={handleCloseErrorMsg} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTemplate;
