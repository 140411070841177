import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import { bondsFilterByOptions, DurationUnits } from '../../Common/constants';
import { capitalizeFirstLetter, convertDuration } from '../../Common/helper';
import DeleteModalCommon from '../../Components/Modal/DeleteModalCommon';
import useConvertDate from '../../hooks/useConvertDate';
import LoadingAnimation from '../CommonComponents/ButtonAnimation/LoadingAnimation';
import EditAndDeleteIconBtn from '../ManageRequets/EditAndDeleteIconBtn';

const TableGrid = ({
  setshowAddApplicantPopup,
  bondRequests,
  deleteBond,
  deleteBondLoading,
  deleteBondSuccess,
  loadNextPage = () => {},
  loading,
  bondActiveButtonLoading,
  bondActiveErrorMsg,
  activateBond,
  editBondSingleRow,
  editBondSubmitError,
  editBondSubmitLoading,
  editBondSubmitErrorMessage,
  isAddButtonView,
}) => {
  const initialRowObject = {
    _id: '',
    bondDurationInDays: '',
    startDate: '',
    endDate: '',
  };
  const { t } = useTranslation();
  const [isEditRow, setIsEditRow] = useState(false);
  const [editRowData, setEditRowData] = useState(initialRowObject);

  const { convertDateISOToString } = useConvertDate();

  const resetSingleRow = () => {
    setIsEditRow(false);
    setEditRowData(initialRowObject);
  };

  const setSingleEditRowData = (date, months) => {
    var result = new Date(date);
    var durationInDays = getBondDurationInDays(months);
    result.setDate(result.getDate() + (parseInt(durationInDays) || 0));
    setEditRowData({
      ...editRowData,
      endDate: result ? moment(result).format() : '',
      startDate: date ? moment(date).format() : '',
      bondDurationInDays: getBondDurationInDays(parseInt(months) || 0),
    });
  };

  const isCurrentRow = id => {
    return isEditRow && editRowData?._id === id;
  };

  useEffect(() => {
    resetSingleRow();
  }, [bondRequests]);
  const [isDeleteBondModal, setIsDeleteBondModal] = useState(false);
  const [viewRequestId, setViewRequestId] = useState();
  const [bondId, setBondId] = useState();
  const [activateBondId, setActivateBondId] = useState();

  useEffect(() => {
    !deleteBondLoading && deleteBondSuccess && setIsDeleteBondModal(false);
  }, [deleteBondLoading, deleteBondSuccess]);

  const onDeleteBond = (bondId, requestId) => {
    setIsDeleteBondModal(true);
    setViewRequestId(requestId);
    setBondId(bondId);
  };

  const getBondDurationInMonths = durationInDays => {
    return convertDuration(
      durationInDays,
      DurationUnits.DAYS,
      DurationUnits.MONTHS
    );
  };
  const getBondDurationInDays = durationInMonths => {
    return convertDuration(
      durationInMonths,
      DurationUnits.MONTHS,
      DurationUnits.DAYS
    );
  };

  return (
    <>
      <div className="RMS__page--body">
        <div className="RMS__page--table">
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Name')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Employee ID')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Email')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Department Id')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('User Designation')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Gross Fee')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Net Fee')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Duration In Months')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Start Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('End Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Status')}</h4>
            </div>
            <div className="RMS__page--table-column"></div>
            <div className="RMS__page--table-column"></div>
          </div>
          <div className="RMS__page--table-body">
            {loading ? (
              <Loader />
            ) : bondRequests?.length ? (
              bondRequests?.map(
                ({
                  customRequestId,
                  applicantName,
                  employeeId,
                  applicantEmail,
                  departmentName,
                  designationName,
                  grossFee,
                  netFee,
                  bondDurationInDays,
                  startDate,
                  endDate,
                  bondStatus,
                  _id,
                }) => (
                  <div key={_id} className="RMS__page--table-row ">
                    <div className="RMS__page--table-column">
                      <p>{customRequestId || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{applicantName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{employeeId || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{applicantEmail || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{departmentName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{designationName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{grossFee?.toString() || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{netFee?.toString() || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      {!isCurrentRow(_id) ? (
                        <p>
                          {getBondDurationInMonths(bondDurationInDays) || '-'}
                        </p>
                      ) : (
                        <input
                          disabled={!isCurrentRow(_id)}
                          type="text"
                          placeholder={t('N/A')}
                          value={
                            isCurrentRow(_id)
                              ? getBondDurationInMonths(
                                  editRowData?.bondDurationInDays
                                )
                              : getBondDurationInMonths(bondDurationInDays) ||
                                '-'
                          }
                          onChange={e => {
                            if (isCurrentRow(_id)) {
                              setSingleEditRowData(
                                editRowData?.startDate?.toString(),
                                e.target.value
                              );
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="RMS__page--table-column">
                      {!isCurrentRow(_id) ? (
                        <p>{convertDateISOToString(startDate) || '-'}</p>
                      ) : (
                        <DatePicker
                          selected={
                            new Date(
                              isCurrentRow(_id)
                                ? editRowData?.startDate
                                : startDate
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={date => {
                            if (isCurrentRow(_id)) {
                              setSingleEditRowData(
                                date.toISOString(),
                                editRowData?.bondDurationInDays
                              );
                            }
                          }}
                          showMonthDropdown
                        />
                      )}
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {convertDateISOToString(
                          isCurrentRow(_id) ? editRowData?.endDate : endDate
                        ) || '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{capitalizeFirstLetter(bondStatus) || '-'}</p>
                    </div>
                    {bondStatus === bondsFilterByOptions.INACTIVE &&
                      (bondActiveButtonLoading && activateBondId === _id ? (
                        <LoadingAnimation />
                      ) : (
                        <div className="RMS__page--table-column">
                          <button
                            className="btn btn--secondary btn--thinButton btn--tableButton"
                            disabled={
                              isEditRow || bondActiveButtonLoading || !startDate
                            }
                            onClick={() => {
                              activateBond(_id);
                              setActivateBondId(_id);
                            }}
                          >
                            {t('Activate')}
                          </button>
                        </div>
                      ))}
                    <div className="RMS__page--table-column">
                      {!isEditRow ? (
                        <EditAndDeleteIconBtn
                          deleteHandler={() => {
                            onDeleteBond(_id, customRequestId);
                          }}
                          isHideEdit={
                            bondStatus !== bondsFilterByOptions.INACTIVE
                          }
                          editHandler={() => {
                            setEditRowData({
                              _id: _id,
                              bondDurationInDays: bondDurationInDays,
                              startDate: startDate,
                              endDate: endDate,
                            });
                            setIsEditRow(true);
                          }}
                        />
                      ) : (
                        <>
                          {isCurrentRow(_id) ? (
                            <>
                              {editBondSubmitLoading ? (
                                <LoadingAnimation />
                              ) : (
                                <button
                                  className={`btn btn--primary btn--thinButton btn--whiteText btn--smallButton`}
                                  onClick={() => {
                                    editBondSingleRow(editRowData);
                                  }}
                                  disabled={!editRowData?.startDate}
                                >
                                  {t('Save')}
                                </button>
                              )}
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                )
              )
            ) : (
              <NoContent message={t('Bond Requests not available')} />
            )}
            <ReactVisibilitySensor
              partialVisibility
              onChange={isVisible => {
                if (isVisible) {
                  loadNextPage();
                }
              }}
            >
              <div>&nbsp;</div>
            </ReactVisibilitySensor>
          </div>
        </div>

        {isDeleteBondModal && (
          <DeleteModalCommon
            onClose={() => setIsDeleteBondModal(false)}
            onCancelClick={() => setIsDeleteBondModal(false)}
            onOkClick={() => deleteBond(bondId)}
            messageFirstPart={t('Are you sure you want to delete the bond for')}
            highlightedText={viewRequestId}
            title={t('Delete template')}
            loading={deleteBondLoading}
            okButtonText={'Yes'}
            cancelButtonText={'No'}
          />
        )}
      </div>
      <div className="RMS__page--submit">
        <div className="RMS__page--error">
          <div className="RMS__page--error-content">
            {bondActiveErrorMsg &&
              t('The activation did not succeed, Please try again.')}
            {editBondSubmitError && t(editBondSubmitErrorMessage)}
          </div>
        </div>
        {isAddButtonView && (
          <button
            className="btn btn--add"
            onClick={() => {
              resetSingleRow();
              setshowAddApplicantPopup(true);
            }}
          >
            <img src={iconAddButton} alt="add-data" />
          </button>
        )}
      </div>
    </>
  );
};

export default TableGrid;
