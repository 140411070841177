import moment from 'moment';
import { v4 } from 'uuid';
import { DurationUnits } from './constants';

export const avoidedDuplicationData = (currentData, newData, key) => {
  if (!newData) {
    return currentData;
  }

  const existingValues = currentData?.map(item => item[key]);

  const filteredData = newData.filter(
    item => !existingValues?.includes(item[key])
  );

  return [...(currentData || []), ...filteredData];
};

export const getArrayIndexUsingKey = (list, key, value) => {
  return list.findIndex(element => element[key] === value);
};

export const sortingDropDownOptionList = array => {
  return array.sort((a, b) => a.label.localeCompare(b.label));
};
export const uuidv4 = () => {
  return v4();
};

export const generateExistingApprovalBracketsArray = (
  approveBracketsExistingData,
  templateAdmin
) => {
  const mainLevelData = approveBracketsExistingData.map(data => {
    return {
      ...data,
      mainKey: uuidv4(),
    };
  });

  const generatedData = mainLevelData?.map(data => {
    const arrangedApprovals = data?.approvalLevels?.map(data => {
      const arrangedAppoveIds = data?.approverIds?.map(data => {
        const index = getArrayIndexUsingKey(templateAdmin, '_id', data);
        return {
          value: data,
          label: templateAdmin[index]?.name,
        };
      });
      const arrangedNotifyData = data?.notifierIds?.map(data => {
        const index = getArrayIndexUsingKey(templateAdmin, '_id', data);
        return {
          value: data,
          label: templateAdmin[index]?.name,
        };
      });
      return {
        ...data,
        adminDropDownArrays: arrangedAppoveIds,
        approvalKey: uuidv4(),
        approverIds: arrangedAppoveIds,
        notifierIds: arrangedNotifyData,
        notifyDropDownArray: arrangedNotifyData,
      };
    });
    return {
      ...data,
      approvalLevels: arrangedApprovals,
    };
  });

  return generatedData;
};

export const setErrorMessage = message => {
  switch (message) {
    case 'DUPLICATE_TEMPLATE_NAME':
    case 'DUPLICATE_BOND_TEMPLATE_NAME':
      return 'Template name is already exist.';
    case 'INCORRECT_GROSS_PRICE_BRACKETS_AMOUNTS':
      return 'Incorrect gross brackets values.';
    case 'USER_EXISTS_IN_ANOTHER_TEMPLATE':
      return 'User already exist in another template!';
    case 'DEFAULT_SUPER_ADMIN_CANNOT_BE_REMOVED':
      return 'Cannot delete default super admin.';
    case 'OVERLAPPED_BOND_DATES':
      return 'Overlapped start & end dates please check.';
    default:
      return 'Failed try again!';
  }
};

export const capitalizeFirstLetter = string => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatText = str => {
  if (!str) {
    return '';
  }
  return str.split('_').map(capitalizeFirstLetter).join(' ').trim();
};

export const getFormattedDate = dateStr => {
  if (moment(dateStr).isValid()) {
    return moment(dateStr).format('Do MMM YYYY');
  } else {
    return '';
  }
};

export const getFormattedTrainingType = (requestType, courseType) => {
  if (!requestType && !courseType) return '';

  const request = capitalizeFirstLetter(requestType)?.split('_')?.[0];
  const course = capitalizeFirstLetter(courseType)?.split('_')?.[0];

  return `${request} - ${course}`;
};

export const convertDuration = (
  duration,
  currentDurationUnit,
  requiredDurationUnit
) => {
  const durationInt = parseInt(duration);

  let hoursForADay = 8;
  let daysForAWeek = 5;
  let daysForAMonth = 30;

  let durationInHours = durationInt;
  let convertedDuration = durationInt;

  switch (currentDurationUnit) {
    case DurationUnits.DAYS:
      durationInHours = durationInt * hoursForADay;
      break;
    case DurationUnits.MONTHS:
      durationInHours = durationInt * hoursForADay * daysForAMonth;
      break;
    case DurationUnits.WEEKS:
      durationInHours = durationInt * hoursForADay * daysForAWeek;
      break;
    default:
      break;
  }

  switch (requiredDurationUnit) {
    case DurationUnits.DAYS:
      convertedDuration = durationInHours / hoursForADay;
      break;
    case DurationUnits.MONTHS:
      convertedDuration = durationInHours / hoursForADay / daysForAMonth;
      break;
    case DurationUnits.WEEKS:
      convertedDuration = durationInHours / hoursForADay / daysForAWeek;
      break;
    case DurationUnits.HOURS:
      convertedDuration = durationInHours;
      break;
    default:
      break;
  }

  return convertedDuration?.toFixed(0);
};
