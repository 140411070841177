import { RMS_GET_CLOUD_STORAGE_DATA_SUCCESS } from '../actionTypes/commonTypes';

const INITIAL_STATE = {
  s3BucketData: null,
};
const commonReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_GET_CLOUD_STORAGE_DATA_SUCCESS:
      return {
        ...state,
        s3BucketData: action?.payload,
      };
    default:
      return state;
  }
};
export default commonReducer;
