import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import iconAddButton from '../../assets/images/svg-images/orange-exclamation.svg';
import { addUserDropDown } from '../../Common/constants';

const GroupAddUserModal = ({
  templateId,
  onHandleClose,
  userData,
  fetchFilterSearch,
  userLoading,
  setSearchLoading,
  triggerAddUserAction,
  selectedUsers,
  saveSelectedUsers,
  budgetsLoading = false,
  hasSufficientBudget,
}) => {
  const filterDropDown = [
    {
      label: 'Name',
      value: addUserDropDown.searchByName,
    },
    {
      label: 'Email',
      value: addUserDropDown.searchByEmail,
    },
  ];

  const { t } = useTranslation();
  const [searchByFilter, setSearchByFilter] = useState(filterDropDown[0].value);
  const [searchKeyValue, setSearchKeyValue] = useState('');

  let searchTimeout;

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchLoading();
    searchTimeout = setTimeout(() => {
      fetchFilterSearch(searchKeyValue);
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyValue]);

  return (
    <div className="reel-view--popup RMS__popUp addUser">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Add Applicants')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onHandleClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <input
              type="text"
              placeholder={t('Type here...')}
              onChange={e => {
                setSearchKeyValue(e.target.value);
              }}
              value={searchKeyValue}
            />
            <Dropdown
              title="selectFilter"
              value={
                filterDropDown[
                  searchByFilter === addUserDropDown.searchByName ? 0 : 1
                ]
              }
              options={filterDropDown.map(({ label, value }) => ({
                label: t(label),
                value,
              }))}
              onChange={({ value }) => {
                setSearchByFilter(value);
                setSearchKeyValue('');
              }}
            />
          </div>

          <div className="reel-view--popup-row">
            {userLoading ? (
              <Loader />
            ) : userData?.length ? (
              <>
                {userData?.map((data, index) => {
                  const hasEnoughBudget = hasSufficientBudget(data?._id);
                  return (
                    <div
                      className={`reel-view--popup-innerRow selected ${
                        !hasEnoughBudget ? 'noBudget' : ''
                      }`}
                      key={data?._id}
                    >
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="form-input--checkbox"
                          id={`user-checked${index}`}
                          name={`user-checked${index}`}
                          checked={selectedUsers?.includes(data._id)}
                          onChange={() => {
                            hasEnoughBudget && triggerAddUserAction(data?._id);
                          }}
                        />

                        <label htmlFor={`user-checked${index}`}>
                          {searchByFilter === addUserDropDown.searchByEmail
                            ? data?.email
                            : data?.name}
                        </label>
                      </div>
                      {!hasEnoughBudget && (
                        <div className="noBudget--wrapper">
                          <p>{t('insufficient budget')}</p>
                          <img src={iconAddButton} alt="no budget" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <NoContent message={t('No Search Result Available')} />
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
             btn btn--primary
             
           `}
              onClick={() =>
                selectedUsers
                  ? saveSelectedUsers() && onHandleClose()
                  : onHandleClose()
              }
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupAddUserModal;
