export const RMS_FETCH_ALL_FINANCIAL_DURATION_REQUEST =
  'RMS_FETCH_ALL_FINANCIAL_DURATION_REQUEST';
export const RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS =
  'RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS';
export const RMS_FETCH_ALL_FINANCIAL_DURATION_FAILED =
  'RMS_FETCH_ALL_FINANCIAL_DURATION_FAILED';
export const RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST =
  'RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST';
export const RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS =
  'RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS';
export const RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED =
  'RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED';
export const RMS_FETCH_BUDGET_STATISTICS_REQUEST =
  'RMS_FETCH_BUDGET_STATISTICS_REQUEST';
export const RMS_FETCH_BUDGET_STATISTICS_SUCCESS =
  'RMS_FETCH_BUDGET_STATISTICS_SUCCESS';
export const RMS_FETCH_BUDGET_STATISTICS_FAILED =
  'RMS_FETCH_BUDGET_STATISTICS_FAILED';
export const RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST =
  'RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST';
export const RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS =
  'RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS';
export const RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED =
  'RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED';

export const RMS_GET_USER_DETAILS_DASHBOARD_REQUEST =
  'RMS_GET_USER_DETAILS_DASHBOARD_REQUEST';
export const RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS =
  'RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS';
export const RMS_GET_USER_DETAILS_DASHBOARD_FAIL =
  'RMS_GET_USER_DETAILS_DASHBOARD_FAIL';

export const RMS_RESET_REPORT_DASHBOARD_DETAILS =
  'RMS_RESET_REPORT_DASHBOARD_DETAILS';

export const RMS_NORMAL_TEMPLATE_DETAILS_REQUEST =
  'RMS_NORMAL_TEMPLATE_DETAILS_REQUEST';
export const RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS =
  'RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS';
export const RMS_NORMAL_TEMPLATE_DETAILS_FAIL =
  'RMS_NORMAL_TEMPLATE_DETAILS_FAIL';

export const RMS_GROUP_TEMPLATE_DETAILS_REQUEST =
  'RMS_GROUP_TEMPLATE_DETAILS_REQUEST';
export const RMS_GROUP_TEMPLATE_DETAILS_SUCCESS =
  'RMS_GROUP_TEMPLATE_DETAILS_SUCCESS';
export const RMS_GROUP_TEMPLATE_DETAILS_FAIL =
  'RMS_GROUP_TEMPLATE_DETAILS_FAIL';

export const RMS_QUALIFICATION_DETAILS_REQUEST =
  'RMS_QUALIFICATION_DETAILS_REQUEST';
export const RMS_QUALIFICATION_DETAILS_SUCCESS =
  'RMS_QUALIFICATION_DETAILS_SUCCESS';
export const RMS_QUALIFICATION_DETAILS_FAIL = 'RMS_QUALIFICATION_DETAILS_FAIL';

export const RMS_DESIGNATION_DETAILS_REQUEST =
  'RMS_DESIGNATION_DETAILS_REQUEST';
export const RMS_DESIGNATION_DETAILS_SUCCESS =
  'RMS_DESIGNATION_DETAILS_SUCCESS';
export const RMS_DESIGNATION_DETAILS_FAIL = 'RMS_DESIGNATION_DETAILS_FAIL';

export const RMS_CENTER_DETAILS_REQUEST = 'RMS_CENTER_DETAILS_REQUEST';
export const RMS_CENTER_DETAILS_SUCCESS = 'RMS_CENTER_DETAILS_SUCCESS';
export const RMS_CENTER_DETAILS_FAIL = 'RMS_CENTER_DETAILS_FAIL';

export const RMS_DEPARTMENT_DETAILS_REQUEST = 'RMS_DEPARTMENT_DETAILS_REQUEST';
export const RMS_DEPARTMENT_DETAILS_SUCCESS = 'RMS_DEPARTMENT_DETAILS_SUCCESS';
export const RMS_DEPARTMENT_DETAILS_FAIL = 'RMS_DEPARTMENT_DETAILS_FAIL';

export const RMS_FETCH_USER_STATS_REQUEST = 'RMS_FETCH_USER_STATS_REQUEST';
export const RMS_FETCH_USER_STATS_SUCCESS = 'RMS_FETCH_USER_STATS_SUCCESS';
export const RMS_FETCH_USER_STATS_FAILED = 'RMS_FETCH_USER_STATS_FAILED';

export const RMS_FETCH_DASHBOARD_BUDGET_DETAILS_REQUEST =
  'RMS_FETCH_DASHBOARD_BUDGET_DETAILS_REQUEST';
export const RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS =
  'RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS';
export const RMS_FETCH_DASHBOARD_BUDGET_DETAILS_FAILED =
  'RMS_FETCH_DASHBOARD_BUDGET_DETAILS_FAILED';
export const RESET_TRAINING_REQUEST_STATS = 'RESET_TRAINING_REQUEST_STATS';

export const RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST =
  'RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST';
export const RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS =
  'RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS';
export const RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL =
  'RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL';

export const RMS_SET_SELECTED_DATE_RANGE_ID = 'RMS_SET_SELECTED_DATE_RANGE_ID';

export const RMS_FETCH_DESIGNATIONS_REQUEST = 'RMS_FETCH_DESIGNATIONS_REQUEST';
export const RMS_FETCH_DESIGNATIONS_SUCCESS = 'RMS_FETCH_DESIGNATIONS_SUCCESS';
export const RMS_FETCH_DESIGNATIONS_FAILED = 'RMS_FETCH_DESIGNATIONS_FAILED';

export const RMS_FETCH_S3_FILE_REQUEST = 'RMS_FETCH_S3_FILE_REQUEST';
export const RMS_FETCH_S3_FILE_SUCCESS = 'RMS_FETCH_S3_FILE_SUCCESS';
export const RMS_FETCH_S3_FILE_FAILED = 'RMS_FETCH_S3_FILE_FAILED';
export const RMS_DASHBOARD_RESET_USER_STATS = 'RMS_DASHBOARD_RESET_USER_STATS';

export const RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST =
  'RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST';
export const RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS =
  'RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS';
export const RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED =
  'RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED';
export const RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST =
  'RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST';
export const RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS =
  'RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS';
export const RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL =
  'RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL';

export const RMS_FETCH_ACTIVE_FINANCIAL_REQUEST =
  'RMS_FETCH_ACTIVE_FINANCIAL_REQUEST';
export const RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS =
  'RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS';
export const RMS_FETCH_ACTIVE_FINANCIAL_FAILED =
  'RMS_FETCH_ACTIVE_FINANCIAL_FAILED';

export const RESET_FEEDBACK_S3_FILE_OBJECT = 'RESET_FEEDBACK_S3_FILE_OBJECT';

export const RESET_USER_DETAILS_S3BUCKET_DATA =
  'RESET_USER_DETAILS_S3BUCKET_DATA';

export const RMS_COMPANY_DETAILS_REQUEST = 'RMS_COMPANY_DETAILS_REQUEST';
export const RMS_COMPANY_DETAILS_SUCCESS = 'RMS_COMPANY_DETAILS_SUCCESS';
export const RMS_COMPANY_DETAILS_FAILED = 'RMS_COMPANY_DETAILS_FAILED';
