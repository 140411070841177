import Auth from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../Hooks/useApi';
import { Services } from '../Common/constants';
import {
  RESET_RMS_LANDING,
  RMS_LANDING_FAIL,
  RMS_LANDING_REQUEST,
  RMS_LANDING_SUCCESS,
} from '../redux/actionTypes/landing';

export default () => {
  const [getUserDetails] = useApi();
  const dispatch = useDispatch();
  const { landingData } = useSelector(state => state.rmsLandingData);

  const fetchUserDetails = email => {
    getUserDetails(
      `/api/v1/users?emailList=${email}`,
      RMS_LANDING_REQUEST,
      RMS_LANDING_SUCCESS,
      RMS_LANDING_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(data => {
      fetchUserDetails(data?.attributes?.email);
    });
    return () => {
      dispatch({ type: RESET_RMS_LANDING });
    };
  }, []);

  return landingData;
};
