import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import NoContent from '../../../Components/NoContent/NoContent';
import btn1 from '../../assets/images/svg-images/btn1.svg';
import btn2 from '../../assets/images/svg-images/btn2.svg';
import btn3 from '../../assets/images/svg-images/btn3.svg';
import btn4 from '../../assets/images/svg-images/btn4.svg';
import btn5 from '../../assets/images/svg-images/btn5.svg';
import btn6 from '../../assets/images/svg-images/btn6.svg';
import btn7 from '../../assets/images/svg-images/btn7.svg';
import orangeArrow from '../../assets/images/svg-images/orange-dropdown.svg';
import { privileges } from '../../Common/constants';
import SelectTemplatePopupContainer from '../../Container/TrainingFormContainer/SelectTemplatePopupContainer';

const LandingComponent = ({
  landingData,
  isNormalTemplate,
  isGroupTemplate,
  activeFinancialDuration,
  isRmsHealthStatusSuccess,
}) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { t } = useTranslation();
  const [isAllPrivilege, setIsAllPrivilege] = useState(false);
  const [isAdminAndApplicantPrivilege, setIsAdminAndApplicantPrivilege] =
    useState(false);
  const [isNoContentMessage, setNoContentMessage] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDashboardDropdown, setOpenDashboardDropdown] = useState(false);
  const [showNormalRequest, setShowNormalRequest] = useState(false);
  const [showGroupRequest, setShowGroupRequest] = useState(false);
  const [showSelectTemplatePopup, setShowSelectTemplatePopup] = useState(false);
  const [isApplicantPrivilege, setIsApplicantPrivilege] = useState(false);

  useEffect(() => {
    if (landingData?.roles?.length) {
      landingData?.roles?.includes(privileges.RMS_DEFAULT_SUPER_ADMIN) &&
        setIsAllPrivilege(true);

      if (
        (landingData.roles.includes(privileges.RMS_DEFAULT_SUPER_ADMIN) ||
          landingData.roles.includes(privileges.RMS_ADMIN)) &&
        isGroupTemplate
      ) {
        setShowGroupRequest(true);
      }
      if (
        landingData.roles.includes(privileges.RMS_DEFAULT_SUPER_ADMIN) ||
        (landingData.roles.includes(privileges.RMS_APPLICANT) &&
          isNormalTemplate)
      ) {
        setShowNormalRequest(true);
      }
      if (
        landingData.roles.includes(privileges.RMS_APPLICANT) &&
        landingData.roles.includes(privileges.RMS_ADMIN)
      ) {
        setIsAdminAndApplicantPrivilege(true);
      }
      if (landingData.roles.includes(privileges.RMS_APPLICANT)) {
        setIsApplicantPrivilege(true);
      }
    } else {
      setNoContentMessage(true);
    }
  }, [landingData, isNormalTemplate, isGroupTemplate]);

  const onGroupRequestSelect = () => {
    setShowSelectTemplatePopup(true);
  };

  const gotoGroupRequest = () => {
    history.push(`${path}/group-request`);
  };

  return (
    <div className="RMS__landing">
      <div className="container">
        {isNoContentMessage || !isRmsHealthStatusSuccess ? (
          <NoContent
            message={
              !isRmsHealthStatusSuccess
                ? t('RMS system is down, please contact support!')
                : t("You don't have access to the page. Please contact HR.")
            }
          />
        ) : (
          <div className="RMS__landing--wrapper">
            <div className="RMS__landing--body">
              <div className="RMS__landing--body-title">
                <h1>{t('Requests & Bonds')}</h1>
              </div>
              <div className="RMS__landing--body-container">
                {isAllPrivilege ? (
                  <div className="RMS__landing--body-container part1">
                    {' '}
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/manage-template`);
                      }}
                    >
                      <img src={btn1} alt="" />{' '}
                      <span>{t('Manage Template')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/admin-manage-requests`);
                      }}
                    >
                      <img src={btn2} alt="" />{' '}
                      <span>{t('Manage Requests')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/manage-bonds`);
                      }}
                    >
                      <img src={btn3} alt="" /> <span>{t('Manage Bonds')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/super-admin-dashboard`);
                      }}
                    >
                      <img src={btn4} alt="" />{' '}
                      <span>{t('Reporting Dashboards')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/user-management`);
                      }}
                    >
                      <img src={btn5} alt="" />{' '}
                      <span>{t('Applicant Management')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/access-control`);
                      }}
                    >
                      <img src={btn6} alt="" />{' '}
                      <span>{t('Access Control')}</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`RMS__landing--body-container part2${
                      !showNormalRequest && !showGroupRequest ? '--v2' : ''
                    }`}
                  >
                    {(showNormalRequest || showGroupRequest) && (
                      <div className={`btn ${openDropdown ? 'open' : ''}`}>
                        <img src={btn7} alt="" />
                        <span>{t('Submit Requests')}</span>
                        <div className="dropdown-selection">
                          {showNormalRequest && (
                            <p
                              onClick={() =>
                                history.push(`${path}/normal-request`)
                              }
                            >
                              {t('Normal Request')}
                            </p>
                          )}
                          {showGroupRequest && (
                            <p onClick={onGroupRequestSelect}>
                              {t('Group Request')}
                            </p>
                          )}
                        </div>
                        <img
                          src={orangeArrow}
                          alt=""
                          onClick={() => setOpenDropdown(!openDropdown)}
                        />
                      </div>
                    )}
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/manage-request`);
                      }}
                    >
                      <img src={btn2} alt="" />
                      <span>{t('Manage Requests')}</span>
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        history.push(`${path}/applicant-bond`);
                      }}
                    >
                      <img src={btn3} alt="" />
                      <span>{t('Bonds')}</span>
                    </div>

                    <div
                      className={`btn ${openDashboardDropdown ? 'open' : ''}`}
                      onClick={() => {
                        if (
                          !isAdminAndApplicantPrivilege &&
                          !isApplicantPrivilege
                        ) {
                          history.push(`${path}/admin-dashboard`);
                        }
                        if (
                          !isAdminAndApplicantPrivilege &&
                          isApplicantPrivilege
                        ) {
                          history.push({
                            pathname: '/rms/applicant-dashboard/reporting',
                            state: {
                              email: landingData?.email,
                              isApplicantDashboard: true,
                              isSuperAdminDashboard: false,
                              userId: landingData?._id,
                              selectedDateRangeId:
                                activeFinancialDuration?.[0]?._id,
                            },
                          });
                        }
                      }}
                    >
                      <img src={btn4} alt="" />
                      <span>{t('Dashboards')}</span>
                      <div className="dropdown-selection">
                        {isAdminAndApplicantPrivilege && (
                          <div>
                            <p
                              onClick={() =>
                                history.push({
                                  pathname:
                                    '/rms/applicant-dashboard/reporting',
                                  state: {
                                    email: landingData?.email,
                                    isApplicantDashboard: true,
                                    isSuperAdminDashboard: false,
                                    userId: landingData?._id,
                                    selectedDateRangeId:
                                      activeFinancialDuration?.[0]?._id,
                                  },
                                })
                              }
                            >
                              {t('Applicant')}
                            </p>
                            <p
                              onClick={() => {
                                history.push(`${path}/admin-dashboard`);
                              }}
                            >
                              {t('Admin')}
                            </p>
                          </div>
                        )}
                      </div>
                      {isAdminAndApplicantPrivilege && (
                        <img
                          src={orangeArrow}
                          alt=""
                          onClick={() =>
                            setOpenDashboardDropdown(!openDashboardDropdown)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {showSelectTemplatePopup && (
          <SelectTemplatePopupContainer
            closePopup={() => setShowSelectTemplatePopup(false)}
            gotoGroupRequest={gotoGroupRequest}
          />
        )}
      </div>
    </div>
  );
};
export default LandingComponent;
