import React from 'react';
import { useTranslation } from 'react-i18next';

const WarningModal = ({ onClose, onOkClick, userName, title, message }) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{title}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <p>
              {userName && <span>{userName} </span>}&nbsp;
              {message}
            </p>
          </div>
          <div className="reel-view--popup-row">
            <button className="btn btn--primary" onClick={onOkClick}>
              {t('Ok')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
