import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RMSPageTitle from '../../Components/CommonComponents/RMSPageTitle';
import ApplicantBondTable from '../../Components/ApplicantBondComponent/ApplicantBondTable';
import {
  ApplicantBondSearchMode,
  bondsFilterByOptions,
  Services,
} from '../../Common/constants';
import useDebounce from '../../hooks/useDebounce';
import useApi from '../../../Hooks/useApi';
import useFetchLoggedInUserDetails from '../../hooks/useFetchLoggedInUserDetails';
import {
  RMS_GET_APPLICANT_BONDS_FAILED,
  RMS_GET_APPLICANT_BONDS_REQUEST,
  RMS_GET_APPLICANT_BONDS_SUCCESS,
  RMS_RESET_APPLICANT_BONDS,
} from '../../redux/actionTypes/applicantBondsTypes';

const ApplicantBondContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getBondsApi] = useApi();

  const { bonds, bondsLoading, bondsPage } = useSelector(
    state => state.rmsApplicantBonds
  );
  const [selectedFilterDropdown, setSelectedFilterDropdown] = useState(
    bondsFilterByOptions.ACTIVE
  );
  const [searchText, setSearchText] = useState('');
  const [searchMode, setSearchMode] = useState(
    ApplicantBondSearchMode.REQUEST_ID
  );
  const applicantDetails = useFetchLoggedInUserDetails();

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    applicantDetails && getBonds(1);
  }, [applicantDetails]);

  const getSearchParameter = () => {
    if (!debouncedSearchText) return '';

    switch (searchMode) {
      case ApplicantBondSearchMode.REQUEST_ID:
        return `&requestIdLike=${debouncedSearchText}`;
      case ApplicantBondSearchMode.COURSE_NAME:
        return `&courseNameLike=${debouncedSearchText}`;
      default:
        return '';
    }
  };

  const getBonds = (page = 1) => {
    if (!applicantDetails || !page) return;

    page === 1 &&
      dispatch({
        type: RMS_RESET_APPLICANT_BONDS,
      });

    getBondsApi(
      `/api/v1/bonds?applicantId=${
        applicantDetails._id
      }&status=${selectedFilterDropdown}${getSearchParameter()}&page=${page}&size=15`,
      RMS_GET_APPLICANT_BONDS_REQUEST,
      RMS_GET_APPLICANT_BONDS_SUCCESS,
      RMS_GET_APPLICANT_BONDS_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService,
      { page }
    );
  };

  useEffect(() => {
    getBonds(1);
  }, [debouncedSearchText, searchMode, selectedFilterDropdown]);

  const loadNextPage = () => {
    getBonds(bondsPage + 1);
  };

  return (
    <div>
      <div className="RMS__page applicant bondsManagement">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Bonds" />
            <div className="RMS__page--content">
              <ApplicantBondTable
                searchText={searchText}
                setSearchText={setSearchText}
                searchMode={searchMode}
                setSearchMode={setSearchMode}
                bonds={bonds}
                bondsLoading={bondsLoading}
                loadNextPage={loadNextPage}
                selectedFilterDropdown={selectedFilterDropdown}
                setSelectedFilterDropdown={setSelectedFilterDropdown}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantBondContainer;
