import React from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DatePicker = ({ setSelectedDate, selectedDate }) => {
  return (
    <>
      <DateRange
        className="calender-range"
        onChange={item => setSelectedDate([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={selectedDate}
      />
    </>
  );
};

export default DatePicker;
