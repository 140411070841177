import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useUploadFile from '../../../hooks/useUploadFile';
import iconAddButton from '../../../assets/images/svg-images/icon-addButton.svg';
import { AssessmentTypes, RequestFormRoutes } from '../../../Common/constants';
import RMSPageTitle from '../../CommonComponents/RMSPageTitle';
import uploadfile from './../../../assets/images/svg-images/icon-upload.svg';
import DatePicker from 'react-datepicker';
import useConvertDate from '../../../hooks/useConvertDate';

const TrainingFormOtherDetails = ({
  onSave,
  onBack,
  match,
  isGroup,
  loadingButton = false,
  submitErrorMessage = '',
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { combineDateIsoStringAndTime } = useConvertDate();

  const [uploadFile, courseBrochureLink, fileUploadingProgress] =
    useUploadFile();

  const assessmentDetailObject = { date: '', time: '', location: '' };

  const getFormattedDate = dateStr => {
    if (dateStr && moment(dateStr).isValid()) {
      return moment(dateStr).format('YYYY-MM-DD');
    } else {
      return '';
    }
  };

  //Form Field States
  const [isAssessment, setIsAssessment] = useState('');
  const [assessmentType, setAssessmentType] = useState('');
  const [assessmentDetails, setAssessmentDetails] = useState([
    { ...assessmentDetailObject },
  ]);
  const [objectives, setObjectives] = useState('');
  const [comment, setComment] = useState('');
  const [remark, setRemark] = useState('');
  const [courseLink, setCourseLink] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  ///////////////////////////////

  const [errorMessage, setErrorMessage] = useState('');

  const updateAssessmentDetails = (index, field, value) => {
    let editingAssessment = assessmentDetails?.[index];

    if (editingAssessment) {
      editingAssessment[field] = value;
    }

    let updatedDetailList = [...assessmentDetails];
    updatedDetailList[index] = editingAssessment;

    setAssessmentDetails(updatedDetailList);
  };

  const addNewAssessmentDetail = () => {
    setAssessmentDetails(assessments => [
      ...assessments,
      { ...assessmentDetailObject },
    ]);
  };

  const removeAssessmentDetailItem = index => {
    setAssessmentDetails(details =>
      details?.filter((item, idx) => index !== idx)
    );
  };

  const handleFileAccept = event => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      setSelectedFile(file);
      uploadFile(file);
    }
  };

  const isAssessmentYes = useMemo(() => {
    return isAssessment === 'yes';
  }, [isAssessment]);

  const validateAndSave = () => {
    if (objectives && comment) {
      if (selectedFile && fileUploadingProgress != 100 && !courseBrochureLink) {
        setErrorMessage(t('Please wait until the file uploads'));
        return;
      }
      if (isAssessment) {
        let formattedData = {};
        if (isAssessmentYes) {
          if (
            assessmentType &&
            assessmentDetails.every(
              ({ date, location, time }) => date && time && location
            )
          ) {
            //valid
            formattedData['examination'] = {
              isAssessment: isAssessmentYes,
              typeOfAssessment: assessmentType,
              scheduledExams: assessmentDetails.map(
                ({ date, time, location }) => ({
                  startDate: combineDateIsoStringAndTime(date, time),
                  location,
                })
              ),
            };
          } else {
            //not valid
            setErrorMessage(t('Please fill all the required fields'));
            return;
          }
        } else {
          //valid without assessment details
          formattedData['examination'] = {
            isAssessment: isAssessmentYes,
          };
        }
        //adding common fields
        formattedData = {
          ...formattedData,
          objective: objectives,
          comment,
          remark,
          brochureUploadPath: courseBrochureLink,
          courseWebsite: courseLink,
        };
        setErrorMessage('');
        onSave(isGroup ? RequestFormRoutes.Personal : '', formattedData);
      } else {
        //not valid
        setErrorMessage(t('Please fill all the required fields'));
        return;
      }
    } else {
      //not valid
      setErrorMessage(t('Please fill all the required fields'));
      return;
    }
  };

  if (!match) {
    return null;
  }

  return (
    <div className="RMS__page trainingForm form3">
      <div className="container">
        <div className="RMS__page--wrapper">
          <RMSPageTitle titleText="Training Form" />
          <div
            className={`RMS__page--content ${
              isAssessmentYes ? `marginBottom addRow` : ''
            }`}
          >
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <p>{t('Other Details')}</p>
                </div>
                <div className="RMS__page--header-right required">
                  <p>
                    {t('Assessments')}
                    <span>*</span>
                  </p>
                  <ReactDropdown
                    options={[
                      { label: t('Yes'), value: 'yes' },
                      { label: t('No'), value: 'no' },
                    ]}
                    value={isAssessment}
                    onChange={option => setIsAssessment(option.value)}
                    placeholder={t('Select')}
                  />
                </div>
              </div>
            </div>
            {isAssessmentYes && (
              <div className="RMS__page--body">
                <div className="RMS__page--body-row">
                  <div className="RMS__page--body-column">
                    <div className="form--field row">
                      <p
                        className={`form--field-title ${
                          isAssessmentYes ? 'required' : ''
                        }`}
                      >
                        {t('Type of Assessments')}
                        {isAssessmentYes && <span>*</span>}
                      </p>
                      <ReactDropdown
                        options={[
                          {
                            label: t('Assessment'),
                            value: AssessmentTypes.ASSESSMENT,
                          },
                          {
                            label: t('Assignment'),
                            value: AssessmentTypes.ASSIGNMENT,
                          },
                          {
                            label: t('Examination'),
                            value: AssessmentTypes.EXAMINATION,
                          },
                        ]}
                        value={assessmentType}
                        onChange={option => setAssessmentType(option.value)}
                        placeholder={t('Select')}
                      />
                    </div>
                  </div>
                </div>
                {assessmentDetails.map(({ date, time, location }, index) => (
                  <div key={index}>
                    <div className="RMS__page--body-row">
                      {assessmentDetails.length > 1 && (
                        <a
                          href="javascript:void(0)"
                          className="closeButton"
                          onClick={() => removeAssessmentDetailItem(index)}
                        >
                          &times;
                        </a>
                      )}
                      <div className="RMS__page--body-column">
                        <div className="form--field twoRows">
                          <p
                            className={`form--field-title ${
                              isAssessmentYes ? 'required' : ''
                            }`}
                          >
                            {t('Date of the exam')}
                            {isAssessmentYes && <span>*</span>}
                          </p>
                          <div className="form--field-input">
                            <DatePicker
                              selected={date}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              onChange={e =>
                                updateAssessmentDetails(index, 'date', e)
                              }
                              showMonthDropdown
                            />
                          </div>
                        </div>
                      </div>
                      <div className="RMS__page--body-column">
                        <div className="form--field twoRows">
                          <p
                            className={`form--field-title ${
                              isAssessmentYes ? 'required' : ''
                            }`}
                          >
                            {t('Time of Exam')}
                            {isAssessmentYes && <span>*</span>}
                          </p>
                          <div className="form--field-input">
                            <input
                              type="time"
                              placeholder={t('Type here')}
                              value={time}
                              onChange={e =>
                                updateAssessmentDetails(
                                  index,
                                  'time',
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="RMS__page--body-column">
                        <div className="form--field twoRows">
                          <p
                            className={`form--field-title ${
                              isAssessmentYes ? 'required' : ''
                            }`}
                          >
                            {t('Location of Exam')}
                            {isAssessmentYes && <span>*</span>}
                          </p>
                          <div className="form--field-input">
                            <input
                              type="text"
                              placeholder={t('Type here')}
                              value={location}
                              onChange={e =>
                                updateAssessmentDetails(
                                  index,
                                  'location',
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {index !== assessmentDetails.length - 1 && (
                      <div className="RMS__page--divider" />
                    )}
                  </div>
                ))}
                <button
                  className="btn btn--add"
                  onClick={addNewAssessmentDetail}
                >
                  <img src={iconAddButton} alt="add-data" />
                </button>
              </div>
            )}
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--body">
              <div className="RMS__page--body-row">
                <div className="form--field twoRows textarea">
                  <p className="form--field-title required">
                    {t('What are the objectives of this course nominations?')}
                    <span>*</span>
                  </p>
                  <div className="form--field-input">
                    <textarea
                      placeholder={t('Type here')}
                      value={objectives}
                      onChange={e => setObjectives(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-row">
                <div className="form--field twoRows textarea">
                  <p className="form--field-title required">
                    {t(
                      'How would you ensure how the course objectives are transferred to your job performance.'
                    )}
                    <span>*</span>
                  </p>
                  <div className="form--field-input">
                    <textarea
                      placeholder={t('Type here')}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-row">
                <div className="form--field twoRows textarea">
                  <p className="form--field-title">{t('Remark')}</p>
                  <div className="form--field-input">
                    <textarea
                      placeholder={t('Type here')}
                      value={remark}
                      onChange={e => setRemark(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--body">
              <div className="RMS__page--body-row twoColumn">
                <div className="RMS__page--body-column">
                  <div className="form--field twoRows">
                    <p className="form--field-title required">
                      {t('Upload course brochure / edm / handout')}
                    </p>
                    <div className="form--field-input uploadIcon">
                      <input
                        type="file"
                        name="uploadfile"
                        class="custom-file-input"
                        onChange={handleFileAccept}
                      />
                      <img src={uploadfile} alt="upload-field" />
                      <div
                        className="backgroundFill"
                        style={{
                          width: `${fileUploadingProgress}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-column">
                  <div className="form--field twoRows">
                    <p className="form--field-title">
                      {t('Course website and / or registration link')}
                    </p>
                    <div className="form--field-input">
                      <input
                        type="text"
                        placeholder={t('Type here')}
                        value={courseLink}
                        onChange={e => setCourseLink(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--submit overall">
            <div className="RMS__page--error">
              <div className="RMS__page--error-content">
                {errorMessage || submitErrorMessage}
              </div>
            </div>
            <button
              className="btn btn--secondary btn--marginRight"
              onClick={() => {
                setSelectedFile('');
                uploadFile('');
                onBack();
              }}
            >
              {t('Back')}
            </button>
            <button
              className={`btn btn--primary ${
                loadingButton ? 'btn--loader' : ''
              }`}
              onClick={validateAndSave}
            >
              {isGroup ? t('Next') : t('Submit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingFormOtherDetails;
