import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import FeedBackModal from '../../Components/Modal/FeedbakFormModal';
import { fileUploadPath } from '../../Common/constants';

import {
  RMS_GET_FEEDBACK_QUESTIONS_SUCCESS,
  RMS_GET_FEEDBACK_QUESTIONS_FAILED,
  RMS_GET_FEEDBACK_QUESTIONS_REQUEST,
  RMS_POST_FEEDBACK_SUCCESS,
  RMS_POST_FEEDBACK_FAILED,
  RMS_POST_FEEDBACK_REQUEST,
  RMS_GET_VIEW_FEEDBACK_REQUEST,
  RMS_GET_VIEW_FEEDBACK_SUCCESS,
  RMS_GET_VIEW_FEEDBACK_FAILED,
  RMS_RESET_VIEW_FEEDBACK_POPUP_DATA,
} from '../../redux/actionTypes/userFeedbackType';

import {
  RMS_GET_CLOUD_STORAGE_DATA_REQUEST,
  RMS_GET_CLOUD_STORAGE_DATA_SUCCESS,
  RMS_GET_CLOUD_STORAGE_DATA__FAILED,
} from '../../redux/actionTypes/commonTypes';

const FeedbackContainer = ({
  onHandleClose,
  requestId,
  applicantId,
  isViewFeedback,
}) => {
  const [fetchFeedbackQuestionsApi] = useApi();
  const [saveUserFeedBack] = useApi();
  const [fetchViewFeedbackDataApi] = useApi();
  const [getCloudStorageDataApi] = useApi();
  const dispatch = useDispatch();

  const {
    feedbackQuestions,
    viewFeedbackData,
    userFeedBackSubmitSuccess,
    userFeedBackSubmitLoading,
    userFeedBackSubmitErrorMsg,
  } = useSelector(state => state.rmsUserFeedBack);

  const getFeedbackQuestions = () => {
    fetchFeedbackQuestionsApi(
      `/api/v1/trainingRequestFeedbackQuestions?page=1&size=50`,
      RMS_GET_FEEDBACK_QUESTIONS_REQUEST,
      RMS_GET_FEEDBACK_QUESTIONS_SUCCESS,
      RMS_GET_FEEDBACK_QUESTIONS_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getViewFeedbackData = questionIds => {
    fetchViewFeedbackDataApi(
      `/api/v1/trainingRequests/${requestId}/feedback/feedbackResponses?questionIds=${questionIds}&page=1&size=${questionIds.length}`,
      RMS_GET_VIEW_FEEDBACK_REQUEST,
      RMS_GET_VIEW_FEEDBACK_SUCCESS,
      RMS_GET_VIEW_FEEDBACK_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const addUserFeedBack = data => {
    const userFeedbackData = {
      ...data,
      applicantId: applicantId,
      trainingRequestId: requestId,
    };

    requestId &&
      saveUserFeedBack(
        `/api/v1/trainingRequests/${requestId}/feedback`,
        RMS_POST_FEEDBACK_REQUEST,
        RMS_POST_FEEDBACK_SUCCESS,
        RMS_POST_FEEDBACK_FAILED,
        userFeedbackData,
        '',
        'POST',
        null,
        'isRmsService'
      );
  };

  const getCloudStorageData = () => {
    getCloudStorageDataApi(
      `/api/v1/cloud-storage/path/${fileUploadPath.FEEDBACK_FORM}`,
      RMS_GET_CLOUD_STORAGE_DATA_REQUEST,
      RMS_GET_CLOUD_STORAGE_DATA_SUCCESS,
      RMS_GET_CLOUD_STORAGE_DATA__FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  useEffect(() => {
    getFeedbackQuestions();
    getCloudStorageData();
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: RMS_RESET_VIEW_FEEDBACK_POPUP_DATA,
      });
    };
  }, []);

  useEffect(() => {
    const questionIds = feedbackQuestions?.map(questions => questions._id);
    isViewFeedback && getViewFeedbackData(questionIds);
  }, [feedbackQuestions]);

  useEffect(() => {
    !userFeedBackSubmitLoading && userFeedBackSubmitSuccess && onHandleClose();
  }, [userFeedBackSubmitSuccess, userFeedBackSubmitLoading]);

  return (
    <FeedBackModal
      onHandleClose={onHandleClose}
      feedbackQuestions={feedbackQuestions}
      setUserFeedBack={addUserFeedBack}
      userFeedBackSubmitSuccess={userFeedBackSubmitSuccess}
      userFeedBackSubmitLoading={userFeedBackSubmitLoading}
      userFeedBackSubmitErrorMsg={userFeedBackSubmitErrorMsg}
      viewFeedbackData={viewFeedbackData}
      isViewFeedback={isViewFeedback}
    />
  );
};

export default FeedbackContainer;
