export const GET_ALL_REQUESTS_REQUEST = 'GET_ALL_REQUESTS_REQUEST';
export const GET_ALL_REQUESTS_SUCCESS = 'GET_ALL_REQUESTS_SUCCESS';
export const GET_ALL_REQUESTS_FAIL = 'GET_ALL_REQUESTS_FAIL';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';

export const GET_APPLICANT_NAMES_REQUEST = 'GET_APPLICANT_NAMES_REQUEST';
export const GET_APPLICANT_NAMES_SUCCESS = 'GET_APPLICANT_NAMES_SUCCESS';
export const GET_APPLICANT_NAMES_FAIL = 'GET_APPLICANT_NAMES_FAIL';

export const RMS_GET_FEEDBACK_REQUEST_MODAL_REQUEST =
  'RMS_GET_FEEDBACK_REQUEST_MODAL_REQUEST';
export const RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS =
  'RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS';
export const RMS_GET_FEEDBACK_REQUEST_MODAL_FAILED =
  'RMS_GET_FEEDBACK_REQUEST_MODAL_FAILED';

export const RESET_REQUEST_TYPES_DATA = 'RESET_REQUEST_TYPES_DATA';
