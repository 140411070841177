import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatText } from '../../../../Common/helper';

const IdDetails = ({ data }) => {
  const { t } = useTranslation();
  const { requestId, course } = data || {};
  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p className="whiteColor smallSize">{`${t(
              'Request ID'
            )}: ${requestId}`}</p>
          </div>
          <div className="RMS__page--header-right required">
            <p>{t('Course Type')}</p>
            <div className="form--field-input">
              <input
                disabled
                type="text"
                placeholder={t('N/A')}
                value={formatText(course?.courseType) || '-'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdDetails;
