import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDropdown from 'react-dropdown';
import { adminDashboardMode } from '../../Common/constants';
import EChartsReact from 'echarts-for-react';
import moment from 'moment';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import RequestsModal from '../RequestModal/RequestsModal';
import Autosuggest from 'react-autosuggest';
import RequestsModalContainer from '../../Container/RequestsModalContainer/RequestsModalContainer';
import useDebounce from '../../hooks/useDebounce';

const GraphicalLayout = ({
  selectedMode,
  setSelectedMode,
  getFinancialDuration,
  financialDuration,
  templates,
  getTrainingRequestStats,
  getAllTemplates,
  getBudgetStatistics,
  normalTrainingRequestStats: {
    numberOfApplicants: normalNumberOfApplicants,
    numberOfGeneralRequests: normalNumberOfGeneralRequests,
    numberOfProfessionalRequests: normalNumberOfProfessionalRequests,
    numberOfRequests: normalNumberOfRequests,
  },
  groupTrainingRequestStats: {
    numberOfApplicants: groupNumberOfApplicants,
    numberOfRequests: groupNumberOfRequests,
  },
  overallTrainingRequestStats: {
    groupRequestCount,
    normalRequestCount,
    totalRequestCount,
  },
  selectedDateRangeId,
  setSelectedDateRangeId,
  resetTrainingRequestStats,
  loading,
  isTemplatesLoading,
  selectedTemplate,
  setSelectedTemplate,
  selectedTemplateLabel,
  setSelectedTemplateLabel,
  normalBudgetRequestStats: {
    generalBudgetUtilization,
    professionalBudgetUtilization,
    totalBalance: normalBudgetTotalBalance,
  },
  groupBudgetRequestStats: {
    groupBudgetUtilization,
    totalBalance: groupBudgetTotalBalance,
  },
  overAllBudgetRequestStats: {
    groupBudgetUtilization: overallGroupbudgetUtilization,
    normalBudgetUtilization,
    totalBalance: overallBudgetTotalBalance,
  },
  isSuperAdminDashboard,
  userDetails,
  resetUserDetailsS3BucketData,
  templateSearchText,
  setTemplateSearchText,
  selectedTemplateOption,
  setSelectedTemplateOption,
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectModeOptions, setSelectModeOptions] = useState([]);
  const debounceSearchText = useDebounce(templateSearchText, 1000);
  const modeOptions = [
    { label: t('Overall'), value: adminDashboardMode.OVERALL },
    { label: t('Normal'), value: adminDashboardMode.NORMAL },
    { label: t('Group'), value: adminDashboardMode.GROUP },
  ];
  const selectedNormalMode = selectedMode === adminDashboardMode.NORMAL;
  const selectedGroupMode = selectedMode === adminDashboardMode.GROUP;
  const selectedOverallMode = selectedMode === adminDashboardMode.OVERALL;

  const getFormatDate = date => {
    if (date && moment(date).isValid()) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return '-';
    }
  };

  const getFormattedTime = timeZoneString => {
    const customTimeZoneString = new Date(timeZoneString);
    return customTimeZoneString.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderChartColor = () => {
    const colours = ['#FF9900', '#BB851B', '#F63B00'];
    if (selectedMode !== adminDashboardMode.GROUP) {
      return colours;
    } else {
      colours.splice(1, 1);
      return colours;
    }
  };

  const renderChartData = () => {
    const overallData = [
      {
        value: overallBudgetTotalBalance || 0,
        name: t('Balance'),
      },
      {
        value: overallGroupbudgetUtilization || 0,
        name: t('Group'),
      },
      {
        value: normalBudgetUtilization || 0,
        name: t('Normal'),
      },
    ];

    const normalBudgetData = [
      {
        value: normalBudgetTotalBalance || 0,
        name: t('Balance'),
      },
      {
        value: professionalBudgetUtilization || 0,
        name: t('Professional'),
      },
      {
        value: generalBudgetUtilization || 0,
        name: t('General'),
      },
    ];

    const groupBudgetData = [
      {
        value: groupBudgetTotalBalance || 0,
        name: t('Balance'),
      },
      {
        value: groupBudgetUtilization || 0,
        name: t('Group'),
      },
    ];

    if (selectedOverallMode) {
      return overallData;
    }
    if (selectedGroupMode) {
      return groupBudgetData;
    }
    if (selectedNormalMode) {
      return normalBudgetData;
    }
  };

  const chartOptions = useMemo(
    () => ({
      title: {
        text: '',
        subtext: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: false,
      },
      color: renderChartColor(),
      series: [
        {
          name: 'Allocations',
          type: 'pie',
          radius: '60%',
          data: renderChartData(),
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }),
    [
      normalBudgetTotalBalance,
      groupBudgetTotalBalance,
      overallBudgetTotalBalance,
      selectedMode,
    ]
  );

  const dateRangeDropdown = financialDuration?.map(item => {
    return {
      label: `${getFormatDate(item.startAt)} - ${getFormattedTime(
        item.startAt
      )} | ${getFormatDate(item.endAt)} - ${getFormattedTime(item.endAt)}`,
      value: `${item._id}`,
    };
  });

  useEffect(() => {
    selectedMode === adminDashboardMode.OVERALL && setSelectedTemplate(null);
  }, [selectedMode]);

  useEffect(() => {
    getFinancialDuration();
    if (!isSuperAdminDashboard) {
      const adminModeOptions = modeOptions.filter(
        option => option.value !== adminDashboardMode.OVERALL
      );

      setSelectModeOptions(adminModeOptions);
      setSelectedMode(adminDashboardMode.NORMAL);
    } else {
      setSelectModeOptions(modeOptions);
    }
  }, []);

  useEffect(() => {
    if (selectedOverallMode) {
      return;
    }

    !isSuperAdminDashboard
      ? userDetails?._id && getAllTemplates(selectedMode, templateSearchText)
      : getAllTemplates(selectedMode, templateSearchText);
  }, [selectedMode, debounceSearchText, userDetails]);

  useEffect(() => {
    setSelectedTemplate('');
    setTemplateSearchText('');
    setSelectedTemplateLabel(null);
  }, [selectedMode]);

  useEffect(() => {
    if (!selectedDateRangeId) return;
    if (selectedOverallMode) {
      getTrainingRequestStats(selectedDateRangeId, selectedTemplate);
      getBudgetStatistics(selectedDateRangeId, selectedTemplate);
    }
  }, [selectedDateRangeId, selectedMode]);

  useEffect(() => {
    if (!selectedDateRangeId || !selectedTemplate) return;
    getTrainingRequestStats(selectedDateRangeId, selectedTemplate);
    getBudgetStatistics(selectedDateRangeId, selectedTemplate);
  }, [selectedDateRangeId, selectedTemplate]);

  useEffect(() => {
    if (selectedMode !== adminDashboardMode.OVERALL && !templateSearchText) {
      resetTrainingRequestStats();
    }
  }, [selectedMode, templateSearchText]);

  useEffect(() => {
    if (!selectedDateRangeId) {
      setSelectedDateRangeId(financialDuration?.slice(-1)?.[0]?._id || '');
    }
  }, [financialDuration]);

  useEffect(() => {
    setSuggestions(
      templates?.map(item => ({
        name: item.name,
        id: item._id,
      })) || []
    );
    //TODO need to fixe
    // if (templates?.length && !selectedTemplateLabel) {
    //   setSelectedTemplateLabel(templates?.[0]?.name);
    //   setTemplateSearchText(templates?.[0]?.name);
    //   setSelectedTemplate(templates?.[0]?._id);
    //   setSelectedTemplateOption({
    //     name: templates?.[0]?.name,
    //     id: templates?.[0]?._id,
    //   });
    // }
    // if (templates?.length && selectedTemplateLabel) {
    //   setSelectedTemplateLabel(selectedTemplateOption?.name || '');
    //   setTemplateSearchText(selectedTemplateOption?.name || '');
    //   setSelectedTemplate(selectedTemplateOption?.id || '');
    // }
  }, [templates]);

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return <div {...containerProps}>{!isTemplatesLoading && children}</div>;
  };

  const renderInputComponent = inputProps => (
    <div className="react-autosuggest__wrapper">
      <input {...inputProps} />
      {isTemplatesLoading && templateSearchText && (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-right">
            <h4>{t('Financial Duration')}</h4>
            <div className="form--field"></div>
            <div className="form--field">
              <div className="form--field-input">
                <ReactDropdown
                  options={dateRangeDropdown}
                  placeholder={t('Filter By')}
                  value={selectedDateRangeId}
                  onChange={option => {
                    setSelectedDateRangeId(option.value);
                    resetUserDetailsS3BucketData();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard__wrapper">
          <div className="dashboard--row">
            <div className="dashboard--column">
              <div className="dataRow">
                {!isSuperAdminDashboard ? (
                  <h2>{t('Template types')}</h2>
                ) : (
                  <h2>{t('Mode')}</h2>
                )}
                <ReactDropdown
                  options={selectModeOptions}
                  placeholder={t('Filter By')}
                  value={selectedMode}
                  onChange={option => setSelectedMode(option.value)}
                />
              </div>
              {(selectedNormalMode || selectedGroupMode) && (
                <div className="dataRow">
                  <Autosuggest
                    suggestions={suggestions}
                    shouldRenderSuggestions={() => true}
                    onSuggestionsFetchRequested={({ value }) => {
                      setTemplateSearchText(value);
                    }}
                    onSuggestionSelected={(_, { suggestionIndex }) => {
                      setSelectedTemplate(suggestions[suggestionIndex]?.id);
                      setSelectedTemplateLabel(
                        suggestions[suggestionIndex]?.name
                      );
                      setSelectedTemplateOption({
                        name: suggestions[suggestionIndex]?.name,
                        id: suggestions[suggestionIndex]?.id,
                      });
                    }}
                    getSuggestionValue={suggestion => suggestion.name}
                    renderSuggestion={suggestion => (
                      <span>{suggestion.name}</span>
                    )}
                    inputProps={{
                      placeholder: t('Search templates'),
                      value: templateSearchText,
                      onChange: (_, { newValue }) => {
                        setTemplateSearchText(newValue);
                      },
                    }}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    renderInputComponent={renderInputComponent}
                  />
                </div>
              )}

              <div className="dataRow">
                <h2>{t('Allocations')}</h2>
                {loading ? (
                  <DottedLoader />
                ) : (
                  <div className="allocations__wrapper">
                    {selectedMode !== adminDashboardMode.GROUP && (
                      <div className="allocations__data normal">
                        <p>
                          <span className="allocations__data--color"></span>{' '}
                          {selectedOverallMode ? t('Normal') : t('General')}
                        </p>
                        <p>{`SGD ${
                          normalBudgetUtilization ||
                          generalBudgetUtilization ||
                          0
                        }`}</p>
                      </div>
                    )}
                    <div
                      className={`allocations__data ${
                        selectedGroupMode ? 'normal' : 'group'
                      }`}
                    >
                      <p>
                        <span className="allocations__data--color"></span>
                        {selectedOverallMode || selectedGroupMode
                          ? t('Group')
                          : t('Professional')}
                      </p>
                      <p>{`SGD ${
                        groupBudgetUtilization ||
                        overallGroupbudgetUtilization ||
                        professionalBudgetUtilization ||
                        0
                      }`}</p>
                    </div>
                    <div className="allocations__data balance">
                      <p>
                        <span className="allocations__data--color"></span>{' '}
                        {t('Balance')}
                      </p>
                      <p>{`SGD ${
                        normalBudgetTotalBalance ||
                        groupBudgetTotalBalance ||
                        overallBudgetTotalBalance ||
                        0
                      }`}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="dashboard--column">
              <div className="piechart-row row-1">
                {loading ? (
                  <DottedLoader />
                ) : (
                  <EChartsReact option={chartOptions} />
                )}
              </div>
            </div>

            <div
              className={`dashboard--column ${
                selectedNormalMode && 'normalMode'
              } ${selectedGroupMode && 'groupMode'}`}
            >
              {(selectedNormalMode || selectedGroupMode) && (
                <div className="dashboard--dataWrapper">
                  <p>{t('Number of Applicants')}</p>
                  {loading ? (
                    <DottedLoader />
                  ) : (
                    <p>{`${
                      normalNumberOfApplicants || groupNumberOfApplicants || 0
                    }`}</p>
                  )}
                </div>
              )}
              {selectedMode !== adminDashboardMode.GROUP && (
                <div
                  className="dashboard--dataWrapper clickable"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <p>
                    {selectedNormalMode ? t('Requests') : t('Total Requests')}
                  </p>
                  {loading ? (
                    <DottedLoader />
                  ) : (
                    <p>{`${
                      totalRequestCount || normalNumberOfRequests || 0
                    }`}</p>
                  )}
                </div>
              )}

              <div
                className={`dashboard--dataWrapper ${
                  selectedGroupMode ? 'clickable' : ''
                } `}
                onClick={() =>
                  selectedGroupMode ? setModalOpen(!modalOpen) : ''
                }
              >
                <p>
                  {selectedNormalMode
                    ? t('General Requests')
                    : selectedGroupMode
                    ? t('Total Group Requests')
                    : t('Normal Requests')}
                </p>
                {loading ? (
                  <DottedLoader />
                ) : (
                  <p>{`${
                    normalNumberOfGeneralRequests ||
                    groupNumberOfRequests ||
                    normalRequestCount ||
                    0
                  }`}</p>
                )}
              </div>
              {selectedMode !== adminDashboardMode.GROUP && (
                <div className="dashboard--dataWrapper">
                  <p>
                    {selectedNormalMode
                      ? t('Professional Requests')
                      : t('Group Requests')}
                  </p>
                  {loading ? (
                    <DottedLoader />
                  ) : (
                    <p>{`${
                      groupRequestCount ||
                      normalNumberOfProfessionalRequests ||
                      0
                    }`}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {modalOpen && (
          <RequestsModalContainer
            financialDurationId={selectedDateRangeId}
            handleClose={() => setModalOpen(!modalOpen)}
            isSuperAdminDashboard={isSuperAdminDashboard}
            userDetails={userDetails}
            selectedTemplate={selectedTemplate}
            selectedDateRangeId={selectedDateRangeId}
            selectedMode={selectedMode}
            selectedTemplateLabel={selectedTemplateLabel}
          />
        )}
      </div>
    </>
  );
};

export default GraphicalLayout;
