import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import useApi from '../../../Hooks/useApi';
import { Services, TemplateTypes } from '../../Common/constants';
import UserDetails from '../../Components/Dashboard/ReportingDashBoard/UserDetailsComponent';
import RMSPageTitle from '../../Components/CommonComponents/RMSPageTitle';
import BudgetDetails from '../../Components/Dashboard/ReportingDashBoard/BudgetDetailsComponent';
import Requests from '../../Components/Dashboard/ReportingDashBoard/RequestsComponent';
import Bonds from '../../Components/Dashboard/ReportingDashBoard/BondsComponent';
import { SecondaryBtn } from '../../Components/CommonComponents/Button/Button';
import {
  RMS_CENTER_DETAILS_FAIL,
  RMS_CENTER_DETAILS_REQUEST,
  RMS_CENTER_DETAILS_SUCCESS,
  RMS_DEPARTMENT_DETAILS_FAIL,
  RMS_DEPARTMENT_DETAILS_REQUEST,
  RMS_DEPARTMENT_DETAILS_SUCCESS,
  RMS_DESIGNATION_DETAILS_FAIL,
  RMS_DESIGNATION_DETAILS_REQUEST,
  RMS_DESIGNATION_DETAILS_SUCCESS,
  RMS_GET_USER_DETAILS_DASHBOARD_FAIL,
  RMS_GET_USER_DETAILS_DASHBOARD_REQUEST,
  RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS,
  RMS_GROUP_TEMPLATE_DETAILS_FAIL,
  RMS_GROUP_TEMPLATE_DETAILS_REQUEST,
  RMS_GROUP_TEMPLATE_DETAILS_SUCCESS,
  RMS_NORMAL_TEMPLATE_DETAILS_FAIL,
  RMS_NORMAL_TEMPLATE_DETAILS_REQUEST,
  RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS,
  RMS_QUALIFICATION_DETAILS_FAIL,
  RMS_QUALIFICATION_DETAILS_REQUEST,
  RMS_QUALIFICATION_DETAILS_SUCCESS,
  RMS_RESET_REPORT_DASHBOARD_DETAILS,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_REQUEST,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_FAILED,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL,
  RMS_FETCH_ACTIVE_FINANCIAL_REQUEST,
  RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS,
  RMS_FETCH_ACTIVE_FINANCIAL_FAILED,
  RMS_COMPANY_DETAILS_REQUEST,
  RMS_COMPANY_DETAILS_SUCCESS,
  RMS_COMPANY_DETAILS_FAILED,
} from '../../redux/actionTypes/superAdminDashboardTypes';

const ReportingDashboardContainer = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [getUserDetails] = useApi();
  const [getNormalTemplate] = useApi();
  const [getGroupTemplate] = useApi();
  const [getQualification] = useApi();
  const [getDesignation] = useApi();
  const [getCenter] = useApi();
  const [getDepartment] = useApi();
  const [fetchBudgetDetails] = useApi();
  const [getTrainingRequestForApplicants] = useApi();
  const [getBondDetails] = useApi();
  const [fetchTrainingRequestDetailsById] = useApi();
  const [getCurrentFinancialDuration] = useApi();
  const [getCompany] = useApi();

  const {
    userReportDashboardDetails,
    normalTemplateDetails,
    groupTemplateDetails,
    qualificationDetails,
    designationDetails,
    departmentDetails,
    centerDetails,
    budgetDetails,
    trainingRequestsData,
    trainingRequestDataLoading,
    requestPage,
    requestTotalRecords,
    bondDetails,
    bondDetailsLoading,
    bondPage,
    bondsTotalRecords,
    companyDetails,
  } = useSelector(state => state.rmsSuperAdminDashboard);
  const dispatch = useDispatch();

  const { selectedDateRangeId, userId } = state;

  const getBudgetDetails = userId => {
    fetchBudgetDetails(
      `/api/v1/financialDurations/${selectedDateRangeId}/userBudget?userId=${userId}`,
      RMS_FETCH_DASHBOARD_BUDGET_DETAILS_REQUEST,
      RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS,
      RMS_FETCH_DASHBOARD_BUDGET_DETAILS_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };

  const fetchUserDetails = email => {
    getUserDetails(
      `/api/v1/users?emailList=${email}`,
      RMS_GET_USER_DETAILS_DASHBOARD_REQUEST,
      RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS,
      RMS_GET_USER_DETAILS_DASHBOARD_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const fetchNormalTemplateDetails = id => {
    getNormalTemplate(
      `/api/v1/templates?applicantId=${id}&type=${TemplateTypes.NORMAL_TEMPLATE}`,
      RMS_NORMAL_TEMPLATE_DETAILS_REQUEST,
      RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS,
      RMS_NORMAL_TEMPLATE_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const fetchGroupTemplateDetails = id => {
    getGroupTemplate(
      `/api/v1/templates?applicantId=${id}&type=${TemplateTypes.GROUP_TEMPLATE}`,
      RMS_GROUP_TEMPLATE_DETAILS_REQUEST,
      RMS_GROUP_TEMPLATE_DETAILS_SUCCESS,
      RMS_GROUP_TEMPLATE_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchQualificationDetails = id => {
    getQualification(
      `/api/v1/qualifications?qualificationIds=${id}`,
      RMS_QUALIFICATION_DETAILS_REQUEST,
      RMS_QUALIFICATION_DETAILS_SUCCESS,
      RMS_QUALIFICATION_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const fetchCenterDetails = id => {
    getCenter(
      `/api/v1/centers?centerIds=${id}`,
      RMS_CENTER_DETAILS_REQUEST,
      RMS_CENTER_DETAILS_SUCCESS,
      RMS_CENTER_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchTrainingRequestForApplicants = page => {
    getTrainingRequestForApplicants(
      `/api/v1/financialDurations/${selectedDateRangeId}/trainingRequests?page=${page}&size=20&applicantId=${userId}${
        !state?.isApplicantDashboard && !state?.isSuperAdminDashboard
          ? `&templateId=${state?.selectedTemplate}`
          : ''
      }`,
      RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST,
      RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS,
      RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchDesignationDetails = id => {
    getDesignation(
      `/api/v1/designations?designationIds=${id}`,
      RMS_DESIGNATION_DETAILS_REQUEST,
      RMS_DESIGNATION_DETAILS_SUCCESS,
      RMS_DESIGNATION_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchBondDetails = page => {
    getBondDetails(
      `/api/v1/financialDurations/${selectedDateRangeId}/applicantBonds?applicantId=${userId}&page=${page}&size=20${
        !state?.isApplicantDashboard && !state?.isSuperAdminDashboard
          ? `&templateId=${state?.selectedTemplate}`
          : ''
      }`,
      RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST,
      RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS,
      RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService,
      { page }
    );
  };

  const fetchDepartmentDetails = id => {
    getDepartment(
      `/api/v1/departments?departmentIds=${id}`,
      RMS_DEPARTMENT_DETAILS_REQUEST,
      RMS_DEPARTMENT_DETAILS_SUCCESS,
      RMS_DEPARTMENT_DETAILS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchCompanyDetails = id => {
    getCompany(
      `/api/v1/companies?companyIds=${id}`,
      RMS_COMPANY_DETAILS_REQUEST,
      RMS_COMPANY_DETAILS_SUCCESS,
      RMS_COMPANY_DETAILS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    if (state?.email) {
      fetchUserDetails(state?.email);
    }
  }, [state]);

  useEffect(() => {
    const {
      _id,
      qualificationId,
      centerId,
      designationId,
      departmentId,
      companyId,
    } = userReportDashboardDetails || {};
    if (_id) {
      fetchNormalTemplateDetails(_id);
      fetchGroupTemplateDetails(_id);
    }
    qualificationId && fetchQualificationDetails(qualificationId);
    centerId && fetchCenterDetails(centerId);
    designationId && fetchDesignationDetails(designationId);
    departmentId && fetchDepartmentDetails(departmentId);
    companyId && fetchCompanyDetails(companyId);
  }, [userReportDashboardDetails]);

  useEffect(() => {
    return () => {
      dispatch({ type: RMS_RESET_REPORT_DASHBOARD_DETAILS });
    };
  }, []);

  useEffect(() => {
    if (!userId || !selectedDateRangeId) {
      return;
    }
    getBudgetDetails(userId);
  }, [userId]);

  useEffect(() => {
    if (!userId || !selectedDateRangeId) {
      return;
    }
    fetchTrainingRequestForApplicants(1);
  }, [userId, selectedDateRangeId]);

  useEffect(() => {
    if ((!selectedDateRangeId, !userId)) return;
    fetchBondDetails(1);
  }, [selectedDateRangeId, userId]);

  const loadNextRequestPage = () => {
    const maxPage = Math.ceil(requestTotalRecords / 10);
    const nextPage = requestPage + 1;
    nextPage <= maxPage && fetchTrainingRequestForApplicants(requestPage + 1);
  };

  const loadNextBondPage = () => {
    const maxPage = Math.ceil(bondsTotalRecords / 10);
    const nextPage = bondPage + 1;
    nextPage <= maxPage && fetchBondDetails(bondPage + 1);
  };

  const backHandler = () => {
    history.goBack();
  };

  return (
    <div className="RMS__page dashboard">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--content">
            <RMSPageTitle titleText="Reporting Dashboard" />
            <div className="dashboard__wrapper">
              <div className="dashboard--row userDetails">
                <UserDetails
                  userReportDashboardDetails={userReportDashboardDetails}
                  normalTemplateDetails={normalTemplateDetails}
                  groupTemplateDetails={groupTemplateDetails}
                  qualificationDetails={qualificationDetails}
                  designationDetails={designationDetails}
                  departmentDetails={departmentDetails}
                  centerDetails={centerDetails}
                  companyDetails={companyDetails}
                />
              </div>
              <div className="dashboard--row budget">
                <BudgetDetails budgetDetails={budgetDetails} />
              </div>
              <div className="dashboard--row dashboard__request">
                <Requests
                  requests={trainingRequestsData}
                  loading={trainingRequestDataLoading}
                  loadNextRequestPage={loadNextRequestPage}
                  userId={userId}
                />
              </div>
              <div className="dashboard--row dashboard__request">
                <Bonds
                  bonds={bondDetails}
                  bondsLoading={bondDetailsLoading}
                  loadNextBondPage={loadNextBondPage}
                />
              </div>
              <div className="RMS__page--submit">
                <SecondaryBtn text="back" onClick={backHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingDashboardContainer;
