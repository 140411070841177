import React from 'react';
import { useTranslation } from 'react-i18next';

export const PrimaryBtn = ({
  onClick = () => {},
  text = '',
  extraClassNames = '',
}) => {
  const { t } = useTranslation();

  return (
    <>
      <button
        className={`btn btn--primary ${extraClassNames}`}
        onClick={onClick}
      >
        {t(text)}
      </button>
    </>
  );
};

export const SecondaryBtn = ({ onClick = () => {}, text = '' }) => {
  const { t } = useTranslation();

  return (
    <>
      <button className="btn btn--secondary btn--marginRight" onClick={onClick}>
        {t(text)}
      </button>
    </>
  );
};
