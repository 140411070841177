import React from 'react';
import { useTranslation } from 'react-i18next';

const BudgetCheckModal = ({ onClose, onOkClick, userName, isGroupRequest }) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Insufficient Budget')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            {isGroupRequest ? (
              <p>
                <span>{userName} </span>
                {userName
                  ? t('have insufficient budget to create the request')
                  : t(
                      'All applicants have insufficient budget to create the request'
                    )}
              </p>
            ) : (
              <p>
                <span>{userName} </span>
                {t('has insufficient budget to create the request')}
              </p>
            )}
          </div>
          <div className="reel-view--popup-row">
            <button className="btn btn--primary" onClick={onOkClick}>
              {t('Ok')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetCheckModal;
