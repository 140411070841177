import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import useApiPromiseArray from '../../../Hooks/useApiPromiseArray';
import CreateTemplate from '../../Components/ManageTemplate/CreateTemplate';
import {
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_BULK_SELECTED_USERS,
  GET_RMS_EXISTING_USER_FAILED,
  GET_RMS_EXISTING_USER_REQUEST,
  GET_RMS_EXISTING_USER_SUCCESS,
  GET_RMS_TEMPLATE_ADMIN_FAILED,
  GET_RMS_TEMPLATE_ADMIN_REQUEST,
  GET_RMS_TEMPLATE_ADMIN_SUCCESS,
  GET_RMS_USERS_DEPARTMENTS_FAIL,
  GET_RMS_USERS_DEPARTMENTS_REQUEST,
  GET_RMS_USERS_DEPARTMENTS_SUCCESS,
  PUT_RMS_TEMPLATE_UPDATE_FAIL,
  PUT_RMS_TEMPLATE_UPDATE_REQUEST,
  PUT_RMS_TEMPLATE_UPDATE_SUCCESS,
  RESET_RMS_TEMPLATE,
  SELECTED_BULK_BUDGET_USERS,
  SET_ALL_BULK_BUDGET_USERS,
  SET_BULK_BUDGET_VALUES,
  SET_INDIVIDUAL_BUDGET_LIMIT,
  SET_RMS_ADDED_TEMPLATE_USERS,
  SET_RMS_EDIT_USER_DATA,
  RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE,
} from '../../redux/actionTypes/templateTypes';

const CreateTemplateContainer = ({ history, isGroupTemplateType = false }) => {
  const dispatch = useDispatch();
  const [postTemplate] = useApi();
  const [putTemplate] = useApi();
  const [fetchUserDetails] = useApi();
  const [fetchExistingUserDetails] = useApi();
  const [fetchUsersDepartments] = useApi();
  const [getTemplateMemberInfo] = useApiPromiseArray();
  const {
    selectedUsers,
    usersDepartments,
    selectedBudgetUsers,
    createTemplateLoading,
    createTemplateSuccessLoading,
    createTemplateFailed,
    templateAdmin,
    createTemplateFailMessage,
  } = useSelector(state => state.templateManagement);

  const [isCreateTemplate, setIsCreateTemplate] = useState(true);
  const [templateData, setTemplateData] = useState(null);
  const [templateUserIds, setTemplateUserIds] = useState(null);

  const createTemplate = templateName => {
    let arrangeData = {};
    if (isGroupTemplateType) {
      arrangeData = {
        name: templateName,
        groupTemplateApplicants: selectedUsers?.map(data => {
          return {
            userId: data?._id,
            groupBudgetLimit: data?.generalBudgetLimit,
          };
        }),
      };
    } else {
      arrangeData = {
        name: templateName,
        normalTemplateApplicants: selectedUsers?.map(data => {
          return {
            userId: data?._id,
            generalBudgetLimit: data?.generalBudgetLimit,
            professionalBudgetLimit: data?.professionalBudgetLimit,
          };
        }),
      };
    }

    postTemplate(
      `/api/v1/${!isGroupTemplateType ? 'normalTemplate' : 'groupTemplate'}`,
      CREATE_TEMPLATE_REQUEST,
      CREATE_TEMPLATE_SUCCESS,
      CREATE_TEMPLATE_FAIL,
      arrangeData,
      '',
      'POST',
      null,
      'isRmsService'
    );
  };
  const editTemplate = (id, templateName, approvalBracket, bondTemplateId) => {
    let arrangeData;

    arrangeData = {
      _id: id,
      name: templateName,
      rmsAdminIds: templateAdmin?.map(data => data?._id) || [],
      bondTemplateId: bondTemplateId || null,
      grossPriceBrackets: approvalBracket?.map(data => {
        const formatApprovalLevels = data?.approvalLevels?.map(levelData => {
          return {
            approverIds: levelData?.approverIds?.map(({ value }) => value),
            notifierIds: levelData?.notifierIds?.map(({ value }) => value),
            minApproval: levelData?.minApproval,
          };
        });
        return {
          grossStartAmount: data?.grossStartAmount,
          grossEndAmount: data?.grossEndAmount,
          approvalLevels: formatApprovalLevels,
        };
      }),
    };
    if (!isGroupTemplateType) {
      arrangeData.normalTemplateApplicants = selectedUsers?.map(data => {
        return {
          userId: data?._id,
          generalBudgetLimit: data?.generalBudgetLimit,
          professionalBudgetLimit: data?.professionalBudgetLimit,
        };
      });
    } else {
      arrangeData.groupTemplateApplicants = selectedUsers?.map(data => {
        return {
          userId: data?._id,
          groupBudgetLimit: data?.generalBudgetLimit,
        };
      });
    }

    putTemplate(
      `/api/v1/${!isGroupTemplateType ? 'normalTemplate' : 'groupTemplate'}`,
      PUT_RMS_TEMPLATE_UPDATE_REQUEST,
      PUT_RMS_TEMPLATE_UPDATE_SUCCESS,
      PUT_RMS_TEMPLATE_UPDATE_FAIL,
      arrangeData,
      '',
      'PUT',
      null,
      'isRmsService'
    );
  };
  const getUserDetails = userIds => {
    fetchUserDetails(
      `/api/v1/users?userIds=${userIds}&page=1&size=${userIds?.length}`,
      GET_RMS_TEMPLATE_ADMIN_REQUEST,
      GET_RMS_TEMPLATE_ADMIN_SUCCESS,
      GET_RMS_TEMPLATE_ADMIN_FAILED,
      '',
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getTemplateUserDetails = async () => {
    if (templateUserIds?.length) {
      const chunkOfChatMemberList = [];
      const chunkSize = 50;
      for (let i = 0; i < templateUserIds?.length; i += chunkSize) {
        const chunk = templateUserIds?.slice(i, i + chunkSize);
        chunkOfChatMemberList.push(chunk);
      }
      const personalChatMemberInfoUrls = [];
      for (let index = 0; index < chunkOfChatMemberList.length; index++) {
        const element = chunkOfChatMemberList[index];
        personalChatMemberInfoUrls.push(
          `/api/v1/users?userIds=${element}&page=1&size=${element?.length}`
        );
      }

      try {
        const values = await getTemplateMemberInfo(
          personalChatMemberInfoUrls,
          'GET',
          'isRmsService'
        );
        const memberInfoList = [
          ...values?.flatMap(res => res?.data?.data),
        ].filter(memberInfo => memberInfo);
        dispatch({
          type: GET_RMS_EXISTING_USER_SUCCESS,
          payload: { data: memberInfoList?.length && memberInfoList },
        });
      } catch (error) {
        dispatch({
          type: GET_RMS_EXISTING_USER_FAILED,
        });
      }
    }
  };

  useEffect(() => {
    if (templateUserIds?.length) {
      getTemplateUserDetails();
    }
  }, [templateUserIds]);

  const getUsersDepartMents = deptIds => {
    fetchUsersDepartments(
      `/api/v1/departments?departmentIds=${deptIds}&page=1&size=${deptIds?.length}`,
      GET_RMS_USERS_DEPARTMENTS_REQUEST,
      GET_RMS_USERS_DEPARTMENTS_SUCCESS,
      GET_RMS_USERS_DEPARTMENTS_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_RMS_TEMPLATE });
    };
  }, []);

  useEffect(() => {
    if (
      !isCreateTemplate &&
      (isGroupTemplateType
        ? templateData?.groupTemplateApplicants?.length
        : templateData?.normalTemplateApplicants?.length)
    ) {
      dispatch({
        type: SET_RMS_EDIT_USER_DATA,
        payload: {
          data: isGroupTemplateType
            ? templateData?.groupTemplateApplicants
            : templateData?.normalTemplateApplicants,
          type: isGroupTemplateType,
        },
      });
      const existingUserIds = isGroupTemplateType
        ? templateData?.groupTemplateApplicants?.map(
            applicant => applicant.userId
          )
        : templateData?.normalTemplateApplicants?.map(
            applicant => applicant.userId
          );
      setTemplateUserIds(existingUserIds);
    }
  }, [templateData, isCreateTemplate]);

  useEffect(() => {
    if (!isCreateTemplate && selectedUsers?.length) {
      const deptIds = selectedUsers?.map(data => data?.departmentId);

      const filteredDeptIds = deptIds?.filter(data => data !== undefined) || [];
      filteredDeptIds?.length &&
        getUsersDepartMents([...new Set(filteredDeptIds)]);
    }
  }, [isCreateTemplate, selectedUsers]);

  return (
    <CreateTemplate
      isGroupTemplateType={isGroupTemplateType}
      users={selectedUsers}
      usersDepartments={usersDepartments}
      selectedBudgetUsers={selectedBudgetUsers}
      handleBudgetLimit={(value, email, type) => {
        dispatch({
          type: SET_INDIVIDUAL_BUDGET_LIMIT,
          customInput: { email, value, type },
        });
      }}
      handleBulkSelect={email =>
        dispatch({ type: SELECTED_BULK_BUDGET_USERS, customInput: { email } })
      }
      toggleSelectAllBudgetUsers={selectAll =>
        dispatch({ type: SET_ALL_BULK_BUDGET_USERS, customInput: selectAll })
      }
      setBulkBudgetValues={(budgetType, value) =>
        dispatch({
          type: SET_BULK_BUDGET_VALUES,
          customInput: { budgetType, value },
        })
      }
      removeSelectedUsers={data =>
        dispatch({ type: SET_RMS_ADDED_TEMPLATE_USERS, customInput: data })
      }
      bulkRemove={() => dispatch({ type: DELETE_BULK_SELECTED_USERS })}
      createTemplateCallBack={createTemplate}
      createTemplateLoading={createTemplateLoading}
      createTemplateSuccessLoading={createTemplateSuccessLoading}
      createTemplateFailed={createTemplateFailed}
      editTemplate={editTemplate}
      getTemplateAdminDetails={getUserDetails}
      templateAdmin={templateAdmin}
      isCreateTemplate={setIsCreateTemplate}
      getTemplateData={setTemplateData}
      createTemplateFailMessage={createTemplateFailMessage}
      cancelLastUpdate={backUpData =>
        dispatch({
          type: RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE,
          payload: backUpData,
        })
      }
    />
  );
};

export default CreateTemplateContainer;
