import { adminDashboardMode } from '../../Common/constants';
import { avoidedDuplicationData } from '../../Common/helper';
import {
  RMS_CENTER_DETAILS_SUCCESS,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS,
  RMS_DEPARTMENT_DETAILS_SUCCESS,
  RMS_DESIGNATION_DETAILS_SUCCESS,
  RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS,
  RMS_FETCH_BUDGET_STATISTICS_FAILED,
  RMS_FETCH_BUDGET_STATISTICS_REQUEST,
  RMS_FETCH_BUDGET_STATISTICS_SUCCESS,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS,
  RMS_FETCH_USER_STATS_REQUEST,
  RMS_FETCH_USER_STATS_SUCCESS,
  RMS_FETCH_USER_STATS_FAILED,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_REQUEST,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS,
  RMS_FETCH_DASHBOARD_BUDGET_DETAILS_FAILED,
  RESET_TRAINING_REQUEST_STATS,
  RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS,
  RMS_GROUP_TEMPLATE_DETAILS_SUCCESS,
  RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS,
  RMS_QUALIFICATION_DETAILS_SUCCESS,
  RMS_RESET_REPORT_DASHBOARD_DETAILS,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS,
  RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL,
  RMS_FETCH_S3_FILE_SUCCESS,
  RMS_DASHBOARD_RESET_USER_STATS,
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST,
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS,
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS,
  RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL,
  RMS_FETCH_DESIGNATIONS_REQUEST,
  RMS_FETCH_DESIGNATIONS_SUCCESS,
  RMS_FETCH_DESIGNATIONS_FAILED,
  RMS_FETCH_S3_FILE_REQUEST,
  RMS_FETCH_S3_FILE_FAILED,
  RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS,
  RESET_FEEDBACK_S3_FILE_OBJECT,
  RESET_USER_DETAILS_S3BUCKET_DATA,
  RMS_COMPANY_DETAILS_SUCCESS,
} from '../actionTypes/superAdminDashboardTypes';

const INITIAL_STATE = {
  financialDuration: [],
  userId: '',
  budgetDetails: {},
  trainingRequestStats: [],
  normalTrainingRequestStats: [],
  groupTrainingRequestStats: [],
  overallTrainingRequestStats: [],
  normalBudgetRequestStats: [],
  groupBudgetRequestStats: [],
  overAllBudgetRequestStats: [],
  budgetStats: [],
  templates: [],
  isStatisticsLoading: false,
  isTemplatesLoading: false,
  isUserStatsLoading: false,
  userStats: [],
  userStatsTotalRecords: 0,
  userStatsPage: 0,
  requestTotalRecords: 0,
  requestPage: 0,
  bondsTotalRecords: 0,
  bondPage: 0,
  userReportDashboardDetails: null,
  normalTemplateDetails: null,
  groupTemplateDetails: null,
  qualificationDetails: null,
  designationDetails: null,
  departmentDetails: null,
  centerDetails: null,
  trainingRequestsData: [],
  trainingRequestDataLoading: false,
  requestS3FileObject: null,
  isRequestS3FileObjectFailed: false,
  feedbackS3FileObject: null,
  isFeedbackS3FileObjectFailed: false,
  bondDetails: [],
  bondDetailsLoading: false,
  designations: [],
  isDesignationLoading: false,
  activeFinancialDuration: [],
  companyDetails: null,
};

const superAdminDashboardReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS:
      return {
        ...state,
        financialDuration: data || [],
      };
    case RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST:
      return {
        ...state,
        isStatisticsLoading: true,
      };
    case RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS:
      return {
        ...state,
        normalTrainingRequestStats: getNormalRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        groupTrainingRequestStats: getGroupRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        overallTrainingRequestStats: getOverallRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        isStatisticsLoading: false,
      };
    case RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED:
      return {
        ...state,
        isStatisticsLoading: false,
      };
    case RMS_FETCH_BUDGET_STATISTICS_REQUEST:
      return {
        ...state,
        isStatisticsLoading: true,
      };
    case RMS_FETCH_BUDGET_STATISTICS_SUCCESS:
      return {
        ...state,
        normalBudgetRequestStats: getNormalBudgetRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        groupBudgetRequestStats: getGroupBudgetRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        overAllBudgetRequestStats: getOverallBudgetRequestStats(
          customResponse.selectionMode,
          action.payload
        ),
        isStatisticsLoading: false,
      };
    case RMS_FETCH_BUDGET_STATISTICS_FAILED:
      return {
        ...state,
        isStatisticsLoading: false,
        normalBudgetRequestStats: [],
        groupBudgetRequestStats: [],
        overAllBudgetRequestStats: [],
      };
    case RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST:
      return {
        ...state,
        isTemplatesLoading: true,
      };
    case RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: data || [],
        isTemplatesLoading: false,
      };
    case RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED:
      return {
        ...state,
        isTemplatesLoading: false,
      };
    case RMS_FETCH_USER_STATS_REQUEST:
      return {
        ...state,
        isUserStatsLoading: true,
      };
    case RMS_FETCH_USER_STATS_SUCCESS:
      return {
        ...state,
        isUserStatsLoading: false,
        userStats:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(state.userStats, data || [], 'email'),
        userStatsTotalRecords: totalRecords || state.userStatsTotalRecords,
        userStatsPage: page || state.userStatsPage,
      };
    case RMS_FETCH_USER_STATS_FAILED:
      return {
        ...state,
        isUserStatsLoading: false,
      };
    case RMS_DASHBOARD_RESET_USER_STATS:
      return {
        ...state,
        userStats: [],
      };
    case RMS_FETCH_DASHBOARD_BUDGET_DETAILS_SUCCESS:
      return {
        ...state,
        budgetDetails: action.payload || {},
      };
    case RMS_GET_USER_DETAILS_DASHBOARD_SUCCESS:
      return {
        ...state,
        userReportDashboardDetails: data?.[0] || null,
        userId: data?.[0]?._id || '',
      };
    case RMS_NORMAL_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        normalTemplateDetails: data?.[0] || null,
      };
    case RMS_GROUP_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        groupTemplateDetails: data?.[0] || null,
      };
    case RMS_QUALIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        qualificationDetails: data?.[0] || null,
      };
    case RMS_DESIGNATION_DETAILS_SUCCESS:
      return {
        ...state,
        designationDetails: data?.[0] || null,
      };
    case RMS_CENTER_DETAILS_SUCCESS:
      return {
        ...state,
        centerDetails: data?.[0] || null,
      };

    case RMS_DEPARTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        departmentDetails: data?.[0] || null,
      };

    case RMS_RESET_REPORT_DASHBOARD_DETAILS:
      return {
        ...state,
        userReportDashboardDetails: null,
        normalTemplateDetails: null,
        groupTemplateDetails: null,
        qualificationDetails: null,
        designationDetails: null,
        departmentDetails: null,
        centerDetails: null,
        companyDetails: null,
        trainingRequestsData: [],
      };

    case RESET_TRAINING_REQUEST_STATS:
      return {
        ...state,
        normalTrainingRequestStats: [],
        groupTrainingRequestStats: [],
        overallTrainingRequestStats: [],
      };
    case RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_REQUEST:
      return {
        ...state,
        trainingRequestDataLoading: true,
      };
    case RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_SUCCESS:
      return {
        ...state,
        trainingRequestsData:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(
                state.trainingRequestsData,
                data || [],
                '_id'
              ),
        requestPage: page || state.usersPage,
        requestTotalRecords: totalRecords || state.totalRecords,
        trainingRequestDataLoading: false,
      };
    case RMS_REPORTING_DASHBOARD_FETCH_TRAINING_REQUESTS_FAIL:
      return {
        ...state,
        trainingRequestDataLoading: false,
      };

    case RMS_FETCH_S3_FILE_REQUEST:
      return {
        ...state,
        isRequestS3FileObjectFailed: false,
        requestS3FileObject: null,
      };

    case RMS_FETCH_S3_FILE_SUCCESS:
      return {
        ...state,
        requestS3FileObject: payload || null,
        isRequestS3FileObjectFailed: false,
      };

    case RMS_FETCH_S3_FILE_FAILED:
      return {
        ...state,
        isRequestS3FileObjectFailed: true,
      };

    case RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST:
      return {
        ...state,
        isFeedbackS3FileObjectFailed: false,
      };
    case RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS:
      return {
        ...state,
        feedbackS3FileObject: payload || [],
        isFeedbackS3FileObjectFailed: false,
      };
    case RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED:
      return {
        ...state,
        isFeedbackS3FileObjectFailed: true,
      };
    case RMS_DASHBOARD_FETCH_BOND_DETAILS_REQUEST:
      return {
        ...state,
        bondDetailsLoading: true,
      };
    case RESET_FEEDBACK_S3_FILE_OBJECT:
      return {
        ...state,
        feedbackS3FileObject: null,
        isFeedbackS3FileObjectFailed: false,
      };
    case RMS_DASHBOARD_FETCH_BOND_DETAILS_SUCCESS:
      return {
        ...state,
        bondDetails:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(state.bondDetails, data || [], '_id'),
        bondPage: page || 0,
        bondsTotalRecords: totalRecords || 0,
        bondDetailsLoading: false,
      };
    case RMS_DASHBOARD_FETCH_BOND_DETAILS_FAIL:
      return {
        ...state,
        bondDetailsLoading: false,
      };

    case RMS_FETCH_DESIGNATIONS_REQUEST:
      return {
        ...state,
        isDesignationLoading: true,
      };
    case RMS_FETCH_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        designations: data || [],
        isDesignationLoading: false,
      };
    case RMS_FETCH_DESIGNATIONS_FAILED:
      return {
        ...state,
        isDesignationLoading: false,
      };
    case RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS:
      return {
        ...state,
        trainingRequestsData: [],
        activeFinancialDuration: data || [],
      };
    case RESET_USER_DETAILS_S3BUCKET_DATA:
      return {
        ...state,
        requestS3FileObject: null,
        isRequestS3FileObjectFailed: false,
      };

    case RMS_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: data?.[0] || null,
      };

    default:
      return state;
  }
};

const getNormalRequestStats = (selectionMode, payload) => {
  let normalRequestStats = [];
  if (selectionMode === adminDashboardMode.NORMAL) {
    normalRequestStats = payload || [];
  }
  return normalRequestStats;
};
const getGroupRequestStats = (selectionMode, payload) => {
  let groupRequestStats = [];
  if (selectionMode === adminDashboardMode.GROUP) {
    groupRequestStats = payload || [];
  }
  return groupRequestStats;
};
const getOverallRequestStats = (selectionMode, payload) => {
  let overallRequestStats = [];
  if (selectionMode === adminDashboardMode.OVERALL) {
    overallRequestStats = payload || [];
  }
  return overallRequestStats;
};
const getNormalBudgetRequestStats = (selectionMode, payload) => {
  let normalBudgetRequestStats = [];
  if (selectionMode === adminDashboardMode.NORMAL) {
    normalBudgetRequestStats = payload || [];
  }
  return normalBudgetRequestStats;
};
const getGroupBudgetRequestStats = (selectionMode, payload) => {
  let groupBudgetRequestStats = [];
  if (selectionMode === adminDashboardMode.GROUP) {
    groupBudgetRequestStats = payload || [];
  }
  return groupBudgetRequestStats;
};
const getOverallBudgetRequestStats = (selectionMode, payload) => {
  let overallBudgetRequestStats = [];
  if (selectionMode === adminDashboardMode.OVERALL) {
    overallBudgetRequestStats = payload || [];
  }
  return overallBudgetRequestStats;
};

export default superAdminDashboardReducer;
