export const RMS_GET_USER_MODE_REQUESTS_REQUEST =
  'RMS_GET_USER_MODE_REQUESTS_REQUEST';
export const RMS_GET_USER_MODE_REQUESTS_SUCCESS =
  'RMS_GET_USER_MODE_REQUESTS_SUCCESS';
export const RMS_GET_USER_MODE_REQUESTS_FAILED =
  'RMS_GET_USER_MODE_REQUESTS_FAILED';

export const RMS_GET_ADMIN_MODE_REQUESTS_REQUEST =
  'RMS_GET_ADMIN_MODE_REQUESTS_REQUEST';
export const RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS =
  'RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS';
export const RMS_GET_ADMIN_MODE_REQUESTS_FAILED =
  'RMS_GET_ADMIN_MODE_REQUESTS_FAILED';

export const RMS_DELETE_REQUEST_REQUEST = 'RMS_DELETE_REQUEST_REQUEST';
export const RMS_DELETE_REQUEST_SUCCESS = 'RMS_DELETE_REQUEST_SUCCESS';
export const RMS_DELETE_REQUEST_FAILED = 'RMS_DELETE_REQUEST_FAILED';

export const RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST =
  'RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST';
export const RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS =
  'RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS';
export const RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED =
  'RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED';

export const RMS_RESET_REQUEST_DATA = 'RMS_RESET_REQUEST_DATA';

export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS';
export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED';
export const RMS_GET_REQUEST_USERS_REQUEST = 'RMS_GET_REQUEST_USERS_REQUEST';
export const RMS_GET_REQUEST_USERS_SUCCESS = 'RMS_GET_REQUEST_USERS_SUCCESS';
export const RMS_GET_REQUEST_USERS_FAILED = 'RMS_GET_REQUEST_USERS_FAILED';

export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS';
export const RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS';

export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS';
export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED';

export const RMS_GET_FEEDBACK_REQUEST_REQUEST =
  'RMS_GET_FEEDBACK_REQUEST_REQUEST';
export const RMS_GET_FEEDBACK_REQUEST_SUCCESS =
  'RMS_GET_FEEDBACK_REQUEST_SUCCESS';
export const RMS_GET_FEEDBACK_REQUEST_FAILED =
  'RMS_GET_FEEDBACK_REQUEST_FAILED';
