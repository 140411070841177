import React, { useEffect, useState } from 'react';
import RMSPageTitle from '../../Components/CommonComponents/RMSPageTitle';
import GraphicalLayout from '../../Components/Dashboard/GraphicalLayout';
import useApi from '../../../Hooks/useApi';
import { useSelector, useDispatch } from 'react-redux';
import { Services, adminDashboardMode } from '../../Common/constants';
import TableHeader from '../../Components/Dashboard/TableSubComponents/TableHeader';
import TableGrid from '../../Components/Dashboard/TableSubComponents/TableGrid';
import useDebounce from '../../hooks/useDebounce';
import useS3FileView from '../../hooks/useS3FileView';

import { userStatsSearchDropDown } from '../../Common/constants';
import useFetchLoggedInUserDetails from '../../hooks/useFetchLoggedInUserDetails';
import {
  RMS_FETCH_ALL_FINANCIAL_DURATION_REQUEST,
  RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS,
  RMS_FETCH_ALL_FINANCIAL_DURATION_FAILED,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS,
  RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED,
  RMS_FETCH_BUDGET_STATISTICS_REQUEST,
  RMS_FETCH_BUDGET_STATISTICS_SUCCESS,
  RMS_FETCH_BUDGET_STATISTICS_FAILED,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS,
  RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED,
  RMS_FETCH_USER_STATS_REQUEST,
  RMS_FETCH_USER_STATS_SUCCESS,
  RMS_FETCH_USER_STATS_FAILED,
  RESET_TRAINING_REQUEST_STATS,
  RMS_FETCH_DESIGNATIONS_REQUEST,
  RMS_FETCH_DESIGNATIONS_SUCCESS,
  RMS_FETCH_DESIGNATIONS_FAILED,
  RMS_FETCH_S3_FILE_REQUEST,
  RMS_FETCH_S3_FILE_SUCCESS,
  RMS_FETCH_S3_FILE_FAILED,
  RMS_DASHBOARD_RESET_USER_STATS,
  RESET_USER_DETAILS_S3BUCKET_DATA,
} from '../../redux/actionTypes/superAdminDashboardTypes';

const AdminDashboardContainer = ({
  selectedDateRangeId,
  setSelectedDateRangeId,
  isSuperAdminDashboard,
  selectedTemplate,
  setSelectedTemplate,
  selectedTemplateLabel,
  setSelectedTemplateLabel,
  templateSearchText,
  setTemplateSearchText,
  selectedTemplateOption,
  setSelectedTemplateOption,
  selectedMode,
  setSelectedMode,
}) => {
  const {
    financialDuration,
    normalTrainingRequestStats,
    groupTrainingRequestStats,
    overallTrainingRequestStats,
    budgetStats,
    isStatisticsLoading,
    templates,
    isTemplatesLoading,
    isUserStatsLoading,
    userStats,
    userStatsTotalRecords,
    userStatsPage,
    designations,
    isDesignationLoading,
    normalBudgetRequestStats,
    groupBudgetRequestStats,
    overAllBudgetRequestStats,
    requestS3FileObject,
    isRequestS3FileObjectFailed,
    feedbackS3FileObject,
    isFeedbackS3FileObjectFailed,
  } = useSelector(state => state.rmsSuperAdminDashboard);

  const [fetchFinancialDuration] = useApi();
  const [fetchTrainingRequestStats] = useApi();
  const [fetchBudgetStatistics] = useApi();
  const [fetchAllTemplates] = useApi();
  const [fetchUserStats] = useApi();
  const [fetchDesignations] = useApi();
  const [fetchReport] = useApi();
  const [getFromBucket, url, isFileDownloadStarted] = useS3FileView();

  useEffect(() => {
    requestS3FileObject?.fileKey &&
      getFromBucket(
        requestS3FileObject?.bucketName,
        requestS3FileObject?.fileKey,
        {
          timeDelay: 5000,
          isAutoDownload: true,
          errorAction: RMS_FETCH_S3_FILE_FAILED,
        }
      );
  }, [requestS3FileObject]);

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [selectedFilterOptions, setSelectedFilterOptions] = useState();
  const debouncedSearchText = useDebounce(searchText, 500);
  const [selectedDesignation, setSelectedDesignation] = useState();
  const [selectedSearchFilterOptions, setSelectedSearchFilterOptions] =
    useState();
  const userDetails = useFetchLoggedInUserDetails();

  const getFinancialDuration = () => {
    fetchFinancialDuration(
      `/api/v1/financialDurations?page=1&size=50`,
      RMS_FETCH_ALL_FINANCIAL_DURATION_REQUEST,
      RMS_FETCH_ALL_FINANCIAL_DURATION_SUCCESS,
      RMS_FETCH_ALL_FINANCIAL_DURATION_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getTrainingRequestStats = (financialDurationId, selectedTemplateId) => {
    fetchTrainingRequestStats(
      `/api/v1/financialDurations/${financialDurationId}/${
        (selectedMode === adminDashboardMode.OVERALL &&
          'overalTrainingRequestStats') ||
        (selectedMode === adminDashboardMode.NORMAL &&
          'normalTrainingRequestStats') ||
        (selectedMode === adminDashboardMode.GROUP &&
          'groupTrainingRequestStats')
      }?templateId=${
        selectedMode !== adminDashboardMode.OVERALL ? selectedTemplateId : ''
      }`,
      RMS_FETCH_TRAINING_REQUEST_STATISTICS_REQUEST,
      RMS_FETCH_TRAINING_REQUEST_STATISTICS_SUCCESS,
      RMS_FETCH_TRAINING_REQUEST_STATISTICS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService,
      { selectionMode: selectedMode }
    );
  };

  const getBudgetStatistics = (financialDurationId, selectedTemplateId) => {
    fetchBudgetStatistics(
      `/api/v1/financialDurations/${financialDurationId}/${
        (selectedMode === adminDashboardMode.OVERALL && 'budgetStats') ||
        (selectedMode === adminDashboardMode.NORMAL &&
          'normalRequestBudgetStats') ||
        (selectedMode === adminDashboardMode.GROUP && 'groupRequestBudgetStats')
      }?templateId=${
        selectedMode !== adminDashboardMode.OVERALL ? selectedTemplateId : ''
      }`,
      RMS_FETCH_BUDGET_STATISTICS_REQUEST,
      RMS_FETCH_BUDGET_STATISTICS_SUCCESS,
      RMS_FETCH_BUDGET_STATISTICS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService,
      { selectionMode: selectedMode }
    );
  };

  const getAllTemplates = (type = '', searchText) => {
    fetchAllTemplates(
      `/api/v1/templates?type=${type.toUpperCase()}_TEMPLATE&searchNameLike=${searchText}${
        !isSuperAdminDashboard ? `&adminId=${userDetails?._id}` : ''
      }&page=1&size=10`,
      RMS_DASHBOARD_FETCH_ALL_TEMPLATES_REQUEST,
      RMS_DASHBOARD_FETCH_ALL_TEMPLATES_SUCCESS,
      RMS_DASHBOARD_FETCH_ALL_TEMPLATES_FAILED,

      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getUserStats = (financialDurationId, page = 1) => {
    fetchUserStats(
      `/api/v1/financialDurations/${financialDurationId}/userStats?page=${page}&size=10${
        selectedTemplate ? `&templateId=${selectedTemplate}` : ''
      }${debouncedSearchText ? `&searchText=${debouncedSearchText}` : ''}${
        selectedDesignation ? `&filterDesignationId=${selectedDesignation}` : ''
      }${
        selectedSearchFilterOptions
          ? `&isBonded=${
              selectedSearchFilterOptions ===
              userStatsSearchDropDown.searchByYes
                ? true
                : false
            }`
          : ''
      }${
        debouncedSearchText && selectedFilterOptions
          ? `&searchInField=${selectedFilterOptions}`
          : ''
      }`,
      RMS_FETCH_USER_STATS_REQUEST,
      RMS_FETCH_USER_STATS_SUCCESS,
      RMS_FETCH_USER_STATS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService,
      { page }
    );
  };

  const getDesignations = designationSearchText => {
    fetchDesignations(
      `/api/v1/designations?page=1&size=10${
        designationSearchText ? `&searchNameLike=${designationSearchText}` : ''
      }`,
      RMS_FETCH_DESIGNATIONS_REQUEST,
      RMS_FETCH_DESIGNATIONS_SUCCESS,
      RMS_FETCH_DESIGNATIONS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    dispatch({
      type: RMS_DASHBOARD_RESET_USER_STATS,
    });

    if (selectedMode !== adminDashboardMode.OVERALL && !selectedTemplate)
      return;
    selectedDateRangeId && getUserStats(selectedDateRangeId);
  }, [
    selectedDateRangeId,
    selectedTemplate,
    debouncedSearchText,
    selectedDesignation,
    selectedSearchFilterOptions,
    selectedFilterOptions,
    selectedMode,
  ]);

  const loadNextPage = () => {
    if (!isSuperAdminDashboard && !selectedTemplate) return;
    selectedDateRangeId &&
      userStatsTotalRecords > userStats?.length &&
      getUserStats(selectedDateRangeId, userStatsPage + 1);
  };

  const [selectedIds, setSelectedIds] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const exportReport = () => {
    fetchReport(
      `/api/v1/financialDurations/${selectedDateRangeId}/userStats/report${
        selectAll
          ? `${selectedTemplate ? `?templateId=${selectedTemplate}` : ''}`
          : `?userIds=${selectedIds}`
      }`,
      RMS_FETCH_S3_FILE_REQUEST,
      RMS_FETCH_S3_FILE_SUCCESS,
      RMS_FETCH_S3_FILE_FAILED,
      {},
      '',
      'POST',
      null,
      Services.isRmsService
    );
  };
  useEffect(() => {
    if (!isSuperAdminDashboard && !selectedTemplate) {
      dispatch({
        type: RMS_DASHBOARD_RESET_USER_STATS,
      });
    }
  }, [isSuperAdminDashboard, selectedTemplate]);

  const resetUserDetailsS3BucketData = () =>
    dispatch({
      type: RESET_USER_DETAILS_S3BUCKET_DATA,
    });

  useEffect(() => {
    return () => {
      resetUserDetailsS3BucketData();
    };
  }, []);

  return (
    <div className="RMS__page dashboard">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--content">
            <RMSPageTitle titleText="Reporting Dashboard" />
            <div className="dashboard__wrapper">
              <div className="dashboard__graphic">
                <GraphicalLayout
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                  getFinancialDuration={getFinancialDuration}
                  financialDuration={financialDuration}
                  getTrainingRequestStats={getTrainingRequestStats}
                  getBudgetStatistics={getBudgetStatistics}
                  budgetStats={budgetStats}
                  normalTrainingRequestStats={normalTrainingRequestStats}
                  groupTrainingRequestStats={groupTrainingRequestStats}
                  overallTrainingRequestStats={overallTrainingRequestStats}
                  loading={isStatisticsLoading}
                  getAllTemplates={getAllTemplates}
                  templates={templates}
                  isTemplatesLoading={isTemplatesLoading}
                  selectedDateRangeId={selectedDateRangeId}
                  setSelectedDateRangeId={setSelectedDateRangeId}
                  resetTrainingRequestStats={() => {
                    dispatch({
                      type: RESET_TRAINING_REQUEST_STATS,
                    });
                  }}
                  resetUserDetailsS3BucketData={resetUserDetailsS3BucketData}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                  normalBudgetRequestStats={normalBudgetRequestStats}
                  groupBudgetRequestStats={groupBudgetRequestStats}
                  overAllBudgetRequestStats={overAllBudgetRequestStats}
                  isSuperAdminDashboard={isSuperAdminDashboard}
                  userDetails={userDetails}
                  selectedTemplateLabel={selectedTemplateLabel}
                  setSelectedTemplateLabel={setSelectedTemplateLabel}
                  templateSearchText={templateSearchText}
                  setTemplateSearchText={setTemplateSearchText}
                  selectedTemplateOption={selectedTemplateOption}
                  setSelectedTemplateOption={setSelectedTemplateOption}
                />
              </div>
              <div className="dashboard--row dashboard__requests">
                <TableHeader
                  searchText={searchText}
                  setSearchText={setSearchText}
                  selectedFilterOptions={selectedFilterOptions}
                  setSelectedFilterOptions={setSelectedFilterOptions}
                  getDesignations={getDesignations}
                  designations={designations}
                  isDesignationLoading={isDesignationLoading}
                  setSelectedDesignation={setSelectedDesignation}
                  selectedSearchFilterOptions={selectedSearchFilterOptions}
                  setSelectedSearchFilterOptions={
                    setSelectedSearchFilterOptions
                  }
                  selectedIds={selectedIds}
                  exportReport={exportReport}
                  isRequestS3FileObjectFailed={isRequestS3FileObjectFailed}
                  url={url}
                  isFileDownloadStarted={isFileDownloadStarted}
                  resetUserDetailsS3BucketData={resetUserDetailsS3BucketData}
                />
                <TableGrid
                  loadNextPage={loadNextPage}
                  isUserStatsLoading={isUserStatsLoading}
                  userStats={userStats}
                  onSelectUserIds={setSelectedIds}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  isSuperAdminDashboard={isSuperAdminDashboard}
                  selectedTemplate={selectedTemplate}
                  setSelectedDateRangeId={setSelectedDateRangeId}
                  selectedDateRangeId={selectedDateRangeId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardContainer;
