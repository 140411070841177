export const RMS_GET_TEMPLATES_REQUEST = 'RMS_GET_TEMPLATES_REQUEST';
export const RMS_GET_TEMPLATES_SUCCESS = 'RMS_GET_TEMPLATES_SUCCESS';
export const RMS_GET_TEMPLATES_FAILED = 'RMS_GET_TEMPLATES_FAILED';

export const RMS_GET_TEMPLATE_USERS_REQUEST = 'RMS_GET_TEMPLATE_USERS_REQUEST';
export const RMS_GET_TEMPLATE_USERS_SUCCESS = 'RMS_GET_TEMPLATE_USERS_SUCCESS';
export const RMS_GET_TEMPLATE_USERS_FAILED = 'RMS_GET_TEMPLATE_USERS_FAILED';

export const RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST =
  'RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST';
export const RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS =
  'RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS';
export const RMS_GET_TEMPLATE_ADMIN_USERS_FAILED =
  'RMS_GET_TEMPLATE_ADMIN_USERS_FAILED';

export const RMS_GET_TEMPLATE_USERS_RESET = 'RMS_GET_TEMPLATE_USERS_RESET';

export const RMS_SAVE_TEMPLATE_USERS_REQUEST =
  'RMS_SAVE_TEMPLATE_USERS_REQUEST';
export const RMS_SAVE_TEMPLATE_USERS_SUCCESS =
  'RMS_SAVE_TEMPLATE_USERS_SUCCESS';
export const RMS_SAVE_TEMPLATE_USERS_FAILED = 'RMS_SAVE_TEMPLATE_USERS_FAILED';

export const RMS_RESET_ACCESS_CONTROL = 'RMS_RESET_ACCESS_CONTROL';
