import { avoidedDuplicationData } from '../../Common/helper';
import {
  RMS_GET_APPLICANT_BONDS_FAILED,
  RMS_GET_APPLICANT_BONDS_REQUEST,
  RMS_GET_APPLICANT_BONDS_SUCCESS,
  RMS_RESET_APPLICANT_BONDS,
} from '../actionTypes/applicantBondsTypes';

const INITIAL_STATE = {
  bonds: [],
  bondsLoading: false,
  bondsPage: 0,
};
const applicantBondsReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_GET_APPLICANT_BONDS_REQUEST:
      return {
        ...state,
        bondsLoading: true,
      };
    case RMS_GET_APPLICANT_BONDS_SUCCESS:
      return {
        ...state,
        bondsLoading: false,
        bonds:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(state.bonds, data || [], '_id'),
        bondsPage: page || customResponse?.page,
      };
    case RMS_GET_APPLICANT_BONDS_FAILED:
      return {
        ...state,
        bondsLoading: false,
      };

    case RMS_RESET_APPLICANT_BONDS:
      return {
        ...state,
        bonds: [],
      };
    default:
      return state;
  }
};
export default applicantBondsReducer;
