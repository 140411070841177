import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import {
  RMS_LANDING_SUCCESS,
  RESET_RMS_LANDING,
  RMS_LANDING_FAIL,
  RMS_LOGIN_USER_TEMPLATE_SUCCESS,
  RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS,
  RMS_SERVICE_STATUS_REQUEST,
  RMS_SERVICE_STATUS_SUCCESS,
  RMS_SERVICE_STATUS_FAIL,
  RESET_RMS_SERVICE_STATUS,
} from '../actionTypes/landing';
import { ServiceHealth } from '../../Common/constants';

const InitialState = {
  landingLoading: true,
  landingData: null,
  loginApplicantNormalTemplate: null,
  loginApplicantGroupTemplate: null,
  isRmsHealthCheckerLoading: false,
  isRmsHealthStatusSuccess: false,
};

const landingReducer = (state = InitialState, action) => {
  switch (action.type) {
    case RMS_LANDING_SUCCESS:
      return {
        ...state,
        landingLoading: false,
        landingData: action?.payload?.data?.[0],
      };
    case RMS_LANDING_FAIL:
      return {
        ...state,
        landingLoading: false,
      };

    case RMS_LOGIN_USER_TEMPLATE_SUCCESS:
      return {
        ...state,
        loginApplicantNormalTemplate: action?.payload?.data || null,
      };
    case RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS:
      return {
        ...state,
        loginApplicantGroupTemplate: action?.payload?.data || null,
      };
    case RMS_SERVICE_STATUS_REQUEST:
      return {
        ...state,
        isRmsHealthCheckerLoading: true,
      };
    case RMS_SERVICE_STATUS_SUCCESS:
      return {
        ...state,
        isRmsHealthCheckerLoading: false,
        isRmsHealthStatusSuccess:
          action?.payload?.status === ServiceHealth.serviceUp,
      };
    case RMS_SERVICE_STATUS_FAIL:
      return {
        ...state,
        isRmsHealthCheckerLoading: false,
      };
    case RESET_RMS_SERVICE_STATUS:
      return {
        ...state,
        isRmsHealthCheckerLoading: false,
        isRmsHealthStatusSuccess: false,
      };
    case RESET_RMS_LANDING:
    case CLEAR_SESSION:
      return {
        ...InitialState,
      };

    default:
      return state;
  }
};

export default landingReducer;
