import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useConvertId from '../../../../hooks/useConvertId';
import useConvertDate from '../../../../hooks/useConvertDate';

const EmployeeDetails = ({
  applicantDetails,
  designations,
  centers,
  qualifications,
  departments,
}) => {
  const { t } = useTranslation();
  const { convertDateToThemeFormat } = useConvertDate();
  const {
    getQualificationDetails,
    getCenterDetails,
    getDepartmentDetails,
    getDesignationDetails,
  } = useConvertId();

  const {
    name,
    nricNo,
    qualificationId,
    departmentId,
    centerId,
    designationId,
    dob,
    joinedDate,
  } = applicantDetails?.data?.[0] || {};

  useEffect(() => {
    if (!applicantDetails) return;
    getCenterDetails(centerId);
    getDesignationDetails(designationId);
    getDepartmentDetails(departmentId);
    getQualificationDetails(qualificationId);
  }, [applicantDetails]);

  return (
    <div>
      <div className="RMS__page--body">
        <div className="RMS__page--body-row fourColumn">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Name')}</p>
              <div className="form--field-input">
                <input
                  readOnly
                  type="text"
                  placeholder={t('N/A')}
                  value={name}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('NRIC NO')}</p>
              <div className="form--field-input">
                <input
                  readOnly
                  type="text"
                  placeholder={t('N/A')}
                  value={nricNo}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">
                {t('Highest Qualification')}
              </p>
              <div className="form--field-input">
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('N/A')}
                    value={qualifications?.[qualificationId]?.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Department Id')}</p>
              <div className="form--field-input">
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('N/A')}
                    value={departments?.[departmentId]?.name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="RMS__page--body-row fourColumn">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Section Code')}</p>
              <div className="form--field-input">
                <input
                  readOnly
                  type="text"
                  placeholder={t('N/A')}
                  value={centers?.[centerId]?.name}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Designation')}</p>
              <div className="form--field-input">
                <input
                  readOnly
                  type="text"
                  placeholder={t('N/A')}
                  value={designations?.[designationId]?.name}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title">{t('Date of Birth')}</p>
              <div className="form--field-input">
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('N/A')}
                    value={convertDateToThemeFormat(dob)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title">{t('Joined Date')}</p>
              <div className="form--field-input">
                <input
                  readOnly
                  type="text"
                  placeholder={t('N/A')}
                  value={convertDateToThemeFormat(joinedDate)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="RMS__page--divider"></div>
    </div>
  );
};

export default EmployeeDetails;
