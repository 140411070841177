import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useApi from '../../../Hooks/useApi';
import { Services } from '../../Common/constants';
import ManageRequestForm from '../../Components/ManageRequets/ManageRequestForm/ManageRequestForm';
import {
  RMS_ADMIN_UPDATE_REQUEST_FAILED,
  RMS_ADMIN_UPDATE_REQUEST_REQUEST,
  RMS_ADMIN_UPDATE_REQUEST_RESET,
  RMS_ADMIN_UPDATE_REQUEST_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
} from '../../redux/actionTypes/adminManageRequests';
import {
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS,
} from '../../redux/actionTypes/manageRequestTypes';

const AdminManageRequestFormContainer = props => {
  const { t } = useTranslation();
  const [updateRequestApi] = useApi();
  const [fetchApprovalLevels] = useApi();
  const [fetchApprovalLevelInfo] = useApi();
  const [fetchApplicantNames] = useApi();
  const [fetchTrainingRequstDetailsById] = useApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    approvalLevels,
    approvalInfo,
    approverNames,
    approvalHistoryLoading,
  } = useSelector(state => state.rmsManageRequest);

  const {
    updateRequestLoading,
    updateRequestSuccess,
    updateRequestFailed,
    requestDetailsById,
    updateRequestFailedMessage,
  } = useSelector(state => state.rmsAdminManageRequest);

  const [updatedCourseData, setUpdatedCourseData] = useState({ course: {} });
  const [selectedApprovalLevel, setSelectedApprovalLevel] = useState(1);
  const [updateReqErrorMessage, setUpdateReqErrorMessage] = useState('');

  const onRequestUpdate = () => {
    if (!props?.formData?._id) return;

    const requestBody = {
      _id: props.formData._id,
      ...updatedCourseData,
    };

    updateRequestApi(
      `/api/v1/trainingRequests`,
      RMS_ADMIN_UPDATE_REQUEST_REQUEST,
      RMS_ADMIN_UPDATE_REQUEST_SUCCESS,
      RMS_ADMIN_UPDATE_REQUEST_FAILED,
      requestBody,
      {},
      'PUT',
      false,
      Services.isRmsService
    );
  };

  const getApprovalLevels = requestId => {
    fetchApprovalLevels(
      `/api/v1/trainingRequests/${requestId}/approvalLevels?page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApprovalLevelInfo = (requestId, selectedApprovalLevel) => {
    fetchApprovalLevelInfo(
      `/api/v1/trainingRequests/${requestId}/approvalLevels/${selectedApprovalLevel}`,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApplicantNames = (userIds = []) => {
    fetchApplicantNames(
      `/api/v1/users?userIds=${userIds}&page=1&size=${userIds.length}`,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getTrainingRequstDetailsById = trainingRequestId => {
    fetchTrainingRequstDetailsById(
      `/api/v1/trainingRequests/${trainingRequestId}`,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    if (!updateRequestLoading) {
      if (updateRequestSuccess) {
        history.goBack();
      } else if (updateRequestFailed) {
        setUpdateReqErrorMessage(
          updateRequestFailedMessage
            ? t(updateRequestFailedMessage)
            : t('Something Went Wrong, Please Try Again Later.')
        );
      }
    } else {
      setUpdateReqErrorMessage('');
    }
  }, [updateRequestLoading, updateRequestSuccess, updateRequestFailed]);

  useEffect(() => {
    if (!props?.match) {
      dispatch({
        type: RMS_ADMIN_UPDATE_REQUEST_RESET,
      });
      setUpdateReqErrorMessage('');
    } else {
      props?.match?.params?.formId &&
        getTrainingRequstDetailsById(props?.match?.params?.formId);
    }
  }, [props.match]);

  useEffect(() => {
    getApprovalLevels(requestDetailsById?._id);
  }, [requestDetailsById]);

  useEffect(() => {
    getApprovalLevelInfo(requestDetailsById?._id, selectedApprovalLevel);
  }, [selectedApprovalLevel, requestDetailsById]);

  const updateCourseData = (key, val) => {
    setUpdatedCourseData(current => ({
      course: {
        ...current.course,
        [key]: val,
      },
    }));
  };

  return (
    <ManageRequestForm
      {...props}
      updateCourseData={updateCourseData}
      onRequestUpdate={onRequestUpdate}
      btnLoading={updateRequestLoading}
      ApprovalLevels={approvalLevels}
      selectedApprovalLevel={selectedApprovalLevel}
      setSelectedApprovalLevel={setSelectedApprovalLevel}
      approvalInfo={approvalInfo}
      approverNames={approverNames}
      approvalHistoryLoading={approvalHistoryLoading}
      getApplicantNames={getApplicantNames}
      errorMessage={updateReqErrorMessage}
    />
  );
};

export default AdminManageRequestFormContainer;
