export const SEARCH_RMS_CONVERTID_USERS_REQUEST =
  'SEARCH_RMS_CONVERTID_USERS_REQUEST';
export const SEARCH_RMS_CONVERTID_USERS_SUCCESS =
  'SEARCH_RMS_CONVERTID_USERS_SUCCESS';
export const SEARCH_RMS_CONVERTID_USERS_FAILED =
  'SEARCH_RMS_CONVERTID_USERS_FAILED';

export const SEARCH_RMS_CONVERTID_USERS_RESET =
  'SEARCH_RMS_CONVERTID_USERS_RESET';

export const GET_RMS_CONVERTID_USER_QUALIFICATIONS_REQUEST =
  'GET_RMS_CONVERTID_USER_QUALIFICATIONS_REQUEST';
export const GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS =
  'GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS';
export const GET_RMS_CONVERTID_USER_QUALIFICATIONS_FAILED =
  'GET_RMS_CONVERTID_USER_QUALIFICATIONS_FAILED';

export const GET_RMS_CONVERTID_CENTER_DETAILS_REQUEST =
  'GET_RMS_CONVERTID_CENTER_DETAILS_REQUEST';
export const GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS =
  'GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS';
export const GET_RMS_CONVERTID_CENTER_DETAILS_FAILED =
  'GET_RMS_CONVERTID_CENTER_DETAILS_FAILED';

export const GET_RMS_CONVERTID_CENTER_REQUEST =
  'GET_RMS_CONVERTID_CENTER_REQUEST';
export const GET_RMS_CONVERTID_CENTER_SUCCESS =
  'GET_RMS_CONVERTID_CENTER_SUCCESS';
export const GET_RMS_CONVERTID_CENTER_FAILED =
  'GET_RMS_CONVERTID_CENTER_FAILED';

export const GET_RMS_CONVERTID_DEPARTMENT_REQUEST =
  'GET_RMS_CONVERTID_DEPARTMENT_REQUEST';
export const GET_RMS_CONVERTID_DEPARTMENT_SUCCESS =
  'GET_RMS_CONVERTID_DEPARTMENT_SUCCESS';
export const GET_RMS_CONVERTID_DEPARTMENT_FAILED =
  'GET_RMS_CONVERTID_DEPARTMENT_FAILED';

export const GET_RMS_CONVERTID_DESIGNATION_REQUEST =
  'GET_RMS_CONVERTID_DESIGNATION_REQUEST';
export const GET_RMS_CONVERTID_DESIGNATION_SUCCESS =
  'GET_RMS_CONVERTID_DESIGNATION_SUCCESS';
export const GET_RMS_CONVERTID_DESIGNATION_FAILED =
  'GET_RMS_CONVERTID_DESIGNATION_FAILED';

export const GET_RMS_CONVERTID_QUALIFICATION_REQUEST =
  'GET_RMS_CONVERTID_QUALIFICATION_REQUEST';
export const GET_RMS_CONVERTID_QUALIFICATION_SUCCESS =
  'GET_RMS_CONVERTID_QUALIFICATION_SUCCESS';
export const GET_RMS_CONVERTID_QUALIFICATION_FAILED =
  'GET_RMS_CONVERTID_QUALIFICATION_FAILED';

export const GET_RMS_CONVERTID_DEPARTMENT_DETAILS_REQUEST =
  'GET_RMS_CONVERTID_DEPARTMENT_DETAILS_REQUEST';
export const GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS =
  'GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS';
export const GET_RMS_CONVERTID_DEPARTMENT_DETAILS_FAILED =
  'GET_RMS_CONVERTID_DEPARTMENT_DETAILS_FAILED';

export const GET_RMS_CONVERTID_DESIGNATION_DETAILS_REQUEST =
  'GET_RMS_CONVERTID_DESIGNATION_DETAILS_REQUEST';
export const GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS =
  'GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS';
export const GET_RMS_CONVERTID_DESIGNATION_DETAILS_FAILED =
  'GET_RMS_CONVERTID_DESIGNATION_DETAILS_FAILED';

export const RMS_CONVERTID_USER_MANAGEMENT_REQUEST =
  'RMS_CONVERTID_USER_MANAGEMENT_REQUEST';
export const RMS_CONVERTID_USER_MANAGEMENT_SUCCESS =
  'RMS_CONVERTID_USER_MANAGEMENT_SUCCESS';
export const RMS_CONVERTID_USER_MANAGEMENT_FAIL =
  'RMS_CONVERTID_USER_MANAGEMENT_FAIL';

export const RMS_CONVERTID_EXISTING_USER_MANAGEMENT_REQUEST =
  'RMS_CONVERTID_EXISTING_USER_MANAGEMENT_REQUEST';
export const RMS_CONVERTID_EXISTING_USER_MANAGEMENT_SUCCESS =
  'RMS_CONVERTID_EXISTING_USER_MANAGEMENT_SUCCESS';
export const RMS_CONVERTID_EXISTING_USER_MANAGEMENT_FAIL =
  'RMS_CONVERTID_EXISTING_USER_MANAGEMENT_FAIL';

export const RMS_CONVERTID_POST_ADDED_USERS_REQUEST =
  'RMS_CONVERTID_POST_ADDED_USERS_REQUEST';
export const RMS_CONVERTID_POST_ADDED_USERS_SUCCESS =
  'RMS_CONVERTID_POST_ADDED_USERS_SUCCESS';
export const RMS_CONVERTID_POST_ADDED_USERS_FAIL =
  'RMS_CONVERTID_POST_ADDED_USERS_FAIL';

export const RESET_ADD_USER_POPUP_DATA = 'RESET_ADD_USER_POPUP_DATA';

export const RMS_CONVERTID_SEARCH_LOADING_SET =
  'RMS_CONVERTID_SEARCH_LOADING_SET';

export const RMS_CONVERTID_ADD_REMOVE_SELECTED_USERS =
  'RMS_CONVERTID_ADD_REMOVE_SELECTED_USERS';

export const RMS_CONVERTID_UPDATE_USER_REQUEST =
  'RMS_CONVERTID_UPDATE_USER_REQUEST';
export const RMS_CONVERTID_UPDATE_USER_SUCCESS =
  'RMS_CONVERTID_UPDATE_USER_SUCCESS';
export const RMS_CONVERTID_UPDATE_USER_FAILED =
  'RMS_CONVERTID_UPDATE_USER_FAILED';

export const RMS_CONVERTID_RESET_USER_UPDATE =
  'RMS_CONVERTID_RESET_USER_UPDATE';

export const RMS_CONVERTID_RESET_USER_MANAGEMENT =
  'RMS_CONVERTID_RESET_USER_MANAGEMENT';
