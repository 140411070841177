export const GET_RMS_TEMPLATE_REQUEST = 'GET_RMS_TEMPLATE_REQUEST';
export const GET_RMS_TEMPLATE_SUCCESS = 'GET_RMS_TEMPLATE_SUCCESS';
export const GET_RMS_TEMPLATE_FAIL = 'GET_RMS_TEMPLATE_FAIL';

export const GET_RMS_TEMPLATE_DESIGNATION_REQUEST =
  'GET_RMS_TEMPLATE_DESIGNATION_REQUEST';
export const GET_RMS_TEMPLATE_DESIGNATION_SUCCESS =
  'GET_RMS_TEMPLATE_DESIGNATION_SUCCESS';
export const GET_RMS_TEMPLATE_DESIGNATION_FAIL =
  'GET_RMS_TEMPLATE_DESIGNATION_FAIL';

export const GET_RMS_TEMPLATE_DEPARTMENT_REQUEST =
  'GET_RMS_TEMPLATE_DEPARTMENT_REQUEST';
export const GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS =
  'GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS';
export const GET_RMS_TEMPLATE_DEPARTMENT_FAIL =
  'GET_RMS_TEMPLATE_DEPARTMENT_FAIL';

export const GET_RMS_TEMPLATE_USERS_REQUEST = 'GET_RMS_TEMPLATE_USERS_REQUEST';
export const GET_RMS_TEMPLATE_USERS_SUCCESS = 'GET_RMS_TEMPLATE_USERS_SUCCESS';
export const GET_RMS_TEMPLATE_USERS_FAIL = 'GET_RMS_TEMPLATE_USERS_FAIL';

export const GET_RMS_USERS_DEPARTMENTS_REQUEST =
  'GET_RMS_USERS_DEPARTMENTS_REQUEST';
export const GET_RMS_USERS_DEPARTMENTS_SUCCESS =
  'GET_RMS_USERS_DEPARTMENTS_SUCCESS';
export const GET_RMS_USERS_DEPARTMENTS_FAIL = 'GET_RMS_USERS_DEPARTMENTS_FAIL';

export const GET_RMS_USERS_DESIGNATION_REQUEST =
  'GET_RMS_USERS_DESIGNATION_REQUEST';
export const GET_RMS_USERS_DESIGNATION_SUCCESS =
  'GET_RMS_USERS_DESIGNATION_SUCCESS';
export const GET_RMS_USERS_DESIGNATION_FAIL = 'GET_RMS_USERS_DESIGNATION_FAIL';

export const SET_RMS_ADDED_TEMPLATE_USERS = 'SET_RMS_ADDED_TEMPLATE_USERS';

export const RESET_RMS_TEMPLATE = 'RESET_RMS_TEMPLATE';
export const TOGGLE_RMS_TEMPLATE_TYPE = 'TOGGLE_RMS_TEMPLATE_TYPE';
export const RESET_TEMPLATE_USERS = 'RESET_TEMPLATE_USERS';
export const RMS_TEMPLATE_USER_FETCH_LOADING =
  'RMS_TEMPLATE_USER_FETCH_LOADING';

export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL';

export const GET_BOND_TEMPLATES_REQUEST = 'GET_BOND_TEMPLATES_REQUEST';
export const GET_BOND_TEMPLATES_SUCCESS = 'GET_BOND_TEMPLATES_SUCCESS';
export const GET_BOND_TEMPLATES_FAIL = 'GET_BOND_TEMPLATES_FAIL';

export const POST_BOND_TEMPLATE_REQUEST = 'POST_BOND_TEMPLATE_REQUEST';
export const POST_BOND_TEMPLATE_SUCCESS = 'POST_BOND_TEMPLATE_SUCCESS';
export const POST_BOND_TEMPLATE_FAIL = 'POST_BOND_TEMPLATE_FAIL';

export const RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED =
  'RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED';
export const SET_INDIVIDUAL_BUDGET_LIMIT = 'SET_INDIVIDUAL_BUDGET_LIMIT';
export const SELECTED_BULK_BUDGET_USERS = 'SELECTED_BULK_BUDGET_USERS';
export const SET_ALL_BULK_BUDGET_USERS = 'SET_ALL_BULK_BUDGET_USERS';
export const SET_BULK_BUDGET_VALUES = 'SET_BULK_BUDGET_VALUES';
export const DELETE_BULK_SELECTED_USERS = 'DELETE_BULK_SELECTED_USERS';

export const GET_RMS_BOND_TEMPLATES_REQUEST = 'GET_RMS_BOND_TEMPLATES_REQUEST';
export const GET_RMS_BOND_TEMPLATES_SUCCESS = 'GET_RMS_BOND_TEMPLATES_SUCCESS';
export const GET_RMS_BOND_TEMPLATES_FAILED = 'GET_RMS_BOND_TEMPLATES_FAILED';

export const GET_RMS_TEMPLATE_ADMIN_REQUEST = 'GET_RMS_TEMPLATE_ADMIN_REQUEST';
export const GET_RMS_TEMPLATE_ADMIN_SUCCESS = 'GET_RMS_TEMPLATE_ADMIN_SUCCESS';
export const GET_RMS_TEMPLATE_ADMIN_FAILED = 'GET_RMS_TEMPLATE_ADMIN_FAILED';

export const PUT_RMS_TEMPLATE_UPDATE_REQUEST =
  'PUT_RMS_TEMPLATE_UPDATE_REQUEST';
export const PUT_RMS_TEMPLATE_UPDATE_SUCCESS =
  'PUT_RMS_TEMPLATE_UPDATE_SUCCESS';
export const PUT_RMS_TEMPLATE_UPDATE_FAIL = 'PUT_RMS_TEMPLATE_UPDATE_FAIL';

export const GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST =
  'GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST';
export const GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS =
  'GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS';
export const GET_RMS_BOND_TEMPLATE_BY_ID_FAILED =
  'GET_RMS_BOND_TEMPLATE_BY_ID_FAILED';

export const RESET_RMS_SELECTED_BOND_FOR_TEMPLATE =
  'RESET_RMS_SELECTED_BOND_FOR_TEMPLATE';

export const GET_RMS_EXISTING_USER_REQUEST = 'GET_RMS_EXISTING_USER_REQUEST';
export const GET_RMS_EXISTING_USER_SUCCESS = 'GET_RMS_EXISTING_USER_SUCCESS';
export const GET_RMS_EXISTING_USER_FAILED = 'GET_RMS_EXISTING_USER_FAILED';

export const SET_RMS_EDIT_USER_DATA = 'SET_RMS_EDIT_USER_DATA';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';
export const CLOSE_DELETE_TEMPLATE_ERROR_MSG =
  'CLOSE_DELETE_TEMPLATE_ERROR_MSG';

export const GET_RMS_SELECTED_TEMPLATES_FOR_BOND_REQUEST =
  'GET_RMS_SELECTED_TEMPLATES_FOR_BOND_REQUEST';
export const GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS =
  'GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS';
export const GET_RMS_SELECTED_TEMPLATES_FOR_BOND_FAILED =
  'GET_RMS_SELECTED_TEMPLATES_FOR_BOND_FAILED';

export const RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE =
  'RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE';
