export const RMS_GET_FEEDBACK_QUESTIONS_SUCCESS =
  'RMS_GET_FEEDBACK_QUESTIONS_SUCCESS';
export const RMS_GET_FEEDBACK_QUESTIONS_FAILED =
  'RMS_GET_FEEDBACK_QUESTIONS_FAILED';
export const RMS_GET_FEEDBACK_QUESTIONS_REQUEST =
  'RMS_GET_FEEDBACK_QUESTIONS_REQUEST';

export const RMS_POST_FEEDBACK_SUCCESS = 'RMS_POST_FEEDBACK_SUCCESS';
export const RMS_POST_FEEDBACK_FAILED = 'RMS_POST_FEEDBACK_FAILED';
export const RMS_POST_FEEDBACK_REQUEST = 'RMS_POST_FEEDBACK_REQUEST';

export const RMS_GET_VIEW_FEEDBACK_REQUEST = 'RMS_GET_VIEW_FEEDBACK_REQUEST';
export const RMS_GET_VIEW_FEEDBACK_SUCCESS = 'RMS_GET_VIEW_FEEDBACK_SUCCESS';
export const RMS_GET_VIEW_FEEDBACK_FAILED = 'RMS_GET_VIEW_FEEDBACK_FAILED';

export const RMS_RESET_VIEW_FEEDBACK_POPUP_DATA =
  'RMS_RESET_VIEW_FEEDBACK_POPUP_DATA';
