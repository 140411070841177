import React from 'react';
import { useTranslation } from 'react-i18next';

const GeneralErrorMsgModal = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="reel-view--popup message 123">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <h3>{t('Error')}</h3>
        </div>
        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            {t('Something Went Wrong, Please Try Again Later.')}
          </div>
          <div className="reel-view--popup-row">
            <div className="reel-view--popup-field">
              <button className="btn btn--primary" onClick={handleSubmit}>
                {t('Close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralErrorMsgModal;
