import React, { useMemo, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import {
  ApplicantBondSearchMode,
  bondsFilterByOptions,
  DurationUnits,
} from '../../Common/constants';
import { getFormattedTrainingType, convertDuration } from '../../Common/helper';
import useConvertDate from '../../hooks/useConvertDate';

const ApplicantBondTable = ({
  bonds,
  bondsLoading,
  loadNextPage,
  searchMode,
  searchText,
  setSearchMode,
  setSearchText,
  selectedFilterDropdown,
  setSelectedFilterDropdown,
}) => {
  const { t } = useTranslation();
  const { convertDateISOToString } = useConvertDate();

  const filterByOptions = [
    { label: t('Active'), value: bondsFilterByOptions.ACTIVE },
    { label: t('Completed'), value: bondsFilterByOptions.COMPLETED },
  ];

  const searchByOptions = useMemo(() => {
    return [
      {
        label: t('Request ID'),
        value: ApplicantBondSearchMode.REQUEST_ID,
      },
      {
        label: t('Course'),
        value: ApplicantBondSearchMode.COURSE_NAME,
      },
    ];
  }, []);

  const getBondDurationInMonths = durationInDays => {
    return convertDuration(
      durationInDays,
      DurationUnits.DAYS,
      DurationUnits.MONTHS
    );
  };

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row underline">
          <div className="RMS__page--header-left ">
            <input
              type="text"
              placeholder={t('Search')}
              className={`form-input form-input--search`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
          <div className="RMS__page--header-right ">
            <p>{t('Filter')}</p>
            <ReactDropdown
              options={filterByOptions}
              placeholder={t('Filter By')}
              value={selectedFilterDropdown}
              onChange={option => setSelectedFilterDropdown(option.value)}
            />
            <ReactDropdown
              options={searchByOptions}
              placeholder={t('Select Mode')}
              value={searchMode}
              onChange={option => setSearchMode(option.value)}
            />
          </div>
        </div>
      </div>
      <div className="RMS__page--body">
        <div className={`RMS__page--table `}>
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('Training Type')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Course')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Training Start Date')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('Bond Duration In Months')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('Bond Start Date')}</h4>
            </div>
          </div>
          <div className="RMS__page--table-body">
            {!bonds.length && (bondsLoading ? <DottedLoader /> : <NoContent />)}
            {bonds.map(
              ({
                _id,
                customRequestId,
                requestType,
                courseType,
                courseName,
                trainingStartDate,
                bondDurationInDays,
                startDate,
              }) => (
                <>
                  <div className="RMS__page--table-row" key={_id}>
                    <div className="RMS__page--table-column">
                      <p>{customRequestId || '-'}</p>
                    </div>

                    <div className="RMS__page--table-column">
                      <p>
                        {getFormattedTrainingType(requestType, courseType) ||
                          '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{courseName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(trainingStartDate) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{`${
                        bondDurationInDays
                          ? getBondDurationInMonths(bondDurationInDays)
                          : 0
                      } months`}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(startDate) || '-'}</p>
                    </div>
                  </div>
                </>
              )
            )}
            <ReactVisibilitySensor
              partialVisibility
              onChange={isVisible => {
                if (isVisible && bonds?.length) {
                  loadNextPage();
                }
              }}
            >
              <div>&nbsp;</div>
            </ReactVisibilitySensor>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantBondTable;
