import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BudgetDetails = ({
  budgetDetails: {
    generalBudgetTotal,
    generalBudgetUtilized,
    generalBudgetBalance,
    professionalBudgetTotal,
    professionalBudgetUtilized,
    professionalBudgetBalance,
    groupBudgetTotal,
    groupBudgetUtilized,
    groupBudgetBalance,
  },
}) => {
  const { t } = useTranslation();
  const [isNormalView, setIsNormalView] = useState(true);

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Budget Breakdown')}</p>
          </div>
          <div className="RMS__page--header-right ">
            <button
              className={`btn btn--marginRight ${
                isNormalView ? 'btn--orange' : 'btn--secondary'
              } `}
              type="button"
              onClick={() => setIsNormalView(true)}
            >
              {t('Normal')}
            </button>
            <button
              className={`btn ${
                !isNormalView ? 'btn--orange' : 'btn--secondary'
              }`}
              type="button"
              onClick={() => setIsNormalView(false)}
            >
              {t('Group')}
            </button>
          </div>
        </div>
      </div>
      <div className="RMS__page--body">
        {isNormalView && (
          <>
            <div className="budget__row">
              <div className="budget__row--title">
                <p>{t('General')}</p>
              </div>
              <div className="budget__row--calculation">
                <div className="budget__item">
                  <p>{t('Total')}</p>
                  <p>{`$ ${generalBudgetTotal || 0}`}</p>
                </div>
                <div className="budget__icon minus"></div>
                <div className="budget__item">
                  <p>{t('Used')}</p>
                  <p>{`$ ${generalBudgetUtilized || 0}`}</p>
                </div>
                <div className="budget__icon equal"></div>
                <div className="budget__item">
                  <p>{t('Balance')}</p>
                  <p>{`$ ${generalBudgetBalance || 0}`}</p>
                </div>
              </div>
            </div>
            <div className="budget__row">
              <div className="budget__row--title">
                <p>{t('Professional')}</p>
              </div>
              <div className="budget__row--calculation">
                <div className="budget__item">
                  <p>{t('Total')}</p>
                  <p>{`$ ${professionalBudgetTotal || 0}`}</p>
                </div>
                <div className="budget__icon minus"></div>
                <div className="budget__item">
                  <p>{t('Used')}</p>
                  <p>{`$ ${professionalBudgetUtilized || 0}`}</p>
                </div>
                <div className="budget__icon equal"></div>
                <div className="budget__item">
                  <p>{t('Balance')}</p>
                  <p>{`$ ${professionalBudgetBalance || 0}`}</p>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="budget__row total">
          <div className="budget__row--title">
            <p>{t('Total')}</p>
          </div>
          <div className="budget__row--calculation">
            <div className="budget__item">
              <p>{t('Total')}</p>
              <p>{`$ ${
                isNormalView
                  ? generalBudgetTotal + professionalBudgetTotal || 0
                  : groupBudgetTotal || 0
              }`}</p>
            </div>
            <div className="budget__icon minus"></div>
            <div className="budget__item">
              <p>{t('Used')}</p>
              <p>{`$ ${
                isNormalView
                  ? generalBudgetUtilized + professionalBudgetUtilized || 0
                  : groupBudgetUtilized || 0
              }`}</p>
            </div>
            <div className="budget__icon equal"></div>
            <div className="budget__item">
              <p>{t('Balance')}</p>
              <p>{`$ ${
                isNormalView
                  ? generalBudgetBalance + professionalBudgetBalance || 0
                  : groupBudgetBalance || 0
              }`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetDetails;
