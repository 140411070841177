import React, { useEffect, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import { bondArrayKeys, DurationUnits } from '../../Common/constants';
import {
  convertDuration,
  getArrayIndexUsingKey,
  sortingDropDownOptionList,
  uuidv4,
} from '../../Common/helper';

const CreateBondTemplate = ({
  bondTemplates,
  createBondTemplate,
  postBondTemplateLoading,
  postBondTemplateSuccess,
  postBondTemplateFailMessage,
  isEdit,
  selectedBond,
  bondAttachedTemplates,
}) => {
  const initialBondArray = {
    minGrossAmount: '',
    maxGrossAmount: '',
    duration: '',
    durationUnit: DurationUnits.MONTHS,
    key: uuidv4(),
  };

  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedTemplateValue, setSelectedTemplateValue] = useState(null);
  const [selectedTemplatesArray, setSelectedTemplatesArray] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [bondArray, setBondArray] = useState([initialBondArray]);
  const [templateDropdown, setTemplateDropdown] = useState([]);

  useEffect(() => {
    if (isEdit && selectedBond) {
      const { name, attachedTemplateIds, bandBrackets } = selectedBond;
      name && setTemplateName(name);
      bandBrackets.length &&
        setBondArray(
          bandBrackets.map(bracket => ({ ...bracket, key: uuidv4() }))
        );

      if (bondAttachedTemplates?.length && attachedTemplateIds?.length) {
        const filteredBondTemplateIds = attachedTemplateIds.filter(
          templateId => {
            const matchTemplate = bondAttachedTemplates.find(
              temp => temp._id === templateId
            );
            return matchTemplate && templateId;
          }
        );
        setSelectedTemplatesArray(
          filteredBondTemplateIds.map(template => {
            const templateName =
              bondAttachedTemplates.find(temp => temp._id === template)?.name ||
              '';
            return { value: template, label: templateName };
          })
        );
      }
    }
  }, [isEdit, selectedBond, bondAttachedTemplates]);

  const setBondBracketValues = (attribute, key, value) => {
    const index = getArrayIndexUsingKey(bondArray, 'key', key);
    if (attribute === bondArrayKeys?.minGrossAmount) {
      setBondArray(current => {
        current[index].minGrossAmount = value;
        return [...current];
      });
    } else if (attribute === bondArrayKeys?.maxGrossAmount) {
      setBondArray(current => {
        current[index].maxGrossAmount = value;
        return [...current];
      });
    } else {
      setBondArray(current => {
        current[index].duration = value;
        current[index].durationUnit = DurationUnits.MONTHS;
        return [...current];
      });
    }
  };
  const handleSubmit = () => {
    if (!templateName) {
      setErrorMessage(t('Please fill template name.'));
    } else {
      if (selectedTemplatesArray?.length) {
        if (
          bondArray?.every(
            bond => bond.maxGrossAmount && bond.minGrossAmount && bond.duration
          )
        ) {
          if (validRangeOverLap(bondArray || [])) {
            if (
              bondArray?.every(
                bond =>
                  Number(bond.maxGrossAmount) > Number(bond.minGrossAmount)
              )
            ) {
              setErrorMessage('');
              createBondTemplate(
                templateName,
                bondArray,
                selectedTemplatesArray
              );
            } else {
              setErrorMessage(
                t(
                  'Minimum gross amount should be less than maximum gross amount.'
                )
              );
            }
          } else {
            setErrorMessage(t('Please remove overlapping price brackets.'));
          }
        } else {
          setErrorMessage(t('Please fill all bond bracket fields.'));
        }
      } else {
        setErrorMessage(t('Please select template.'));
      }
    }
  };

  const validRangeOverLap = data => {
    const length = data.length;
    if (length > 1) {
      for (let i = 0; i < length; i++) {
        for (let u = 0; u < length; u++) {
          if (i == u) {
            // skip if comparing the same range
            continue;
          }
          const rangeOne = data[i];
          const rangeTwo = data[u];
          const rangeOneMin = parseInt(rangeOne.minGrossAmount);
          const rangeTwoMax = parseInt(rangeTwo.maxGrossAmount);
          const rangeTwoMin = parseInt(rangeTwo.minGrossAmount);
          const rangeOneMax = parseInt(rangeOne.maxGrossAmount);

          if (rangeOneMin >= rangeTwoMin && rangeOneMin <= rangeTwoMax) {
            return false;
          }
          if (rangeOneMax >= rangeTwoMin && rangeOneMax <= rangeTwoMax) {
            return false;
          }
          if (rangeOneMin <= rangeTwoMin && rangeTwoMax <= rangeOneMax) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const getOptionList = () => {
    return (
      bondTemplates?.length &&
      bondTemplates?.map(value => ({
        value: value._id,
        label: value?.name,
      }))
    );
  };

  useEffect(() => {
    const optionList = getOptionList();

    if (!isEdit) {
      const filteredOptionList = optionList?.filter(
        template => template.value !== location?.state?.templateId
      );

      setTemplateDropdown(
        filteredOptionList?.length
          ? sortingDropDownOptionList(filteredOptionList)
          : []
      );

      const matchTemplate = optionList?.find(
        template => template?.value === location?.state?.templateId
      );

      setSelectedTemplateValue(matchTemplate?.label || null);

      matchTemplate && setSelectedTemplatesArray([matchTemplate]);
    } else {
      setTemplateDropdown(
        optionList ? sortingDropDownOptionList(optionList) : []
      );
    }
  }, [location, bondTemplates]);

  useEffect(() => {
    !postBondTemplateLoading &&
      postBondTemplateSuccess &&
      history.push(`/rms/manage-template`);
  }, [postBondTemplateLoading, postBondTemplateSuccess]);

  const getBondDuration = bondItem => {
    const { duration, durationUnit } = bondItem || {};
    return duration
      ? convertDuration(duration, durationUnit, DurationUnits.MONTHS)
      : '';
  };

  const changeSelectedTemplateArrayAndTemplateDropDown = (
    isDropdownSelect,
    selectedTemplate
  ) => {
    if (isDropdownSelect) {
      setSelectedTemplateValue(selectedTemplate?.label);
      setTemplateDropdown(
        templateDropdown.filter(
          template => template.value !== selectedTemplate?.value
        )
      );
      setSelectedTemplatesArray([...selectedTemplatesArray, selectedTemplate]);
    } else {
      setSelectedTemplatesArray(
        selectedTemplatesArray.filter(
          template => template.value !== selectedTemplate?.value
        )
      );
      setTemplateDropdown([...templateDropdown, selectedTemplate]);
    }
  };

  return (
    <div className="RMS__page createBondTemplate">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>
              {isEdit ? t('Edit Bond Template') : t('Create Bond Template')}
            </h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <p>{t('Basic Info')}</p>
                </div>
              </div>
            </div>
            <div className="RMS__page--body">
              <div className="RMS__page--body-row twoColumn">
                <div className="RMS__page--body-column">
                  <div className="form--field row">
                    <p className="form--field-title">{t('Template Name')} :</p>
                    <div className="form--field-input">
                      <input
                        type="text"
                        placeholder={t('Template Name')}
                        value={templateName}
                        onChange={e => setTemplateName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-column row">
                  <p className="form--field-title">{t('Template')} :</p>
                  <div className="form--field__multipleselect">
                    <ReactDropdown
                      options={templateDropdown}
                      placeholder={t('Select Templates')}
                      onChange={option => {
                        changeSelectedTemplateArrayAndTemplateDropDown(
                          true,
                          option
                        );
                      }}
                      value={selectedTemplateValue}
                    />
                    <div className="form--field__multipleselect-item">
                      {selectedTemplatesArray?.map((item, index) => {
                        return (
                          <p
                            className="form__form--field dropdown-selected"
                            key={index}
                          >
                            {item.label}
                            <a
                              className=""
                              aria-hidden="true"
                              onClick={() => {
                                changeSelectedTemplateArrayAndTemplateDropDown(
                                  false,
                                  item
                                );
                              }}
                            >
                              &times;
                            </a>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--content marginBottom">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <p>{t('Bond Brackets')}</p>
                </div>
              </div>
            </div>
            {bondArray?.map((data, index) => {
              return (
                <div className="RMS__page--body black" key={data.key}>
                  {bondArray.length > 1 && (
                    <a
                      className="closeButton"
                      aria-hidden="true"
                      onClick={() =>
                        setBondArray(current =>
                          current.filter(bond => bond?.key !== data?.key)
                        )
                      }
                    >
                      &times;
                    </a>
                  )}
                  <div className="RMS__page--body-row twoColumn">
                    <div className="RMS__page--body-column">
                      <div className="form--field row">
                        <p className="form--field-title">
                          {t('Gross Amount')} :
                        </p>
                        <div className="form--field-input">
                          <input
                            type="text"
                            placeholder={t('Min')}
                            pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
                            value={
                              bondArray?.[
                                getArrayIndexUsingKey(
                                  bondArray,
                                  'key',
                                  data?.key
                                )
                              ]?.minGrossAmount
                            }
                            onChange={e => {
                              e.target.validity.valid &&
                                setBondBracketValues(
                                  bondArrayKeys.minGrossAmount,
                                  data?.key,
                                  e.target.value
                                );
                            }}
                          />
                        </div>
                        <span>-</span>
                        <div className="form--field-input">
                          <input
                            type="text"
                            placeholder={t('Max')}
                            pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
                            value={
                              bondArray?.[
                                getArrayIndexUsingKey(
                                  bondArray,
                                  'key',
                                  data?.key
                                )
                              ]?.maxGrossAmount
                            }
                            onChange={e => {
                              e.target.validity.valid &&
                                setBondBracketValues(
                                  bondArrayKeys.maxGrossAmount,
                                  data?.key,
                                  e.target.value
                                );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="RMS__page--body-column">
                      <div className="form--field row">
                        <p className="form--field-title">
                          {t('Duration In Months')} :
                        </p>
                        <div className="form--field-input">
                          <input
                            type="text"
                            placeholder={t('Type Here')}
                            pattern="^[1-9]\d{0,6}$"
                            value={getBondDuration(
                              bondArray?.[
                                getArrayIndexUsingKey(
                                  bondArray,
                                  'key',
                                  data?.key
                                )
                              ]
                            )}
                            onChange={e => {
                              e.target.validity.valid &&
                                setBondBracketValues(
                                  bondArrayKeys.duration,
                                  data?.key,
                                  e.target.value
                                );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {index === bondArray.length - 1 && (
                    <button
                      className="btn btn--add"
                      onClick={() =>
                        setBondArray(current => [...current, initialBondArray])
                      }
                    >
                      <img src={iconAddButton} alt="add-data" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <div className="RMS__page--submit overall">
            <div className="RMS__page--error">
              <div className="RMS__page--error-content">
                {errorMessage || t(postBondTemplateFailMessage)}
              </div>
            </div>

            <button
              className="btn btn--secondary btn--marginRight"
              onClick={() => history.push(`/rms/manage-template`)}
            >
              {t('Back')}
            </button>
            <button
              className={`btn btn--primary 
                ${postBondTemplateLoading && 'btn--loader'}
              `}
              onClick={() => handleSubmit()}
            >
              {!postBondTemplateLoading && t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBondTemplate;
