import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import AddUserModal from '../../Components/Modal/AddUserModal';
import {
  RESET_ADD_USER_POPUP_DATA,
  RMS_ADD_REMOVE_SELECTED_USERS,
  RMS_EXISTING_USER_MANAGEMENT_FAIL,
  RMS_EXISTING_USER_MANAGEMENT_REQUEST,
  RMS_EXISTING_USER_MANAGEMENT_SUCCESS,
  RMS_POST_ADDED_USERS_FAIL,
  RMS_POST_ADDED_USERS_REQUEST,
  RMS_POST_ADDED_USERS_SUCCESS,
  RMS_SEARCH_LOADING_SET,
  RMS_USER_MANAGEMENT_FAIL,
  RMS_USER_MANAGEMENT_REQUEST,
  RMS_USER_MANAGEMENT_SUCCESS,
} from '../../redux/actionTypes/userManagementTypes';

const AddUserContainer = ({ history, onHandleClose }) => {
  const dispatch = useDispatch();
  const [fetchAllUserApi] = useApi();
  const [fetchExistingUser] = useApi();
  const [saveAddedUsers] = useApi();
  const {
    userLoading,
    userData,
    existingUsers,
    existUsersLoading,
    selectedUsers,
    successClose,
    saveLoading,
  } = useSelector(state => state.rmsUserManagement);

  const getAllUserApi = (value, filter) => {
    fetchAllUserApi(
      `/user/candidate-rms-users?searchKey=${value}&searchBy=${filter}&page=1&size=20`,
      RMS_USER_MANAGEMENT_REQUEST,
      RMS_USER_MANAGEMENT_SUCCESS,
      RMS_USER_MANAGEMENT_FAIL,
      {},
      '',
      'GET',
      null,
      'isUserService'
    );
  };

  const getExistingUser = emailList => {
    fetchExistingUser(
      `/api/v1/users?emailList=${emailList}&page=1&size=${emailList?.length}`,
      RMS_EXISTING_USER_MANAGEMENT_REQUEST,
      RMS_EXISTING_USER_MANAGEMENT_SUCCESS,
      RMS_EXISTING_USER_MANAGEMENT_FAIL,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const postAddedUsers = () => {
    const selectedUsersArranged = selectedUsers?.map(data => {
      return { email: data };
    });
    saveAddedUsers(
      `/api/v1/users/bulk`,
      RMS_POST_ADDED_USERS_REQUEST,
      RMS_POST_ADDED_USERS_SUCCESS,
      RMS_POST_ADDED_USERS_FAIL,
      selectedUsersArranged,
      '',
      'POST',
      null,
      'isRmsService'
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ADD_USER_POPUP_DATA });
    };
  }, []);

  useEffect(() => {
    if (userData?.length) {
      const emailList = userData?.map(data => data?.email) || null;
      getExistingUser(emailList);
    }
  }, [userData]);
  useEffect(() => {
    !saveLoading && successClose && onHandleClose();
  }, [saveLoading, successClose]);
  return (
    <AddUserModal
      onHandleClose={onHandleClose}
      userData={userData}
      existingUsers={existingUsers}
      existUsersLoading={existUsersLoading}
      fetchFilterSearch={(value, filter) => getAllUserApi(value, filter)}
      userLoading={userLoading}
      setSearchLoading={() => dispatch({ type: RMS_SEARCH_LOADING_SET })}
      triggerAddUserAction={email =>
        dispatch({ type: RMS_ADD_REMOVE_SELECTED_USERS, payload: email })
      }
      selectedUsers={selectedUsers}
      saveSelectedUsers={() => postAddedUsers()}
      saveButtonLoading={saveLoading}
    />
  );
};

export default AddUserContainer;
