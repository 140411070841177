import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import iconDelete from '../../assets/images/svg-images/icon-delete.svg';
import iconEdit from '../../assets/images/svg-images/icon-edit.svg';
import { FilterBy, SearchBy } from '../../Common/constants';
import AddUserContainer from '../../Container/AddUserContainer/AddUserContainer';
import useConvertDate from '../../hooks/useConvertDate';
import { useHistory } from 'react-router';

const UserManagement = ({
  centers,
  departments,
  designations,
  loadNextUserPage,
  optionsList,
  qualifications,
  refreshData,
  searchText,
  selectedFilter,
  selectedOption,
  selectedSearchByMethod,
  setSearchText,
  setSelectedFilter,
  setSelectedOption,
  setSelectedSearchByMethod,
  showDeleteUser,
  showEditUser,
  users,
  companies,
}) => {
  const { t } = useTranslation();
  const [showAddUserPopup, setAddUserPopUp] = useState(false);
  const { convertDateToThemeFormat } = useConvertDate();
  const history = useHistory();
  const [userIds, setUserIds] = useState([]);

  const getFormattedDate = dateStr => {
    if (moment(dateStr).isValid()) {
      return moment(dateStr).format('Do MMM YYYY');
    } else {
      return '';
    }
  };

  const getQualificationName = qualificationId => {
    return qualifications?.[qualificationId]?.name || '-';
  };

  const getCenterName = centerId => {
    return centers?.[centerId]?.name || '-';
  };
  const getDepartmentName = depId => {
    return departments?.[depId]?.name || '-';
  };
  const getDesignationName = designationId => {
    return designations?.[designationId]?.name || '-';
  };
  const getCompanyName = companyId => {
    return companies?.[companyId]?.name || '-';
  };

  const searchByOptions = useMemo(() => {
    let options = [
      { label: 'Email', value: SearchBy.EMAIL },
      { label: 'Employee ID', value: SearchBy.EMPLOYEE_ID },
      { label: 'Name', value: SearchBy.NAME },
    ];

    if (selectedFilter === FilterBy.INCOMPLETE_PROFILES) {
      options = options.filter(({ value }) => value !== SearchBy.EMPLOYEE_ID);
    }
    return options;
  }, [selectedFilter]);

  useEffect(() => {
    userIds?.length &&
      history.push({
        pathname: `/rms/archival-training-request`,
        state: { userIds: userIds },
      });
  }, [userIds]);

  return (
    <div className="RMS__page userManagement">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>{t('Applicant Management')}</h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div
                  className={`RMS__page--header-left ${
                    optionsList?.length ? 'filterOn' : ''
                  }`}
                >
                  <p>{t('Users')}</p>
                </div>
                <div
                  className={`RMS__page--header-right ${
                    optionsList?.length ? 'filterOn' : ''
                  }`}
                >
                  <p>{t('Filter')}</p>
                  <ReactDropdown
                    options={[
                      { label: 'Section Code', value: FilterBy.CENTER },
                      { label: 'Department Id', value: FilterBy.DEPARTMENT },
                      { label: 'Designation', value: FilterBy.DESIGNATION },
                      {
                        label: 'Incomplete Profiles',
                        value: FilterBy.INCOMPLETE_PROFILES,
                      },
                    ]}
                    onChange={option => setSelectedFilter(option.value)}
                    value={selectedFilter}
                    placeholder={t('Select')}
                  />
                  {optionsList?.length ? (
                    <ReactDropdown
                      options={optionsList}
                      onChange={option => setSelectedOption(option.value)}
                      value={selectedOption}
                      placeholder={t('Select')}
                    />
                  ) : null}
                </div>
              </div>
              <div className="RMS__page--header-row underline">
                <div className="RMS__page--header-left">
                  <input
                    type="text"
                    placeholder={'Search'}
                    className="form-input form-input--search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
                <div className="RMS__page--header-right">
                  <ReactDropdown
                    options={searchByOptions}
                    onChange={option => setSelectedSearchByMethod(option.value)}
                    value={selectedSearchByMethod}
                    placeholder={t('Select')}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--table user1 userManagementTable">
              <div className="RMS__page--table-header">
                <div className="RMS__page--table-column">
                  <h4>{t('Employee ID')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Email')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Date of Birth')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Date Joined')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('NRIC No')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Highest Qualification')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Company')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Section Code')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Designation')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Department Id')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4></h4>
                </div>
              </div>
              <div className="RMS__page--table-body">
                {users?.map(
                  ({
                    centerId,
                    joinedDate,
                    departmentId,
                    designationId,
                    dob,
                    email,
                    employeeId,
                    name,
                    nricNo,
                    qualificationId,
                    _id,
                    companyId,
                  }) => (
                    <div className="RMS__page--table-row">
                      <div className="RMS__page--table-column">
                        <p>{employeeId || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{name || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{email || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{convertDateToThemeFormat(dob) || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{convertDateToThemeFormat(joinedDate) || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{nricNo || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{getQualificationName(qualificationId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{getCompanyName(companyId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{getCenterName(centerId)}</p>
                      </div>
                    <div className="RMS__page--table-column">
                        <p>{getDesignationName(designationId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{getDepartmentName(departmentId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <img
                          src={iconEdit}
                          alt="edit-data"
                          onClick={() => showEditUser(_id)}
                        />
                        {selectedFilter !== FilterBy.INCOMPLETE_PROFILES && (
                          <img
                            src={iconDelete}
                            alt="delete-data"
                            onClick={() => showDeleteUser(_id)}
                          />
                        )}
                        <button
                          className="btn btn--add long"
                          onClick={() => setUserIds(_id)}
                        >
                          Archival Record
                        </button>
                      </div>
                    </div>
                  )
                )}
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible && users?.length) {
                      loadNextUserPage();
                    }
                  }}
                >
                  <div>&nbsp;</div>
                </ReactVisibilitySensor>
              </div>
            </div>
            <div className="RMS__page--submit">
              <button
                className="btn btn--add"
                onClick={() => setAddUserPopUp(true)}
              >
                <img src={iconAddButton} alt="add-data" />
              </button>

              {showAddUserPopup && (
                <AddUserContainer
                  onHandleClose={() => {
                    setAddUserPopUp(false);
                    refreshData();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
