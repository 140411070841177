import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../../Components/Loader/DottedLoader';
import NoContent from '../../../../Components/NoContent/NoContent';

const TableGrid = ({
  loadNextPage = () => {},
  isUserStatsLoading = false,
  userStats,
  onSelectUserIds,
  selectAll,
  setSelectAll,
  isSuperAdminDashboard,
  selectedTemplate,
  setSelectedDateRangeId,
  selectedDateRangeId,
}) => {
  const { t } = useTranslation();

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const history = useHistory();

  const updateSelectUsers = id => {
    setSelectedUserIds(ids => {
      if (ids.includes(id)) {
        return ids.filter(ids => id !== ids);
      } else {
        return [...ids, id];
      }
    });
  };

  const getTotalRequest = (numOfNormalRequest = 0, numOfGroupRequest = 0) => {
    return parseInt(numOfNormalRequest) + parseInt(numOfGroupRequest);
  };

  const selectAllMethod = selectAll => {
    if (selectAll) {
      setSelectedUserIds(userStats.map(({ userId }) => userId));
    } else {
      if (
        updatedUserStats?.filter(({ isSelect }) => isSelect)?.length ===
        updatedUserStats?.length
      ) {
        setSelectedUserIds([]);
      }
    }
    setSelectAll(selectAll);
  };

  useEffect(() => {
    if (selectAll) {
      selectAllMethod(true);
    } else {
      selectAllMethod(false);
    }
  }, [userStats]);

  const updatedUserStats = useMemo(() => {
    let list = [...userStats];

    list = userStats.map(user => ({
      ...user,
      isSelect: selectedUserIds.includes(user.userId),
    }));

    onSelectUserIds(
      list.filter(({ isSelect }) => isSelect).map(({ userId }) => userId)
    );

    return list;
  }, [userStats, selectedUserIds]);

  return (
    <div className="RMS__page--body">
      <div className={`RMS__page--table`}>
        <div className="RMS__page--table-header">
          <div className="RMS__page--table-column">
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-input--checkbox"
                name="selectAll"
                checked={
                  updatedUserStats.length
                    ? updatedUserStats?.filter(({ isSelect }) => isSelect)
                        ?.length === updatedUserStats?.length
                    : false
                }
              />
              <label
                htmlFor="selectAll"
                onClick={() => {
                  selectAllMethod(!selectAll);
                }}
              ></label>
            </div>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Name')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Email')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Department Id')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Designation')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Total Budget')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Budget Utilize')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Budget Blocked')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Total Requests')}</h4>
          </div>
          <div className="RMS__page--table-column">
            <h4>{t('Bonded')}</h4>
          </div>
        </div>
        <div className="RMS__page--table-body">
          {updatedUserStats?.length ? (
            <>
              {updatedUserStats.map(
                ({
                  name,
                  email,
                  departmentName,
                  designationName,
                  allocatedBudgetLimit,
                  utilizedBudget,
                  blockedBudget,
                  numberOfGroupRequests,
                  numberOfNormalRequests,
                  bonded,
                  isSelect,
                  userId,
                }) => (
                  <div
                    key={userId}
                    className="RMS__page--table-row clickable"
                    onClick={() => {
                      history.push({
                        pathname: isSuperAdminDashboard
                          ? '/rms/super-admin-dashboard/reporting'
                          : '/rms/admin-dashboard/reporting',
                        state: {
                          email,
                          isSuperAdminDashboard,
                          selectedTemplate,
                          isApplicantDashboard: false,
                          userId,
                          selectedDateRangeId,
                        },
                      });
                    }}
                  >
                    <div className="RMS__page--table-column">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="form-input--checkbox"
                          name={email}
                          checked={isSelect}
                        />
                        <label
                          htmlFor={email}
                          onClick={e => {
                            e.stopPropagation();
                            updateSelectUsers(userId);
                            setSelectAll(false);
                          }}
                        ></label>
                      </div>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{name || '-'}</p>
                    </div>

                    <div className="RMS__page--table-column">
                      <p>{email || '-'}</p>
                    </div>

                    <div className="RMS__page--table-column">
                      <p>{departmentName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{designationName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{`$ ${allocatedBudgetLimit || 0}`}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{`$ ${utilizedBudget || 0}`}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{`$ ${blockedBudget || 0}`}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {getTotalRequest(
                          numberOfNormalRequests,
                          numberOfGroupRequests
                        )}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{bonded ? t('Yes') : t('No')}</p>
                    </div>
                  </div>
                )
              )}
              <ReactVisibilitySensor
                partialVisibility
                onChange={isVisible => {
                  if (isVisible) {
                    loadNextPage();
                  }
                }}
              >
                <div>&nbsp;</div>
              </ReactVisibilitySensor>
            </>
          ) : isUserStatsLoading ? (
            <Loader />
          ) : (
            <NoContent />
          )}
        </div>
      </div>
    </div>
  );
};

export default TableGrid;
