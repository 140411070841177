import React from 'react';
import { useTranslation } from 'react-i18next';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import Loader from '../../../Components/Loader/loader';
import NoContent from '../../../Components/NoContent/NoContent';

const SelectTemplateModal = ({
  closePopup,
  groupTemplates,
  onTemplateSelect,
  searchText,
  setSearchText,
  templatesLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp addUser selectTemplate">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Select Template')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={closePopup}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <input
              type="text"
              placeholder={t('Search')}
              className="form-input form-input--search"
              onChange={e => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>

          <div className="reel-view--popup-row">
            {templatesLoading ? (
              <DottedLoader />
            ) : groupTemplates?.length ? (
              <>
                {groupTemplates?.map(({ _id, name }) => {
                  return (
                    <div
                      className={`reel-view--popup-innerRow selected `}
                      onClick={() => onTemplateSelect(_id)}
                      key={_id}
                    >
                      <div className="checkbox">
                        <label>{name}</label>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoContent message={t('No Group Templates Available')} />
            )}
          </div>
          <div className="reel-view--popup-row" />
        </div>
      </div>
    </div>
  );
};

export default SelectTemplateModal;
