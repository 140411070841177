export const SEARCH_RMS_USERS_REQUEST = 'SEARCH_RMS_USERS_REQUEST';
export const SEARCH_RMS_USERS_SUCCESS = 'SEARCH_RMS_USERS_SUCCESS';
export const SEARCH_RMS_USERS_FAILED = 'SEARCH_RMS_USERS_FAILED';

export const SEARCH_RMS_USERS_RESET = 'SEARCH_RMS_USERS_RESET';

export const GET_RMS_USER_QUALIFICATIONS_REQUEST =
  'GET_RMS_USER_QUALIFICATIONS_REQUEST';
export const GET_RMS_USER_QUALIFICATIONS_SUCCESS =
  'GET_RMS_USER_QUALIFICATIONS_SUCCESS';
export const GET_RMS_USER_QUALIFICATIONS_FAILED =
  'GET_RMS_USER_QUALIFICATIONS_FAILED';

export const GET_RMS_CENTER_DETAILS_REQUEST = 'GET_RMS_CENTER_DETAILS_REQUEST';
export const GET_RMS_CENTER_DETAILS_SUCCESS = 'GET_RMS_CENTER_DETAILS_SUCCESS';
export const GET_RMS_CENTER_DETAILS_FAILED = 'GET_RMS_CENTER_DETAILS_FAILED';

export const GET_RMS_CENTER_REQUEST = 'GET_RMS_CENTER_REQUEST';
export const GET_RMS_CENTER_SUCCESS = 'GET_RMS_CENTER_SUCCESS';
export const GET_RMS_CENTER_FAILED = 'GET_RMS_CENTER_FAILED';

export const GET_RMS_DEPARTMENT_REQUEST = 'GET_RMS_DEPARTMENT_REQUEST';
export const GET_RMS_DEPARTMENT_SUCCESS = 'GET_RMS_DEPARTMENT_SUCCESS';
export const GET_RMS_DEPARTMENT_FAILED = 'GET_RMS_DEPARTMENT_FAILED';

export const GET_RMS_DESIGNATION_REQUEST = 'GET_RMS_DESIGNATION_REQUEST';
export const GET_RMS_DESIGNATION_SUCCESS = 'GET_RMS_DESIGNATION_SUCCESS';
export const GET_RMS_DESIGNATION_FAILED = 'GET_RMS_DESIGNATION_FAILED';

export const GET_RMS_QUALIFICATION_REQUEST = 'GET_RMS_QUALIFICATION_REQUEST';
export const GET_RMS_QUALIFICATION_SUCCESS = 'GET_RMS_QUALIFICATION_SUCCESS';
export const GET_RMS_QUALIFICATION_FAILED = 'GET_RMS_QUALIFICATION_FAILED';

export const GET_RMS_DEPARTMENT_DETAILS_REQUEST =
  'GET_RMS_DEPARTMENT_DETAILS_REQUEST';
export const GET_RMS_DEPARTMENT_DETAILS_SUCCESS =
  'GET_RMS_DEPARTMENT_DETAILS_SUCCESS';
export const GET_RMS_DEPARTMENT_DETAILS_FAILED =
  'GET_RMS_DEPARTMENT_DETAILS_FAILED';

export const GET_RMS_DESIGNATION_DETAILS_REQUEST =
  'GET_RMS_DESIGNATION_DETAILS_REQUEST';
export const GET_RMS_DESIGNATION_DETAILS_SUCCESS =
  'GET_RMS_DESIGNATION_DETAILS_SUCCESS';
export const GET_RMS_DESIGNATION_DETAILS_FAILED =
  'GET_RMS_DESIGNATION_DETAILS_FAILED';

export const RMS_USER_MANAGEMENT_REQUEST = 'RMS_USER_MANAGEMENT_REQUEST';
export const RMS_USER_MANAGEMENT_SUCCESS = 'RMS_USER_MANAGEMENT_SUCCESS';
export const RMS_USER_MANAGEMENT_FAIL = 'RMS_USER_MANAGEMENT_FAIL';

export const RMS_EXISTING_USER_MANAGEMENT_REQUEST =
  'RMS_EXISTING_USER_MANAGEMENT_REQUEST';
export const RMS_EXISTING_USER_MANAGEMENT_SUCCESS =
  'RMS_EXISTING_USER_MANAGEMENT_SUCCESS';
export const RMS_EXISTING_USER_MANAGEMENT_FAIL =
  'RMS_EXISTING_USER_MANAGEMENT_FAIL';

export const RMS_POST_ADDED_USERS_REQUEST = 'RMS_POST_ADDED_USERS_REQUEST';
export const RMS_POST_ADDED_USERS_SUCCESS = 'RMS_POST_ADDED_USERS_SUCCESS';
export const RMS_POST_ADDED_USERS_FAIL = 'RMS_POST_ADDED_USERS_FAIL';

export const RESET_ADD_USER_POPUP_DATA = 'RESET_ADD_USER_POPUP_DATA';

export const RMS_SEARCH_LOADING_SET = 'RMS_SEARCH_LOADING_SET';

export const RMS_ADD_REMOVE_SELECTED_USERS = 'RMS_ADD_REMOVE_SELECTED_USERS';

export const RMS_UPDATE_USER_REQUEST = 'RMS_UPDATE_USER_REQUEST';
export const RMS_UPDATE_USER_SUCCESS = 'RMS_UPDATE_USER_SUCCESS';
export const RMS_UPDATE_USER_FAILED = 'RMS_UPDATE_USER_FAILED';

export const RMS_RESET_USER_UPDATE = 'RMS_RESET_USER_UPDATE';

export const RMS_RESET_USER_MANAGEMENT = 'RMS_RESET_USER_MANAGEMENT';

export const RMS_DELETE_USER_REQUEST = 'RMS_DELETE_USER_REQUEST';
export const RMS_DELETE_USER_SUCCESS = 'RMS_DELETE_USER_SUCCESS';
export const RMS_DELETE_USER_FAILED = 'RMS_DELETE_USER_FAILED';

export const RMS_RESET_ERROR_MESSAGE = 'RMS_RESET_ERROR_MESSAGE';

export const GET_RMS_COMPANY_REQUEST = 'GET_RMS_COMPANY_REQUEST';
export const GET_RMS_COMPANY_SUCCESS = 'GET_RMS_COMPANY_SUCCESS';
export const GET_RMS_COMPANY_FAILED = 'GET_RMS_COMPANY_FAILED';

export const GET_RMS_COMPANY_DETAILS_REQUEST =
  'GET_RMS_COMPANY_DETAILS_REQUEST';
export const GET_RMS_COMPANY_DETAILS_SUCCESS =
  'GET_RMS_COMPANY_DETAILS_SUCCESS';
export const GET_RMS_COMPANY_DETAILS_FAILED = 'GET_RMS_COMPANY_DETAILS_FAILED';
export const RMS_DESIGNATION_RESET = 'RMS_DESIGNATION_RESET';
export const RMS_QUALIFICATION_RESET = 'RMS_QUALIFICATION_RESET';
export const RMS_CENTER_RESET = 'RMS_CENTER_RESET';
export const RMS_DEPARTMENT_RESET = 'RMS_DEPARTMENT_RESET';
