import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatText } from '../../../../Common/helper';
import useConvertDate from '../../../../hooks/useConvertDate';
import ReactDropdown from 'react-dropdown';
import DatePicker from 'react-datepicker';
import { CourseModes, DurationUnits } from '../../../../Common/constants';

const CourseDetails = ({
  data,
  isEditMode = false,
  updateCourseData = () => {},
}) => {
  const { t } = useTranslation();
  const { removeDateFromIsoString, combineDateIsoStringAndTime } =
    useConvertDate();
  const {
    title,
    courseModeList,
    startDate,
    endDate,
    duration,
    durationUnit,
    sessions,
  } = data?.course || {};

  const [courseTitle, setCourseTitle] = useState('');
  const [courseMode, setCourseMode] = useState('');
  const [courseStartDate, setCourseStartDate] = useState();
  const [courseEndDate, setCourseEndDate] = useState();
  const [courseStartTime, setCourseStartTime] = useState('');
  const [courseEndTime, setCourseEndTime] = useState('');
  const [courseDuration, setCourseDuration] = useState('');
  const [courseSessions, setCourseSessions] = useState('');
  const [durationType, setDurationType] = useState('');

  useEffect(() => {
    if (data?.course) {
      setCourseTitle(title);
      if (
        courseModeList.length > 1 &&
        courseModeList.includes(CourseModes.ONLINE) &&
        courseModeList.includes(CourseModes.PHYSICAL)
      ) {
        setCourseMode(CourseModes.BOTH);
      } else {
        setCourseMode(courseModeList?.[0]);
      }
      setCourseStartDate(startDate);
      setCourseEndDate(endDate);
      setCourseStartTime(removeDateFromIsoString(startDate));
      setCourseEndTime(removeDateFromIsoString(endDate));
      setCourseDuration(duration);
      setCourseSessions(sessions);
      setDurationType(durationUnit);
    }
  }, [data]);

  useEffect(() => {
    updateCourseData('title', courseTitle);
  }, [courseTitle]);

  useEffect(() => {
    updateCourseData(
      'courseModes',
      courseMode === CourseModes.BOTH
        ? [CourseModes.ONLINE, CourseModes.PHYSICAL]
        : [courseMode]
    );
  }, [courseMode]);

  useEffect(() => {
    if (courseStartDate && courseStartTime) {
      updateCourseData(
        'startDate',
        combineDateIsoStringAndTime(courseStartDate, courseStartTime)
      );
    }
  }, [courseStartDate, courseStartTime]);

  useEffect(() => {
    if (courseEndDate && courseEndTime) {
      updateCourseData(
        'endDate',
        combineDateIsoStringAndTime(courseEndDate, courseEndTime)
      );
    }
  }, [courseEndDate, courseEndTime]);

  useEffect(() => {
    updateCourseData('duration', courseDuration);
  }, [courseDuration]);

  useEffect(() => {
    updateCourseData('sessions', courseSessions);
  }, [courseSessions]);

  useEffect(() => {
    updateCourseData('durationUnit', durationType);
  }, [durationType]);

  const memoStartDate = useMemo(() => {
    if (courseStartDate) {
      return new Date(courseStartDate);
    }
  }, [courseStartDate]);

  const memoEndDate = useMemo(() => {
    if (courseEndDate) {
      return new Date(courseEndDate);
    }
  }, [courseEndDate]);

  return (
    <div>
      <div className="RMS__page--body">
        <div className="RMS__page--body-row fourColumn">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Course Title')}</p>
              <div className="form--field-input">
                <input
                  disabled={!isEditMode}
                  type="text"
                  placeholder={t('N/A')}
                  value={courseTitle || '-'}
                  onChange={e =>
                    e.target.validity.valid && setCourseTitle(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Course Mode')}</p>
              <div className="form--field-input">
                <ReactDropdown
                  options={[
                    { label: t('Physical'), value: CourseModes.PHYSICAL },
                    {
                      label: t('Online'),
                      value: CourseModes.ONLINE,
                    },
                    {
                      label: t('Both'),
                      value: CourseModes.BOTH,
                    },
                  ]}
                  value={formatText(courseMode) || '-'}
                  onChange={option => setCourseMode(option.value)}
                  placeholder={t('Select')}
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Start Date')}</p>
              <div className="form--field-input">
                <DatePicker
                  selected={memoStartDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={date => setCourseStartDate(date.toISOString())}
                  showMonthDropdown
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('End Date')}</p>
              <div className="form--field-input">
                <DatePicker
                  selected={memoEndDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={date => setCourseEndDate(date.toISOString())}
                  showMonthDropdown
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="RMS__page--body-row fourColumn">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Duration')}</p>
              <div className="form--field-input withDropdown">
                <input
                  disabled={!isEditMode || durationType !== DurationUnits.HOURS}
                  type="text"
                  placeholder={t('Enter Duration')}
                  value={courseDuration}
                  onChange={e =>
                    e.target.validity.valid && setCourseDuration(e.target.value)
                  }
                  pattern="^[0-9]\d{0,6}(\.\d{0,1})?$"
                />
                <span className="typeLabel">{durationType}</span>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Sessions')}</p>
              <div className="form--field-input">
                <input
                  disabled={!isEditMode}
                  type="text"
                  placeholder={t('Enter Sessions')}
                  value={courseSessions}
                  onChange={e =>
                    e.target.validity.valid && setCourseSessions(e.target.value)
                  }
                  pattern="^[0-9]\d{0,6}(\.\d{0,1})?$"
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title">{t('Start Time')}</p>
              <div className="form--field-input">
                <input
                  disabled={!isEditMode}
                  type="time"
                  placeholder={t('Pick Time')}
                  value={courseStartTime}
                  onChange={e => setCourseStartTime(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title">{t('End Time')}</p>
              <div className="form--field-input">
                <input
                  disabled={!isEditMode}
                  type="time"
                  placeholder={t('Pick Time')}
                  value={courseEndTime}
                  onChange={e => setCourseEndTime(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
