import useApiPromise from './useApiPromise';

const useApiPromiseArray = () => {
  const [fetchApis] = useApiPromise();

  const submitApi = async (urls, method, service) => {
    const urlPromises = urls?.map(url =>
      fetchApis(url, '_', '_', '_', method, service)
    );

    try {
      return await Promise.all(urlPromises);
    } catch (error) {
      console.log(error);
    }
  };
  return [submitApi];
};

export default useApiPromiseArray;
