import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router';
import useApi from '../../../Hooks/useApi';
import {
  RequestSubmissionErrors,
  RequestType,
  Services,
} from '../../Common/constants';
import BondConfirmationModal from '../../Components/Modal/BondConfirmationModal';
import BudgetCheckModal from '../../Components/Modal/BudgetCheckModal';
import GroupFormCourseDetails from '../../Components/TrainingForm/CommonComponents/TrainingFormCourseDetails';
import GroupFormOtherDetails from '../../Components/TrainingForm/CommonComponents/TrainingFormOtherDetails';
import GroupFormPersonalDetails from '../../Components/TrainingForm/GroupRequest/GroupFormPersonalDetails';
import {
  GET_RMS_GROUP_BOND_DATA_FAILED,
  GET_RMS_GROUP_BOND_DATA_REQUEST,
  GET_RMS_GROUP_BOND_DATA_SUCCESS,
  POST_RMS_GROUP_REQUEST_FAILED,
  POST_RMS_GROUP_REQUEST_REQUEST,
  POST_RMS_GROUP_REQUEST_SUCCESS,
  RMS_GROUP_RESET_USER_MANAGEMENT,
} from '../../redux/actionTypes/groupRequestTypes';

const GroupRequestContainer = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [submitGroupRequestApi] = useApi();
  const [getBondDataApi] = useApi();

  const [postData, setPostData] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showBudgetCheckPopup, setShowBudgetCheckPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [failedUsers, setFailedUsers] = useState('');
  const [bondBracketAvailable, setBondBracketAvailable] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const {
    groupRequestSubmitLoading,
    groupRequestSubmitError,
    groupRequestSubmitErrorMessage,
    groupRequestSubmitSuccess,
    groupRequestSubmitResponse,
    templateId,
    allUsers,
    bondDataLoading,
    bondDataSuccess,
    bondData,
  } = useSelector(state => state.rmsGroupRequest);

  useEffect(() => {
    if (!templateId) {
      history.push(`/rms`);
    }
  }, [templateId]);

  const gotoNextRoute = nextRoute => {
    if (!nextRoute) return;
    history.push(`${path}/${nextRoute}`);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const getBondDetails = grossFee => {
    grossFee &&
      templateId &&
      getBondDataApi(
        `/api/v1/bandBrackets/applicableBandBracket?groupTemplateId=${templateId}&grossFee=${grossFee}`,
        GET_RMS_GROUP_BOND_DATA_REQUEST,
        GET_RMS_GROUP_BOND_DATA_SUCCESS,
        GET_RMS_GROUP_BOND_DATA_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService
      );
  };

  useEffect(() => {
    const { grossFee } = postData?.course || {};
    if (grossFee) {
      getBondDetails(grossFee);
    }
  }, [postData?.course]);

  useEffect(() => {
    if (!bondDataLoading && bondDataSuccess) {
      if (Object.keys(bondData || {})?.length) {
        //bond exist
        setBondBracketAvailable(true);
      } else {
        //no bonds
        setBondBracketAvailable(false);
      }
    }
  }, [bondDataLoading]);

  const updatePostData = (nextRoute, data = {}) => {
    setPostData(existingData => {
      return {
        ...existingData,
        ...data,
      };
    });
    if (nextRoute) {
      gotoNextRoute(nextRoute);
    } else {
      //last form
      setErrorMessage('');

      if (bondBracketAvailable) {
        setShowConfirmationPopup(true);
      } else {
        setSubmitClicked(true);
      }
    }
  };

  const isRequiredKeysExists = () => {
    return ['course', 'examination', 'applicantIds'].every(key =>
      Object.keys(postData).includes(key)
    );
  };

  useEffect(() => {
    if (submitClicked && isRequiredKeysExists()) {
      submitGroupRequest(true);
      setSubmitClicked(false);
    }
  }, [submitClicked, postData]);

  const submitGroupRequest = (skipBonding = false) => {
    if (!isRequiredKeysExists()) {
      //course details and other details not added
      return;
    }
    const formattedPostData = {
      ...postData,
      applicableTemplateId: templateId,
      skipBonding,
      type: RequestType.GROUP_REQUEST,
    };
    submitGroupRequestApi(
      `/api/v1/groupRequests`,
      POST_RMS_GROUP_REQUEST_REQUEST,
      POST_RMS_GROUP_REQUEST_SUCCESS,
      POST_RMS_GROUP_REQUEST_FAILED,
      formattedPostData,
      {},
      'POST',
      false,
      Services.isRmsService
    );
  };

  const onBudgetCheckOkClick = () => {
    setFailedUsers('');
    setShowBudgetCheckPopup(false);
    redirectToLandingPage();
  };

  useEffect(() => {
    if (groupRequestSubmitError && groupRequestSubmitErrorMessage) {
      if (
        groupRequestSubmitErrorMessage ===
        RequestSubmissionErrors.INSUFFICIENT_BUDGET
      ) {
        setShowConfirmationPopup(false);
        setShowBudgetCheckPopup(true);
        setErrorMessage('');
      } else if (
        groupRequestSubmitErrorMessage ===
        RequestSubmissionErrors.PRICE_BRACKET_NOT_DEFINED
      ) {
        setErrorMessage(t('Price bracket not defined for the given gross fee'));
      } else {
        setErrorMessage(t('Error while creating request. Please try again.'));
      }
    } else if (groupRequestSubmitError) {
      setErrorMessage(t('Error while creating request. Please try again.'));
    } else {
      setErrorMessage('');
    }
  }, [groupRequestSubmitError]);

  useEffect(() => {
    if (
      !groupRequestSubmitLoading &&
      !groupRequestSubmitError &&
      groupRequestSubmitSuccess
    ) {
      //request save success

      if (groupRequestSubmitResponse?.failedApplicants?.length) {
        const failedIds = groupRequestSubmitResponse?.failedApplicants || [];
        const failedUserNames =
          allUsers
            ?.filter(({ _id }) => failedIds?.includes(_id))
            ?.map(({ name }) => name)
            ?.join(', ') || '';
        setFailedUsers(failedUserNames);
        setShowConfirmationPopup(false);
        setShowBudgetCheckPopup(true);
        setErrorMessage('');
      } else {
        redirectToLandingPage();
      }
    }
  }, [
    groupRequestSubmitLoading,
    groupRequestSubmitError,
    groupRequestSubmitSuccess,
  ]);

  const redirectToLandingPage = () => {
    dispatch({ type: RMS_GROUP_RESET_USER_MANAGEMENT });
    history.push('/rms');
  };

  return (
    <div>
      <Route
        exact
        path="/rms/group-request"
        children={({ match }) => (
          <GroupFormCourseDetails
            match={match}
            onSave={updatePostData}
            onBack={onBackClick}
            isGroup
          />
        )}
      />
      <Route
        path="/rms/group-request/other"
        children={({ match }) => (
          <GroupFormOtherDetails
            match={match}
            onSave={updatePostData}
            onBack={onBackClick}
            isGroup
          />
        )}
      />
      <Route
        path="/rms/group-request/personal"
        children={({ match }) => (
          <GroupFormPersonalDetails
            match={match}
            onSave={updatePostData}
            onBack={onBackClick}
            loadingButton={groupRequestSubmitLoading}
            submitErrorMessage={errorMessage}
          />
        )}
      />
      {showConfirmationPopup && (
        <BondConfirmationModal
          onClose={() => setShowConfirmationPopup(false)}
          onNoClick={() => submitGroupRequest(true)}
          onYesClick={() => submitGroupRequest(false)}
          loadingButton={groupRequestSubmitLoading}
          errorMessage={errorMessage}
          isGroupRequest
        />
      )}
      {showBudgetCheckPopup && (
        <BudgetCheckModal
          onClose={() => setShowBudgetCheckPopup(false)}
          onOkClick={onBudgetCheckOkClick}
          isGroupRequest
          userName={failedUsers}
        />
      )}
    </div>
  );
};

export default GroupRequestContainer;
