import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { toast } from 'react-toastify';
import useApi from '../../../Hooks/useApi';
import {
  Services,
  SuperAdminActionMode,
  SuperAdminRequestFilterOptions,
} from '../../Common/constants';
import ManageRequests from '../../Components/ManageRequets/ManageRequests';
import useFetchLoggedInUserDetails from '../../hooks/useFetchLoggedInUserDetails';
import {
  RESET_RMS_SELECTED_TEMPLATE_REQUESTS,
  RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED,
  RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST,
  RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST,
  RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS,
  RMS_RESET_MANAGE_REQUEST_PAGE_DATA,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
  RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
} from '../../redux/actionTypes/adminManageRequests';
import {
  RMS_GET_FEEDBACK_REQUEST_FAILED,
  RMS_GET_FEEDBACK_REQUEST_REQUEST,
  RMS_GET_FEEDBACK_REQUEST_SUCCESS,
} from '../../redux/actionTypes/manageRequestTypes';
import AdminManageRequestFormContainer from './AdminManageRequestFormContainer';

const ManageRequestContainer = () => {
  const {
    requestDetailsById,
    loading,
    allTemplateRequests,
    selectedTemplateRequests,
    requestTotalRecords,
    requestPage,
    lastEditedUserRole,
    approvalActionLoading,
    approvalActionError,
    approvalActionSuccess,
    approvalActionErrorMessage,
  } = useSelector(state => state.rmsAdminManageRequest);

  const {
    centers = {},
    departments = {},
    designations = {},
    qualifications = {},
  } = useSelector(state => state.rmsConvertId);
  const { feedbackRequest } = useSelector(state => state.rmsManageRequest);

  const dispatch = useDispatch();
  const [fetchApplicantDetails] = useApi();
  const [fetchRequests] = useApi();
  const [fetchRequestsByTemplate] = useApi();
  const [fetchTrainingRequstDetailsById] = useApi();
  const [fetchUserRoles] = useApi();
  const [getFeedbackRequestIdsApi] = useApi();
  const [acceptOrRejectApi] = useApi();
  const userDetails = useFetchLoggedInUserDetails();

  const { t } = useTranslation();

  const [selectedDateConvertedToIso, setSelectedDateConvertedToIso] = useState({
    startDate: '',
    endDate: '',
  });

  const [searchText, setSearchText] = useState('');
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: '',
      key: 'selection',
    },
  ]);
  const [searchKeyValue, setSearchKeyValue] = useState('');

  const [filterMode, setFilterMode] = useState(
    SuperAdminActionMode.ALL_REQUEST
  );
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedDropdown, setSelectedDropdown] = useState(
    SuperAdminRequestFilterOptions.NAME
  );
  const searchByNameParam = `&searchInFields=NAME&searchText=${searchText}`;
  const searchByEmailParam = `&searchInFields=EMAIL&searchText=${searchText}`;
  const searchByRequestIdParam = `&requestId=${searchText}`;
  const dateRangeParam = `&dateSubmittedFrom=${selectedDateConvertedToIso.startDate}&dateSubmittedTo=${selectedDateConvertedToIso.endDate}`;

  const getApplicantDetails = userId => {
    fetchApplicantDetails(
      `/api/v1/users?userIds=${userId}&page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  //convert selected date to ISO string
  useEffect(() => {
    if (!selectedDate?.[0].endDate) return;
    setSelectedDateConvertedToIso({
      startDate: new Date(selectedDate[0].startDate).toISOString(),
      endDate: new Date(selectedDate[0].endDate).toISOString(),
    });
  }, [selectedDate]);

  const filterParameter = useMemo(() => {
    switch (filterMode) {
      case SuperAdminActionMode.APPROVAL:
        return `&pendingActionBy=${userDetails?._id}`;

      case SuperAdminActionMode.PAST_REQUEST:
        return `&actionNotRequiredBy=${userDetails?._id}`;

      default:
        return '';
    }
  }, [filterMode, userDetails]);

  const selectedSearchOptionParam = useMemo(() => {
    switch (selectedDropdown) {
      case SuperAdminRequestFilterOptions.NAME:
        return searchByNameParam;
      case SuperAdminRequestFilterOptions.EMAIL:
        return searchByEmailParam;
      case SuperAdminRequestFilterOptions.DATE_RANGE: {
        const { startDate, endDate } = selectedDateConvertedToIso;
        return startDate || endDate ? dateRangeParam : '';
      }
      case SuperAdminRequestFilterOptions.REQUEST_ID:
        return searchByRequestIdParam;
      default:
        return '';
    }
  }, [selectedDropdown, searchText, selectedDateConvertedToIso]);

  const getAllRequests = page => {
    selectedTemplate &&
      fetchRequestsByTemplate(
        `/api/v1/templates/${selectedTemplate}/applicantTrainingRequests?page=${page}&size=20${selectedSearchOptionParam}${filterParameter}`,
        RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST,
        RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS,
        RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED,
        {},
        '',
        'GET',
        null,
        Services.isRmsService,
        { page }
      );
  };

  const getUserRoles = userEmail => {
    fetchUserRoles(
      `/api/v1/users?emailList=${userEmail}&page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const loadNextRequestPage = () => {
    const maxPage = Math.ceil(requestTotalRecords / 10);
    const nextPage = requestPage + 1;
    nextPage <= maxPage && getAllRequests(requestPage + 1);
  };

  useEffect(() => {
    if (!selectedTemplate) return;
    getAllRequests(1);
  }, [
    searchText,
    selectedDropdown,
    selectedTemplate,
    selectedDateConvertedToIso,
    filterMode,
  ]);

  useEffect(() => {
    dispatch({
      type: RMS_RESET_MANAGE_REQUEST_PAGE_DATA,
    });
  }, [selectedDropdown]);

  const fetchAllRequests = searchText => {
    fetchRequests(
      `/api/v1/templates?page=1&size=10${
        searchText ? `&searchNameLike=${searchText}` : ''
      }`,
      RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST,
      RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS,
      RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getFeedbackRequestIds = requestIds => {
    getFeedbackRequestIdsApi(
      `/api/v1/feedbacks?trainingRequestIds=${requestIds}&page=1&size=${requestIds.length}`,
      RMS_GET_FEEDBACK_REQUEST_REQUEST,
      RMS_GET_FEEDBACK_REQUEST_SUCCESS,
      RMS_GET_FEEDBACK_REQUEST_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };

  const resetSelectedTemplateRequests = () => {
    dispatch({
      type: RESET_RMS_SELECTED_TEMPLATE_REQUESTS,
    });
  };

  const refreshData = () => {
    resetSelectedTemplateRequests();
    getAllRequests(1);
  };

  const acceptOrRejectRequest = (
    action,
    trainingRequestId,
    approvalLevelId
  ) => {
    if (!trainingRequestId || !approvalLevelId) return;
    acceptOrRejectApi(
      `/api/v1/trainingRequests/${trainingRequestId}/approvalLevels/${approvalLevelId}/${
        action ? 'approve' : 'reject'
      }`,
      RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
      RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
      RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED,
      {},
      {},
      'PUT',
      false,
      Services.isRmsService
    );
  };

  useEffect(() => {
    if (!approvalActionLoading) {
      if (approvalActionSuccess) {
        refreshData();
      } else if (approvalActionError) {
        toast.dark(
          approvalActionErrorMessage
            ? t(approvalActionErrorMessage)
            : t('Something Went Wrong, Please Try Again Later.'),
          {
            autoClose: 5000,
          }
        );
      }
    }
  }, [approvalActionSuccess, approvalActionError, approvalActionLoading]);

  useEffect(() => {
    const requestIds = selectedTemplateRequests?.map(request => request._id);
    requestIds.length && getFeedbackRequestIds(requestIds);
  }, [selectedTemplateRequests]);

  //get userRoles from the request
  useEffect(() => {
    if (requestDetailsById.lastUpdatedByEmail) {
      getUserRoles(requestDetailsById.lastUpdatedByEmail);
    }
  }, [requestDetailsById]);

  useEffect(() => {
    fetchAllRequests('');
  }, []);

  //remove existing search text when switching to dateRange
  useEffect(() => {
    if (selectedDropdown === 'dateRange') {
      setSearchText('');
    }
  }, [selectedDropdown]);

  //set initial state when all template data is available
  useEffect(() => {
    if (!allTemplateRequests) return;
    !selectedTemplate && setSelectedTemplate(allTemplateRequests[0]?._id);
  }, [allTemplateRequests]);

  useEffect(() => {
    const applicantId = requestDetailsById?.applicantId;
    if (applicantId) {
      getApplicantDetails(applicantId);
    }
  }, [requestDetailsById]);

  let searchTimeout;

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      fetchAllRequests(searchKeyValue);
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyValue]);

  return (
    <div>
      <Route
        exact
        path="/rms/admin-manage-requests"
        children={({ match }) => (
          <ManageRequests
            match={match}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            selectedDropdown={selectedDropdown}
            setSelectedDropdown={setSelectedDropdown}
            allTemplateRequests={allTemplateRequests}
            allTemplateRequestsLoading={loading}
            selectedTemplateRequests={selectedTemplateRequests}
            loading={loading || approvalActionLoading}
            loadNextRequestPage={loadNextRequestPage}
            feedbackRequest={feedbackRequest}
            acceptOrRejectRequest={acceptOrRejectRequest}
            filterMode={filterMode}
            setFilterMode={setFilterMode}
            setSearchTemplateText={setSearchKeyValue}
          />
        )}
      />
      <Route
        path="/rms/admin-manage-requests/requestform/:formId"
        children={({ match }) => (
          <AdminManageRequestFormContainer
            match={match}
            formData={requestDetailsById}
            loading={loading}
            centers={centers}
            designations={designations}
            qualifications={qualifications}
            departments={departments}
            lastEditedUserRole={lastEditedUserRole}
          />
        )}
      />
    </div>
  );
};

export default ManageRequestContainer;
