import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import awsConfiguration from '../../config/awsCognitoSettings';
import { UPDATE_FILE_UPLOADING_PERCENTAGE } from '../../redux/actionTypes/video';

const useUploadFile = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [urlTemplate, setUrlTemplate] = useState('');

  const { s3BucketData } = useSelector(state => state?.commonData);

  useEffect(() => {
    !uploadingFile && setUploadProgress(0);
  }, [uploadingFile]);

  useEffect(() => {
    const urlString = `https://${
      s3BucketData?.bucketName || ''
    }.s3.amazonaws.com/public/{FILE_KEY}`;
    setUrlTemplate(urlString);
  }, [s3BucketData]);

  useEffect(() => {
    if (uploadingFile) {
      const sanitizedFileName = uploadingFile.name
        .split('.')
        .slice(0, -1)
        .join('_')
        .replace(/\W/g, '');
      const fileExtension = uploadingFile.name.split('.').pop();
      const path = s3BucketData?.filePath || '';
      const fileKey =
        `${path}${Date.now()}-${sanitizedFileName}.${fileExtension}` || '';

      Storage.put(fileKey, uploadingFile, {
        contentType: uploadingFile.type,
        progressCallback(progress) {
          const { loaded, total } = progress;

          const percentageProgress = Math.floor((loaded / total) * 100);
          dispatch({
            type: UPDATE_FILE_UPLOADING_PERCENTAGE,
            payload: percentageProgress,
          });
          setUploadProgress(percentageProgress);
        },
      })
        .then(result => setUrl(urlTemplate.replace('{FILE_KEY}', result?.key)))
        .catch(err => {
          console.log(err);
        });

      setUploadingFile(null);
    }
  }, [uploadingFile]);
  return [setUploadingFile, url, uploadProgress];
};

export default useUploadFile;
