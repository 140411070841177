import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { Services, TemplateTypes } from '../../Common/constants';
import ManageTemplateComponent from '../../Components/ManageTemplate/ManageTemplate';
import {
  GET_RMS_BOND_TEMPLATES_FAILED,
  GET_RMS_BOND_TEMPLATES_REQUEST,
  GET_RMS_BOND_TEMPLATES_SUCCESS,
  GET_RMS_BOND_TEMPLATE_BY_ID_FAILED,
  GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST,
  GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS,
  GET_RMS_TEMPLATE_FAIL,
  GET_RMS_TEMPLATE_REQUEST,
  GET_RMS_TEMPLATE_SUCCESS,
  RESET_RMS_SELECTED_BOND_FOR_TEMPLATE,
  RESET_RMS_TEMPLATE,
  TOGGLE_RMS_TEMPLATE_TYPE,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  CLOSE_DELETE_TEMPLATE_ERROR_MSG,
} from '../../redux/actionTypes/templateTypes';

const ManageTemplateContainer = ({ history }) => {
  const [getTemplateByFilterApi] = useApi();
  const [getBondTemplatesApi] = useApi();
  const [getBondTemplateByIdApi] = useApi();
  const [deleteTemplateApi] = useApi();
  const [templateFilter, setTemplateFilter] = useState(
    TemplateTypes.NORMAL_TEMPLATE
  );
  const [isBondView, setIsBondView] = useState(false);
  const dispatch = useDispatch();
  const {
    templateData,
    templateLoading,
    templateTotalRecords,
    templatePage,
    bondTemplates = [],
    bondTemplatesLoading,
    selectedBondLoading,
    selectedBond,
    deleteTemplateLoading,
    deleteTemplateSuccess,
    isDeleteTemplateFail,
  } = useSelector(state => state.templateManagement);

  const fetchTemplates = page => {
    templateFilter &&
      getTemplateByFilterApi(
        `/api/v1/templates?type=${templateFilter}&page=${page}&size=10`,
        GET_RMS_TEMPLATE_REQUEST,
        GET_RMS_TEMPLATE_SUCCESS,
        GET_RMS_TEMPLATE_FAIL,
        {},
        '',
        'GET',
        null,
        Services.isRmsService
      );
  };

  const fetchBondTemplates = page => {
    getBondTemplatesApi(
      `/api/v1/templates?page=${page}&size=100`,
      GET_RMS_BOND_TEMPLATES_REQUEST,
      GET_RMS_BOND_TEMPLATES_SUCCESS,
      GET_RMS_BOND_TEMPLATES_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const fetchBondByTemplateId = templateId => {
    dispatch({
      type: RESET_RMS_SELECTED_BOND_FOR_TEMPLATE,
    });
    templateId &&
      isBondTemplateExists(templateId) &&
      getBondTemplateByIdApi(
        `/api/v1/bondTemplates?templateId=${templateId}`,
        GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST,
        GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS,
        GET_RMS_BOND_TEMPLATE_BY_ID_FAILED,
        {},
        '',
        'GET',
        null,
        Services.isRmsService
      );
  };

  const isBondTemplateExists = templateId => {
    return !!bondTemplates?.find(({ _id }) => _id === templateId)
      ?.bondTemplateId;
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_RMS_TEMPLATE });
    };
  }, []);

  useEffect(() => {
    !isBondView && fetchTemplates(1);
  }, [templateFilter, isBondView]);

  useEffect(() => {
    if (isBondView) {
      fetchBondTemplates(1);
    }
  }, [isBondView]);

  useEffect(() => {
    if (!deleteTemplateLoading && deleteTemplateSuccess) {
      dispatch({ type: TOGGLE_RMS_TEMPLATE_TYPE });
      if (isBondView) {
        fetchBondTemplates(1);
      } else {
        fetchTemplates(1);
      }
    }
  }, [deleteTemplateLoading, deleteTemplateSuccess]);

  const deleteTemplate = deleteTemplateData => {
    if (!deleteTemplateData) {
      return;
    }
    const requestBody = {
      ...deleteTemplateData,
      rmsAdminIds: deleteTemplateData?.rmsAdminIds || [],
      normalTemplateApplicants:
        deleteTemplateData?.normalTemplateApplicants || [],
      grossPriceBrackets: deleteTemplateData?.grossPriceBrackets || [],
      isRemoved: true,
    };

    deleteTemplateApi(
      `/api/v1/templates/${deleteTemplateData._id}`,
      DELETE_TEMPLATE_REQUEST,
      DELETE_TEMPLATE_SUCCESS,
      DELETE_TEMPLATE_FAIL,
      {},
      '',
      'DELETE',
      null,
      Services.isRmsService
    );
  };

  const deleteBondTemplate = template => {
    template?._id &&
      deleteTemplateApi(
        `/api/v1/bondTemplates/${template?._id}`,
        DELETE_TEMPLATE_REQUEST,
        DELETE_TEMPLATE_SUCCESS,
        DELETE_TEMPLATE_FAIL,
        {},
        '',
        'DELETE',
        null,
        Services.isRmsService
      );
  };

  return (
    <ManageTemplateComponent
      selectedTemplate={template => {
        if (!isBondView) {
          dispatch({ type: TOGGLE_RMS_TEMPLATE_TYPE });
          setTemplateFilter(template);
        } else {
          fetchBondByTemplateId(template);
        }
      }}
      templateData={templateData}
      templateLoading={templateLoading}
      isDeleteTemplateFail={isDeleteTemplateFail}
      loadMoreData={() => {
        !isBondView &&
          templateData?.length &&
          templateTotalRecords > templateData?.length &&
          fetchTemplates(templatePage + 1);
      }}
      isBondView={isBondView}
      setIsBondView={setIsBondView}
      bondTemplates={bondTemplates}
      selectedBond={selectedBond}
      bondLoading={bondTemplatesLoading || selectedBondLoading}
      deleteTemplate={isBondView ? deleteBondTemplate : deleteTemplate}
      deleteTemplateLoading={deleteTemplateLoading}
      deleteTemplateSuccess={deleteTemplateSuccess}
    />
  );
};

export default ManageTemplateContainer;
