import { RMS_GET_FEEDBACK_REQUEST_SUCCESS } from '../actionTypes/manageRequestTypes';
import {
  GET_ALL_REQUESTS_REQUEST,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_FAIL,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_APPLICANT_NAMES_REQUEST,
  GET_APPLICANT_NAMES_SUCCESS,
  GET_APPLICANT_NAMES_FAIL,
  RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS,
  RESET_REQUEST_TYPES_DATA,
} from '../actionTypes/requestsTypes';

const initialState = {
  getRequestsLoading: false,
  getRequestsData: null,
  getRequestsError: false,
  getTemplatesLoading: false,
  getTemplatesData: null,
  getTemplatesError: false,
  getApplicantNamesLoading: false,
  getApplicantNamesData: null,
  getApplicantNamesError: false,
  feedbackRequest: null,
};

const requestsReducer = (state = initialState, action) => {
  const { payload } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case GET_ALL_REQUESTS_REQUEST:
      return {
        ...state,
        getRequestsLoading: true,
      };
    case GET_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        getRequestsData: data || null,
        getRequestsLoading: false,
      };
    case GET_ALL_REQUESTS_FAIL:
      return {
        ...state,
        getRequestsError: true,
        getRequestsLoading: false,
      };
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        getTemplatesLoading: true,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        getTemplatesData: data || null,
        getTemplatesLoading: false,
      };
    case GET_TEMPLATES_FAIL:
      return {
        ...state,
        getTemplatesError: true,
        getTemplatesLoading: false,
      };
    case GET_APPLICANT_NAMES_REQUEST:
      return {
        ...state,
        getApplicantNamesLoading: true,
      };
    case GET_APPLICANT_NAMES_SUCCESS:
      return {
        ...state,
        getApplicantNamesData: data || null,
        getApplicantNamesLoading: false,
      };
    case GET_APPLICANT_NAMES_FAIL:
      return {
        ...state,
        getApplicantNamesError: true,
        getApplicantNamesLoading: false,
      };
    case RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS:
      return {
        ...state,
        feedbackRequest: data || [],
      };
    case RESET_REQUEST_TYPES_DATA:
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default requestsReducer;
