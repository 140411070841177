import { avoidedDuplicationData, setErrorMessage } from '../../Common/helper';
import {
  RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED,
  RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
  RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
  RMS_DELETE_REQUEST_FAILED,
  RMS_DELETE_REQUEST_REQUEST,
  RMS_DELETE_REQUEST_SUCCESS,
  RMS_GET_ADMIN_MODE_REQUESTS_FAILED,
  RMS_GET_ADMIN_MODE_REQUESTS_REQUEST,
  RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS,
  RMS_GET_REQUEST_USERS_SUCCESS,
  RMS_GET_USER_MODE_REQUESTS_FAILED,
  RMS_GET_USER_MODE_REQUESTS_REQUEST,
  RMS_GET_USER_MODE_REQUESTS_SUCCESS,
  RMS_RESET_REQUEST_DATA,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED,
  RMS_GET_FEEDBACK_REQUEST_REQUEST,
  RMS_GET_FEEDBACK_REQUEST_SUCCESS,
  RMS_GET_FEEDBACK_REQUEST_FAILED,
} from '../actionTypes/manageRequestTypes';

const INITIAL_STATE = {
  userRequests: [],
  adminRequests: [],
  adminPage: 0,
  userPage: 0,
  approvalLevels: '',
  approvalInfo: '',
  approverNames: [],
  approvalHistoryLoading: false,
  deleteRequestLoading: false,
  deleteRequestSuccess: false,
  deleteRequestError: false,
  userDetails: {},
  requestLoading: false,
  approvalActionSuccess: false,
  approvalActionError: false,
  approvalActionLoading: false,
  requestTotalRecords: 0,
  feedbackRequest: [],
  approvalActionErrorMessage: '',
};

const adminApplicantManageRequestReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_GET_ADMIN_MODE_REQUESTS_REQUEST:
    case RMS_GET_USER_MODE_REQUESTS_REQUEST:
      return {
        ...state,
        requestLoading: true,
      };
    case RMS_GET_USER_MODE_REQUESTS_SUCCESS:
      return {
        ...state,
        userRequests:
          customResponse.page === 1
            ? data
            : avoidedDuplicationData(state.userRequests, data || [], '_id'),
        userPage: page || state.userPage,
        requestLoading: false,
        requestTotalRecords: totalRecords || 0,
      };
    case RMS_GET_USER_MODE_REQUESTS_FAILED:
    case RMS_GET_ADMIN_MODE_REQUESTS_FAILED:
      return {
        ...state,
        requestLoading: false,
      };

    case RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS:
      return {
        ...state,
        adminRequests:
          customResponse.page === 1
            ? data
            : avoidedDuplicationData(state.adminRequests, data || [], '_id'),
        adminPage: page || state.adminPage,
        requestLoading: false,
        requestTotalRecords: totalRecords || 0,
      };

    case RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS:
      return {
        ...state,
        approvalLevels: action.payload.totalRecords,
      };

    case RMS_DELETE_REQUEST_REQUEST:
      return {
        ...state,
        deleteRequestLoading: true,
        deleteRequestError: false,
        deleteRequestSuccess: false,
      };
    case RMS_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestSuccess: true,
      };
    case RMS_DELETE_REQUEST_FAILED:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestError: true,
      };
    case RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST:
      return {
        ...state,
        approvalActionLoading: true,
        approvalActionError: false,
        approvalActionSuccess: false,
        requestLoading: true,
        approvalActionErrorMessage: '',
      };
    case RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        approvalActionLoading: false,
        approvalActionSuccess: true,
      };
    case RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED:
      return {
        ...state,
        approvalActionLoading: false,
        approvalActionError: true,
        requestLoading: false,
        approvalActionErrorMessage: setErrorMessage(action?.payload),
      };
    case RMS_GET_REQUEST_USERS_SUCCESS: {
      let updatedUsers = state.userDetails;

      if (data?.length) {
        data.forEach(user => {
          updatedUsers[user._id] = user.name;
        });
      }

      return {
        ...state,
        userDetails: updatedUsers,
      };
    }

    case RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST:
      return {
        ...state,
        approvalHistoryLoading: true,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS:
      return {
        ...state,
        approvalInfo: action.payload,
        approvalHistoryLoading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED:
      return {
        ...state,
        approvalHistoryLoading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST:
      return {
        ...state,
        approvalHistoryLoading: true,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS:
      return {
        ...state,
        approverNames: data,
        approvalHistoryLoading: false,
      };
    case RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED:
      return {
        ...state,
        approvalHistoryLoading: false,
      };

    case RMS_GET_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        feedbackRequest: data,
      };

    case RMS_RESET_REQUEST_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default adminApplicantManageRequestReducer;
