import React from 'react';
import { useTranslation } from 'react-i18next';
import useConvertDate from '../../../hooks/useConvertDate';

const UserDetails = ({
  userReportDashboardDetails,
  normalTemplateDetails,
  groupTemplateDetails,
  qualificationDetails,
  designationDetails,
  departmentDetails,
  centerDetails,
  companyDetails,
}) => {
  const { t } = useTranslation();
  const { convertDateToThemeFormat } = useConvertDate();

  const { _id, name, dob, joinedDate, nricNo, employeeId } =
    userReportDashboardDetails || {};

  return (
    <>
      <div className="RMS__page--content">
        <div className="RMS__page--header underline">
          <div className="RMS__page--header-row">
            <div className="RMS__page--header-left">
              <p>{t('User Details')}</p>
            </div>
          </div>
        </div>
        <div className="RMS__page--body">
          <div className="RMS__page--body-row threeColumn">
            <div className="RMS__page--body-column">
              <div className="form--field row">
                <p className="form--field-title">{t('Employee Id')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Employee Id')}
                    value={employeeId || '-'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field row">
                <p className="form--field-title">{t('Normal Template')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Normal Template')}
                    value={normalTemplateDetails?.name || '-'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field row">
                <p className="form--field-title">{t('Group Template')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Group Template')}
                    value={groupTemplateDetails?.name || '-'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-row fourColumn">
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Name')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Name')}
                    value={name || '-'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('NRIC No')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('NRIC No')}
                    value={nricNo || '-'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">
                  {t('Highest Qualification')} :
                </p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Highest Qualification')}
                    value={qualificationDetails?.name || '_'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Department Id')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Department Id')}
                    value={departmentDetails?.name || '_'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-row fourColumn">
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Section Code')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Section Code')}
                    value={centerDetails?.name || '_'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Designation')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Designation')}
                    value={designationDetails?.name || '_'}
                  />
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Date Of Birth')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Date Of Birth')}
                    value={convertDateToThemeFormat(dob)}
                  />
                </div>
              </div>
            </div>{' '}
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Joined Date')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Joined Date')}
                    value={convertDateToThemeFormat(joinedDate)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="RMS__page--body-row fourColumn">
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">{t('Company')} :</p>
                <div className="form--field-input">
                  <input
                    readOnly
                    type="text"
                    placeholder={t('Company')}
                    value={companyDetails?.name || '_'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
