import React, { useState } from 'react';
import { Route } from 'react-router';
import AdminDashboardContainer from './DashboardContainer';
import ReportingDashboardContainer from './ReportingDashboardContainer';
import ManageRequestsFormContainer from '../ManageRequestContainer/ManageRequestFormContainer';
import { adminDashboardMode } from '../../Common/constants';

const DashboardRoutes = ({ isSuperAdminDashboard = false }) => {
  const [selectedDateRangeId, setSelectedDateRangeId] = useState('');
  const [selectedMode, setSelectedMode] = useState(adminDashboardMode.OVERALL);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTemplateLabel, setSelectedTemplateLabel] = useState('');
  const [templateSearchText, setTemplateSearchText] = useState('');
  const [selectedTemplateOption, setSelectedTemplateOption] = useState({});
  
  return (
    <div>
      <Route
        exact
        path={['/rms/super-admin-dashboard', '/rms/admin-dashboard']}
        children={({ match }) =>
          match && (
            <AdminDashboardContainer
              selectedDateRangeId={selectedDateRangeId}
              setSelectedDateRangeId={setSelectedDateRangeId}
              isSuperAdminDashboard={isSuperAdminDashboard}
              selectedMode={selectedMode}
              setSelectedMode={setSelectedMode}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplateLabel={selectedTemplateLabel}
              setSelectedTemplateLabel={setSelectedTemplateLabel}
              templateSearchText={templateSearchText}
              setTemplateSearchText={setTemplateSearchText}
              selectedTemplateOption={selectedTemplateOption}
              setSelectedTemplateOption={setSelectedTemplateOption}
            />
          )
        }
      />
      <Route
        exact
        path={[
          '/rms/super-admin-dashboard/reporting',
          '/rms/applicant-dashboard/reporting',
          '/rms/admin-dashboard/reporting',
        ]}
        children={({ match }) =>
          match && (
            <ReportingDashboardContainer
              selectedDateRangeId={selectedDateRangeId}
              setSelectedDateRangeId={setSelectedDateRangeId}
            />
          )
        }
      />
      <Route
        exact
        path="/rms/super-admin-dashboard/reporting/requestform"
        children={({ match }) => <ManageRequestsFormContainer match={match} />}
      />
    </div>
  );
};

export default DashboardRoutes;
