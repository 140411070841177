import React from 'react';
import iconDelete from '../../assets/images/svg-images/icon-delete.svg';
import iconEdit from '../../assets/images/svg-images/icon-edit.svg';

const EditAndDeleteIconBtn = ({
  editHandler,
  deleteHandler,
  isHideEdit = false,
}) => {
  return (
    <>
      {editHandler && !isHideEdit && (
        <img
          src={iconEdit}
          onClick={e => {
            e.stopPropagation();
            editHandler();
          }}
          alt="edit-data"
        />
      )}
      {deleteHandler && (
        <img
          src={iconDelete}
          onClick={e => {
            e.stopPropagation();
            deleteHandler();
          }}
          alt="delete-data"
        />
      )}
    </>
  );
};

export default EditAndDeleteIconBtn;
