import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import { BondsSearchDropdownOptions } from '../../Common/constants';
import ReactVisibilitySensor from 'react-visibility-sensor';

const AddBondUserModal = ({
  addApplicantSearchDropdown,
  setAddApplicantSearchDropdown,
  onHandleClose = () => {},
  addApplicantSearchText,
  setAddApplicantSearchText,
  applicantsEligibleForBonds,
  convertDepartmentId,
  convertDesignationId,
  getRequestId,
  eligibleApplicantIds,
  addApplicantsToBondTable,
  getApplicantIdsEligibleForBonds,
  getApplicantDetailsEligibleForBonds,
  loading,
  resetModal,
  resetSearchQuery,
  loadNextPage,
}) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState('');

  const searchDropdownOptions = [
    { label: 'Request ID', value: BondsSearchDropdownOptions.REQUESTID },
    { label: 'Name', value: BondsSearchDropdownOptions.NAME },
    { label: 'Email', value: BondsSearchDropdownOptions.EMAIL },
  ];

  const addUserHandler = () => {
    if (!selectedUserId) {
      onHandleClose();
    } else {
      const applicantDetails = eligibleApplicantIds.find(
        item => item.applicantId === selectedUserId
      );
      addApplicantsToBondTable(
        applicantDetails.applicantId,
        applicantDetails._id
      );
      onHandleClose();
    }
  };

  useEffect(() => {
    return () => {
      setAddApplicantSearchText('');
      resetModal();
    };
  }, []);

  useEffect(() => {
    getApplicantIdsEligibleForBonds();
  }, [addApplicantSearchText]);

  useEffect(() => {
    if (!eligibleApplicantIds?.length) {
      resetSearchQuery();
    } else {
      const eligibleIds = eligibleApplicantIds?.map(item => {
        return item?.applicantId;
      });
      getApplicantDetailsEligibleForBonds(eligibleIds);
    }
  }, [eligibleApplicantIds, addApplicantSearchText]);

  return (
    <div className="reel-view--popup RMS__popUp addApplicant wider">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Add Applicants')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose()}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <input
              type="text"
              placeholder={t('Search')}
              className="form-input--search"
              onChange={e => {
                setAddApplicantSearchText(e.target.value);
              }}
              value={addApplicantSearchText}
            />
            <Dropdown
              value={addApplicantSearchDropdown}
              placeholder={t('Select')}
              options={searchDropdownOptions}
              onChange={({ value }) => setAddApplicantSearchDropdown(value)}
            />
          </div>
          {loading ? (
            <DottedLoader />
          ) : (
            <div className="reel-view--popup-row">
              <div className="RMS__page--table user1">
                <div className="RMS__page--table-header">
                  <div className="RMS__page--table-column">
                    <h4>{t('Request ID')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Name')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Employee ID')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Email')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Department Id')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Designation')}</h4>
                  </div>
                </div>
                <div className="RMS__page--table-body">
                  {!applicantsEligibleForBonds?.length ? (
                    <NoContent
                      message={t('No Eligible Applicants Available')}
                    />
                  ) : (
                    applicantsEligibleForBonds?.map(
                      ({
                        _id,
                        name,
                        employeeId,
                        email,
                        departmentId,
                        designationId,
                      }) => {
                        return (
                          <div key={_id} onClick={() => setSelectedUserId(_id)}>
                            <div
                              className={`RMS__page--table-row clickable-row ${
                                _id === selectedUserId ? 'selected' : ''
                              }`}
                            >
                              <div className="RMS__page--table-column">
                                <p>{getRequestId(_id) || '-'}</p>
                              </div>
                              <div className="RMS__page--table-column">
                                <p>{name || '-'}</p>
                              </div>
                              <div className="RMS__page--table-column">
                                <p>{employeeId || '-'}</p>
                              </div>
                              <div className="RMS__page--table-column">
                                <p>{email || '-'}</p>
                              </div>
                              <div className="RMS__page--table-column">
                                <p>
                                  {convertDepartmentId(departmentId) || '-'}
                                </p>
                              </div>
                              <div className="RMS__page--table-column">
                                <p>
                                  {convertDesignationId(designationId) || '-'}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  )}
                  <ReactVisibilitySensor
                    partialVisibility
                    onChange={isVisible => {
                      isVisible && loadNextPage();
                    }}
                  >
                    <div>&nbsp;</div>
                  </ReactVisibilitySensor>
                </div>
              </div>
            </div>
          )}

          <div className="reel-view--popup-row">
            <button className={`btn btn--primary`} onClick={addUserHandler}>
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBondUserModal;
