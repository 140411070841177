import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DeleteUserModal = ({
  onHandleClose,
  header,
  msgPartOne,
  msgPartTwo,
  deleteUserData,
  bulkRemove,
  setSelectAll,
  removeSelectedUsers,
  isBulkRemove,
  errorMessage = '',
  loading = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{header}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose()}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <p>
              {msgPartOne}{' '}
              {deleteUserData ? <span>{deleteUserData.name} </span> : ''}
              {msgPartTwo}
            </p>
          </div>
          <div className="reel-view--popup-row">
            <span className="error">{errorMessage}</span>
            <button
              className={`btn btn--primary  ${loading ? 'btn--loader' : ''}`}
              onClick={() => {
                if (isBulkRemove) {
                  bulkRemove();
                  setSelectAll(false);
                  onHandleClose();
                } else {
                  removeSelectedUsers(deleteUserData);
                }
              }}
            >
              {!loading && t('Yes')}
            </button>
            <button
              className="btn btn--secondary"
              onClick={() => {
                onHandleClose();
              }}
            >
              {t('No')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
