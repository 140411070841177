export const RMS_LANDING_REQUEST = 'RMS_LANDING_REQUEST';
export const RMS_LANDING_SUCCESS = 'RMS_LANDING_SUCCESS';
export const RMS_LANDING_FAIL = 'RMS_LANDING_FAIL';

export const RMS_SERVICE_STATUS_REQUEST = 'RMS_SERVICE_STATUS_REQUEST';
export const RMS_SERVICE_STATUS_SUCCESS = 'RMS_SERVICE_STATUS_SUCCESS';
export const RMS_SERVICE_STATUS_FAIL = 'RMS_SERVICE_STATUS_FAIL';

export const RESET_RMS_SERVICE_STATUS = 'RESET_RMS_SERVICE_STATUS';

export const RESET_RMS_LANDING = 'RESET_RMS_LANDING';

export const RMS_LOGIN_USER_TEMPLATE_REQUEST =
  'RMS_LOGIN_USER_TEMPLATE_REQUEST';
export const RMS_LOGIN_USER_TEMPLATE_SUCCESS =
  'RMS_LOGIN_USER_TEMPLATE_SUCCESS';
export const RMS_LOGIN_USER_TEMPLATE_FAIL = 'RMS_LOGIN_USER_TEMPLATE_FAIL';

export const RMS_LOGIN_USER_GROUP_TEMPLATE_REQUEST =
  'RMS_LOGIN_USER_GROUP_TEMPLATE_REQUEST';
export const RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS =
  'RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS';
export const RMS_LOGIN_USER_GROUP_TEMPLATE_FAIL =
  'RMS_LOGIN_USER_GROUP_TEMPLATE_FAIL';
