import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoContent from '../../../../Components/NoContent/NoContent';
import useApi from '../../../../Hooks/useApi';
import iconAddButton from '../../../assets/images/svg-images/icon-addButton.svg';
import iconDelete from '../../../assets/images/svg-images/icon-delete.svg';
import {
  privileges,
  SearchFieldOptions,
  Services,
} from '../../../Common/constants';
import GroupAddUserContainer from '../../../Container/AddUserContainer/GroupAddUserContainer';
import useConvertDate from '../../../hooks/useConvertDate';
import useConvertId from '../../../hooks/useConvertId';
import {
  REMOVE_GROUP_SAVED_APPLICANT,
  RESET_GROUP_ADD_USER_POPUP_DATA,
  RMS_GET_USER_BUDGET_DETAILS_FAILED,
  RMS_GET_USER_BUDGET_DETAILS_REQUEST,
  RMS_GET_USER_BUDGET_DETAILS_SUCCESS,
  RMS_GROUP_USER_MANAGEMENT_FAIL,
  RMS_GROUP_USER_MANAGEMENT_REQUEST,
  RMS_GROUP_USER_MANAGEMENT_SUCCESS,
} from '../../../redux/actionTypes/groupRequestTypes';
import RMSPageTitle from '../../CommonComponents/RMSPageTitle';

function GroupFormPersonalDetails({
  match,
  onSave,
  onBack,
  loadingButton = false,
  submitErrorMessage = '',
}) {
  const { t } = useTranslation();
  const [fetchAllUserApi] = useApi();
  const [getUserBudgetsApi] = useApi();

  const { convertDateToThemeFormat } = useConvertDate();

  const {
    getQualificationName,
    getCenterName,
    getDepartmentName,
    getDesignationName,
    getQualificationDetails,
    getCenterDetails,
    getDepartmentDetails,
    getDesignationDetails,
  } = useConvertId();
  const { allUsers, templateId, savedUsers, userData } = useSelector(
    state => state.rmsGroupRequest
  );

  const dispatch = useDispatch();

  //initial states
  const [showAddUserPopup, setAddUserPopUp] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState('');
  const [groupTemplateApplicants, setGroupTemplateApplicants] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchFilterSearch = searchText => {
    const searchFields = [SearchFieldOptions.name, SearchFieldOptions.email];
    templateId &&
      fetchAllUserApi(
        `/api/v1/templates/${templateId}/users?roles=${privileges.RMS_APPLICANT}&searchInFields=${searchFields}&searchText=${searchText}&page=1&size=20`,
        RMS_GROUP_USER_MANAGEMENT_REQUEST,
        RMS_GROUP_USER_MANAGEMENT_SUCCESS,
        RMS_GROUP_USER_MANAGEMENT_FAIL,
        {},
        '',
        'GET',
        null,
        'isRmsService'
      );
  };

  const getUserBudgetDetails = userIds => {
    userIds?.length &&
      getUserBudgetsApi(
        `/api/v1/budgets?userIds=${userIds}&page=1&size=${userIds.length}`,
        RMS_GET_USER_BUDGET_DETAILS_REQUEST,
        RMS_GET_USER_BUDGET_DETAILS_SUCCESS,
        RMS_GET_USER_BUDGET_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService
      );
  };

  useEffect(() => {
    if (userData?.length) {
      const userIds = userData?.map(({ _id }) => _id);

      getUserBudgetDetails(userIds);
    }
  }, [userData]);

  useEffect(() => {
    if (allUsers?.length) {
      const qualificationIds =
        allUsers?.map(user => user?.qualificationId)?.filter(Boolean) || [];
      const uniqueQualificationIds = [...new Set(qualificationIds)];

      const centerIds =
        allUsers?.map(user => user?.centerId)?.filter(Boolean) || [];
      const uniqueCenterIds = [...new Set(centerIds)];

      const departmentIds =
        allUsers?.map(user => user?.departmentId)?.filter(Boolean) || [];
      const uniqueDepartmentIds = [...new Set(departmentIds)];

      const designationIds =
        allUsers?.map(user => user?.designationId)?.filter(Boolean) || [];
      const uniqueDesignationIds = [...new Set(designationIds)];

      getQualificationDetails(uniqueQualificationIds);
      getCenterDetails(uniqueCenterIds);
      getDepartmentDetails(uniqueDepartmentIds);
      getDesignationDetails(uniqueDesignationIds);
    }
  }, [allUsers]);

  useEffect(() => {
    let filterUsers = allUsers.filter(applicants =>
      savedUsers.includes(applicants._id)
    );
    setFilteredUsers(filterUsers);
    setGroupTemplateApplicants(filterUsers);
  }, [savedUsers]);

  //perform applicant table search
  useEffect(() => {
    if (!searchText) {
      setGroupTemplateApplicants(
        allUsers.filter(applicants => savedUsers.includes(applicants._id))
      );
    } else {
      const searchResults = groupTemplateApplicants.filter(applicants =>
        applicants?.name.toLowerCase().includes(searchText)
      );

      setGroupTemplateApplicants(searchResults);
    }
  }, [searchText]);

  const deleteApplicantHandler = id => {
    const savedApplicants = filteredUsers
      .filter(item => item._id !== id)
      .map(item => {
        return item._id;
      });

    dispatch({
      type: REMOVE_GROUP_SAVED_APPLICANT,
      payload: savedApplicants,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_GROUP_ADD_USER_POPUP_DATA });
    };
  }, []);

  const validateAndSave = () => {
    if (!savedUsers?.length) {
      setErrorMessage(t('Please add applicants to create the request'));
      return;
    }
    onSave('', {
      applicantIds: savedUsers,
    });
  };

  if (!match) {
    return null;
  }

  return (
    <div>
      <div className="RMS__page personalDetails">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Training Form" />
            <div className="RMS__page--content">
              <div className="RMS__page--header">
                <div className="RMS__page--header-row">
                  <div className="RMS__page--header-left">
                    <p>{t('Personal Details')}</p>
                  </div>
                  <div className="RMS__page--header-right ">
                    <input
                      type="text"
                      placeholder={'Search'}
                      className="form-input form-input--search"
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body">
                {/* Table */}
                <div className="RMS__page--table">
                  <div className="RMS__page--table-header">
                    <div className="RMS__page--table-column">
                      <h4>{t('Name')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('NRIC No')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Date of Birth')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Date Joined')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Highest Qualification')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Center')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Designation')}</h4>
                    </div>
                    <div className="RMS__page--table-column">
                      <h4>{t('Department')}</h4>
                    </div>
                    <div className="RMS__page--table-column"></div>
                  </div>

                  <div className="RMS__page--table-body">
                    {groupTemplateApplicants.length ? (
                      groupTemplateApplicants?.map(
                        (
                          {
                            _id,
                            name,
                            nricNo,
                            dob,
                            joinedDate,
                            qualificationId,
                            centerId,
                            designationId,
                            departmentId,
                          },
                          i
                        ) => (
                          <div key={_id} className="RMS__page--table-row">
                            <div className="RMS__page--table-column">
                              <p>{name || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{nricNo || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{convertDateToThemeFormat(dob) || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>
                                {convertDateToThemeFormat(joinedDate) || '-'}
                              </p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>
                                {getQualificationName(qualificationId) || '-'}
                              </p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{getCenterName(centerId) || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{getDesignationName(designationId) || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{getDepartmentName(departmentId) || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <img
                                src={iconDelete}
                                onClick={() => deleteApplicantHandler(_id)}
                                alt="delete-data"
                              />
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <NoContent message={t('Applicants not selected')} />
                    )}
                  </div>
                </div>
                {/* Table */}
              </div>
              <div className="RMS__page--submit">
                <button
                  className="btn btn--add"
                  onClick={() => {
                    setAddUserPopUp(true);
                    setErrorMessage('');
                  }}
                >
                  <img src={iconAddButton} alt="add-data" />
                </button>

                {showAddUserPopup && (
                  <GroupAddUserContainer
                    fetchFilterSearch={fetchFilterSearch}
                    onHandleClose={() => {
                      setAddUserPopUp(false);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="RMS__page--submit overall">
              <div className="RMS__page--error">
                <div className="RMS__page--error-content">
                  {errorMessage || submitErrorMessage}
                </div>
              </div>
              <button
                className="btn btn--secondary btn--marginRight"
                onClick={onBack}
              >
                {t('Back')}
              </button>
              <button
                className={`btn btn--primary ${
                  loadingButton ? 'btn--loader' : ''
                }`}
                onClick={validateAndSave}
              >
                {t('Submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupFormPersonalDetails;
