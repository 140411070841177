import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { editUserFields } from '../../Common/constants';
import EditUser from '../../Components/Modal/EditUserModal';
import useConvertDate from '../../hooks/useConvertDate';
import { avoidedDuplicationData } from '../../Common/helper';
import {
  RMS_RESET_USER_UPDATE,
  RMS_UPDATE_USER_FAILED,
  RMS_UPDATE_USER_REQUEST,
  RMS_UPDATE_USER_SUCCESS,
  RMS_RESET_ERROR_MESSAGE,
  RMS_DESIGNATION_RESET,
  RMS_QUALIFICATION_RESET,
  RMS_CENTER_RESET,
  RMS_DEPARTMENT_RESET,
} from '../../redux/actionTypes/userManagementTypes';

const EditUserContainer = ({
  closePopup,
  selectedUser,
  refreshData,
  getAllDesignations,
  getAllQualifications,
  getAllCenters,
  getAllDepartments,
  userAssignedDesignations,
  userAssignedQualification,
  userAssignedCenters,
  userAssignedDepartment,
  userAssignedCompanies,
}) => {
  const { t } = useTranslation();
  const {
    allCenters = [],
    allDepartments = [],
    allDesignations = [],
    allQualifications = [],
    updateUserLoading,
    updateUserSuccess,
    updateUserError,
    updateUserErrorMsg,
    allCompanies = [],
    designationPage,
    designationTotalRecord,
    qualificationPage,
    qualificationTotalRecord,
    centerPage,
    centerTotalRecord,
    departmentPage,
    departmentTotalRecord,
  } = useSelector(state => state.rmsUserManagement);

  const [editingUser, setEditingUser] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [saveUserApi] = useApi();
  const dispatch = useDispatch();
  const { getDateObjectFromISOString } = useConvertDate();
  const [designationSearchText, setDesignationSearchText] = useState('');
  const [qualificationSearchText, setQualificationSearchText] = useState('');
  const [centerSearchText, setCenterSearchText] = useState('');
  const [departmentSearchText, setDepartmentSearchText] = useState('');
  const [isCenterSearch, setIsCenterSearch] = useState(false);
  const [isDesignationSearch, setIsDesignationSearch] = useState(false);
  const [isQualificationSearch, setIsQualificationSearch] = useState(false);
  const [isDepartmentSearch, setIsDepartmentSearch] = useState(false);

  useEffect(() => {
    const currentDate = getDateObjectFromISOString(new Date().toISOString());
    const user = { ...selectedUser };

    if (!user?.dob) {
      user.dob = currentDate;
    }

    if (!user?.joinedDate) {
      user.joinedDate = currentDate;
    }

    setEditingUser(user);
  }, [selectedUser]);

  const generateDropdownData = (data = []) => {
    return data?.map(({ _id, name }) => ({ label: name, value: _id })) || [];
  };

  const designationDropDownData = useMemo(() => {
    if (!allDesignations && !selectedUser) {
      return [];
    }
    const userDesignations =
      selectedUser && userAssignedDesignations?.[selectedUser?.designationId];
    let designationDropDownData;
    if (!userDesignations) {
      designationDropDownData = [...allDesignations];
    } else {
      designationDropDownData = [...allDesignations, userDesignations];
    }
    const uniqueDesignationDropDownData = avoidedDuplicationData(
      allDesignations,
      designationDropDownData || [],
      '_id'
    );
    return uniqueDesignationDropDownData.length
      ? generateDropdownData(uniqueDesignationDropDownData)
      : [];
  }, [allDesignations, selectedUser]);

  const qualificationDropDownData = useMemo(() => {
    if (!allQualifications && !selectedUser) {
      return [];
    }
    const userQualifications =
      selectedUser &&
      userAssignedQualification?.[selectedUser?.qualificationId];
    let qualificationDropDownData;
    if (!userQualifications) {
      qualificationDropDownData = [...allQualifications];
    } else {
      qualificationDropDownData = [...allQualifications, userQualifications];
    }
    const uniqueQualificationDropDownData = avoidedDuplicationData(
      allQualifications,
      qualificationDropDownData || [],
      '_id'
    );
    return uniqueQualificationDropDownData.length
      ? generateDropdownData(uniqueQualificationDropDownData)
      : [];
  }, [allQualifications, selectedUser]);

  const centersDropDownData = useMemo(() => {
    if (!allCenters && !selectedUser) {
      return [];
    }
    const userCenters =
      selectedUser && userAssignedCenters?.[selectedUser?.centerId];
    let centersDropDownData;
    if (!userCenters) {
      centersDropDownData = [...allCenters];
    } else {
      centersDropDownData = [...allCenters, userCenters];
    }
    const uniqueCenterDropDownData = avoidedDuplicationData(
      allCenters,
      centersDropDownData || [],
      '_id'
    );
    return uniqueCenterDropDownData?.length
      ? generateDropdownData(uniqueCenterDropDownData)
      : [];
  }, [allCenters, selectedUser]);

  const departmentsDropDownData = useMemo(() => {
    if (!allDepartments && !selectedUser) {
      return [];
    }
    const userDepartments =
      selectedUser && userAssignedDepartment?.[selectedUser?.departmentId];
    let departmentDropDownData;
    if (!userDepartments) {
      departmentDropDownData = [...allDepartments];
    } else {
      departmentDropDownData = [...allDepartments, userDepartments];
    }
    const uniqueDepartmentDropDownData = avoidedDuplicationData(
      allDepartments,
      departmentDropDownData || [],
      '_id'
    );
    return uniqueDepartmentDropDownData?.length
      ? generateDropdownData(uniqueDepartmentDropDownData)
      : [];
  }, [allDepartments, selectedUser]);

  const companiesDropDownData = useMemo(() => {
    if (!allCompanies && !selectedUser) {
      return [];
    }
    const userCompanies =
      selectedUser && userAssignedCompanies?.[selectedUser?.companyId];
    let companiesDropDownData;
    if (!userCompanies) {
      companiesDropDownData = [...allCompanies];
    } else {
      companiesDropDownData = [...allCompanies, userCompanies];
    }

    const uniqueCompanyDropDownData = avoidedDuplicationData(
      allCompanies,
      companiesDropDownData || [],
      '_id'
    );
    return uniqueCompanyDropDownData?.length
      ? generateDropdownData(uniqueCompanyDropDownData)
      : [];
  }, [allCompanies, selectedUser]);

  const updateUser = (field, value) => {
    switch (field) {
      case editUserFields.CENTER:
        setEditingUser(user => ({ ...user, centerId: value }));
        break;
      case editUserFields.DEPARTMENT:
        setEditingUser(user => ({ ...user, departmentId: value }));
        break;
      case editUserFields.DESIGNATION:
        setEditingUser(user => ({ ...user, designationId: value }));
        break;
      case editUserFields.QUALIFICATION:
        setEditingUser(user => ({ ...user, qualificationId: value }));
        break;
      case editUserFields.DATE_JOINED:
        setEditingUser(user => ({ ...user, joinedDate: value }));
        break;
      case editUserFields.EMPLOYEE_ID:
        setEditingUser(user => ({ ...user, employeeId: value }));
        break;
      case editUserFields.DOB:
        setEditingUser(user => ({ ...user, dob: value }));
        break;
      case editUserFields.NRIC_NO:
        setEditingUser(user => ({ ...user, nricNo: value }));
        break;
      case editUserFields.COMPANY:
        setEditingUser(user => ({ ...user, companyId: value }));
        break;

      default:
        break;
    }
    setErrorMessage('');
  };

  const getDropDownData = field => {
    switch (field) {
      case editUserFields.DESIGNATION:
        const designationNextPage = getNextPage(
          designationTotalRecord,
          designationPage
        );
        designationNextPage &&
          getAllDesignations(designationNextPage, designationSearchText);
        break;
      case editUserFields.QUALIFICATION:
        const qualificationNextPage = getNextPage(
          qualificationTotalRecord,
          qualificationPage
        );
        qualificationNextPage &&
          getAllQualifications(qualificationNextPage, qualificationSearchText);
        break;
      case editUserFields.CENTER:
        const centerNextPage = getNextPage(centerTotalRecord, centerPage);
        centerNextPage && getAllCenters(centerNextPage, centerSearchText);
        break;
      case editUserFields.DEPARTMENT:
        const departmentNextPage = getNextPage(
          departmentTotalRecord,
          departmentPage
        );
        departmentNextPage &&
          getAllDepartments(departmentNextPage, departmentSearchText);
        break;
      default:
        break;
    }
  };

  const getNextPage = (totalRecords, page) => {
    const maxPage = Math.ceil(totalRecords / 200);
    const nextPage = page + 1;
    return nextPage <= maxPage && nextPage;
  };

  useEffect(() => {
    let designationSearchTimeout;
    dispatch({
      type: RMS_DESIGNATION_RESET,
    });
    if (designationSearchTimeout) {
      clearTimeout(designationSearchTimeout);
    }

    designationSearchTimeout = setTimeout(() => {
      isDesignationSearch && getAllDesignations(1, designationSearchText);
      setIsDesignationSearch(false);
    }, 1000);

    return () => {
      clearTimeout(designationSearchTimeout);
    };
  }, [designationSearchText]);

  useEffect(() => {
    let qualificationSearchTimeout;
    dispatch({
      type: RMS_QUALIFICATION_RESET,
    });
    if (qualificationSearchTimeout) {
      clearTimeout(qualificationSearchTimeout);
    }

    qualificationSearchTimeout = setTimeout(() => {
      isQualificationSearch && getAllQualifications(1, qualificationSearchText);
      setIsQualificationSearch(false);
    }, 1000);

    return () => {
      clearTimeout(qualificationSearchTimeout);
    };
  }, [qualificationSearchText]);

  useEffect(() => {
    let centerSearchTimeout;
    dispatch({
      type: RMS_CENTER_RESET,
    });
    if (centerSearchTimeout) {
      clearTimeout(centerSearchTimeout);
    }

    centerSearchTimeout = setTimeout(() => {
      isCenterSearch && getAllCenters(1, centerSearchText);
      setIsCenterSearch(false);
    }, 1000);

    return () => {
      clearTimeout(centerSearchTimeout);
    };
  }, [centerSearchText]);

  useEffect(() => {
    let departmentSearchTimeout;
    dispatch({
      type: RMS_DEPARTMENT_RESET,
    });
    if (departmentSearchTimeout) {
      clearTimeout(departmentSearchTimeout);
    }

    departmentSearchTimeout = setTimeout(() => {
      isDepartmentSearch && getAllDepartments(1, departmentSearchText);
      setIsDepartmentSearch(false);
    }, 1000);

    return () => {
      clearTimeout(departmentSearchTimeout);
    };
  }, [departmentSearchText]);

  useEffect(() => {
    !updateUserLoading && updateUserSuccess && closePopup();
  }, [updateUserLoading, updateUserSuccess]);
  useEffect(() => {
    !updateUserLoading &&
      updateUserError &&
      setErrorMessage(t('Error updating the user'));
  }, [updateUserLoading, updateUserError]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RMS_RESET_USER_UPDATE,
      });
      dispatch({
        type: RMS_RESET_ERROR_MESSAGE,
      });
      refreshData();
    };
  }, []);

  const isDataValid = () => {
    const {
      qualificationId,
      designationId,
      centerId,
      departmentId,
      employeeId,
      email,
      name,
      nricNo,
      dob,
      joinedDate,
      companyId,
    } = editingUser;
    if (
      qualificationId &&
      designationId &&
      centerId &&
      departmentId &&
      employeeId &&
      email &&
      name &&
      nricNo &&
      dob &&
      joinedDate &&
      companyId
    ) {
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage(t('Please fill all the required fields'));
      return false;
    }
  };

  const saveUser = () => {
    if (!isDataValid()) return;

    saveUserApi(
      `/api/v1/users`,
      RMS_UPDATE_USER_REQUEST,
      RMS_UPDATE_USER_SUCCESS,
      RMS_UPDATE_USER_FAILED,
      editingUser,
      {},
      'PUT',
      false,
      'isRmsService'
    );
  };

  return (
    <div>
      <EditUser
        closePopup={closePopup}
        loading={updateUserLoading}
        centerList={centersDropDownData}
        departmentList={departmentsDropDownData}
        designationList={designationDropDownData}
        qualificationList={qualificationDropDownData}
        updateUser={updateUser}
        user={editingUser}
        saveUser={saveUser}
        errorMessage={errorMessage}
        updateUserErrorMsg={updateUserErrorMsg}
        companiesList={companiesDropDownData}
        getDropDownData={getDropDownData}
        setDesignationSearchText={setDesignationSearchText}
        setQualificationSearchText={setQualificationSearchText}
        setCenterSearchText={setCenterSearchText}
        setDepartmentSearchText={setDepartmentSearchText}
        setIsCenterSearch={setIsCenterSearch}
        setIsDesignationSearch={setIsDesignationSearch}
        setIsQualificationSearch={setIsQualificationSearch}
        setIsDepartmentSearch={setIsDepartmentSearch}
      />
    </div>
  );
};

export default EditUserContainer;
