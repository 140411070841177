import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import ArchivalTrainingRequestComponent from '../../Components/ArchivalTrainingRequestComponent/ArchivalTrainingRequestComponent';
import { Services } from '../../Common/constants';
import { useLocation } from 'react-router-dom';
import useS3FileView from '../../hooks/useS3FileView';
import {
  RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST,
  RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS,
  RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED,
  RMS_RESET_ARCHIVAL_TRAINING_DATA,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED,
  RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA,
} from '../../redux/actionTypes/ArchivalTrainingRequestTypes';

const ArchivalTrainingRequestContainer = () => {
  const dispatch = useDispatch();
  const [fetchArchivalTrainingRequests] = useApi();
  const [exportArchivalTrainingRequestsApi] = useApi();
  const { state } = useLocation();
  const {
    archivalTrainingRequests,
    archivalTrainingRequestsLoading,
    archivalTrainingRequestsPage,
    archivalTrainingRequestsTotalRecord,
    archivalTrainingRequestS3FileObject,
    archivalTrainingRequestS3FileObjectFailed,
  } = useSelector(state => state.rmsArchivalTrainingRequestData);

  const [getFromBucket, url, isFileDownloadStarted] = useS3FileView();

  const { userIds } = state;

  const getArchivalTrainingRequests = page => {
    fetchArchivalTrainingRequests(
      `/api/v1/archival-training-requests${
        userIds?.length ? `?userIdList=${userIds}` : ''
      }&page=${page}&size=20`,
      RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST,
      RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS,
      RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService,
      { page }
    );
  };

  const exportArchivalTrainingRequests = () => {
    exportArchivalTrainingRequestsApi(
      `/api/v1/archival-training-requests/report${
        userIds?.length ? `?userIdList=${userIds}` : ''
      }`,
      RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST,
      RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS,
      RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED,
      {},
      '',
      'POST',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RMS_RESET_ARCHIVAL_TRAINING_DATA });
      dispatch({ type: RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA });
    };
  }, []);

  useEffect(() => {
    userIds?.length && getArchivalTrainingRequests(1);
  }, []);

  const loadNextRequestPage = () => {
    const maxPage = Math.ceil(archivalTrainingRequestsTotalRecord / 20);
    const nextPage = archivalTrainingRequestsPage + 1;
    userIds?.length &&
      nextPage <= maxPage &&
      getArchivalTrainingRequests(nextPage);
  };

  useEffect(() => {
    archivalTrainingRequestS3FileObject?.fileKey &&
      getFromBucket(
        archivalTrainingRequestS3FileObject?.bucketName,
        archivalTrainingRequestS3FileObject?.fileKey,
        {
          timeDelay: 5000,
          isAutoDownload: true,
          errorAction: RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED,
        }
      );
  }, [archivalTrainingRequestS3FileObject]);
  return (
    <ArchivalTrainingRequestComponent
      archivalTrainingRequests={archivalTrainingRequests}
      lording={archivalTrainingRequestsLoading}
      loadNextRequestPage={loadNextRequestPage}
      archivalTrainingRequestS3FileObject={archivalTrainingRequestS3FileObject}
      archivalTrainingRequestS3FileObjectFailed={
        archivalTrainingRequestS3FileObjectFailed
      }
      exportArchivalTrainingRequests={exportArchivalTrainingRequests}
      url={url}
      isFileDownloadStarted={isFileDownloadStarted}
    />
  );
};

export default ArchivalTrainingRequestContainer;
