import React, { useEffect, useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import useConvertDate from '../../hooks/useConvertDate';
import {
  adminDashboardMode,
  CourseTypes,
  RequestSearchFilterBy,
  RequestStatus,
  RequestType,
} from '../../Common/constants';
import { getArrayIndexUsingKey } from '../../Common/helper';
import FeedbackContainer from '../../Container/FeedBackContainer/FeedBackContainer';
import useDebounce from '../../hooks/useDebounce';

const RequestsModal = ({
  handleClose,
  financialDurationId,
  getRequestsLoading,
  getRequestsData,
  getRequestsError,
  getTemplatesLoading,
  getTemplatesData,
  getTemplatesError,
  getApplicantNamesLoading,
  getApplicantNamesData,
  getApplicantNamesError,
  feedbackRequest,
  getApplicantNamesAPI,
  getAllTemplatesAPI,
  getAllRequestsAPI,
  getFeedbackRequestIds,
  userDetails,
  isSuperAdminDashboard,
  isFeedbackS3FileObjectFailed,
  exportReport,
  selectedIds,
  setSelectedIds,
  selectAll,
  setSelectAll,
  isFileDownloadStarted,
  url,
  selectedTemplateId,
  setSelectedTemplateId,
  selectedUserIds,
  setSelectedUserIds,
  selectedMode,
  selectedTemplate,
  selectedTemplateLabel,
}) => {
  const { t } = useTranslation();
  const { convertDateISOToString } = useConvertDate();

  const [selectedRequestStatus, setSelectedRequestStatus] = useState();
  const [selectedFilterType, setSelectedFilterType] = useState(
    RequestSearchFilterBy.REQUEST_ID
  );
  const [searchTemplateText, setSearchTemplateText] = useState('');
  const [searchText, setSearchText] = useState('');

  const [applicantIds, setApplicantIds] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isViewFeedback, setIsViewFeedback] = useState(false);
  const [requestIdForFeedBack, setRequestIdForFeedBack] = useState();
  const [applicantId, setApplicantId] = useState();

  const updateSelectUsers = id => {
    setSelectedUserIds(ids => {
      if (ids.includes(id)) {
        return ids.filter(userId => id !== userId);
      } else {
        return [...ids, id];
      }
    });
  };

  const selectAllMethod = selectAll => {
    if (selectAll) {
      setSelectedUserIds(getRequestsData?.map(({ _id }) => _id));
    } else {
      if (
        updatedRequestData?.filter(({ isSelect }) => isSelect)?.length ===
        updatedRequestData?.length
      ) {
        setSelectedUserIds([]);
      }
    }
    setSelectAll(selectAll);
  };

  const updatedRequestData = useMemo(() => {
    let list = [...(getRequestsData || [])];

    list = getRequestsData?.map(user => ({
      ...user,
      isSelect: selectedUserIds.includes(user._id),
    }));
    setSelectedIds(
      list?.filter(({ isSelect }) => isSelect).map(({ _id }) => _id)
    );

    return list;
  }, [getRequestsData, selectedUserIds]);

  const debounceSearchText = useDebounce(searchTemplateText, 1000);

  const requestDataHeaders = [
    'Request ID',
    'Name',
    'Normal/Group',
    'Training Type',
    'Course Name',
    'Institution',
    'Gross Fee',
    'Start Date',
    'End Date',
    'Status',
    ' ',
  ];

  const formatRequestType = requestType => {
    switch (requestType) {
      case RequestType.NORMAL_REQUEST:
        return 'Normal';
      case RequestType.GROUP_REQUEST:
        return 'Group';
      default:
        return requestType;
    }
  };

  const formatTrainingType = trainingType => {
    switch (trainingType) {
      case CourseTypes.GENERAL:
        return 'General';
      case CourseTypes.PROFESSIONAL:
        return 'Professional';
      default:
        return trainingType;
    }
  };

  const renderSearchFilterEndpoint = () => {
    if (searchText) {
      switch (selectedFilterType) {
        case RequestSearchFilterBy.REQUEST_ID:
          return `requestIdLike=${searchText}`;
        case RequestSearchFilterBy.COURSE_NAME:
          return `courseNameLike=${searchText}`;
        case RequestSearchFilterBy.NAME:
          return `searchText=${searchText}&searchInFields=NAME`;
        default:
          return '';
      }
    }
  };

  useEffect(() => {
    if (getRequestsData) {
      const applicantIdsData = getRequestsData?.map(
        request => request.applicantId
      );
      setApplicantIds(applicantIdsData);
    }
  }, [getRequestsData]);

  useEffect(() => {
    if (financialDurationId) {
      getAllRequestsAPI(
        selectedTemplateId,
        selectedRequestStatus,
        searchText,
        renderSearchFilterEndpoint
      );
    }
  }, [financialDurationId, selectedTemplateId, selectedRequestStatus]);

  useEffect(() => {
    //TODO need to fix
    // if (allTemplates?.length) {
    //   if (
    //     selectedMode === adminDashboardMode.OVERALL ||
    //     (selectedMode === adminDashboardMode.NORMAL && !selectedTemplate) ||
    //     (selectedMode === adminDashboardMode.GROUP && !selectedTemplate)
    //   ) {
    //     if (!searchTemplateText && !selectedTemplateId) {
    //       setSelectedTemplateId(allTemplates[0].value);
    //       setSearchTemplateText(allTemplates[0].label);
    //     }
    //   } else {
    //     if (selectedMode !== adminDashboardMode.OVERALL) {
    //       setSelectedTemplateId(selectedTemplate);
    //       setSearchTemplateText(selectedTemplateLabel);
    //     }
    //   }
    // }
  }, [allTemplates]);

  useEffect(() => {
    setSelectedRequestStatus(RequestStatus.COMPLETED);
    setSelectedFilterType(RequestSearchFilterBy.REQUEST_ID);
  }, []);

  useEffect(() => {
    !isSuperAdminDashboard
      ? userDetails?._id &&
        getAllTemplatesAPI(searchTemplateText, userDetails?._id)
      : getAllTemplatesAPI(searchTemplateText, '');
  }, [userDetails, debounceSearchText]);

  useEffect(() => {
    if (getRequestsData && applicantIds.length) {
      getApplicantNamesAPI([...new Set(applicantIds)]);
    }
  }, [applicantIds]);

  useEffect(() => {
    if (getTemplatesData) {
      const newArray = getTemplatesData?.map(template => {
        return {
          label: template.name,
          value: template._id,
        };
      });

      setAllTemplates(newArray);
    }
  }, [getTemplatesData]);

  useEffect(() => {
    const newArray = getTemplatesData?.map(template => ({
      label: template.name,
      value: template._id,
    }));
    setAllTemplates(newArray);
  }, [getTemplatesData]);

  useEffect(() => {
    setSelectedUserIds([]);
  }, [getTemplatesData, selectedRequestStatus]);

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return <div {...containerProps}>{!getTemplatesLoading && children}</div>;
  };

  const renderInputComponent = inputProps => (
    <div className="react-autosuggest__wrapper">
      <input {...inputProps} />
      {getTemplatesLoading && searchTemplateText && (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (getRequestsData?.length) {
      const requestIds = getRequestsData?.map(request => request._id);
      requestIds.length && getFeedbackRequestIds(requestIds);
    }
  }, [getRequestsData]);

  const viewFeedbackCheck = id => {
    const isView = feedbackRequest?.find(data => {
      return data.trainingRequestId === id;
    });
    if (isView) {
      return true;
    } else {
      return false;
    }
  };

  const getUserName = applicantId => {
    return getApplicantNamesData?.length
      ? getApplicantNamesData?.[
          getArrayIndexUsingKey(getApplicantNamesData, '_id', applicantId)
        ]?.name
      : '';
  };

  const clickOnViewFeedBack = (id, applicantId) => {
    setIsViewFeedback(true);
    setIsFeedback(true);
    setRequestIdForFeedBack(id);
    setApplicantId(applicantId);
  };

  return (
    <div className="reel-view--popup RMS__popUp dashboard--requestPopup wider">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header">
          <div className="RMS__page--header">
            <div className="RMS__page--header-row">
              <div className={`RMS__page--header-left`}>
                <p>{t('Requests')}</p>
              </div>
              <div className={`RMS__page--header-right`}>
                <p>{t('Template')}</p>
                <Autosuggest
                  highlightFirstSuggestion={true}
                  suggestions={allTemplates ? allTemplates : []}
                  shouldRenderSuggestions={() => true}
                  onSuggestionsFetchRequested={({ value }) => {
                    {
                      setSearchTemplateText(value);
                    }
                  }}
                  onSuggestionSelected={(_, { suggestionIndex }) => {
                    setSelectedTemplateId(allTemplates[suggestionIndex]?.value);
                    getAllRequestsAPI(
                      allTemplates[suggestionIndex]?.value,
                      selectedRequestStatus,
                      searchText,
                      renderSearchFilterEndpoint
                    );
                  }}
                  getSuggestionValue={suggestion => suggestion.label}
                  renderSuggestion={suggestion => (
                    <span>{suggestion.label}</span>
                  )}
                  inputProps={{
                    placeholder: t('Search templates'),
                    value: searchTemplateText,
                    onChange: (_, { newValue }) => {
                      setSearchTemplateText(newValue);
                    },
                  }}
                  renderSuggestionsContainer={renderSuggestionsContainer}
                  renderInputComponent={renderInputComponent}
                />
                <ReactDropdown
                  options={[
                    {
                      label: t('Completed'),
                      value: RequestStatus.COMPLETED,
                    },
                    {
                      label: t('Approved'),
                      value: RequestStatus.APPROVED,
                    },
                  ]}
                  onChange={option => setSelectedRequestStatus(option.value)}
                  value={selectedRequestStatus}
                  placeholder={t('Select')}
                />
              </div>
            </div>
            <div className="RMS__page--header-row underline">
              <div className="RMS__page--header-left">
                <form
                  className="form-input--search"
                  onSubmit={e => {
                    e.preventDefault();
                    getAllRequestsAPI(
                      selectedTemplateId,
                      selectedRequestStatus,
                      searchText,
                      renderSearchFilterEndpoint
                    );
                  }}
                >
                  <input
                    type="text"
                    placeholder={t('Search')}
                    className="form-input form-input--search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </form>
              </div>
              <div className="RMS__page--header-right">
                <ReactDropdown
                  options={[
                    {
                      label: t('Request ID'),
                      value: RequestSearchFilterBy.REQUEST_ID,
                    },
                    { label: t('Name'), value: RequestSearchFilterBy.NAME },
                    {
                      label: t('Course Name'),
                      value: RequestSearchFilterBy.COURSE_NAME,
                    },
                  ]}
                  onChange={option => setSelectedFilterType(option.value)}
                  value={selectedFilterType}
                  placeholder={t('Select')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="reel-view--popup-body">
          <div className="RMS__page--table">
            <div className="RMS__page--table-header">
              <div className="RMS__page--table-column">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    className="form-input--checkbox"
                    name="selectAll"
                    checked={
                      updatedRequestData?.length
                        ? updatedRequestData?.filter(({ isSelect }) => isSelect)
                            ?.length === updatedRequestData?.length
                        : false
                    }
                  />
                  <label
                    htmlFor="selectAll"
                    onClick={() => {
                      selectAllMethod(!selectAll);
                    }}
                  ></label>
                </div>
              </div>
              {requestDataHeaders?.map((header, index) => (
                <div className={`RMS__page--table-column column-${index + 1}`}>
                  <h4>{t(`${header}`)}</h4>
                </div>
              ))}
            </div>
            <div className="RMS__page--table-body">
              {!getRequestsLoading &&
                !getApplicantNamesLoading &&
                updatedRequestData?.map(
                  (
                    {
                      _id,
                      requestId,
                      type,
                      course,
                      requestStatus,
                      applicantId,
                      isSelect,
                    },
                    index
                  ) => (
                    <div className="RMS__page--table-row">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="form-input--checkbox"
                          name={_id}
                          checked={isSelect}
                        />
                        <label
                          htmlFor={_id}
                          onClick={e => {
                            e.stopPropagation();
                            updateSelectUsers(_id);
                            setSelectAll(false);
                          }}
                        ></label>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{requestId}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{getUserName(applicantId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{formatRequestType(type)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{formatTrainingType(course?.courseType)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{course?.title}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{course?.institutionName}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{course?.grossFee?.toString() || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{convertDateISOToString(course?.startDate)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{convertDateISOToString(course?.endDate)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{requestStatus}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        {viewFeedbackCheck(_id) && (
                          <button
                            className="btn btn--secondary btn--thinButton btn--tableButton"
                            onClick={e => {
                              e.stopPropagation();
                              clickOnViewFeedBack(_id, applicantId);
                            }}
                          >
                            {t('View Feedback')}
                          </button>
                        )}
                      </div>
                    </div>
                  )
                )}
              <ReactVisibilitySensor partialVisibility>
                <div>&nbsp;</div>
              </ReactVisibilitySensor>
            </div>
          </div>

          <div className="RMS__page--submit">
            <button
              className="btn btn--secondary btn--marginRight"
              onClick={handleClose}
            >
              {t('Back')}
            </button>
            <button
              className={`btn btn--primary ${
                url && !isFileDownloadStarted
                  ? `btn--loader`
                  : !isFeedbackS3FileObjectFailed && ` btn--export`
              }`}
              disabled={!selectedIds?.length}
              onClick={exportReport}
            >
              {isFeedbackS3FileObjectFailed
                ? t('Error! Feedback not available')
                : t('Export Feedback')}
            </button>
          </div>
        </div>
      </div>

      {isFeedback && (
        <FeedbackContainer
          onHandleClose={() => setIsFeedback(false)}
          requestId={requestIdForFeedBack}
          applicantId={applicantId}
          isViewFeedback={isViewFeedback}
        />
      )}
    </div>
  );
};

export default RequestsModal;
