import React, { useEffect, useMemo, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import iconDelete from '../../assets/images/svg-images/icon-delete.svg';
import { AccessControlTabs, privileges } from '../../Common/constants';
import DeleteUserModal from '../Modal/DeleteUserModal';

const AccessControl = ({
  templates = [],
  setSelectedTemplateId,
  selectedTemplateId,
  searchText,
  setSearchText,
  templateUsers = [],
  defaultAdminIds = [],
  showAddUserPopup,
  allSelected,
  toggleSelectAll,
  toggleSelectUser,
  selectedUserIds,
  onRemove,
  onSubmit,
  loading,
  errorMessage = '',
  showPageLoader,
  clearSession,
  templateDelete,
  createTemplateFailMessage,
}) => {
  const { t } = useTranslation();
  const [accessTab, setAccessTab] = useState(AccessControlTabs.TEMPLATES);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState();

  const templateList = useMemo(() => {
    if (!templates?.length) return [];

    return templates.map(template => ({
      label: template.name,
      value: template._id,
    }));
  }, [templates]);

  const checkIsSuperAdmin = roles => {
    return roles?.find(
      role =>
        role === privileges.RMS_SUPER_ADMIN ||
        role === privileges.RMS_DEFAULT_SUPER_ADMIN
    );
  };

  useEffect(() => {
    clearSession(accessTab === AccessControlTabs.TEMPLATES);
  }, [accessTab]);

  return (
    <div className="RMS__page accessControl">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>{t('Access Control')}</h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              {/*
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <button
                    className={`btn btn--marginRight ${
                      accessTab === AccessControlTabs.TEMPLATES
                        ? 'btn--primary'
                        : 'btn--secondary'
                    }`}
                    onClick={() => setAccessTab(AccessControlTabs.TEMPLATES)}
                  >
                    {t('Templates')}
                  </button>
                  <button
                    className={`btn ${
                      accessTab === AccessControlTabs.SUPER_ADMIN
                        ? 'btn--primary'
                        : 'btn--secondary'
                    }`}
                    onClick={() => setAccessTab(AccessControlTabs.SUPER_ADMIN)}
                  >
                    {t('Super Admin')}
                  </button>
                </div>
              </div>
              */}
              <div className="RMS__page--header-row underline">
                <div className="RMS__page--header-left">
                  <input
                    type="text"
                    placeholder={'Search'}
                    className="form-input form-input--search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
                {accessTab === AccessControlTabs.TEMPLATES && (
                  <div className="RMS__page--header-right">
                    <ReactDropdown
                      options={templateList}
                      onChange={option => setSelectedTemplateId(option?.value)}
                      value={selectedTemplateId}
                      placeholder={t("Select")}
                    />
                  </div>
                )}
              </div>
            </div>
            {accessTab === AccessControlTabs.TEMPLATES &&
            !selectedTemplateId ? (
              <NoContent message={t('Select a Template')} />
            ) : (
              <>
                {!showPageLoader && templateUsers?.length ? (
                  <div className="RMS__page--table user1">
                    <div className="RMS__page--table-header">
                      <div className="RMS__page--table-column">
                        {accessTab === AccessControlTabs.SUPER_ADMIN && (
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              className="form-input--checkbox"
                              name="selectAll"
                              checked={allSelected}
                            />
                            <label
                              htmlFor="selectAll"
                              onClick={toggleSelectAll}
                            ></label>
                          </div>
                        )}
                      </div>
                      <div className="RMS__page--table-column">
                        <h4>{t('Name')}</h4>
                      </div>
                      <div className="RMS__page--table-column">
                        <h4>{t('Employee ID')}</h4>
                      </div>
                      <div className="RMS__page--table-column">
                        <h4>{t('Email')}</h4>
                      </div>
                      <div className="RMS__page--table-column">
                        <h4>{t('User Type')}</h4>
                      </div>
                      <div className="RMS__page--table-column"></div>
                    </div>
                    <div className="RMS__page--table-body">
                      {templateUsers?.map(
                        ({ name, employeeId, email, _id, roles }, index) => (
                          <div className="RMS__page--table-row">
                            <div className="RMS__page--table-column">
                              {accessTab === AccessControlTabs.SUPER_ADMIN && (
                                <div className="checkbox">
                                  <input
                                    type="checkbox"
                                    className="form-input--checkbox"
                                    name={email}
                                    checked={selectedUserIds?.includes(_id)}
                                  />
                                  <label
                                    htmlFor={email}
                                    onClick={() =>
                                      !allSelected && toggleSelectUser(_id)
                                    }
                                  ></label>
                                </div>
                              )}
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{name || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{employeeId || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{email || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              {t('Admin')}
                            </div>
                            {accessTab === AccessControlTabs.TEMPLATES && (
                              <div className="RMS__page--table-column">
                                {!checkIsSuperAdmin(roles) && (
                                  <img
                                    src={iconDelete}
                                    alt="delete-data"
                                    onClick={() => {
                                      setIsUserDeleteModalOpen(true);
                                      setDeleteUserData(templateUsers[index]);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : showPageLoader ? (
                  <DottedLoader />
                ) : (
                  <NoContent
                    message={t('Add some admins to view information')}
                  />
                )}
                <div className="RMS__page--submit">
                  <div className="RMS__page--error">
                    <div className="RMS__page--error-content">
                      {errorMessage || t(createTemplateFailMessage)}
                    </div>
                  </div>
                  {!showPageLoader && templateUsers?.length ? (
                    <>
                      {accessTab === AccessControlTabs.SUPER_ADMIN && (
                        <button
                          className="btn btn--secondary btn--marginRight"
                          onClick={onRemove}
                          disabled={loading}
                        >
                          {t('Remove')}
                        </button>
                      )}
                    </>
                  ) : null}
                  {!showPageLoader && (
                    <button
                      className="btn btn--add"
                      onClick={showAddUserPopup}
                      disabled={loading}
                    >
                      <img src={iconAddButton} alt="add-data" />
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isUserDeleteModalOpen && (
        <DeleteUserModal
          onHandleClose={() => setIsUserDeleteModalOpen(false)}
          header={t('Remove Admin')}
          msgPartOne={t('Are you sure you want to delete?')}
          msgPartTwo={''}
          deleteUserData={deleteUserData}
          isBulkRemove={false}
          removeSelectedUsers={data => {
            setIsUserDeleteModalOpen(false);
            templateDelete(data);
          }}
        />
      )}
    </div>
  );
};

export default AccessControl;
