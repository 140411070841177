import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import {
  bondsFilterByOptions,
  BondsSearchDropdownOptions,
  Services,
} from '../../Common/constants';
import RMSPageTitle from '../../Components/CommonComponents/RMSPageTitle';
import TableGrid from '../../Components/ManageBonds/TableGrid';
import TableHeader from '../../Components/ManageBonds/TableHeader';
import AddBondUserModal from '../../Components/Modal/AddBondUserModal';
import {
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED,
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST,
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS,
  PUT_RMS_BOND_ACTIVE_FAILED,
  PUT_RMS_BOND_ACTIVE_REQUEST,
  PUT_RMS_BOND_ACTIVE_SUCCESS,
  RMS_DELETE_BOND_FAILED,
  RMS_DELETE_BOND_REQUEST,
  RMS_DELETE_BOND_SUCCESS,
  RMS_EDIT_BOND_DETAILS_FAIL,
  RMS_EDIT_BOND_DETAILS_REQUEST,
  RMS_EDIT_BOND_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS,
  RMS_RESET_ADD_APPLICANT_SEARCH,
  RMS_RESET_ADD_ELIGIBLE_APPLICANT,
} from '../../redux/actionTypes/manageBondsTypes';
import useDebounce from '../../hooks/useDebounce';

function ManageBondsContainer() {
  const {
    allBondRequests,
    applicantsEligibleForBonds,
    eligibleApplicantIds,
    allDepartmentNames,
    allDesignationNames,
    applicantDetailsLoading,
    deleteBondLoading,
    deleteBondSuccess,
    bondPage,
    addApplicantPage,
    requestTotalRecords,
    bondPageLoading,
    addEligibleApplicantLoading,
    addEligibleApplicantSuccess,
    bondActiveButtonLoading,
    bondActiveSuccess,
    bondActiveErrorMsg,
    editBondSubmitError,
    editBondSubmitLoading,
    editBondSubmitSuccess,
    editBondSubmitErrorMessage,
  } = useSelector(state => state.rmsManageBonds);
  const [selectedFilterDropdown, setSelectedFilterDropdown] = useState(
    bondsFilterByOptions.INACTIVE
  );
  const [showAddApplicantPopup, setshowAddApplicantPopup] = useState(false);
  const [selectedSearchDropdown, setselectedSearchDropdown] = useState(
    BondsSearchDropdownOptions.NAME
  );
  const [addApplicantSearchDropdown, setAddApplicantSearchDropdown] = useState(
    BondsSearchDropdownOptions.REQUESTID
  );
  const [mainTableSearchText, setMainTableSearchText] = useState('');
  const [addApplicantSearchText, setAddApplicantSearchText] = useState('');
  const debouncedSearchText = useDebounce(mainTableSearchText, 1000);

  const [fetchAllBondDetails] = useApi();
  const [fetchApplicantIdsEligibleForBonds] = useApi();
  const [fetchApplicantDetailsEligibleForBonds] = useApi();
  const [fetchDepartmentName] = useApi();
  const [fetchDesignationName] = useApi();
  const [deleteBondApi] = useApi();
  const [addApplicantsApi] = useApi();
  const [putBondDetails] = useApi();
  const [activateBondApi] = useApi();
  const dispatch = useDispatch();

  const generateUrlBasedOnDropdown = (dropdown, searchText) => {
    const searchByNameParam = `searchInFields=NAME&searchText=${searchText}`;
    const searchByEmailParam = `searchInFields=EMAIL&searchText=${searchText}`;
    const searchByRequestIdParam = `requestIdLike=${searchText}`;

    switch (dropdown) {
      case BondsSearchDropdownOptions.REQUESTID:
        return searchByRequestIdParam;
      case BondsSearchDropdownOptions.NAME:
        return searchByNameParam;
      case BondsSearchDropdownOptions.EMAIL:
        return searchByEmailParam;
      default:
        break;
    }
  };

  const getApplicantIdsEligibleForBonds = (page = 1) => {
    fetchApplicantIdsEligibleForBonds(
      `/api/v1/trainingRequests/trainingRequestsEligibleForBond?${generateUrlBasedOnDropdown(
        addApplicantSearchDropdown,
        addApplicantSearchText
      )}`,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_REQUEST,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const editBondDetails = data => {
    putBondDetails(
      `/api/v1/bonds`,
      RMS_EDIT_BOND_DETAILS_REQUEST,
      RMS_EDIT_BOND_DETAILS_SUCCESS,
      RMS_EDIT_BOND_DETAILS_FAIL,
      data,
      '',
      'PUT',
      null,
      Services.isRmsService
    );
  };

  const getApplicantDetailsEligibleForBonds = (userIds = [], page = 1) => {
    if (!userIds.length) return;
    fetchApplicantDetailsEligibleForBonds(
      `/api/v1/users?page=${page}&size=${userIds.length}&userIds=${userIds}`,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS,
      RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getAllBondDetails = (page = 1) => {
    fetchAllBondDetails(
      `/api/v1/bonds?status=${
        selectedFilterDropdown !== bondsFilterByOptions.ALL
          ? selectedFilterDropdown
          : ''
      }&${generateUrlBasedOnDropdown(
        selectedSearchDropdown,
        mainTableSearchText
      )}&page=${page}&size=20`,
      RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST,
      RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS,
      RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getAllDepartmentNames = (departmentIds = []) => {
    fetchDepartmentName(
      `/api/v1/departments?departmentIds=${departmentIds}&page=1&size=${departmentIds.length}`,
      RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_REQUEST,
      RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS,
      RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getAllDesignationNames = (designationIds = []) => {
    fetchDesignationName(
      `/api/v1/designations?designationIds=${designationIds}&page=1&size=${designationIds.length}`,
      RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_REQUEST,
      RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS,
      RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const activateBond = bondId => {
    activateBondApi(
      `/api/v1/bonds/${bondId}/activate`,
      PUT_RMS_BOND_ACTIVE_REQUEST,
      PUT_RMS_BOND_ACTIVE_SUCCESS,
      PUT_RMS_BOND_ACTIVE_FAILED,
      {},
      '',
      'PUT',
      null,
      Services.isRmsService
    );
  };

  const addApplicantsToBondTable = (selectedApplicantId, selectedRequestId) => {
    if (!selectedApplicantId || !selectedRequestId) {
      return;
    }
    const applicantDetails = {
      applicantId: selectedApplicantId,
      requestId: selectedRequestId,
    };
    addApplicantsApi(
      `/api/v1/bonds`,
      POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST,
      POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS,
      POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED,
      applicantDetails,
      {},
      'POST',
      false,
      Services.isRmsService
    );
  };

  const deleteBond = bondId => {
    deleteBondApi(
      `/api/v1/bonds/${bondId}`,
      RMS_DELETE_BOND_REQUEST,
      RMS_DELETE_BOND_SUCCESS,
      RMS_DELETE_BOND_FAILED,
      {},
      '',
      'DELETE',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    if (applicantsEligibleForBonds.length) {
      const departmentIds = applicantsEligibleForBonds?.map(item => {
        return item.departmentId;
      });
      getAllDepartmentNames(departmentIds);
    }
  }, [applicantsEligibleForBonds]);

  useEffect(() => {
    if (applicantsEligibleForBonds.length) {
      const designationIds = applicantsEligibleForBonds?.map(item => {
        return item.designationId;
      });
      getAllDesignationNames(designationIds);
    }
  }, [applicantsEligibleForBonds]);

  useEffect(() => {
    getAllBondDetails();
  }, [selectedFilterDropdown, selectedSearchDropdown]);

  useEffect(() => {
    getAllBondDetails();
  }, [debouncedSearchText]);

  const convertDepartmentId = departmentId => {
    const matchedDepartmentDetails = allDepartmentNames?.find(
      item => item._id === departmentId
    );
    return matchedDepartmentDetails?.name || '-';
  };

  const convertDesignationId = designationId => {
    const matchedDesignationDetails = allDesignationNames?.find(
      item => item._id === designationId
    );
    return matchedDesignationDetails?.name || '-';
  };

  useEffect(() => {
    if (!deleteBondLoading && deleteBondSuccess) {
      getAllBondDetails();
    }
  }, [deleteBondLoading, deleteBondSuccess]);
  const onHandleClose = () => {
    setshowAddApplicantPopup(false);
  };

  const getRequestId = id => {
    if (!id) {
      return '-';
    }
    const applicantDetails = eligibleApplicantIds?.find(
      item => item?.applicantId
    );
    return applicantDetails?.requestId;
  };

  const loadNextBondPage = () => {
    requestTotalRecords > allBondRequests?.length &&
      getAllBondDetails(bondPage + 1);
  };

  const loadNextAddApplicantsPage = () => {
    if (!eligibleApplicantIds) {
      return;
    }
    const eligibleIds = eligibleApplicantIds?.map(item => {
      return item.applicantId;
    });
    if (requestTotalRecords > applicantsEligibleForBonds?.length) {
      getApplicantDetailsEligibleForBonds(eligibleIds, addApplicantPage + 1);
      getApplicantIdsEligibleForBonds(addApplicantPage + 1);
    }
  };

  useEffect(() => {
    if (!addEligibleApplicantLoading && addEligibleApplicantSuccess) {
      getAllBondDetails();
    }
  }, [addEligibleApplicantLoading, addEligibleApplicantSuccess]);

  useEffect(() => {
    if (!bondActiveButtonLoading && bondActiveSuccess) {
      getAllBondDetails();
      getApplicantIdsEligibleForBonds();
    }
  }, [bondActiveButtonLoading, bondActiveSuccess]);

  useEffect(() => {
    if (!editBondSubmitLoading && editBondSubmitSuccess) {
      getAllBondDetails();
    }
  }, [editBondSubmitLoading, editBondSubmitSuccess]);

  return (
    <div>
      <div className="RMS__page manageBonds">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Manage Bonds" />
            <div className="RMS__page--content">
              <TableHeader
                setSelectedFilterDropdown={setSelectedFilterDropdown}
                selectedFilterDropdown={selectedFilterDropdown}
                selectedSearchDropdown={selectedSearchDropdown}
                setselectedSearchDropdown={setselectedSearchDropdown}
                setMainTableSearchText={setMainTableSearchText}
                mainTableSearchText={mainTableSearchText}
              />
              <TableGrid
                setshowAddApplicantPopup={setshowAddApplicantPopup}
                bondRequests={allBondRequests}
                deleteBond={deleteBond}
                deleteBondLoading={deleteBondLoading}
                deleteBondSuccess={deleteBondSuccess}
                loadNextPage={loadNextBondPage}
                loading={bondPageLoading || addEligibleApplicantLoading}
                bondActiveButtonLoading={bondActiveButtonLoading}
                bondActiveErrorMsg={bondActiveErrorMsg}
                editBondSubmitError={editBondSubmitError}
                editBondSubmitErrorMessage={editBondSubmitErrorMessage}
                activateBond={activateBond}
                editBondSingleRow={editBondDetails}
                editBondSubmitLoading={editBondSubmitLoading}
                isAddButtonView={
                  selectedFilterDropdown !== bondsFilterByOptions.ACTIVE
                }
              />
              {showAddApplicantPopup && (
                <AddBondUserModal
                  onHandleClose={onHandleClose}
                  addApplicantSearchDropdown={addApplicantSearchDropdown}
                  setAddApplicantSearchDropdown={setAddApplicantSearchDropdown}
                  addApplicantSearchText={addApplicantSearchText}
                  setAddApplicantSearchText={setAddApplicantSearchText}
                  applicantsEligibleForBonds={applicantsEligibleForBonds}
                  convertDepartmentId={convertDepartmentId}
                  convertDesignationId={convertDesignationId}
                  loading={applicantDetailsLoading}
                  getRequestId={getRequestId}
                  eligibleApplicantIds={eligibleApplicantIds}
                  addApplicantsToBondTable={addApplicantsToBondTable}
                  loadNextPage={loadNextAddApplicantsPage}
                  getApplicantIdsEligibleForBonds={
                    getApplicantIdsEligibleForBonds
                  }
                  getApplicantDetailsEligibleForBonds={
                    getApplicantDetailsEligibleForBonds
                  }
                  addApplicantSearchText={addApplicantSearchText}
                  resetModal={() => {
                    dispatch({ type: RMS_RESET_ADD_ELIGIBLE_APPLICANT });
                  }}
                  resetSearchQuery={() => {
                    dispatch({ type: RMS_RESET_ADD_APPLICANT_SEARCH });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageBondsContainer;
