import React, { useEffect, useMemo, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import {
  approvalBracketsAttributes,
  approveBracketsDropDownAttributes,
} from '../../Common/constants';
import {
  avoidedDuplicationData,
  generateExistingApprovalBracketsArray,
  getArrayIndexUsingKey,
  sortingDropDownOptionList,
  uuidv4,
} from '../../Common/helper';

const ApprovalBrackets = ({
  createTemplateClick,
  templateAdmin,
  getArrayBracket,
  templateData,
}) => {
  const initialApprovalLevel = {
    adminDropDownArrays: [],
    approvalKey: uuidv4(),
    approverIds: [],
    notifierIds: [],
    minApproval: '',
    notifyDropDownArray: [],
  };
  const initialApprovalArray = {
    approvalLevels: [initialApprovalLevel],
    grossStartAmount: '',
    grossEndAmount: '',
    mainKey: uuidv4(),
  };
  const { t } = useTranslation();
  const [approvalBracketArray, setApprovalBracketArray] = useState(null);

  const getTextBoxValues = (mainKey, approvalKey, attribute) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    const approveIndex = getArrayIndexUsingKey(
      approvalBracketArray?.[index]?.approvalLevels,
      'approvalKey',
      approvalKey
    );

    return attribute === approvalBracketsAttributes.minApproval
      ? approvalBracketArray?.[index]?.approvalLevels?.[approveIndex]
          ?.minApproval
      : attribute === approvalBracketsAttributes.grossStartAmount
      ? approvalBracketArray?.[index]?.grossStartAmount
      : approvalBracketArray?.[index]?.grossEndAmount;
  };
  const setTextBoxAttributeValue = (mainKey, approvalKey, attribute, value) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    const approveIndex = getArrayIndexUsingKey(
      approvalBracketArray?.[index]?.approvalLevels,
      'approvalKey',
      approvalKey
    );
    if (attribute === approvalBracketsAttributes.grossStartAmount) {
      setApprovalBracketArray(current => {
        current[index].grossStartAmount = value;
        return [...current];
      });
    } else if (attribute === approvalBracketsAttributes.grossEndAmount) {
      setApprovalBracketArray(current => {
        current[index].grossEndAmount = value;
        return [...current];
      });
    } else {
      setApprovalBracketArray(current => {
        current[index].approvalLevels[approveIndex].minApproval = value;
        return [...current];
      });
    }
  };
  const getTemplateAdminArray = (mainKey, approvalKey, type) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    const approveIndex = getArrayIndexUsingKey(
      approvalBracketArray[index]?.approvalLevels,
      'approvalKey',
      approvalKey
    );

    return type === approveBracketsDropDownAttributes.notifierIds
      ? approvalBracketArray?.[index]?.approvalLevels?.[approveIndex]
          ?.notifierIds
      : approvalBracketArray?.[index]?.approvalLevels?.[approveIndex]
          ?.approverIds;
  };

  const closeSingleBracketRow = (mainKey, approvalKey) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    setApprovalBracketArray(current => {
      const updatedData = current[index].approvalLevels.filter(
        row => row?.approvalKey !== approvalKey
      );
      current[index].approvalLevels = updatedData;
      return [...current];
    });
  };
  const dropDownOnChange = (
    mainKey,
    approvalKey,
    selectedDropDownArray,
    attribute,
    value
  ) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    const approveIndex = getArrayIndexUsingKey(
      approvalBracketArray?.[index]?.approvalLevels,
      'approvalKey',
      approvalKey
    );

    addSelectedItemToArray(selectedDropDownArray, generateAdmins, value);

    setApprovalBracketArray(current => {
      if (approveBracketsDropDownAttributes.approverIds === attribute) {
        current[index].approvalLevels[approveIndex].approverIds =
          avoidedDuplicationData(
            current?.[index]?.approvalLevels?.[approveIndex]?.approverIds,
            [value],
            'value'
          );
      } else {
        current[index].approvalLevels[approveIndex].notifierIds =
          avoidedDuplicationData(
            current[index].approvalLevels[approveIndex].notifierIds,
            [value],
            'value'
          );
      }

      return [...current];
    });
  };
  const deleteDropDownSelected = (mainKey, approvalKey, attribute, value) => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    const approveIndex = getArrayIndexUsingKey(
      approvalBracketArray[index]?.approvalLevels,
      'approvalKey',
      approvalKey
    );

    setApprovalBracketArray(current => {
      if (attribute === approveBracketsDropDownAttributes.approverIds) {
        const removedData = current[index].approvalLevels[
          approveIndex
        ].adminDropDownArrays.filter(data => data?.value !== value);
        current[index].approvalLevels[approveIndex].adminDropDownArrays =
          removedData;
      } else {
        const removedData = current[index].approvalLevels[
          approveIndex
        ].notifyDropDownArray.filter(data => data?.value !== value);

        current[index].approvalLevels[approveIndex].notifyDropDownArray =
          removedData;
      }

      return [...current];
    });
    setApprovalBracketArray(current => {
      if (attribute === approveBracketsDropDownAttributes.approverIds) {
        const updatedData = current[index]?.approvalLevels?.[
          approveIndex
        ]?.approverIds?.filter(approvalIds => approvalIds?.value !== value);
        current[index].approvalLevels[approveIndex].approverIds = updatedData;
      } else {
        const updatedData = current[index]?.approvalLevels?.[
          approveIndex
        ]?.notifierIds?.filter(notifyIds => notifyIds?.value !== value);
        current[index].approvalLevels[approveIndex].notifierIds = updatedData;
      }

      return [...current];
    });
  };
  const addSingleRow = mainKey => {
    const index = getArrayIndexUsingKey(
      approvalBracketArray,
      'mainKey',
      mainKey
    );
    if (approvalBracketArray[index].approvalLevels?.length < 20) {
      setApprovalBracketArray(current => {
        current[index].approvalLevels = [
          ...current[index].approvalLevels,
          initialApprovalLevel,
        ];
        return [...current];
      });
    }
  };

  //<------------------drop down functions ------------------------------------------------------>

  const generateAdmins = useMemo(() => {
    if (!templateAdmin) {
      return [];
    }

    const optionList = templateAdmin.map(value => ({
      value: value._id,
      label: value?.name,
    }));

    return sortingDropDownOptionList(optionList);
  }, [templateAdmin]);

  const filteredList = (list, selectedList) => {
    const mapSelectedList = selectedList.map(({ value }) => value);
    return list.filter(item => !mapSelectedList.includes(item.value));
  };
  const filterObjectBySelectedValue = (array, selectedValue) => {
    return array.filter(item => item.value === selectedValue?.value);
  };
  const addSelectedItemToArray = (state, array, value) => {
    if (!state.filter(item => item.value === value).length) {
      state.push(filterObjectBySelectedValue(array, value)?.[0]);
    }
    return state;
  };

  useEffect(() => {
    getArrayBracket(approvalBracketArray);
  }, [approvalBracketArray]);

  useEffect(() => {
    if (!createTemplateClick) {
      if (templateAdmin?.length) {
        if (templateData?.grossPriceBrackets?.length) {
          const generatedApproveBracketArray =
            generateExistingApprovalBracketsArray(
              templateData?.grossPriceBrackets,
              templateAdmin
            );
          setApprovalBracketArray(generatedApproveBracketArray);
        } else {
          setApprovalBracketArray([initialApprovalArray]);
        }
      }
    } else {
      setApprovalBracketArray([initialApprovalArray]);
    }
  }, [templateAdmin, templateData]);

  return (
    <div
      className={`RMS__page--content marginBottom ${
        createTemplateClick && 'disabled-block'
      } `}
    >
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Approval Brackets')}</p>
          </div>
        </div>
      </div>
      {approvalBracketArray?.map((mainData, mainIndex) => {
        return (
          <div key={mainData?.mainKey}>
            <div className="RMS__page--body black">
              {approvalBracketArray?.length > 1 && (
                <a
                  href="javascript:void(0)"
                  className="closeButton"
                  aria-hidden="true"
                  onClick={() => {
                    setApprovalBracketArray(current =>
                      current.filter(
                        approval => approval?.mainKey !== mainData?.mainKey
                      )
                    );
                  }}
                >
                  &times;
                </a>
              )}

              <div className="RMS__page--body-row twoColumn firstRow">
                <div className="RMS__page--body-column">
                  <div className="form--field row">
                    <p className="form--field-title">{t('Gross Amount')} :</p>
                    <div className="form--field-input">
                      <input
                        type="text"
                        placeholder={t('Min')}
                        disabled={createTemplateClick}
                        pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                        value={getTextBoxValues(
                          mainData?.mainKey,
                          '',
                          approvalBracketsAttributes.grossStartAmount
                        )}
                        onChange={e => {
                          const { value } = e.target;
                          e.target.validity.valid &&
                            setTextBoxAttributeValue(
                              mainData?.mainKey,
                              '',
                              approvalBracketsAttributes.grossStartAmount,
                              value
                            );
                        }}
                      />
                    </div>
                    <span>-</span>
                    <div className="form--field-input">
                      <input
                        type="text"
                        placeholder={t('Max')}
                        disabled={createTemplateClick}
                        pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
                        value={getTextBoxValues(
                          mainData?.mainKey,
                          '',
                          approvalBracketsAttributes.grossEndAmount
                        )}
                        onChange={e => {
                          const { value } = e.target;
                          e.target.validity.valid &&
                            setTextBoxAttributeValue(
                              mainData?.mainKey,
                              '',
                              approvalBracketsAttributes.grossEndAmount,
                              value
                            );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-column"></div>
              </div>
              {mainData?.approvalLevels?.map(
                (approvalLevelData, approveLevelIndex) => {
                  return (
                    <div key={approvalLevelData?.approvalKey}>
                      <div className="RMS__page--body-row">
                        {mainData?.approvalLevels?.length > 1 && (
                          <a
                            href="javascript:void(0)"
                            className="closeButton"
                            aria-hidden="true"
                            onClick={() => {
                              if (!createTemplateClick) {
                                closeSingleBracketRow(
                                  mainData?.mainKey,
                                  approvalLevelData?.approvalKey
                                );
                              }
                            }}
                          >
                            &times;
                          </a>
                        )}

                        <div className="RMS__page--body-column">
                          <div className="form--field twoRows">
                            <p className="form--field-title">
                              {approveLevelIndex + 1} {t('Approval')} :
                            </p>
                            <div className="form--field-input">
                              <ReactDropdown
                                disabled={createTemplateClick}
                                placeholder={t('Select')}
                                options={filteredList(
                                  generateAdmins,
                                  approvalLevelData?.adminDropDownArrays
                                )}
                                onChange={value => {
                                  dropDownOnChange(
                                    mainData?.mainKey,
                                    approvalLevelData?.approvalKey,
                                    approvalLevelData?.adminDropDownArrays,
                                    approveBracketsDropDownAttributes.approverIds,
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="form--field__multipleselect-item">
                              {getTemplateAdminArray(
                                mainData?.mainKey,
                                approvalLevelData?.approvalKey,
                                approveBracketsDropDownAttributes.approverIds
                              )?.map((item, index) => {
                                return (
                                  <p
                                    className="form__form--field dropdown-selected"
                                    key={index}
                                  >
                                    {item?.label}
                                    <a
                                      className=""
                                      aria-hidden="true"
                                      onClick={() => {
                                        deleteDropDownSelected(
                                          mainData?.mainKey,
                                          approvalLevelData?.approvalKey,
                                          approveBracketsDropDownAttributes.approverIds,
                                          item?.value
                                        );
                                      }}
                                    >
                                      &times;
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="RMS__page--body-column">
                          <div className="form--field twoRows">
                            <p className="form--field-title">{t('Notify')} :</p>
                            <div className="form--field-input">
                              <ReactDropdown
                                disabled={createTemplateClick}
                                placeholder={t('Select')}
                                options={filteredList(
                                  generateAdmins,
                                  approvalLevelData?.notifyDropDownArray
                                )}
                                onChange={value => {
                                  dropDownOnChange(
                                    mainData?.mainKey,
                                    approvalLevelData?.approvalKey,
                                    approvalLevelData?.notifyDropDownArray,
                                    approveBracketsDropDownAttributes.notifierIds,
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="form--field__multipleselect-item">
                              {getTemplateAdminArray(
                                mainData?.mainKey,
                                approvalLevelData?.approvalKey,
                                approveBracketsDropDownAttributes.notifierIds
                              )?.map((item, index) => {
                                return (
                                  <p
                                    className="form__form--field dropdown-selected"
                                    key={index}
                                  >
                                    {item?.label}
                                    <a
                                      className=""
                                      aria-hidden="true"
                                      onClick={() => {
                                        deleteDropDownSelected(
                                          mainData?.mainKey,
                                          approvalLevelData?.approvalKey,
                                          approveBracketsDropDownAttributes.notifierIds,
                                          item?.value
                                        );
                                      }}
                                    >
                                      &times;
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="RMS__page--body-column">
                          <div className="form--field twoRows">
                            <p className="form--field-title">
                              {t('Min Approvals')} :
                            </p>
                            <div className="form--field-input">
                              <input
                                type="text"
                                placeholder={t('Count')}
                                disabled={createTemplateClick}
                                pattern="^[1-9][0-9]*$"
                                value={getTextBoxValues(
                                  mainData?.mainKey,
                                  approvalLevelData?.approvalKey,
                                  approvalBracketsAttributes.minApproval
                                )}
                                onChange={e => {
                                  const { value } = e.target;
                                  e.target.validity.valid &&
                                    setTextBoxAttributeValue(
                                      mainData?.mainKey,
                                      approvalLevelData?.approvalKey,
                                      approvalBracketsAttributes.minApproval,
                                      value
                                    );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {mainData?.approvalLevels?.length !==
                        approveLevelIndex + 1 && (
                        <div className="RMS__page--divider" />
                      )}

                      <button
                        className="btn btn--add"
                        onClick={() => {
                          if (!createTemplateClick) {
                            addSingleRow(mainData?.mainKey);
                          }
                        }}
                      >
                        <img src={iconAddButton} alt="add-data" />
                      </button>
                    </div>
                  );
                }
              )}
            </div>
            {mainIndex === approvalBracketArray.length - 1 && (
              <button
                className="btn btn--add long"
                onClick={() => {
                  if (!createTemplateClick) {
                    setApprovalBracketArray(current => [
                      ...current,
                      initialApprovalArray,
                    ]);
                  }
                }}
              >
                <img src={iconAddButton} alt="add-data" />
                {t('Add another Section')}
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ApprovalBrackets;
