import { avoidedDuplicationData } from '../../Common/helper';
import {
  RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST,
  RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS,
  RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED,
  RMS_RESET_ARCHIVAL_TRAINING_DATA,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS,
  RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED,
  RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA,
} from '../actionTypes/ArchivalTrainingRequestTypes';

const INITIAL_STATE = {
  archivalTrainingRequests: [],
  archivalTrainingRequestsLoading: false,
  archivalTrainingRequestsPage: 0,
  archivalTrainingRequestsTotalRecord: 0,
  archivalTrainingRequestS3FileObject: null,
  archivalTrainingRequestS3FileObjectFailed: false,
};
const ArchivalTrainingRequestReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST:
      return {
        ...state,
        archivalTrainingRequestsLoading: true,
      };
    case RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        archivalTrainingRequests:
          customResponse?.page === 1
            ? data
            : avoidedDuplicationData(
                state.archivalTrainingRequests,
                data || [],
                '_id'
              ),
        archivalTrainingRequestsLoading: false,
        archivalTrainingRequestsPage:
          page || state?.archivalTrainingRequestsPage,
        archivalTrainingRequestsTotalRecord:
          totalRecords || state.archivalTrainingRequestsTotalRecord,
      };
    case RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED:
      return {
        ...state,
        archivalTrainingRequestsLoading: false,
      };
    case RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST:
      return {
        ...state,
        archivalTrainingRequestS3FileObjectFailed: false,
        archivalTrainingRequestS3FileObject: null,
      };

    case RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS:
      return {
        ...state,
        archivalTrainingRequestS3FileObject: payload || null,
        archivalTrainingRequestS3FileObjectFailed: false,
      };

    case RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED:
      return {
        ...state,
        archivalTrainingRequestS3FileObjectFailed: true,
      };
    case RMS_RESET_ARCHIVAL_TRAINING_DATA:
      return {
        ...state,
        archivalTrainingRequests: [],
        archivalTrainingRequestsPage: 0,
        archivalTrainingRequestsTotalRecord: 0,
      };
    case RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA:
      return {
        ...state,
        archivalTrainingRequestS3FileObject: null,
        archivalTrainingRequestS3FileObjectFailed: false,
      };
    default:
      return state;
  }
};
export default ArchivalTrainingRequestReducer;
