import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import { addUserDropDown } from '../../Common/constants';

const AddUserModal = ({
  onHandleClose,
  userData,
  existingUsers,
  fetchFilterSearch,
  existUsersLoading,
  userLoading,
  setSearchLoading,
  triggerAddUserAction,
  selectedUsers,
  saveSelectedUsers,
  saveButtonLoading,
}) => {
  const { t } = useTranslation();

  const filterDropDown = [
    {
      label: t('Name'),
      value: addUserDropDown.searchByName,
    },
    {
      label: t('Email'),
      value: addUserDropDown.searchByEmail,
    },
  ];

  const [searchByFilter, setSearchByFilter] = useState(filterDropDown[0].value);
  const [searchKeyValue, setSearchKeyValue] = useState('');

  let searchTimeout;

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchLoading();
    searchTimeout = setTimeout(() => {
      fetchFilterSearch(searchKeyValue, searchByFilter);
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyValue]);

  return (
    <div className="reel-view--popup RMS__popUp addUser">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Add User')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onHandleClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <input
              type="text"
              placeholder={t('Type here...')}
              onChange={e => {
                setSearchKeyValue(e.target.value);
              }}
              value={searchKeyValue}
            />
            <Dropdown
              title="selectFilter"
              value={
                filterDropDown[
                  searchByFilter === addUserDropDown.searchByName ? 0 : 1
                ]
              }
              options={filterDropDown.map(({ label, value }) => ({
                label: t(label),
                value,
              }))}
              onChange={({ value }) => {
                setSearchByFilter(value);
                setSearchKeyValue('');
              }}
            />
          </div>

          <div className="reel-view--popup-row">
            {existUsersLoading || userLoading ? (
              <Loader />
            ) : userData?.length ? (
              <>
                {userData?.map((data, index) => {
                  return (
                    <>
                      <div
                        className={`reel-view--popup-innerRow selected ${
                          existingUsers?.includes(data.email) ? 'disabled' : ''
                        }`}
                      >
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            className="form-input--checkbox"
                            id={`user-checked${index}`}
                            name={`user-checked${index}`}
                            checked={
                              existingUsers?.includes(data.email) ||
                              selectedUsers?.includes(data.email)
                            }
                            onChange={() =>
                              !existingUsers?.includes(data.email) &&
                              triggerAddUserAction(data?.email)
                            }
                          />
                          <label htmlFor={`user-checked${index}`}>
                            {searchByFilter === addUserDropDown.searchByEmail
                              ? data?.email
                              : data?.designation}
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <NoContent message={t('No Search Result Available')} />
            )}
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
             btn btn--primary
             ${saveButtonLoading ? 'btn--loader' : ''}
           `}
              onClick={() =>
                selectedUsers?.length ? saveSelectedUsers() : onHandleClose()
              }
            >
              {!saveButtonLoading && t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
