import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApi from '../../../Hooks/useApi';
import {
  ActionMode,
  privileges,
  RequestType,
  Services,
  UserModes,
} from '../../Common/constants';
import TableGrid from '../../Components/AdminApplicantManageRequestComponents/TableGrid';
import TableHeader from '../../Components/AdminApplicantManageRequestComponents/TableHeader';
import RMSPageTitle from '../../Components/CommonComponents/RMSPageTitle';
import DeleteModalCommon from '../../Components/Modal/DeleteModalCommon';
import {
  RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED,
  RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
  RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
  RMS_DELETE_REQUEST_FAILED,
  RMS_DELETE_REQUEST_REQUEST,
  RMS_DELETE_REQUEST_SUCCESS,
  RMS_GET_ADMIN_MODE_REQUESTS_FAILED,
  RMS_GET_ADMIN_MODE_REQUESTS_REQUEST,
  RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS,
  RMS_GET_FEEDBACK_REQUEST_FAILED,
  RMS_GET_FEEDBACK_REQUEST_REQUEST,
  RMS_GET_FEEDBACK_REQUEST_SUCCESS,
  RMS_GET_REQUEST_USERS_FAILED,
  RMS_GET_REQUEST_USERS_REQUEST,
  RMS_GET_REQUEST_USERS_SUCCESS,
  RMS_GET_USER_MODE_REQUESTS_FAILED,
  RMS_GET_USER_MODE_REQUESTS_REQUEST,
  RMS_GET_USER_MODE_REQUESTS_SUCCESS,
  RMS_RESET_REQUEST_DATA,
} from '../../redux/actionTypes/manageRequestTypes';

const ManageRequests = ({ match, landingData }) => {
  const { state } = useLocation();
  const [getUserModeRequestsApi] = useApi();
  const [getAdminModeRequestsApi] = useApi();
  const [acceptOrRejectApi] = useApi();
  const [getFeedbackRequestIdsApi] = useApi();

  const { t } = useTranslation();

  const [deleteRequestApi] = useApi();
  const [getRequestUsersApi] = useApi();
  const [searchText, setSearchText] = useState('');
  const {
    adminPage,
    userPage,
    deleteRequestLoading,
    deleteRequestSuccess,
    deleteRequestError,
    adminRequests,
    userRequests,
    userDetails,
    requestLoading,
    approvalActionSuccess,
    approvalActionError,
    approvalActionLoading,
    requestTotalRecords,
    feedbackRequest,
    approvalActionErrorMessage,
  } = useSelector(state => state.rmsManageRequest);

  const { userFeedBackSubmitSuccess, userFeedBackSubmitLoading } = useSelector(
    state => state.rmsUserFeedBack
  );

  const dispatch = useDispatch();

  //make selected user empty string
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedAdminDropdown, setSelectedAdminDropdown] = useState(
    ActionMode.APPROVALS
  );
  const [userPrivilages, setuserPrivilages] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

  //set user previlage status based on roles
  useEffect(() => {
    if (landingData?.roles?.length) {
      landingData.roles?.includes(privileges.RMS_ADMIN) &&
        landingData.roles?.includes(privileges.RMS_APPLICANT) &&
        setuserPrivilages('allPrivilage');
      landingData.roles?.includes(privileges.RMS_APPLICANT) &&
        !landingData.roles?.includes(privileges.RMS_ADMIN) &&
        setuserPrivilages('applicant');
      landingData.roles?.includes(privileges.RMS_ADMIN) &&
        !landingData.roles?.includes(privileges.RMS_APPLICANT) &&
        setuserPrivilages('admin');
    }
  }, [landingData]);

  const resetData = () => {
    dispatch({
      type: RMS_RESET_REQUEST_DATA,
    });
  };

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  const loadNextAdminPage = () => {
    requestTotalRecords > adminRequests?.length &&
      getAdminModeRequests(adminPage + 1);
  };
  const loadNextUserPage = () => {
    requestTotalRecords > userRequests?.length &&
      getUserModeRequests(userPage + 1);
  };

  const getRequestUsers = (userIds = []) => {
    userIds.length &&
      getRequestUsersApi(
        `/api/v1/users?userIds=${userIds}&page=1&size=${userIds.length}`,
        RMS_GET_REQUEST_USERS_REQUEST,
        RMS_GET_REQUEST_USERS_SUCCESS,
        RMS_GET_REQUEST_USERS_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService
      );
  };

  useEffect(() => {
    let userIds = [];
    if (selectedUser === UserModes.USER) {
      userIds = userRequests.map(({ applicantId }) => applicantId);
    } else {
      userIds = adminRequests.map(({ applicantId }) => applicantId);
    }
    getRequestUsers(userIds);
  }, [adminRequests, userRequests]);

  const getUserModeRequests = (page = 1) => {
    landingData?._id &&
      getUserModeRequestsApi(
        `/api/v1/trainingRequests?applicantId=${
          landingData?._id
        }&page=${page}&size=10${searchText && `&requestIdLike=${searchText}`}`,
        RMS_GET_USER_MODE_REQUESTS_REQUEST,
        RMS_GET_USER_MODE_REQUESTS_SUCCESS,
        RMS_GET_USER_MODE_REQUESTS_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService,
        { page }
      );
  };
  const acceptOrRejectApiAction = (
    action,
    trainingRequestId,
    approvalLevelId
  ) => {
    resetData();
    acceptOrRejectApi(
      `/api/v1/trainingRequests/${trainingRequestId}/approvalLevels/${approvalLevelId}/${
        action ? 'approve' : 'reject'
      }`,
      RMS_ACCEPT_OR_REJECT_APPROVAL_REQUEST,
      RMS_ACCEPT_OR_REJECT_APPROVAL_SUCCESS,
      RMS_ACCEPT_OR_REJECT_APPROVAL_FAILED,
      {},
      {},
      'PUT',
      false,
      Services.isRmsService
    );
  };

  const getRequestParameters = () => {
    switch (selectedAdminDropdown) {
      case ActionMode.APPROVALS:
        return `?pendingActionBy=${landingData?._id}`;
      case ActionMode.SUBMITTED:
        return `?submittedBy=${landingData?._id}&requestType=${RequestType.GROUP_REQUEST}`;
      case ActionMode.PAST_REQUESTS:
        return `?actionNotRequiredBy=${landingData?._id}`;
      default:
        return '';
    }
  };

  const getAdminModeRequests = (page = 1) => {
    landingData?._id &&
      getAdminModeRequestsApi(
        `/api/v1/trainingRequests${getRequestParameters()}&page=${page}&size=10${
          searchText && `&requestIdLike=${searchText}`
        }`,
        RMS_GET_ADMIN_MODE_REQUESTS_REQUEST,
        RMS_GET_ADMIN_MODE_REQUESTS_SUCCESS,
        RMS_GET_ADMIN_MODE_REQUESTS_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService,
        { page }
      );
  };

  //approval & admin selected
  useEffect(() => {
    if (selectedUser === UserModes.ADMIN && selectedAdminDropdown) {
      resetData();
      getAdminModeRequests();
    } else if (selectedUser === UserModes.USER) {
      getUserModeRequests();
    }
  }, [selectedUser, selectedAdminDropdown, landingData]);

  let searchTimeout;
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      if (selectedUser === UserModes.ADMIN) {
        selectedAdminDropdown && getAdminModeRequests();
      } else {
        getUserModeRequests();
      }
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);

  useEffect(() => {
    if (!deleteRequestLoading) {
      if (deleteRequestSuccess) {
        //refresh data after successful deletion
        if (selectedUser === UserModes.ADMIN) {
          getAdminModeRequests();
        } else {
          getUserModeRequests();
        }
        setShowDeleteModal(false);
      } else if (deleteRequestError) {
        setDeleteErrorMessage(
          t('Something Went Wrong, Please Try Again Later.')
        );
      }
    } else {
      setDeleteErrorMessage('');
    }
  }, [deleteRequestLoading, deleteRequestSuccess, deleteRequestError]);

  useEffect(() => {
    if (!approvalActionLoading) {
      if (approvalActionSuccess) {
        //refresh data after successful deletion
        setSearchText('');
        getAdminModeRequests();
      } else if (approvalActionError) {
        setSearchText('');
        getAdminModeRequests();
        toast.dark(
          approvalActionErrorMessage
            ? t(approvalActionErrorMessage)
            : t('Something Went Wrong, Please Try Again Later.'),
          {
            autoClose: 5000,
          }
        );
      }
    }
  }, [approvalActionSuccess, approvalActionError, approvalActionLoading]);

  const onRequestDelete = (requestId, _id) => {
    setSelectedRequest(requestId);
    setSelectedRequestId(_id);
    setShowDeleteModal(true);
  };

  const onDeleteConfirmed = () => {
    selectedRequest &&
      deleteRequestApi(
        `/api/v1/trainingRequests/${selectedRequestId}`,
        RMS_DELETE_REQUEST_REQUEST,
        RMS_DELETE_REQUEST_SUCCESS,
        RMS_DELETE_REQUEST_FAILED,
        {},
        {},
        'DELETE',
        false,
        Services.isRmsService
      );
  };

  const getFeedbackRequestIds = requestIds => {
    getFeedbackRequestIdsApi(
      `/api/v1/feedbacks?trainingRequestIds=${requestIds}&page=1&size=${requestIds.length}`,
      RMS_GET_FEEDBACK_REQUEST_REQUEST,
      RMS_GET_FEEDBACK_REQUEST_SUCCESS,
      RMS_GET_FEEDBACK_REQUEST_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };

  const onDeleteCancelled = () => {
    setShowDeleteModal(false);
    setSelectedRequest('');
    setDeleteErrorMessage('');
  };
  const requests = useMemo(() => {
    return selectedUser === UserModes.USER ? userRequests : adminRequests;
  }, [selectedUser, userRequests, adminRequests]);

  useEffect(() => {
    const requestIds = requests?.map(request => request._id);
    requestIds.length && getFeedbackRequestIds(requestIds);
  }, [requests]);

  useEffect(() => {
    !userFeedBackSubmitLoading &&
      userFeedBackSubmitSuccess &&
      getUserModeRequests();
  }, [userFeedBackSubmitSuccess, userFeedBackSubmitLoading]);

  useEffect(() => {
    state?.searchText && setSearchText(state?.searchText || '');
  }, [state]);

  if (!match) {
    return null;
  }

  return (
    <div>
      <div className="RMS__page applicant manageRequests">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Manage Requests" />
            <div className="RMS__page--content">
              <TableHeader
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                selectedAdminDropdown={selectedAdminDropdown}
                setSelectedAdminDropdown={setSelectedAdminDropdown}
                userPrivilages={userPrivilages}
                setSearchText={setSearchText}
                searchText={searchText}
                isFromNotification={!!state?.searchText}
              />
              <TableGrid
                userMode={selectedUser}
                onDelete={onRequestDelete}
                selectedAdminDropdown={selectedAdminDropdown}
                requests={requests}
                loadNextPage={
                  selectedUser === UserModes.USER
                    ? loadNextUserPage
                    : loadNextAdminPage
                }
                userDetails={userDetails}
                onRemoveRow={acceptOrRejectApiAction}
                requestLoading={requestLoading}
                feedbackRequest={feedbackRequest}
              />
            </div>
          </div>
          {showDeleteModal && (
            <DeleteModalCommon
              onClose={onDeleteCancelled}
              onCancelClick={onDeleteCancelled}
              onOkClick={onDeleteConfirmed}
              messageFirstPart={t('Are you sure you want to withdraw request')}
              highlightedText={selectedRequest}
              title={t('Withdraw Request')}
              errorMessage={deleteErrorMessage}
              loading={deleteRequestLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageRequests;
