import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ManageRequestForm from '../../Components/ManageRequets/ManageRequestForm/ManageRequestForm';
import { useLocation } from 'react-router-dom';
import useApi from '../../../Hooks/useApi';
import {
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
  RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED,
} from '../../redux/actionTypes/adminManageRequests';

import {
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS,
  RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED,
} from '../../redux/actionTypes/manageRequestTypes';

const ManageRequestsFormContainer = ({ match }) => {
  const { state } = useLocation();
  const { requestDetailsById, loading, lastEditedUserRole } = useSelector(
    state => state.rmsAdminManageRequest
  );

  const {
    approvalLevels,
    approvalInfo,
    approverNames,
    approvalHistoryLoading,
  } = useSelector(state => state.rmsManageRequest);

  const [selectedApprovalLevel, setSelectedApprovalLevel] = useState(1);

  const {
    centers = {},
    departments = {},
    designations = {},
    qualifications = {},
  } = useSelector(state => state.rmsConvertId);
  const [fetchTrainingRequestDetailsById] = useApi();
  const [fetchApplicantDetails] = useApi();
  const [fetchApprovalLevels] = useApi();
  const [fetchApprovalLevelInfo] = useApi();
  const [fetchApplicantNames] = useApi();
  const [fetchUserRoles] = useApi();

  // get request details
  const getTrainingRequestDetailsById = trainingRequestId => {
    fetchTrainingRequestDetailsById(
      `/api/v1/trainingRequests/${trainingRequestId}`,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApplicantDetails = userId => {
    fetchApplicantDetails(
      `/api/v1/users?userIds=${userId}&page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getUserRoles = userEmail => {
    fetchUserRoles(
      `/api/v1/users?emailList=${userEmail}&page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApprovalLevels = requestId => {
    fetchApprovalLevels(
      `/api/v1/trainingRequests/${requestId}/approvalLevels?page=1&size=1`,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_LEVELS_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApprovalLevelInfo = (requestId, selectedApprovalLevel) => {
    fetchApprovalLevelInfo(
      `/api/v1/trainingRequests/${requestId}/approvalLevels/${selectedApprovalLevel}`,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPROVAL_INFO_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  const getApplicantNames = (userIds = []) => {
    fetchApplicantNames(
      `/api/v1/users?userIds=${userIds}&page=1&size=${userIds.length}`,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_REQUEST,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_SUCCESS,
      RMS_FETCH_MANAGE_REQUEST_APPLICANT_NAMES_FAILED,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };

  useEffect(() => {
    if (requestDetailsById.lastUpdatedByEmail) {
      getUserRoles(requestDetailsById.lastUpdatedByEmail);
    }
  }, [requestDetailsById]);

  useEffect(() => {
    const applicantId = requestDetailsById?.applicantId || null;
    if (applicantId) {
      getApplicantDetails(applicantId);
    }
  }, [requestDetailsById]);

  useEffect(() => {
    state?.trainingRequestId &&
      getTrainingRequestDetailsById(state.trainingRequestId);
  }, [state?.trainingRequestId]);

  return (
    <div>
      <ManageRequestForm
        match={match}
        formData={requestDetailsById}
        loading={loading}
        centers={centers}
        designations={designations}
        qualifications={qualifications}
        departments={departments}
        ApprovalLevels={approvalLevels}
        selectedApprovalLevel={selectedApprovalLevel}
        setSelectedApprovalLevel={setSelectedApprovalLevel}
        approvalInfo={approvalInfo}
        getApplicantNames={getApplicantNames}
        approverNames={approverNames}
        approvalHistoryLoading={approvalHistoryLoading}
        lastEditedUserRole={lastEditedUserRole}
        getApprovalLevels={getApprovalLevels}
        getApprovalLevelInfo={getApprovalLevelInfo}
      />
    </div>
  );
};

export default ManageRequestsFormContainer;
