import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import {
  GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS,
  GET_RMS_NORMAL_BOND_DATA_FAILED,
  GET_RMS_NORMAL_BOND_DATA_REQUEST,
  GET_RMS_NORMAL_BOND_DATA_SUCCESS,
  POST_RMS_NORMAL_REQUEST_FAILED,
  POST_RMS_NORMAL_REQUEST_REQUEST,
  POST_RMS_NORMAL_REQUEST_SUCCESS,
  RESET_NORMAL_REQUEST_DATA,
} from '../actionTypes/normalRequestTypes';

const INITIAL_STATE = {
  bondData: null,
  bondDataError: false,
  bondDataLoading: false,
  bondDataSuccess: false,
  normalRequestSubmitError: false,
  normalRequestSubmitErrorMessage: false,
  normalRequestSubmitLoading: false,
  normalRequestSubmitSuccess: false,
  userDetails: null,
};

const normalRequestReducer = (state = INITIAL_STATE, action) => {
  const {
    data = [],
    page = 0,
    size = 0,
    totalRecords = 0,
  } = action.payload || {};
  switch (action.type) {
    case GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: data?.[0] || null,
      };

    case POST_RMS_NORMAL_REQUEST_REQUEST:
      return {
        ...state,
        normalRequestSubmitLoading: true,
        normalRequestSubmitSuccess: false,
        normalRequestSubmitError: false,
        normalRequestSubmitErrorMessage: '',
      };
    case POST_RMS_NORMAL_REQUEST_SUCCESS:
      return {
        ...state,
        normalRequestSubmitLoading: false,
        normalRequestSubmitSuccess: true,
      };
    case POST_RMS_NORMAL_REQUEST_FAILED:
      return {
        ...state,
        normalRequestSubmitLoading: false,
        normalRequestSubmitError: true,
        normalRequestSubmitErrorMessage: action.payload || '',
      };

    case GET_RMS_NORMAL_BOND_DATA_REQUEST:
      return {
        ...state,
        bondDataLoading: true,
        bondDataSuccess: false,
        bondDataError: false,
        bondData: null,
      };
    case GET_RMS_NORMAL_BOND_DATA_SUCCESS:
      return {
        ...state,
        bondDataLoading: false,
        bondDataSuccess: true,
        bondData: action?.payload,
      };
    case GET_RMS_NORMAL_BOND_DATA_FAILED:
      return {
        ...state,
        bondDataLoading: false,
        bondDataError: true,
      };

    case RESET_NORMAL_REQUEST_DATA:
    case CLEAR_SESSION:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default normalRequestReducer;
