import { avoidedDuplicationData } from '../../Common/helper';
import {
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED,
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST,
  POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS,
  PUT_RMS_BOND_ACTIVE_FAILED,
  PUT_RMS_BOND_ACTIVE_REQUEST,
  PUT_RMS_BOND_ACTIVE_SUCCESS,
  RMS_DELETE_BOND_FAILED,
  RMS_DELETE_BOND_REQUEST,
  RMS_DELETE_BOND_SUCCESS,
  RMS_EDIT_BOND_DETAILS_FAIL,
  RMS_EDIT_BOND_DETAILS_REQUEST,
  RMS_EDIT_BOND_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS,
  RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS,
  RMS_RESET_ADD_APPLICANT_SEARCH,
  RMS_RESET_ADD_ELIGIBLE_APPLICANT,
} from '../actionTypes/manageBondsTypes';
import { setErrorMessage } from '../../Common/helper';

const INITIAL_STATE = {
  allBondRequests: [],
  eligibleApplicantIds: [],
  applicantsEligibleForBonds: [],
  allDepartmentNames: [],
  allDesignationNames: [],
  applicantDetailsLoading: false,
  deleteBondLoading: false,
  deleteBondSuccess: false,
  bondPageLoading: false,
  addEligibleApplicantLoading: false,
  addEligibleApplicantSuccess: false,
  bondPage: 0,
  addApplicantPage: 0,
  requestTotalRecords: 0,
  editBondSubmitError: false,
  editBondSubmitErrorMessage: '',
  editBondSubmitLoading: false,
  editBondSubmitSuccess: false,
  bondActiveButtonLoading: false,
  bondActiveSuccess: false,
  bondActiveErrorMsg: false,
};

const manageBondsReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST:
      return {
        ...state,
        addEligibleApplicantLoading: true,
        addEligibleApplicantSuccess: false,
      };
    case POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS:
      return {
        ...state,
        addEligibleApplicantLoading: false,
        addEligibleApplicantSuccess: true,
      };
    case POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED:
      return {
        ...state,
        addEligibleApplicantLoading: false,
        addEligibleApplicantSuccess: false,
      };

    case RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST:
      return {
        ...state,
        bondPageLoading: true,
      };

    case RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS:
      return {
        ...state,
        deleteBondSuccess: false,
        allBondRequests:
          page <= 1
            ? data
            : avoidedDuplicationData(state.allBondRequests, data || [], '_id'),
        bondPage: page || state.bondPage,
        requestTotalRecords: totalRecords || 0,
        bondPageLoading: false,
        bondActiveErrorMsg: false,
        editBondSubmitError: false,
      };
    case RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED:
      return {
        ...state,
        bondPageLoading: false,
      };
    case RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS:
      return {
        ...state,
        eligibleApplicantIds: data || [],
      };

    case RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST:
      return {
        ...state,
        applicantDetailsLoading: true,
      };
    case RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS:
      return {
        ...state,
        applicantsEligibleForBonds:
          page <= 1
            ? data
            : avoidedDuplicationData(
                state.applicantsEligibleForBonds,
                data || [],
                '_id'
              ),
        bondPage: page || state.addApplicantPage,
        requestTotalRecords: totalRecords || 0,
        applicantDetailsLoading: false,
      };

    case RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED:
      return {
        ...state,
        applicantDetailsLoading: false,
      };
    case RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS:
      return {
        ...state,
        allDepartmentNames: data || [],
      };
    case RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS:
      return {
        ...state,
        allDesignationNames: data || [],
      };
    case RMS_RESET_ADD_ELIGIBLE_APPLICANT:
      return {
        ...state,
        eligibleApplicantIds: [],
        applicantsEligibleForBonds: [],
      };
    case RMS_RESET_ADD_APPLICANT_SEARCH:
      return {
        ...state,
        applicantsEligibleForBonds: [],
      };

    case RMS_EDIT_BOND_DETAILS_REQUEST:
      return {
        ...state,
        editBondSubmitLoading: true,
        editBondSubmitSuccess: false,
        editBondSubmitError: false,
        editBondSubmitErrorMessage: '',
      };
    case RMS_EDIT_BOND_DETAILS_SUCCESS:
      return {
        ...state,
        editBondSubmitLoading: false,
        editBondSubmitSuccess: true,
      };
    case RMS_EDIT_BOND_DETAILS_FAIL:
      return {
        ...state,
        editBondSubmitLoading: false,
        editBondSubmitError: true,
        editBondSubmitErrorMessage: setErrorMessage(action.payload),
      };
    case PUT_RMS_BOND_ACTIVE_REQUEST:
      return {
        ...state,
        bondActiveButtonLoading: true,
        bondActiveSuccess: false,
        bondActiveErrorMsg: false,
      };
    case PUT_RMS_BOND_ACTIVE_SUCCESS:
      return {
        ...state,
        bondActiveButtonLoading: false,
        bondActiveSuccess: true,
      };
    case PUT_RMS_BOND_ACTIVE_FAILED:
      return {
        ...state,
        bondActiveButtonLoading: false,
        bondActiveErrorMsg: true,
      };

    case RMS_DELETE_BOND_REQUEST:
      return {
        ...state,
        deleteBondLoading: true,
        deleteBondSuccess: false,
      };
    case RMS_DELETE_BOND_SUCCESS:
      return {
        ...state,
        deleteBondLoading: false,
        deleteBondSuccess: true,
      };
    case RMS_DELETE_BOND_FAILED:
      return {
        ...state,
        deleteBondLoading: false,
        deleteBondSuccess: false,
      };

    default:
      return state;
  }
};

export default manageBondsReducer;
