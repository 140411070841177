import React from 'react';
import { useTranslation } from 'react-i18next';

const ObjectivesAndRemarks = ({ data }) => {
  const { t } = useTranslation();
  const { objective, comment, remark } = data || {};

  return (
    <>
      <div className="RMS__page--content">
        <div className="RMS__page--body">
          <div className="RMS__page--body-row">
            <div className="form--field twoRows textarea">
              <p className="form--field-title">
                {t('What are the objectives of this course nominations?')}
              </p>
              <div className="form--field-input">
                <textarea
                  disabled
                  placeholder={t('N/A')}
                  value={objective || '-'}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-row">
            <div className="form--field twoRows textarea">
              <p className="form--field-title">
                {t(
                  'How would you ensure how the course objectives are transferred to your job performance.'
                )}
              </p>
              <div className="form--field-input">
                <textarea
                  disabled
                  placeholder={t('N/A')}
                  value={comment || '-'}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-row">
            <div className="form--field twoRows textarea">
              <p className="form--field-title">{t('Remark')}</p>
              <div className="form--field-input">
                <textarea
                  disabled
                  placeholder={t('N/A')}
                  value={remark || '-'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ObjectivesAndRemarks;
