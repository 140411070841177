import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { privileges, SearchFieldOptions } from '../../Common/constants';
import AddTemplateUsers from '../../Components/Modal/AddTemplateUsers';
import {
  GET_RMS_TEMPLATE_DEPARTMENT_FAIL,
  GET_RMS_TEMPLATE_DEPARTMENT_REQUEST,
  GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS,
  GET_RMS_TEMPLATE_DESIGNATION_FAIL,
  GET_RMS_TEMPLATE_DESIGNATION_REQUEST,
  GET_RMS_TEMPLATE_DESIGNATION_SUCCESS,
  GET_RMS_TEMPLATE_USERS_FAIL,
  GET_RMS_TEMPLATE_USERS_REQUEST,
  GET_RMS_TEMPLATE_USERS_SUCCESS,
  GET_RMS_USERS_DEPARTMENTS_FAIL,
  GET_RMS_USERS_DEPARTMENTS_REQUEST,
  GET_RMS_USERS_DEPARTMENTS_SUCCESS,
  GET_RMS_USERS_DESIGNATION_FAIL,
  GET_RMS_USERS_DESIGNATION_REQUEST,
  GET_RMS_USERS_DESIGNATION_SUCCESS,
  RESET_TEMPLATE_USERS,
  RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED,
  RMS_TEMPLATE_USER_FETCH_LOADING,
  SET_RMS_ADDED_TEMPLATE_USERS,
} from '../../redux/actionTypes/templateTypes';

const AddTemplateUserContainer = ({
  history,
  onHandleClose,
  isAddAdmin = false,
  onDoneClick,
  hiddenUserIdList = [],
  selectedTemplateId = '',
  isGroupTemplateType,
  isTemplate,
  templateId = '',
}) => {
  const dispatch = useDispatch();
  const [fetchDesignation] = useApi();
  const [fetchDepartments] = useApi();
  const [fetchUsersDepartments] = useApi();
  const [fetchUsersDesignation] = useApi();
  const [fetchAllUsers] = useApi();
  const {
    departments,
    designations,
    templateUsers,
    templateUserLoading,
    usersDepartments,
    usersDesignations,
    templateUsersPage,
    templateUsersTotalRecords,
    selectedUsers,
    usersDesignationsLoading,
    usersDepartmentsLoading,
  } = useSelector(state => state.templateManagement);

  const getAllDesignation = () => {
    fetchDesignation(
      `/api/v1/designations?page=1&size=50`,
      GET_RMS_TEMPLATE_DESIGNATION_REQUEST,
      GET_RMS_TEMPLATE_DESIGNATION_SUCCESS,
      GET_RMS_TEMPLATE_DESIGNATION_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };
  const getAllDepartments = () => {
    fetchDepartments(
      `/api/v1/departments?page=1&size=50`,
      GET_RMS_TEMPLATE_DEPARTMENT_REQUEST,
      GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS,
      GET_RMS_TEMPLATE_DEPARTMENT_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };
  const getUsersDepartMents = deptIds => {
    fetchUsersDepartments(
      `/api/v1/departments?departmentIds=${deptIds}&page=1&size=${deptIds?.length}`,
      GET_RMS_USERS_DEPARTMENTS_REQUEST,
      GET_RMS_USERS_DEPARTMENTS_SUCCESS,
      GET_RMS_USERS_DEPARTMENTS_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getUsersDesignation = designationIds => {
    fetchUsersDesignation(
      `/api/v1/designations?designationIds=${designationIds}&page=1&size=${designationIds?.length}`,
      GET_RMS_USERS_DESIGNATION_REQUEST,
      GET_RMS_USERS_DESIGNATION_SUCCESS,
      GET_RMS_USERS_DESIGNATION_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllUsers = (matchText, deptId, designationId, page) => {
    if (isAddAdmin) {
      getAllCandidateAdminsForTemplate(
        selectedTemplateId,
        page,
        matchText,
        deptId,
        designationId
      );
    } else {
      getAllUsersForTemplate(matchText, deptId, designationId, page);
    }
  };

  const getAllCandidateAdminsForTemplate = (
    templateId,
    page,
    matchText,
    deptId,
    designationId
  ) => {
    const searchFields = [
      SearchFieldOptions.email,
      SearchFieldOptions.employeeId,
      SearchFieldOptions.name,
    ];

    fetchAllUsers(
      `/api/v1/${
        isTemplate
          ? `templates/${templateId}/candidate-admins?`
          : `users/candidate-super-admins?`
      }page=${page}&size=10&searchInFields=${searchFields}&searchText=${matchText}&filteringDepartmentId=${deptId}&filteringDesignationId=${designationId}`,

      GET_RMS_TEMPLATE_USERS_REQUEST,
      GET_RMS_TEMPLATE_USERS_SUCCESS,
      GET_RMS_TEMPLATE_USERS_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };
  const getAllUsersForTemplate = (matchText, deptId, designationId, page) => {
    fetchAllUsers(
      `/api/v1/users?searchInFields=${SearchFieldOptions.email},${
        SearchFieldOptions.employeeId
      },${
        SearchFieldOptions.name
      }&searchText=${matchText}&filteringDepartmentId=${deptId}&filteringDesignationId=${designationId}&${
        isGroupTemplateType
          ? 'assignedToGroupTemplate=false'
          : 'assignedToNormalTemplate=false'
      }&page=${page}&size=10&isProfileCompleted=true${
        templateId ? `&assignedToTemplateId=${templateId}` : ''
      }`,
      GET_RMS_TEMPLATE_USERS_REQUEST,
      GET_RMS_TEMPLATE_USERS_SUCCESS,
      GET_RMS_TEMPLATE_USERS_FAIL,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  useEffect(() => {
    getAllDesignation();
    getAllDepartments();
    return () => {
      dispatch({ type: RESET_TEMPLATE_USERS });
    };
  }, []);

  useEffect(() => {
    if (templateUsers?.length) {
      const designationIds = templateUsers
        ?.slice(templateUsersPage * 10 - 10, templateUsers?.length)
        ?.map(data => data?.designationId);
      const filteredDesignationIds =
        designationIds?.filter(data => data !== undefined) || [];

      filteredDesignationIds?.length &&
        getUsersDesignation([...new Set(filteredDesignationIds)]);

      const deptIds = templateUsers
        ?.slice(templateUsersPage * 10 - 10, templateUsers?.length)
        ?.map(data => data?.departmentId);

      const filteredDeptIds = deptIds?.filter(data => data !== undefined) || [];
      filteredDeptIds?.length &&
        getUsersDepartMents([...new Set(filteredDeptIds)]);
    }
  }, [templateUsers]);

  return (
    <AddTemplateUsers
      isGroupTemplateType={isGroupTemplateType}
      onHandleClose={onHandleClose}
      departments={departments}
      designations={designations}
      templateUsers={
        isAddAdmin
          ? templateUsers?.filter(user => !hiddenUserIdList?.includes(user._id))
          : templateUsers
      }
      templateUserCount={templateUsers?.length}
      templateUserLoading={templateUserLoading}
      setFilterValues={(searchKey, designation, department) => {
        getAllUsers(searchKey, department || '', designation || '', 1);
      }}
      usersDepartments={usersDepartments}
      usersDesignations={usersDesignations}
      loadMoreUsers={(searchKey, designation, department) => {
        getAllUsers(
          searchKey,
          department || '',
          designation || '',
          templateUsersPage + 1
        );
      }}
      templateUsersTotalRecords={templateUsersTotalRecords}
      singleUserToggle={data =>
        dispatch({ type: SET_RMS_ADDED_TEMPLATE_USERS, customInput: data })
      }
      selectedUsers={selectedUsers}
      usersDesignationsLoading={usersDesignationsLoading}
      usersDepartmentsLoading={usersDepartmentsLoading}
      setSearchLoading={() => {
        dispatch({ type: RESET_TEMPLATE_USERS });
        dispatch({ type: RMS_TEMPLATE_USER_FETCH_LOADING });
      }}
      toggleSelectAll={selectAll =>
        dispatch({
          type: RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED,
          customInput: selectAll,
        })
      }
      onDoneClick={onDoneClick}
      isAddAdmin={isAddAdmin}
    />
  );
};

export default AddTemplateUserContainer;
