import React from 'react';
import { useTranslation } from 'react-i18next';

const InstitutionDetails = ({ data }) => {
  const { t } = useTranslation();
  const { institutionName, contactPerson, email, phoneNumber } =
    data?.course || {};
  return (
    <>
      <div className="RMS__page--divider"></div>
      <div className="RMS__page--body">
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field row">
              <p className="form--field-title required">{t('Institution')}</p>
              <div className="form--field-input">
                <input
                  disabled
                  type="text"
                  placeholder={t('N/A')}
                  value={institutionName || '-'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">
                {t('Contact Person')}
              </p>
              <div className="form--field-input">
                <input
                  disabled
                  type="text"
                  placeholder={t('N/A')}
                  value={contactPerson || '-'}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Email')}</p>
              <div className="form--field-input">
                <input
                  disabled
                  type="text"
                  placeholder={t('N/A')}
                  value={email || '-'}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Phone Number')}</p>
              <div className="form--field-input">
                <input
                  disabled
                  type="text"
                  placeholder={t('N/A')}
                  value={phoneNumber || '-'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="RMS__page--divider"></div>
      </div>
    </>
  );
};

export default InstitutionDetails;
