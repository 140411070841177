import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { Services } from '../../Common/constants';
import RequestsModal from '../../Components/RequestModal/RequestsModal';
import useS3FileView from '../../hooks/useS3FileView';

import {
  GET_ALL_REQUESTS_FAIL,
  GET_ALL_REQUESTS_REQUEST,
  GET_ALL_REQUESTS_SUCCESS,
  GET_APPLICANT_NAMES_FAIL,
  GET_APPLICANT_NAMES_REQUEST,
  GET_APPLICANT_NAMES_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  RMS_GET_FEEDBACK_REQUEST_MODAL_FAILED,
  RMS_GET_FEEDBACK_REQUEST_MODAL_REQUEST,
  RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS,
  RESET_REQUEST_TYPES_DATA,
} from '../../redux/actionTypes/requestsTypes';

import {
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST,
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS,
  RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED,
  RESET_FEEDBACK_S3_FILE_OBJECT,
} from '../../redux/actionTypes/superAdminDashboardTypes';

const RequestsModalContainer = ({
  financialDurationId,
  handleClose,
  isSuperAdminDashboard,
  userDetails,
  selectedDateRangeId,
  selectedTemplate,
  selectedMode,
  selectedTemplateLabel
}) => {
  const dispatch = useDispatch();
  const [getAllRequests] = useApi();
  const [getApplicantNames] = useApi();
  const [getFeedbackRequestIdsApi] = useApi();
  const [getAllTemplates] = useApi();
  const [selectedFeedbackIds, setSelectedFeedbackIds] = useState();
  const [selectAllfeedback, setSelectAllFeedback] = useState(false);
  const [fetchReport] = useApi();
  const [getFromBucket, url, isFileDownloadStarted] = useS3FileView();

  const {
    getRequestsLoading,
    getRequestsData,
    getRequestsError,
    getTemplatesLoading,
    getTemplatesData,
    getTemplatesError,
    getApplicantNamesLoading,
    getApplicantNamesData,
    getApplicantNamesError,
    feedbackRequest,
  } = useSelector(state => state.rmsRequestsReducer);

  const { feedbackS3FileObject, isFeedbackS3FileObjectFailed } = useSelector(
    state => state.rmsSuperAdminDashboard
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedRequestId, setSelectedRequestId] = useState([]);

  const getAllRequestsAPI = (
    templateId,
    selectedRequestStatus,
    searchText,
    renderSearchFilterEndpoint
  ) => {
    getAllRequests(
      `/api/v1/financialDurations/${financialDurationId}/trainingRequests?requestStatus=${selectedRequestStatus}${
        templateId && `&templateId=${templateId}`
      }${searchText && `&${renderSearchFilterEndpoint()}`}`,
      GET_ALL_REQUESTS_REQUEST,
      GET_ALL_REQUESTS_SUCCESS,
      GET_ALL_REQUESTS_FAIL,
      null,
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const exportFeedbackReport = () => {
    fetchReport(
      selectAllfeedback
        ? `/api/v1/financialDurations/${selectedDateRangeId}/feedbacks/report?templateId=${selectedTemplateId}`
        : `/api/v1/financialDurations/${selectedDateRangeId}/feedbacks/report?requestIds=${selectedRequestId}`,
      RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_REQUEST,
      RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_SUCCESS,
      RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED,
      {},
      '',
      'POST',
      null,
      Services.isRmsService
    );
  };

  const getAllTemplatesAPI = (searchTemplateText, id = '') => {
    getAllTemplates(
      `/api/v1/templates?page=1&size=10${
        searchTemplateText && `&searchNameLike=${searchTemplateText}`
      }${!isSuperAdminDashboard ? `&adminId=${id}` : ''}`,
      GET_TEMPLATES_REQUEST,
      GET_TEMPLATES_SUCCESS,
      GET_TEMPLATES_FAIL,
      null,
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getApplicantNamesAPI = userIds => {
    getApplicantNames(
      `/api/v1/users?userIds=${userIds}`,
      GET_APPLICANT_NAMES_REQUEST,
      GET_APPLICANT_NAMES_SUCCESS,
      GET_APPLICANT_NAMES_FAIL,
      null,
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getFeedbackRequestIds = requestIds => {
    getFeedbackRequestIdsApi(
      `/api/v1/feedbacks?trainingRequestIds=${requestIds}&page=1&size=${requestIds.length}`,
      RMS_GET_FEEDBACK_REQUEST_MODAL_REQUEST,
      RMS_GET_FEEDBACK_REQUEST_MODAL_SUCCESS,
      RMS_GET_FEEDBACK_REQUEST_MODAL_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };

  useEffect(() => {
    feedbackS3FileObject?.fileKey &&
      getFromBucket(
        feedbackS3FileObject?.bucketName,
        feedbackS3FileObject?.fileKey,
        {
          timeDelay: 5000,
          isAutoDownload: true,
          errorAction: RMS_FETCH_EXPORT_FEEDBACK_S3_FILE_FAILED,
        }
      );
  }, [feedbackS3FileObject]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_REQUEST_TYPES_DATA });
      dispatch({ type: RESET_FEEDBACK_S3_FILE_OBJECT });
      setSelectedTemplateId('');
      setSelectedRequestId([]);
    };
  }, []);

  return (
    <>
      <RequestsModal
        handleClose={handleClose}
        financialDurationId={financialDurationId}
        getRequestsLoading={getRequestsLoading}
        getRequestsData={getRequestsData}
        getRequestsError={getRequestsError}
        getTemplatesLoading={getTemplatesLoading}
        getTemplatesData={getTemplatesData}
        getTemplatesError={getTemplatesError}
        getApplicantNamesLoading={getApplicantNamesLoading}
        getApplicantNamesData={getApplicantNamesData}
        getApplicantNamesError={getApplicantNamesError}
        feedbackRequest={feedbackRequest}
        getApplicantNamesAPI={userIds => getApplicantNamesAPI(userIds)}
        getAllTemplatesAPI={getAllTemplatesAPI}
        getAllRequestsAPI={(
          templateId,
          selectedRequestStatus,
          searchText,
          renderSearchFilterEndpoint
        ) =>
          getAllRequestsAPI(
            templateId,
            selectedRequestStatus,
            searchText,
            renderSearchFilterEndpoint
          )
        }
        getFeedbackRequestIds={requestIds => getFeedbackRequestIds(requestIds)}
        isSuperAdminDashboard={isSuperAdminDashboard}
        userDetails={userDetails}
        exportReport={exportFeedbackReport}
        isFeedbackS3FileObjectFailed={isFeedbackS3FileObjectFailed}
        selectedIds={selectedFeedbackIds}
        setSelectedIds={setSelectedFeedbackIds}
        selectAll={selectAllfeedback}
        setSelectAll={setSelectAllFeedback}
        url={url}
        isFileDownloadStarted={isFileDownloadStarted}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedUserIds={selectedRequestId}
        setSelectedUserIds={setSelectedRequestId}
        selectedMode={selectedMode}
        selectedTemplate={selectedTemplate}
        selectedTemplateLabel={selectedTemplateLabel}
      />
    </>
  );
};

export default RequestsModalContainer;
