export const RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST =
  'RMS_FETCH_ALL_MANAGE_REQUESTS_REQUEST';
export const RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_REQUESTS_SUCCESS';
export const RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED =
  'RMS_FETCH_ALL_MANAGE_REQUESTS_FAILED';

export const RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST =
  'RMS_MANGE_REQUEST_FETCH_TEMPLATE_REQUEST';
export const RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED =
  'RMS_MANGE_REQUEST_FETCH_TEMPLATE_FAILED';
export const RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS =
  'RMS_MANGE_REQUEST_FETCH_TEMPLATE_SUCCESS';

export const RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_FAILED';
export const RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_DETAILS_BY_ID_SUCCESS';

export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_FAILED';
export const RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_APPLICANT_DETAILS_SUCCESS';

export const RMS_RESET_MANAGE_REQUEST_PAGE_DATA =
  'RMS_RESET_MANAGE_REQUEST_PAGE_DATA';

export const RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST =
  'RMS_FETCH_MANAGE_REQUEST_USER_ROLES_REQUEST';
export const RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS =
  'RMS_FETCH_MANAGE_REQUEST_USER_ROLES_SUCCESS';
export const RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED =
  'RMS_FETCH_MANAGE_REQUEST_USER_ROLES_FAILED';
export const RMS_ADMIN_UPDATE_REQUEST_REQUEST =
  'RMS_ADMIN_UPDATE_REQUEST_REQUEST';
export const RMS_ADMIN_UPDATE_REQUEST_SUCCESS =
  'RMS_ADMIN_UPDATE_REQUEST_SUCCESS';
export const RMS_ADMIN_UPDATE_REQUEST_FAILED =
  'RMS_ADMIN_UPDATE_REQUEST_FAILED';

export const RMS_ADMIN_UPDATE_REQUEST_RESET = 'RMS_ADMIN_UPDATE_REQUEST_RESET';

export const RESET_RMS_REQUEST_DETAILS_FORM_DATA =
  'RESET_RMS_REQUEST_DETAILS_FORM_DATA';

export const RESET_RMS_SELECTED_TEMPLATE_REQUESTS =
  'RESET_RMS_SELECTED_TEMPLATE_REQUESTS';

export const RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST =
  'RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_REQUEST';
export const RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS =
  'RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_SUCCESS';
export const RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED =
  'RMS_SUPERADMIN_ACCEPT_OR_REJECT_APPROVAL_FAILED';
