import { avoidedDuplicationData } from '../../Common/helper';
import {
  RMS_GET_FEEDBACK_QUESTIONS_SUCCESS,
  RMS_POST_FEEDBACK_SUCCESS,
  RMS_POST_FEEDBACK_FAILED,
  RMS_POST_FEEDBACK_REQUEST,
  RMS_GET_VIEW_FEEDBACK_REQUEST,
  RMS_GET_VIEW_FEEDBACK_SUCCESS,
  RMS_GET_VIEW_FEEDBACK_FAILED,
  RMS_RESET_VIEW_FEEDBACK_POPUP_DATA,
} from '../actionTypes/userFeedbackType';

const INITIAL_STATE = {
  feedbackQuestions: [],
  feedback: {},
  viewFeedbackData: {},
  userFeedBackSubmitSuccess: false,
  userFeedBackSubmitLoading: false,
  userFeedBackSubmitErrorMsg: false,
};

const userFeedbackReducer = (state = INITIAL_STATE, action) => {
  const { payload, customResponse } = action;
  const { data = [], page = 0, size = 0, totalRecords = 0 } = payload || {};

  switch (action.type) {
    case RMS_GET_FEEDBACK_QUESTIONS_SUCCESS:
      return {
        ...state,
        feedbackQuestions: data,
      };
    case RMS_POST_FEEDBACK_REQUEST:
      return {
        ...state,
        userFeedBackSubmitSuccess: false,
        userFeedBackSubmitLoading: true,
        userFeedBackSubmitErrorMsg: false,
      };
    case RMS_POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        userFeedBackSubmitSuccess: true,
        userFeedBackSubmitLoading: false,
        userFeedBackSubmitErrorMsg: false,
      };
    case RMS_POST_FEEDBACK_FAILED:
      return {
        ...state,
        userFeedBackSubmitSuccess: false,
        userFeedBackSubmitLoading: false,
        userFeedBackSubmitErrorMsg: true,
      };
    case RMS_GET_VIEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        viewFeedbackData: payload,
      };

    case RMS_RESET_VIEW_FEEDBACK_POPUP_DATA:
      return {
        ...state,
        userFeedBackSubmitSuccess: false,
        userFeedBackSubmitLoading: false,
        userFeedBackSubmitErrorMsg: false,
      };
    default:
      return state;
  }
};

export default userFeedbackReducer;
