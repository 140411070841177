import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../Hooks/useApi';
import {
  GET_RMS_CONVERTID_CENTER_DETAILS_FAILED,
  GET_RMS_CONVERTID_CENTER_DETAILS_REQUEST,
  GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_CENTER_FAILED,
  GET_RMS_CONVERTID_CENTER_REQUEST,
  GET_RMS_CONVERTID_CENTER_SUCCESS,
  GET_RMS_CONVERTID_DEPARTMENT_DETAILS_FAILED,
  GET_RMS_CONVERTID_DEPARTMENT_DETAILS_REQUEST,
  GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_DEPARTMENT_FAILED,
  GET_RMS_CONVERTID_DEPARTMENT_REQUEST,
  GET_RMS_CONVERTID_DEPARTMENT_SUCCESS,
  GET_RMS_CONVERTID_DESIGNATION_DETAILS_FAILED,
  GET_RMS_CONVERTID_DESIGNATION_DETAILS_REQUEST,
  GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_DESIGNATION_FAILED,
  GET_RMS_CONVERTID_DESIGNATION_REQUEST,
  GET_RMS_CONVERTID_DESIGNATION_SUCCESS,
  GET_RMS_CONVERTID_QUALIFICATION_FAILED,
  GET_RMS_CONVERTID_QUALIFICATION_REQUEST,
  GET_RMS_CONVERTID_QUALIFICATION_SUCCESS,
  GET_RMS_CONVERTID_USER_QUALIFICATIONS_FAILED,
  GET_RMS_CONVERTID_USER_QUALIFICATIONS_REQUEST,
  GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS,
  RMS_CONVERTID_RESET_USER_MANAGEMENT,
} from '../redux/actionTypes/convertIdTypes';

function useConvertId() {
  const [getAllUsersApi] = useApi();
  const [getUserQualificationsApi] = useApi();
  const [getCenterDetailsApi] = useApi();
  const [getAllCentersApi] = useApi();
  const [getAllDepartmentsApi] = useApi();
  const [getAllDesignationsApi] = useApi();
  const [getAllQualificationsApi] = useApi();
  const [getDepartmentDetailsApi] = useApi();
  const [getDesignationDetailsApi] = useApi();

  const dispatch = useDispatch();

  const {
    centers = {},
    departments = {},
    designations = {},
    qualifications = {},
    users = [],
    usersPage,
    usersTotalRecords,
  } = useSelector(state => state.rmsConvertId);

  const getQualificationDetails = qualificationIds => {
    qualificationIds?.length &&
      getUserQualificationsApi(
        `/api/v1/qualifications?qualificationIds=${qualificationIds}&page=1&size=${qualificationIds.length}`,
        GET_RMS_CONVERTID_USER_QUALIFICATIONS_REQUEST,
        GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS,
        GET_RMS_CONVERTID_USER_QUALIFICATIONS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getCenterDetails = centerIds => {
    centerIds?.length &&
      getCenterDetailsApi(
        `/api/v1/centers?centerIds=${centerIds}&page=1&size=${centerIds.length}`,
        GET_RMS_CONVERTID_CENTER_DETAILS_REQUEST,
        GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS,
        GET_RMS_CONVERTID_CENTER_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDepartmentDetails = departmentIds => {
    departmentIds?.length &&
      getDepartmentDetailsApi(
        `/api/v1/departments?departmentIds=${departmentIds}&page=1&size=${departmentIds.length}`,
        GET_RMS_CONVERTID_DEPARTMENT_DETAILS_REQUEST,
        GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS,
        GET_RMS_CONVERTID_DEPARTMENT_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDesignationDetails = designationIds => {
    designationIds?.length &&
      getDesignationDetailsApi(
        `/api/v1/designations?designationIds=${designationIds}&page=1&size=${designationIds.length}`,
        GET_RMS_CONVERTID_DESIGNATION_DETAILS_REQUEST,
        GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS,
        GET_RMS_CONVERTID_DESIGNATION_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RMS_CONVERTID_RESET_USER_MANAGEMENT });
    };
  }, []);

  useEffect(() => {
    if (users?.length) {
      const qualificationIds =
        users
          ?.filter(user => user?.qualificationId)
          ?.map(user => user?.qualificationId) || [];

      const uniqueQualificationIds = [...new Set(qualificationIds)];

      const centerIds =
        users?.filter(user => user?.centerId)?.map(user => user?.centerId) ||
        [];
      const uniqueCenterIds = [...new Set(centerIds)];

      const departmentIds =
        users
          ?.filter(user => user?.departmentId)
          ?.map(user => user?.departmentId) || [];
      const uniqueDepartmentIds = [...new Set(departmentIds)];

      const designationIds =
        users
          ?.filter(user => user?.designationId)
          ?.map(user => user?.designationId) || [];
      const uniqueDesignationIds = [...new Set(designationIds)];

      getQualificationDetails(uniqueQualificationIds);
      getCenterDetails(uniqueCenterIds);
      getDepartmentDetails(uniqueDepartmentIds);
      getDesignationDetails(uniqueDesignationIds);
    }
  }, [users]);

  const getAllCenters = () => {
    getAllCentersApi(
      `/api/v1/centers?page=1&size=50`,
      GET_RMS_CONVERTID_CENTER_REQUEST,
      GET_RMS_CONVERTID_CENTER_SUCCESS,
      GET_RMS_CONVERTID_CENTER_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllDepartments = () => {
    getAllDepartmentsApi(
      `/api/v1/departments?page=1&size=50`,
      GET_RMS_CONVERTID_DEPARTMENT_REQUEST,
      GET_RMS_CONVERTID_DEPARTMENT_SUCCESS,
      GET_RMS_CONVERTID_DEPARTMENT_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getAllDesignations = () => {
    getAllDesignationsApi(
      `/api/v1/designations?page=1&size=50`,
      GET_RMS_CONVERTID_DESIGNATION_REQUEST,
      GET_RMS_CONVERTID_DESIGNATION_SUCCESS,
      GET_RMS_CONVERTID_DESIGNATION_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };
  const getAllQualifications = () => {
    getAllQualificationsApi(
      `/api/v1/qualifications?page=1&size=50`,
      GET_RMS_CONVERTID_QUALIFICATION_REQUEST,
      GET_RMS_CONVERTID_QUALIFICATION_SUCCESS,
      GET_RMS_CONVERTID_QUALIFICATION_FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  const getFormattedDate = dateStr => {
    if (moment(dateStr).isValid()) {
      return moment(dateStr).format('Do MMM YYYY');
    } else {
      return '';
    }
  };

  const getQualificationName = qualificationId => {
    return qualifications?.[qualificationId]?.name || '-';
  };
  const getCenterName = centerId => {
    return centers?.[centerId]?.name || '-';
  };
  const getDepartmentName = depId => {
    return departments?.[depId]?.name || '-';
  };
  const getDesignationName = designationId => {
    return designations?.[designationId]?.name || '-';
  };

  return {
    getFormattedDate,
    getQualificationName,
    getCenterName,
    getDepartmentName,
    getDesignationName,
    getQualificationDetails,
    getCenterDetails,
    getDepartmentDetails,
    getDesignationDetails,
  };
}

export default useConvertId;
