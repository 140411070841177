import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import { getArrayIndexUsingKey } from '../../Common/helper';

const AddTemplateUsers = ({
  isGroupTemplateType = false,
  onHandleClose = () => {},
  departments = [],
  designations = [],
  setFilterValues = () => {},
  templateUsers = [],
  templateUserLoading = false,
  usersDepartments = [],
  usersDesignations = [],
  loadMoreUsers = () => {},
  singleUserToggle = () => {},
  selectedUsers = [],
  usersDesignationsLoading = false,
  usersDepartmentsLoading = false,
  setSearchLoading = () => {},
  templateUsersTotalRecords = 0,
  toggleSelectAll = () => {},
  onDoneClick = () => {},
  isAddAdmin = false,
  templateUserCount = 0,
}) => {
  const { t } = useTranslation();
  let searchTimeout;
  const [selectedDesignation, SetSelectedDesignation] = useState();
  const [selectedDepartment, SetSelectedDepartment] = useState();
  const [searchKey, setSearchKey] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsersBackUp, setSelectedUsersBackUp] = useState([]);

  useEffect(() => {
    setSelectAll(false);
    setSearchLoading();
    (selectedDesignation || selectedDepartment) &&
      setFilterValues(searchKey, selectedDesignation, selectedDepartment);
  }, [selectedDesignation, selectedDepartment]);

  useEffect(() => {
    if (selectedUsers) {
      const selectedUsersInitial = [...selectedUsers];
      setSelectedUsersBackUp(selectedUsersInitial);
    }
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSearchLoading();
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setFilterValues(searchKey, selectedDesignation, selectedDepartment);
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKey]);

  return (
    <div className="reel-view--popup RMS__popUp addTempUser wider">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{isAddAdmin ? t('Add Admins') : t('Add Users')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose(true, selectedUsersBackUp)}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <div className="header-column">
              <input
                type="text"
                placeholder={t('Search')}
                className="form-input--search"
                onChange={e => {
                  setSearchKey(e.target.value);
                }}
                value={searchKey}
              />
            </div>
            <div className="header-column">
              <h3>{t('User Designation')}</h3>
              <Dropdown
                title="selectDesignation"
                value={selectedDesignation}
                placeholder={t('Select Designation')}
                options={
                  designations?.map(data => ({
                    label: data?.name,
                    value: data?._id,
                  })) || []
                }
                onChange={({ value }) => SetSelectedDesignation(value)}
              />
            </div>
            <div className="header-column">
              <h3>{t('Department Id')}</h3>
              <Dropdown
                title="selectDepartment"
                value={selectedDepartment}
                placeholder={t('Select Department')}
                options={
                  departments?.map(data => ({
                    label: data?.name,
                    value: data?._id,
                  })) || []
                }
                onChange={({ value }) => SetSelectedDepartment(value)}
              />
            </div>
          </div>
          {!templateUsers?.length &&
          (usersDesignationsLoading ||
            usersDepartmentsLoading ||
            templateUserLoading) ? (
            <DottedLoader />
          ) : (
            <div className="reel-view--popup-row">
              <div
                className={`RMS__page--table user1 ${
                  isAddAdmin ? t('add_admin') : t('add_users')
                }`}
              >
                <div className="RMS__page--table-header">
                  <div className="RMS__page--table-column">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        className="form-input--checkbox"
                        name="selectAll"
                        checked={selectAll}
                      />
                      <label
                        htmlFor="selectAll"
                        onClick={() => {
                          setSelectAll(!selectAll);
                          toggleSelectAll(!selectAll);
                        }}
                      ></label>
                    </div>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>
                      {isGroupTemplateType ? t('User Name') : t('Employee ID')}
                    </h4>
                  </div>

                  <div className="RMS__page--table-column">
                    <h4>{t('Email')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Department Id')}</h4>
                  </div>

                  <div className="RMS__page--table-column">
                    <h4>{t('User Designation')}</h4>
                  </div>
                </div>
                <div className="RMS__page--table-body">
                  {!templateUsers?.length ? (
                    <NoContent message={t('No Search Result Available')} />
                  ) : (
                    templateUsers?.map(data => {
                      return (
                        <>
                          <div className="RMS__page--table-row">
                            <div className="RMS__page--table-column">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  className="form-input--checkbox"
                                  name={`${data?.email}`}
                                  checked={
                                    selectAll ||
                                    getArrayIndexUsingKey(
                                      selectedUsers,
                                      'email',
                                      data?.email
                                    ) >= 0
                                  }
                                />
                                <label
                                  htmlFor={`${data?.email}`}
                                  onClick={() =>
                                    !selectAll && singleUserToggle(data)
                                  }
                                ></label>
                              </div>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>
                                {' '}
                                {isGroupTemplateType
                                  ? data?.name || '-'
                                  : data?.employeeId || '-'}
                              </p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>{data?.email || '-'}</p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>
                                {usersDepartments?.[
                                  getArrayIndexUsingKey(
                                    usersDepartments,
                                    '_id',
                                    data?.departmentId
                                  )
                                ]?.name || '-'}
                              </p>
                            </div>
                            <div className="RMS__page--table-column">
                              <p>
                                {' '}
                                {usersDesignations?.[
                                  getArrayIndexUsingKey(
                                    usersDesignations,
                                    '_id',
                                    data?.designationId
                                  )
                                ]?.name || '-'}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })
                  )}

                  <ReactVisibilitySensor
                    partialVisibility
                    onChange={isVisible => {
                      if (isVisible) {
                        if (
                          templateUsers?.length &&
                          (!templateUserCount
                            ? templateUsersTotalRecords > templateUsers?.length
                            : templateUsersTotalRecords > templateUserCount)
                        ) {
                          setSelectAll(false);
                          loadMoreUsers(
                            searchKey,
                            selectedDesignation,
                            selectedDepartment
                          );
                        }
                      }
                    }}
                  >
                    <div>&nbsp;</div>
                  </ReactVisibilitySensor>
                </div>
              </div>
            </div>
          )}

          <div className="reel-view--popup-row">
            <button
              className={`btn btn--primary`}
              onClick={() => {
                onHandleClose(false, selectedUsersBackUp);
                onDoneClick && onDoneClick();
              }}
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplateUsers;
