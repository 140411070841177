import React from 'react';
import { useTranslation } from 'react-i18next';

function RMSPageTitle({ titleText }) {
  const { t } = useTranslation();

  return (
    <div className="RMS__page--title">
      <h2>{t(titleText)}</h2>
    </div>
  );
}

export default RMSPageTitle;
