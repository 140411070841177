import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import '../assets/scss/rms-common-style.scss';
import AccessControlContainer from '../Container/AccessControl/AccessControlContainer';
import LandingContainer from '../Container/LandingContainer/LandingContainer';
import CreateBondTemplateContainer from '../Container/ManageTemplateContainer/CreateBondTemplate';
import CreateTemplateContainer from '../Container/ManageTemplateContainer/CreateTemplateContainer';
import ManageTemplateContainer from '../Container/ManageTemplateContainer/ManageTemplateContainer';
import NormalRequestContainer from '../Container/TrainingFormContainer/NormalRequestContainer';
import GroupRequestContainer from '../Container/TrainingFormContainer/GroupRequestContainer';
import UserManagementContainer from '../Container/UserManagementContainer/UserManagementContainer';
import AdminManageRequestContainer from '../Container/AdminManageRequestsContainer/AdminManageRequestContainer';
import ManageRequestsRoutes from '../Container/ManageRequestContainer/ManageRequestsRoutes';
import ApplicantBondContainer from '../Container/BondContainer/ApplicantBondContainer';
import ManageBondsContainer from '../Container/ManageBondsContainer/ManageBondsContainer';
import DashboardRoutes from '../Container/DashboardContainer/DashboardRoutes';
import ArchivalTrainingRequestContainer from '../Container/ArchivalTrainingRequestContainer/ArchivalTrainingRequestContainer';

const RmsRoutes = props => {
  const { path } = useRouteMatch();
  return (
    <div className="RMS_extension">
      <Switch>
        <Route exact path={`${path}`} component={LandingContainer} />
        <Route
          path={`${path}/user-management`}
          component={UserManagementContainer}
        />
        <Route
          path={`${path}/manage-template`}
          component={ManageTemplateContainer}
        />
        <Route
          path={`${path}/create-template`}
          component={CreateTemplateContainer}
        />
        <Route path={`${path}/manage-bonds`} component={ManageBondsContainer} />
        <Route
          path={`${path}/access-control`}
          component={AccessControlContainer}
        />
        <Route
          path={`${path}/normal-request`}
          component={NormalRequestContainer}
        />
        <Route
          path={`${path}/group-request`}
          render={props => <GroupRequestContainer {...props} />}
        />
        <Route
          path={`${path}/admin-manage-requests`}
          render={props => <AdminManageRequestContainer {...props} />}
        />
        <Route
          path={`${path}/super-admin-dashboard`}
          render={props => <DashboardRoutes {...props} isSuperAdminDashboard />}
        />
        <Route
          path={`${path}/manage-request`}
          render={props => <ManageRequestsRoutes {...props} />}
        />
        <Route
          path={`${path}/applicant-bond`}
          render={props => <ApplicantBondContainer {...props} />}
        />
        <Route
          path={`${path}/admin-dashboard`}
          render={props => <DashboardRoutes {...props} />}
        />
        <Route
          path={`${path}/applicant-dashboard`}
          render={props => <DashboardRoutes {...props} />}
        />
        <Route
          path={`${path}/create-bond-template`}
          component={CreateBondTemplateContainer}
        />
        <Route
          path={`${path}/edit-bond-template`}
          render={props => <CreateBondTemplateContainer isEdit {...props} />}
        />
        <Route
          path={[
            `${path}/create-group-template`,
            `${path}/edit-group-template`,
          ]}
          render={() => <CreateTemplateContainer isGroupTemplateType />}
        />
        <Route
          path={`${path}/archival-training-request`}
          component={ArchivalTrainingRequestContainer}
        />
      </Switch>
    </div>
  );
};

export default RmsRoutes;
