import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useApi from '../../../Hooks/useApi';
import { privileges, Services, TemplateTypes } from '../../Common/constants';
import { getArrayIndexUsingKey } from '../../Common/helper';
import AccessControl from '../../Components/AccessControl/AccessControl';
import {
  RMS_GET_TEMPLATES_FAILED,
  RMS_GET_TEMPLATES_REQUEST,
  RMS_GET_TEMPLATES_SUCCESS,
  RMS_GET_TEMPLATE_ADMIN_USERS_FAILED,
  RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST,
  RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS,
  RMS_GET_TEMPLATE_USERS_FAILED,
  RMS_GET_TEMPLATE_USERS_REQUEST,
  RMS_GET_TEMPLATE_USERS_RESET,
  RMS_GET_TEMPLATE_USERS_SUCCESS,
  RMS_RESET_ACCESS_CONTROL,
  RMS_SAVE_TEMPLATE_USERS_FAILED,
  RMS_SAVE_TEMPLATE_USERS_REQUEST,
  RMS_SAVE_TEMPLATE_USERS_SUCCESS,
} from '../../redux/actionTypes/accessControlTypes';
import {
  PUT_RMS_TEMPLATE_UPDATE_FAIL,
  PUT_RMS_TEMPLATE_UPDATE_REQUEST,
  PUT_RMS_TEMPLATE_UPDATE_SUCCESS,
  RESET_RMS_TEMPLATE,
} from '../../redux/actionTypes/templateTypes';
import AddTemplateUserContainer from '../AddUserContainer/AddTemplateUserContainer';

const AccessControlContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getTemplatesApi] = useApi();
  const [getTemplateUsersApi] = useApi();
  const [updateAdminsApi] = useApi();
  const [fetchRmsSuperAdmins] = useApi();
  const [putTemplate] = useApi();

  const {
    templates = [],
    templateUsers = [],
    adminSaveTaskId,
    adminSaveLoading,
    adminSaveSuccess,
    templateUsersLoading,
    templatesLoading,
  } = useSelector(state => state.rmsAccessControl);
  const {
    selectedUsers,
    createTemplateLoading,
    createTemplateSuccessLoading,
    createTemplateFailed,
    createTemplateFailMessage,
  } = useSelector(state => state.templateManagement);
  const { lastMessage } = useSelector(state => state.websocket);

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentAdminIds, setCurrentAdminIds] = useState([]);
  const [initialAdminIds, setInitialAdminIds] = useState([]);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [isTemplateTab, setIsTemplateTab] = useState(true);
  const [initialUserLoad, setInitialUserLoad] = useState(true);

  const getTemplates = () => {
    getTemplatesApi(
      `/api/v1/templates?page=1&size=100`,
      RMS_GET_TEMPLATES_REQUEST,
      RMS_GET_TEMPLATES_SUCCESS,
      RMS_GET_TEMPLATES_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };

  const setInitialStates = () => {
    setSelectedTemplateId(null);
    setSearchText('');
    setCurrentAdminIds([]);
    setInitialAdminIds([]);
    setAllSelected(false);
    setShowAddUserPopup(false);
    setSelectedUserIds([]);
    setErrorMessage('');
    setLoadingButton(false);
    setInitialUserLoad(true);
  };

  const getTemplateUsers = (userIds = []) => {
    userIds.length &&
      getTemplateUsersApi(
        `/api/v1/users?userIds=${userIds}&page=1&size=${userIds.length}`,
        RMS_GET_TEMPLATE_USERS_REQUEST,
        RMS_GET_TEMPLATE_USERS_SUCCESS,
        RMS_GET_TEMPLATE_USERS_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService
      );
  };
  const getRmsSuperAdmins = page => {
    fetchRmsSuperAdmins(
      `/api/v1/users?page=${page}&size=1000&roles=${privileges.RMS_SUPER_ADMIN}&isProfileCompleted=true`,
      RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST,
      RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS,
      RMS_GET_TEMPLATE_ADMIN_USERS_FAILED,
      {},
      {},
      'GET',
      false,
      Services.isRmsService
    );
  };
  const editTemplate = updatedData => {
    putTemplate(
      `/api/v1/${
        updatedData?.type === TemplateTypes.NORMAL_TEMPLATE
          ? 'normalTemplate'
          : 'groupTemplate'
      }`,
      PUT_RMS_TEMPLATE_UPDATE_REQUEST,
      PUT_RMS_TEMPLATE_UPDATE_SUCCESS,
      PUT_RMS_TEMPLATE_UPDATE_FAIL,
      updatedData,
      '',
      'PUT',
      null,
      'isRmsService'
    );
  };

  useEffect(() => {
    getTemplates();

    return () => {
      dispatch({ type: RMS_RESET_ACCESS_CONTROL });
      dispatch({ type: RESET_RMS_TEMPLATE });
    };
  }, []);

  useEffect(() => {
    if (selectedTemplateId) {
      setErrorMessage('');
      setSearchText('');
      dispatch({ type: RMS_GET_TEMPLATE_USERS_RESET });

      const selectedTemplate =
        templates.find(template => template._id === selectedTemplateId) || {};
      const { rmsAdminIds = [] } = selectedTemplate;

      setCurrentAdminIds(rmsAdminIds);
      setInitialAdminIds(rmsAdminIds || []);

      const userIds = [...(rmsAdminIds || [])];
      getTemplateUsers(userIds);
    }
  }, [selectedTemplateId, templates]);

  useEffect(() => {
    if (!isTemplateTab && templateUsers?.length && initialUserLoad) {
      const rmsAdminIds = templateUsers?.map(data => data?._id);
      setCurrentAdminIds(rmsAdminIds);
      setInitialAdminIds(rmsAdminIds || []);
    }
  }, [templateUsers]);

  const onDone = () => {
    setInitialUserLoad(false);
    const userIds = selectedUsers?.map(user => user._id);
    const updatedAdmins = [...(currentAdminIds || []), ...(userIds || [])];
    setCurrentAdminIds(updatedAdmins);

    dispatch({ type: RESET_RMS_TEMPLATE });
    setIsSubmitPending(true);
  };

  useEffect(() => {
    if (isSubmitPending && currentAdminIds.length) {
      onSubmit();
      setIsSubmitPending(false);
    }
  }, [isSubmitPending, currentAdminIds]);

  useEffect(() => {
    if (!adminSaveLoading && adminSaveSuccess) {
      setLoadingButton(false);
      toast.dark(
        t(
          "Your request is being processed. We will let you know once it's done"
        ),
        { autoClose: 5000 }
      );
    }
  }, [adminSaveLoading, adminSaveSuccess]);

  const toggleSelectUser = userId => {
    if (allSelected) return;
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(currentIds => currentIds.filter(id => id !== userId));
    } else {
      setSelectedUserIds(currentIds => [...currentIds, userId]);
    }
  };

  useEffect(() => {
    if (allSelected) {
      setSelectedUserIds(templateUsers.map(user => user._id));
    } else {
      setSelectedUserIds([]);
    }
  }, [allSelected]);

  const onRemove = data => {
    if (isTemplateTab) {
      setErrorMessage('');
      const index = getArrayIndexUsingKey(templates, '_id', selectedTemplateId);
      let updatedData = { ...templates?.[index] };
      const newRmsAdminIds = [...updatedData.rmsAdminIds];
      const filteredRmsAdminIds = newRmsAdminIds.filter(
        adminId => adminId !== data?._id
      );
      updatedData.rmsAdminIds = filteredRmsAdminIds;
      editTemplate(updatedData);
    } else {
      if (selectedUserIds.length) {
        setErrorMessage('');
        const updatedAdminIds = currentAdminIds?.filter(
          id => !selectedUserIds?.includes(id)
        );

        setSelectedUserIds([]);
        setAllSelected(false);
      } else {
        setErrorMessage(t('Please select some admins'));
      }
    }
  };

  const onSubmit = () => {
    if (!currentAdminIds?.length) {
      setErrorMessage(t('Please add some admins'));
      return;
    } else {
      setErrorMessage('');
    }
    let reqBody = {};

    if (isTemplateTab) {
      reqBody = {
        _id: selectedTemplateId,
        type: 'ADD_USER_TO_TEMPLATE',
        rmsAdminIds:
          currentAdminIds.filter(id => !initialAdminIds.includes(id)) || [],
      };
    } else {
      reqBody = {
        type: 'ADD_SUPER_ADMIN_TO_SYSTEM',
        rmsSuperAdminIds:
          currentAdminIds.filter(id => !initialAdminIds.includes(id)) || [],
      };
    }
    if (reqBody?.rmsAdminIds?.length) {
      updateAdminsApi(
        `/api/v1/async-requests`,
        RMS_SAVE_TEMPLATE_USERS_REQUEST,
        RMS_SAVE_TEMPLATE_USERS_SUCCESS,
        RMS_SAVE_TEMPLATE_USERS_FAILED,
        reqBody,
        {},
        'POST',
        false,
        Services.isRmsService
      );

      setLoadingButton(true);
    }
  };

  useEffect(() => {
    if (!createTemplateLoading && createTemplateSuccessLoading) {
      setSearchText('');
      setErrorMessage('');
      getTemplates();
    }
  }, [createTemplateLoading, createTemplateSuccessLoading]);

  useEffect(() => {
    if (lastMessage?.eventType === 'RMS_USER_ADDED_TO_TEMPLATE') {
      if (adminSaveTaskId === lastMessage?.data?.asyncRequestId?.[0]) {
        getTemplates();
      }
    }
  }, [lastMessage]);

  const filteredUsers = useMemo(() => {
    return templateUsers?.filter(
      user =>
        user.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.employeeId?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, templateUsers]);

  return (
    <>
      <AccessControl
        templates={templates}
        setSelectedTemplateId={setSelectedTemplateId}
        selectedTemplateId={selectedTemplateId}
        searchText={searchText}
        setSearchText={setSearchText}
        templateUsers={filteredUsers}
        defaultAdminIds={currentAdminIds}
        showAddUserPopup={() => setShowAddUserPopup(true)}
        allSelected={allSelected}
        toggleSelectAll={() => setAllSelected(current => !current)}
        toggleSelectUser={toggleSelectUser}
        selectedUserIds={selectedUserIds}
        onSubmit={onSubmit}
        onRemove={onRemove}
        loading={loadingButton || adminSaveLoading}
        errorMessage={errorMessage}
        showPageLoader={
          templateUsersLoading || createTemplateLoading || templatesLoading
        }
        clearSession={isTemplate => {
          setInitialStates();
          dispatch({ type: RMS_RESET_ACCESS_CONTROL });
          setIsTemplateTab(isTemplate);
          if (isTemplate) {
            getTemplates();
          } else {
            getRmsSuperAdmins(1);
          }
        }}
        templateDelete={onRemove}
        createTemplateFailMessage={createTemplateFailMessage}
      />
      {showAddUserPopup ? (
        <AddTemplateUserContainer
          onHandleClose={() => setShowAddUserPopup(false)}
          isTemplate={isTemplateTab}
          isAddAdmin
          onDoneClick={onDone}
          hiddenUserIdList={currentAdminIds || []}
          selectedTemplateId={selectedTemplateId}
        />
      ) : null}
    </>
  );
};

export default AccessControlContainer;
