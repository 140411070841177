import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API, Auth } from 'aws-amplify';

const useS3FileView = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const [s3path, setS3path] = useState();
  const [options, setOptions] = useState();
  const [s3Bucket, setS3Bucket] = useState();
  const [isFileDownloadStarted, setIsFileDownloadStarted] = useState(false);

  const autoDownload = async url => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${s3path}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setUrl('');
    setIsFileDownloadStarted(false);
  };

  useEffect(() => {
    if (!s3Bucket || !s3path || !options?.expireTime) return;
    let modifiedPath = s3path.charAt(0) === '/' ? s3path.substr(1) : s3path;
    Storage.configure({
      bucket: s3Bucket,
      level: 'public',
      expires: options.expireTime,
      customPrefix: {
        public: '',
      },
    });

    Storage.get(modifiedPath).then(res => {
      setUrl(res);
    });

    setTimeout(() => {
      Storage.list('')
        .then(results => {
          if (results.find(({ key }) => key === s3path)) {
            Storage.get(modifiedPath).then(res => {
              setIsFileDownloadStarted(true);
              options?.isAutoDownload && autoDownload(res);
            });
          } else {
            options?.errorAction && dispatch({ type: options?.errorAction });
            setUrl('');
            setIsFileDownloadStarted(false);
          }
        })
        .catch(err => {
          options?.errorAction && dispatch({ type: options?.errorAction });
          setUrl('');
          setIsFileDownloadStarted(false);
        });
    }, options?.timeDelay);
  }, [s3path, s3Bucket]);

  const getFromBucket = (bucket, path, options) => {
    setS3Bucket(bucket);
    setS3path(path);
    setOptions({
      expireTime: options?.expireTime || 7200,
      timeDelay: options?.timeDelay || 0,
      isAutoDownload: !!options?.isAutoDownload || false,
      errorAction: options?.errorAction || null,
    });
    setIsFileDownloadStarted(false);
  };

  return [getFromBucket, url, isFileDownloadStarted];
};

export default useS3FileView;
