import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestFormRoutes } from '../../Common/constants';
import useConvertDate from '../../hooks/useConvertDate';
import RMSPageTitle from '../CommonComponents/RMSPageTitle';

const TrainingFormPersonalDetails = ({
  onSave,
  onBack,
  userDetails,
  departments,
  designations,
  qualifications,
  centers,
  match,
}) => {
  const { t } = useTranslation();
  const { convertDateToThemeFormat } = useConvertDate();

  const {
    centerId,
    departmentId,
    designationId,
    qualificationId,
    name,
    nricNo,
    dob,
    createdAt,
    joinedDate,
    employeeId,
  } = userDetails || {};

  const generateFieldValue = (dataSet, id) => {
    return dataSet?.[id]?.name || '';
  };

  if (!match) {
    return null;
  }

  return (
    <div>
      <div className="RMS__page trainingForm form1">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Training Form" />
            <div className="RMS__page--content">
              <div className="RMS__page--header">
                <div className="RMS__page--header-row">
                  <div className="RMS__page--header-left">
                    <p>{t('Personal Details')}</p>
                  </div>
                  <div className="RMS__page--header-right">
                    <p>{t('Employee ID')}</p>
                    <div className="form--field-input">
                      <input
                        type="text"
                        placeholder={t('Employee ID')}
                        readOnly
                        defaultValue={employeeId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="RMS__page--body">
                <div className="RMS__page--body-row fourColumn">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Name')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Name')}
                          readOnly
                          defaultValue={name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('NRIC No')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('NRIC No')}
                          readOnly
                          defaultValue={nricNo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">
                        {t('Highest Qualification')}
                      </p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Highest Qualification')}
                          readOnly
                          defaultValue={generateFieldValue(
                            qualifications,
                            qualificationId
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Department Id')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Department Id')}
                          readOnly
                          defaultValue={generateFieldValue(
                            departments,
                            departmentId
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RMS__page--body-row fourColumn">
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Section Code')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Section Code')}
                          readOnly
                          defaultValue={generateFieldValue(centers, centerId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Designation')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Designation')}
                          readOnly
                          defaultValue={generateFieldValue(
                            designations,
                            designationId
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Date of Birth')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Date of Birth')}
                          readOnly
                          value={convertDateToThemeFormat(dob)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RMS__page--body-column">
                    <div className="form--field twoRows">
                      <p className="form--field-title">{t('Joined Date')}</p>
                      <div className="form--field-input">
                        <input
                          type="text"
                          placeholder={t('Joined Date')}
                          readOnly
                          value={convertDateToThemeFormat(joinedDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="RMS__page--submit overall">
              <button
                className="btn btn--secondary btn--marginRight"
                onClick={onBack}
              >
                {t('Back')}
              </button>
              <button
                className="btn btn--primary"
                onClick={() => onSave(RequestFormRoutes.Course)}
              >
                {t('Next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingFormPersonalDetails;
