import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { TemplateTypes } from '../../Common/constants';
import SelectTemplateModal from '../../Components/Modal/SelectTemplateModal';
import {
  RMS_FETCH_GROUP_TEMPLATES_FAILED,
  RMS_FETCH_GROUP_TEMPLATES_REQUEST,
  RMS_FETCH_GROUP_TEMPLATES_SUCCESS,
  RMS_SET_SELECTED_GROUP_TEMPLATE,
} from '../../redux/actionTypes/groupRequestTypes';

const SelectTemplatePopupContainer = ({ closePopup, gotoGroupRequest }) => {
  const [fetchGroupTemplatesApi] = useApi();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  const { groupTemplates, groupTemplatesLoading } = useSelector(
    state => state.rmsGroupRequest
  );

  const { landingData } = useSelector(state => state.rmsLandingData);

  const getGroupTemplate = () => {
    landingData?._id &&
      fetchGroupTemplatesApi(
        `/api/v1/templates?type=${TemplateTypes.GROUP_TEMPLATE}&page=1&size=50&adminId=${landingData._id}`,
        RMS_FETCH_GROUP_TEMPLATES_REQUEST,
        RMS_FETCH_GROUP_TEMPLATES_SUCCESS,
        RMS_FETCH_GROUP_TEMPLATES_FAILED,
        {},
        '',
        'GET',
        null,
        'isRmsService'
      );
  };

  useEffect(() => {
    getGroupTemplate();
  }, []);

  const onTemplateSelect = templateId => {
    dispatch({
      type: RMS_SET_SELECTED_GROUP_TEMPLATE,
      payload: templateId,
    });

    gotoGroupRequest();
  };

  return (
    <div>
      <SelectTemplateModal
        closePopup={closePopup}
        groupTemplates={groupTemplates}
        onTemplateSelect={onTemplateSelect}
        searchText={searchText}
        setSearchText={setSearchText}
        templatesLoading={groupTemplatesLoading}
      />
    </div>
  );
};

export default SelectTemplatePopupContainer;
