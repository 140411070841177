import React, { useEffect, useMemo, useState } from 'react';
import MandatoryMark from '../../../Components/validation/MandatoryMark';
import { Select } from 'antd';
import 'antd/dist/antd.css';

const DropdownWithPagination = ({
  Controller,
  control,
  errorMessage,
  inputClass = 'form-input',
  label,
  name,
  placeholder,
  required,
  rules,
  wrapperClass = 'reel-view--popup-body__row',
  options,
  customOnchangeAction,
  disabled,
  value,
  isRequiredIconShow = true,
  scrollCallBack,
  hideLabelText,
  /** if this is true, lable is required unless maxTagCount > 0 */
  multiple,
  showSearch = true,
  size = 'small',
  maxTagCount = 0, //if this is 0 in multiple mode, label is required
  onHandleSearch,
  onDropdownVisibleChange,
  ...rest
}) => {
  const Option = Select.Option;

  const [inputValue, setInputValue] = useState('');

  const children = useMemo(() => {
    if (!options) {
      return [];
    }
    return options.map(({ value, label }, i) => {
      return (
        <Option key={i} value={value}>
          {label}
        </Option>
      );
    });
  }, [options]);

  useEffect(() => {
    setInputValue(value === '' ? null : value);
  }, [value]);

  const handleChange = value => {
    if (Array.isArray(value)) {
      value = value.filter(v => v); //filter out null/undefined values
    }
    customOnchangeAction && customOnchangeAction(value);
    setInputValue(value);
  };

  const onScroll = event => {
    var target = event.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      scrollCallBack && scrollCallBack();
    }
  };

  const multipleModeProps = useMemo(() => {
    if (!multiple) return {};

    return {
      mode: 'multiple',
      maxTagCount: maxTagCount,
      maxTagPlaceholder: label,
    };
  }, [multiple, label]);

  const handleSearch = value => {
    onHandleSearch && onHandleSearch(value);
  };

  return (
    <div className={`${wrapperClass}  ${disabled ? 'disabled' : ''}`}>
      <label>
        {!hideLabelText && label}
        {required && isRequiredIconShow ? <MandatoryMark /> : ''}
      </label>

      {Controller ? (
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ onChange, ref }) => (
            <Select
              value={inputValue}
              className={`${inputClass} ${!hideLabelText && 'with_label'}`}
              placeholder={`${placeholder || ''}`}
              onChange={value => {
                onChange(value);
                handleChange(value);
              }}
              onPopupScroll={onScroll}
              disabled={disabled}
              bordered={false}
              showSearch
              size={size}
              {...multipleModeProps}
              allowClear
              onSearch={value => handleSearch(value)}
              notFoundContent={null}
              filterOption={false}
              onDropdownVisibleChange={open => onDropdownVisibleChange(open)}
            >
              {children}
            </Select>
          )}
        />
      ) : (
        <Select
          value={inputValue}
          className={`${inputClass} ${!hideLabelText && 'with_label'}`}
          placeholder={`${placeholder || ''}`}
          onChange={value => {
            handleChange(value);
          }}
          onPopupScroll={onScroll}
          disabled={disabled}
          bordered={false}
          showSearch={showSearch}
          size={size}
          {...multipleModeProps}
          allowClear
          onSearch={value => handleSearch(value)}
          notFoundContent={null}
          filterOption={false}
          onDropdownVisibleChange={open => onDropdownVisibleChange(open)}
        >
          {children}
        </Select>
      )}

      {errorMessage?.length && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default DropdownWithPagination;
