import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import { BudgetTypes } from '../../Common/constants';
import {
  avoidedDuplicationData,
  getArrayIndexUsingKey,
  setErrorMessage,
} from '../../Common/helper';
import {
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_BULK_SELECTED_USERS,
  GET_BOND_TEMPLATES_SUCCESS,
  GET_RMS_BOND_TEMPLATES_FAILED,
  GET_RMS_BOND_TEMPLATES_REQUEST,
  GET_RMS_BOND_TEMPLATES_SUCCESS,
  GET_RMS_BOND_TEMPLATE_BY_ID_FAILED,
  GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST,
  GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS,
  GET_RMS_EXISTING_USER_SUCCESS,
  GET_RMS_EXISTING_USER_FAILED,
  GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS,
  GET_RMS_TEMPLATE_ADMIN_SUCCESS,
  GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS,
  GET_RMS_TEMPLATE_DESIGNATION_SUCCESS,
  GET_RMS_TEMPLATE_FAIL,
  GET_RMS_TEMPLATE_REQUEST,
  GET_RMS_TEMPLATE_SUCCESS,
  GET_RMS_TEMPLATE_USERS_FAIL,
  GET_RMS_TEMPLATE_USERS_REQUEST,
  GET_RMS_TEMPLATE_USERS_SUCCESS,
  GET_RMS_USERS_DEPARTMENTS_FAIL,
  GET_RMS_USERS_DEPARTMENTS_REQUEST,
  GET_RMS_USERS_DEPARTMENTS_SUCCESS,
  GET_RMS_USERS_DESIGNATION_FAIL,
  GET_RMS_USERS_DESIGNATION_REQUEST,
  GET_RMS_USERS_DESIGNATION_SUCCESS,
  POST_BOND_TEMPLATE_FAIL,
  POST_BOND_TEMPLATE_REQUEST,
  POST_BOND_TEMPLATE_SUCCESS,
  PUT_RMS_TEMPLATE_UPDATE_FAIL,
  PUT_RMS_TEMPLATE_UPDATE_REQUEST,
  PUT_RMS_TEMPLATE_UPDATE_SUCCESS,
  RESET_RMS_SELECTED_BOND_FOR_TEMPLATE,
  RESET_RMS_TEMPLATE,
  RESET_TEMPLATE_USERS,
  RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED,
  RMS_TEMPLATE_USER_FETCH_LOADING,
  SELECTED_BULK_BUDGET_USERS,
  SET_ALL_BULK_BUDGET_USERS,
  SET_BULK_BUDGET_VALUES,
  SET_INDIVIDUAL_BUDGET_LIMIT,
  SET_RMS_ADDED_TEMPLATE_USERS,
  SET_RMS_EDIT_USER_DATA,
  TOGGLE_RMS_TEMPLATE_TYPE,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  CLOSE_DELETE_TEMPLATE_ERROR_MSG,
  RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE,
} from '../actionTypes/templateTypes';

const InitialState = {
  templateData: null,
  templateLoading: false,
  templateTotalRecords: 0,
  templatePage: 1,
  isDeleteTemplateFail: false,
  designations: null,
  departments: null,
  templateUsers: null,
  templateUsersPage: 1,
  templateUsersTotalRecords: 0,
  templateUserLoading: false,
  usersDepartments: null,
  usersDepartmentsLoading: false,
  usersDesignations: null,
  usersDesignationsLoading: false,
  selectedUsers: [],
  selectedBudgetUsers: [],
  createTemplateLoading: false,
  createTemplateSuccessLoading: false,
  createTemplateFailed: false,
  createTemplateFailMessage: '',
  bondTemplates: null,
  postBondTemplateLoading: false,
  postBondTemplateSuccess: false,
  postBondTemplateFailMessage: '',
  bondTemplatesLoading: false,
  selectedBond: null,
  selectedBondLoading: false,
  templateAdmin: null,
  editUserBudget: null,
  isGroupTypeEditUserBudget: false,
  deleteTemplateLoading: false,
  deleteTemplateSuccess: false,
  bondAttachedTemplates: [],
};

const templateReducer = (state = InitialState, action) => {
  const {
    data = null,
    page = 1,
    totalRecords = 0,
    type = false,
  } = action.payload || {};
  const customInput = action?.customInput || '';

  switch (action.type) {
    case GET_RMS_TEMPLATE_REQUEST:
      return {
        ...state,
        templateLoading: true,
        deleteTemplateSuccess: false,
      };
    case GET_RMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateData: state?.templateData
          ? avoidedDuplicationData(state?.templateData, data || null, '_id')
          : data || null,
        templateLoading: false,
        templateTotalRecords: totalRecords || state.templateTotalRecords,
        templatePage: page || state.templatePage,
      };
    case GET_RMS_TEMPLATE_FAIL:
      return {
        ...state,
        templateLoading: false,
      };
    case TOGGLE_RMS_TEMPLATE_TYPE:
      return {
        ...state,
        templateData: null,
        templateLoading: false,
        templateTotalRecords: 0,
        templatePage: 1,
        bondTemplates: null,
        selectedBond: null,
      };
    case GET_RMS_TEMPLATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        designations: data || null,
      };
    case GET_RMS_TEMPLATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: data || null,
      };
    case GET_RMS_TEMPLATE_USERS_REQUEST:
      return {
        ...state,
        templateUserLoading: true,
        usersDepartmentsLoading: true,
        usersDesignationsLoading: true,
      };
    case GET_RMS_TEMPLATE_USERS_SUCCESS:
      return {
        ...state,
        templateUsers: state?.templateUsers
          ? avoidedDuplicationData(state?.templateUsers, data || null, '_id')
          : data || null,
        templateUserLoading: false,
        templateUsersPage: page || state.templateUsersPage,
        templateUsersTotalRecords:
          totalRecords || state.templateUsersTotalRecords,
        usersDepartmentsLoading: !!data,
        usersDesignationsLoading: !!data,
      };
    case GET_RMS_TEMPLATE_USERS_FAIL:
      return {
        ...state,
        templateUserLoading: false,
        usersDepartmentsLoading: false,
        usersDesignationsLoading: false,
      };
    case GET_RMS_USERS_DEPARTMENTS_REQUEST:
      return {
        ...state,
        usersDepartmentsLoading: true,
      };
    case GET_RMS_USERS_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        usersDepartments: state?.usersDepartments
          ? avoidedDuplicationData(state?.usersDepartments, data || null, '_id')
          : data || null,
        usersDepartmentsLoading: false,
      };
    case GET_RMS_USERS_DEPARTMENTS_FAIL:
      return {
        ...state,
        usersDepartmentsLoading: false,
      };
    case GET_RMS_USERS_DESIGNATION_REQUEST:
      return {
        ...state,
        usersDesignationsLoading: true,
      };
    case GET_RMS_USERS_DESIGNATION_SUCCESS:
      return {
        ...state,
        usersDesignations: state?.usersDesignations
          ? avoidedDuplicationData(
              state?.usersDesignations,
              data || null,
              '_id'
            )
          : data || null,
        usersDesignationsLoading: false,
      };
    case GET_RMS_USERS_DESIGNATION_FAIL:
      return {
        ...state,
        usersDesignationsLoading: false,
      };
    case SET_RMS_ADDED_TEMPLATE_USERS:
      return {
        ...state,
        selectedUsers: setSelectedUsers(customInput, state?.selectedUsers),
        selectedBudgetUsers: removeFromSelectedUsers(
          customInput,
          state?.selectedBudgetUsers
        ),
      };
    case RMS_SELECT_ALL_TEMPLATE_USERS_CLICKED:
      return {
        ...state,
        selectedUsers: customInput ? state?.templateUsers : [],
      };
    case RMS_SELECTED_USERS_RESET_BACK_ON_CLOSE:
      return {
        ...state,
        selectedUsers: action?.payload || [],
      };
    case RMS_TEMPLATE_USER_FETCH_LOADING:
      return {
        ...state,
        usersDesignationsLoading: true,
        usersDepartmentsLoading: true,
        templateUserLoading: true,
      };
    case SET_INDIVIDUAL_BUDGET_LIMIT:
      return {
        ...state,
        selectedUsers: setBudgetValues(state?.selectedUsers, customInput),
      };
    case SELECTED_BULK_BUDGET_USERS:
      return {
        ...state,
        selectedBudgetUsers: selectedBudgetUsers(
          customInput,
          state.selectedBudgetUsers
        ),
      };
    case SET_ALL_BULK_BUDGET_USERS:
      return {
        ...state,
        selectedBudgetUsers: customInput
          ? state?.selectedUsers.map(data => data?.email)
          : [],
      };
    case SET_BULK_BUDGET_VALUES:
      return {
        ...state,
        selectedUsers: setBulkBudgetValues(
          state?.selectedUsers,
          customInput,
          state?.selectedBudgetUsers
        ),
      };
    case DELETE_BULK_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: deleteSelectedUsers(
          state?.selectedUsers,
          state?.selectedBudgetUsers
        ),
        selectedBudgetUsers: [],
      };
    }
    case CREATE_TEMPLATE_REQUEST: {
      return {
        ...state,
        createTemplateLoading: true,
        createTemplateFailed: false,
        createTemplateFailMessage: '',
      };
    }
    case PUT_RMS_TEMPLATE_UPDATE_REQUEST: {
      return {
        ...state,
        createTemplateLoading: true,
        createTemplateFailed: false,
        createTemplateFailMessage: '',
        createTemplateSuccessLoading: false,
      };
    }

    case CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        createTemplateLoading: false,
        createTemplateSuccessLoading: true,
      };
    }
    case PUT_RMS_TEMPLATE_UPDATE_SUCCESS: {
      return {
        ...state,
        createTemplateLoading: false,
        createTemplateSuccessLoading: true,
      };
    }
    case CREATE_TEMPLATE_FAIL: {
      return {
        ...state,
        createTemplateLoading: false,
        createTemplateFailed: true,
        createTemplateFailMessage: setErrorMessage(action?.payload),
      };
    }
    case PUT_RMS_TEMPLATE_UPDATE_FAIL: {
      return {
        ...state,
        createTemplateLoading: false,
        createTemplateFailed: true,
        createTemplateFailMessage: setErrorMessage(action?.payload),
      };
    }
    case POST_BOND_TEMPLATE_REQUEST: {
      return {
        ...state,
        postBondTemplateLoading: true,
        postBondTemplateFailMessage: '',
      };
    }
    case POST_BOND_TEMPLATE_SUCCESS: {
      return {
        ...state,
        postBondTemplateLoading: false,
        postBondTemplateSuccess: true,
      };
    }
    case POST_BOND_TEMPLATE_FAIL: {
      return {
        ...state,
        postBondTemplateLoading: false,
        postBondTemplateFailMessage: setErrorMessage(action?.payload),
      };
    }
    case GET_BOND_TEMPLATES_SUCCESS: {
      return {
        ...state,
        bondTemplates: data || null,
      };
    }

    case RESET_TEMPLATE_USERS: {
      return {
        ...state,
        templateUsers: null,
        templateUserLoading: false,
        usersDesignationsLoading: false,
        usersDepartmentsLoading: false,
      };
    }
    case GET_RMS_BOND_TEMPLATES_REQUEST:
      return {
        ...state,
        bondTemplatesLoading: true,
      };
    case GET_RMS_BOND_TEMPLATES_SUCCESS:
      return {
        ...state,
        bondTemplatesLoading: false,
        bondTemplates: data,
      };
    case GET_RMS_BOND_TEMPLATES_FAILED:
      return {
        ...state,
        bondTemplatesLoading: false,
      };

    case GET_RMS_BOND_TEMPLATE_BY_ID_REQUEST:
      return {
        ...state,
        selectedBond: null,
        selectedBondLoading: true,
      };

    case GET_RMS_BOND_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        selectedBond: action?.payload,
        selectedBondLoading: false,
      };

    case GET_RMS_BOND_TEMPLATE_BY_ID_FAILED:
      return {
        ...state,
        selectedBondLoading: false,
      };
    case GET_RMS_TEMPLATE_ADMIN_SUCCESS:
      return {
        ...state,
        templateAdmin: data || null,
      };

    case GET_RMS_EXISTING_USER_SUCCESS:
      return {
        ...state,
        selectedUsers: arrangeBudgetForUsers(
          state?.editUserBudget,
          state?.isGroupTypeEditUserBudget,
          data
        ),
        editUserBudget: null,
        isGroupTypeEditUserBudget: false,
      };
    case GET_RMS_EXISTING_USER_FAILED:
      return {
        ...state,
        selectedUsers: [],
      };

    case GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS:
      return {
        ...state,
        bondAttachedTemplates: data,
      };

    case RESET_RMS_SELECTED_BOND_FOR_TEMPLATE:
      return {
        ...state,
        selectedBond: null,
      };

    case RESET_RMS_TEMPLATE:
      return {
        ...InitialState,
        selectedUsers: [],
        selectedBudgetUsers: [],
        selectedBond: state.selectedBond, //making sure this value stays when redirecting to edit page
      };

    case SET_RMS_EDIT_USER_DATA:
      return {
        ...state,
        editUserBudget: data || null,
        isGroupTypeEditUserBudget: type || false,
      };

    case DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteTemplateLoading: true,
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteTemplateLoading: false,
        deleteTemplateSuccess: true,
      };
    case DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        deleteTemplateLoading: false,
        isDeleteTemplateFail: true,
      };
    case CLOSE_DELETE_TEMPLATE_ERROR_MSG:
      return {
        ...state,
        isDeleteTemplateFail: false,
      };

    case CLEAR_SESSION:
      return InitialState;

    default:
      return state;
  }
};
const setSelectedUsers = (selectedUser, currentData) => {
  const index = getArrayIndexUsingKey(
    currentData,
    'email',
    selectedUser?.email
  );
  if (index > -1) {
    currentData.splice(index, 1);
  } else {
    currentData.push(selectedUser);
  }
  return currentData || [];
};

const removeFromSelectedUsers = (selectedUser, currentData) => {
  let updatedData = [...currentData];
  const index = updatedData?.indexOf(selectedUser?.email || '');
  if (index > -1) {
    updatedData.splice(index, 1);
  }
  return updatedData || [];
};

const selectedBudgetUsers = (selectedUser, currentData) => {
  const index = currentData?.indexOf(selectedUser?.email);
  if (index > -1) {
    currentData.splice(index, 1);
  } else {
    currentData.push(selectedUser?.email);
  }
  return currentData || [];
};
const setBudgetValues = (currentData, customInput) => {
  const index = getArrayIndexUsingKey(currentData, 'email', customInput?.email);
  BudgetTypes.generalBudget === customInput?.type
    ? (currentData[index].generalBudgetLimit = customInput?.value)
    : (currentData[index].professionalBudgetLimit = customInput?.value);
  return currentData;
};

const setBulkBudgetValues = (currentData, customInput, selectedBudgetUsers) => {
  currentData.map(data => {
    if (selectedBudgetUsers?.includes(data?.email)) {
      const index = getArrayIndexUsingKey(currentData, 'email', data?.email);
      if (customInput?.budgetType === BudgetTypes.generalBudget) {
        currentData[index].generalBudgetLimit = customInput?.value;
      } else {
        currentData[index].professionalBudgetLimit = customInput?.value;
      }
    }
  });
  return currentData;
};
const deleteSelectedUsers = (currentData, selectedUsers) => {
  const removedUsers = currentData?.filter(
    data => !selectedUsers?.includes(data?.email)
  );
  return removedUsers || [];
};

const arrangeBudgetForUsers = (budgetData, isGroupTypeBudgetType, userData) => {
  const updatedUserData = userData.map(data => {
    const index = getArrayIndexUsingKey(budgetData, 'userId', data._id);
    if (!isGroupTypeBudgetType) {
      return {
        ...data,
        generalBudgetLimit: budgetData[index].generalBudgetLimit,
        professionalBudgetLimit: budgetData[index].professionalBudgetLimit,
      };
    } else {
      return {
        ...data,
        generalBudgetLimit: budgetData[index].groupBudgetLimit,
      };
    }
  });
  return updatedUserData;
};

export default templateReducer;
