import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';

const ArchivalTrainingRequestComponent = ({
  archivalTrainingRequests,
  lording,
  loadNextRequestPage,
  archivalTrainingRequestS3FileObjectFailed,
  exportArchivalTrainingRequests,
  url,
  isFileDownloadStarted,
}) => {
  const { t } = useTranslation();
  return (
    <div className="RMS__page userManagement archivalTrainingRequest">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>{t('Archival Records')}</h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left ">
                  <p>{t('Records')}</p>
                </div>
                <div className="RMS__page--header-right">
                  <button
                    className={`btn btn--primary  btn--marginLeft ${
                      url && !isFileDownloadStarted
                        ? `btn--loader`
                        : !archivalTrainingRequestS3FileObjectFailed &&
                          ` btn--export`
                    }`}
                    disabled={!archivalTrainingRequests?.length}
                    onClick={exportArchivalTrainingRequests}
                  >
                    {archivalTrainingRequestS3FileObjectFailed
                      ? t('Error! Try again.')
                      : t('Export')}
                  </button>
                </div>
              </div>
            </div>
            <div className="RMS__page--table user1 archiveUserManagementTable">
              <div className="RMS__page--table-header">
                <div className="RMS__page--table-column">
                  <h4>{t('Employee ID')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Department Id')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Department')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Section Code')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Section')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Designation')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Course')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Institution')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Course Start Date')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Course End Date')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Course Free')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Subsidy Amount')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Balance Amount')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Duration In Days')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Duration In Hours')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Company Name')}</h4>
                </div>
              </div>
              <div className="RMS__page--table-body">
                {archivalTrainingRequests?.length ? (
                  archivalTrainingRequests?.map(
                    ({
                      employeeCode,
                      employeeName,
                      departmentCode,
                      department,
                      sectionCode,
                      section,
                      designation,
                      course,
                      institution,
                      courseStartDate,
                      courseEndDate,
                      courseFree,
                      subsidyAmount,
                      balanceAmount,
                      durationInDays,
                      durationInHours,
                      companyName,
                    }) => (
                      <div className="RMS__page--table-row">
                        <div className="RMS__page--table-column">
                          <p>{employeeCode || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{employeeName || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{departmentCode || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{department || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{sectionCode || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{section || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{designation || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{course || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{institution || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{courseStartDate || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{courseEndDate || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{courseFree?.toString() || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{subsidyAmount?.toString() || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{balanceAmount?.toString() || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{durationInDays?.toString() || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{durationInHours?.toString() || '-'}</p>
                        </div>
                        <div className="RMS__page--table-column">
                          <p>{companyName || '-'}</p>
                        </div>
                      </div>
                    )
                  )
                ) : lording ? (
                  <Loader />
                ) : (
                  <NoContent message={t('No Content')} />
                )}
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible && archivalTrainingRequests?.length) {
                      loadNextRequestPage();
                    }
                  }}
                >
                  <div>&nbsp;</div>
                </ReactVisibilitySensor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivalTrainingRequestComponent;
