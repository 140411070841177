import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactDropdown from 'react-dropdown';
import {
  bondsFilterByOptions,
  BondsSearchDropdownOptions,
} from '../../Common/constants';

const TableHeader = ({
  setSelectedFilterDropdown,
  selectedFilterDropdown,
  selectedSearchDropdown,
  setselectedSearchDropdown,
  setMainTableSearchText,
  mainTableSearchText,
}) => {
  const { t } = useTranslation();

  const filterByOptions = [
    { label: t('Active'), value: bondsFilterByOptions.ACTIVE },
    { label: t('Inactive'), value: bondsFilterByOptions.INACTIVE },
    { label: t('Completed'), value: bondsFilterByOptions.COMPLETED },
    { label: t('All'), value: bondsFilterByOptions.ALL },
  ];

  const searchDropdownOptions = [
    { label: t('Request ID'), value: BondsSearchDropdownOptions.REQUESTID },
    { label: t('Name'), value: BondsSearchDropdownOptions.NAME },
    { label: t('Email'), value: BondsSearchDropdownOptions.EMAIL },
  ];

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Bonds')}</p>
          </div>
          <div className="RMS__page--header-right ">
            <p>{t('Filter')}</p>
            <ReactDropdown
              options={filterByOptions}
              placeholder={t('Filter By')}
              value={selectedFilterDropdown}
              onChange={option => setSelectedFilterDropdown(option.value)}
            />
          </div>
        </div>
        <div className="RMS__page--header-row underline">
          <div className="RMS__page--header-left">
            <input
              type="text"
              placeholder={t('Search')}
              className={`form-input form-input--search  `}
              value={mainTableSearchText}
              onChange={e => {
                setMainTableSearchText(e.target.value);
              }}
            />
          </div>
          <div className="RMS__page--header-right">
            <ReactDropdown
              options={searchDropdownOptions}
              value={selectedSearchDropdown}
              onChange={option => {
                setselectedSearchDropdown(option.value);
              }}
              placeholder={t("Select")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
