export const SEARCH_RMS_GROUP_USERS_REQUEST = 'SEARCH_RMS_GROUP_USERS_REQUEST';
export const SEARCH_RMS_GROUP_USERS_SUCCESS = 'SEARCH_RMS_GROUP_USERS_SUCCESS';
export const SEARCH_RMS_GROUP_USERS_FAILED = 'SEARCH_RMS_GROUP_USERS_FAILED';

export const SEARCH_RMS_GROUP_USERS_RESET = 'SEARCH_RMS_GROUP_USERS_RESET';

export const GET_RMS_GROUP_USER_QUALIFICATIONS_REQUEST =
  'GET_RMS_GROUP_USER_QUALIFICATIONS_REQUEST';
export const GET_RMS_GROUP_USER_QUALIFICATIONS_SUCCESS =
  'GET_RMS_GROUP_USER_QUALIFICATIONS_SUCCESS';
export const GET_RMS_GROUP_USER_QUALIFICATIONS_FAILED =
  'GET_RMS_GROUP_USER_QUALIFICATIONS_FAILED';

export const GET_RMS_GROUP_CENTER_DETAILS_REQUEST =
  'GET_RMS_GROUP_CENTER_DETAILS_REQUEST';
export const GET_RMS_GROUP_CENTER_DETAILS_SUCCESS =
  'GET_RMS_GROUP_CENTER_DETAILS_SUCCESS';
export const GET_RMS_GROUP_CENTER_DETAILS_FAILED =
  'GET_RMS_GROUP_CENTER_DETAILS_FAILED';

export const GET_RMS_GROUP_CENTER_REQUEST = 'GET_RMS_GROUP_CENTER_REQUEST';
export const GET_RMS_GROUP_CENTER_SUCCESS = 'GET_RMS_GROUP_CENTER_SUCCESS';
export const GET_RMS_GROUP_CENTER_FAILED = 'GET_RMS_GROUP_CENTER_FAILED';

export const GET_RMS_GROUP_DEPARTMENT_REQUEST =
  'GET_RMS_GROUP_DEPARTMENT_REQUEST';
export const GET_RMS_GROUP_DEPARTMENT_SUCCESS =
  'GET_RMS_GROUP_DEPARTMENT_SUCCESS';
export const GET_RMS_GROUP_DEPARTMENT_FAILED =
  'GET_RMS_GROUP_DEPARTMENT_FAILED';

export const GET_RMS_GROUP_DESIGNATION_REQUEST =
  'GET_RMS_GROUP_DESIGNATION_REQUEST';
export const GET_RMS_GROUP_DESIGNATION_SUCCESS =
  'GET_RMS_GROUP_DESIGNATION_SUCCESS';
export const GET_RMS_GROUP_DESIGNATION_FAILED =
  'GET_RMS_GROUP_DESIGNATION_FAILED';

export const GET_RMS_GROUP_QUALIFICATION_REQUEST =
  'GET_RMS_GROUP_QUALIFICATION_REQUEST';
export const GET_RMS_GROUP_QUALIFICATION_SUCCESS =
  'GET_RMS_GROUP_QUALIFICATION_SUCCESS';
export const GET_RMS_GROUP_QUALIFICATION_FAILED =
  'GET_RMS_GROUP_QUALIFICATION_FAILED';

export const GET_RMS_GROUP_DEPARTMENT_DETAILS_REQUEST =
  'GET_RMS_GROUP_DEPARTMENT_DETAILS_REQUEST';
export const GET_RMS_GROUP_DEPARTMENT_DETAILS_SUCCESS =
  'GET_RMS_GROUP_DEPARTMENT_DETAILS_SUCCESS';
export const GET_RMS_GROUP_DEPARTMENT_DETAILS_FAILED =
  'GET_RMS_GROUP_DEPARTMENT_DETAILS_FAILED';

export const GET_RMS_GROUP_DESIGNATION_DETAILS_REQUEST =
  'GET_RMS_GROUP_DESIGNATION_DETAILS_REQUEST';
export const GET_RMS_GROUP_DESIGNATION_DETAILS_SUCCESS =
  'GET_RMS_GROUP_DESIGNATION_DETAILS_SUCCESS';
export const GET_RMS_GROUP_DESIGNATION_DETAILS_FAILED =
  'GET_RMS_GROUP_DESIGNATION_DETAILS_FAILED';

export const RMS_GROUP_USER_MANAGEMENT_REQUEST =
  'RMS_GROUP_USER_MANAGEMENT_REQUEST';
export const RMS_GROUP_USER_MANAGEMENT_SUCCESS =
  'RMS_GROUP_USER_MANAGEMENT_SUCCESS';
export const RMS_GROUP_USER_MANAGEMENT_FAIL = 'RMS_GROUP_USER_MANAGEMENT_FAIL';

export const REMOVE_GROUP_SAVED_APPLICANT = 'REMOVE_GROUP_SAVED_APPLICANT';

export const RMS_GROUP_SAVE_SELECTED_USERS = 'RMS_GROUP_SAVE_SELECTED_USERS';

export const RMS_GROUP_FETCH_ALL_USER_REQUEST =
  'RMS_GROUP_FETCH_ALL_USER_REQUEST';
export const RMS_GROUP_FETCH_ALL_USER_SUCCESS =
  'RMS_GROUP_FETCH_ALL_USER_SUCCESS';
export const RMS_GROUP_FETCH_ALL_USER_FAIL = 'RMS_GROUP_FETCH_ALL_USER_FAIL';

export const RMS_GROUP_POST_ADDED_USERS_REQUEST =
  'RMS_GROUP_POST_ADDED_USERS_REQUEST';
export const RMS_GROUP_POST_ADDED_USERS_SUCCESS =
  'RMS_GROUP_POST_ADDED_USERS_SUCCESS';
export const RMS_GROUP_POST_ADDED_USERS_FAIL =
  'RMS_GROUP_POST_ADDED_USERS_FAIL';

export const RESET_GROUP_ADD_USER_POPUP_DATA =
  'RESET_GROUP_ADD_USER_POPUP_DATA';

export const RMS_GROUP_SEARCH_LOADING_SET = 'RMS_GROUP_SEARCH_LOADING_SET';

export const RMS_GROUP_ADD_REMOVE_SELECTED_USERS =
  'RMS_GROUP_ADD_REMOVE_SELECTED_USERS';

export const RMS_GROUP_UPDATE_USER_REQUEST = 'RMS_GROUP_UPDATE_USER_REQUEST';
export const RMS_GROUP_UPDATE_USER_SUCCESS = 'RMS_GROUP_UPDATE_USER_SUCCESS';
export const RMS_GROUP_UPDATE_USER_FAILED = 'RMS_GROUP_UPDATE_USER_FAILED';

export const RMS_GROUP_RESET_USER_UPDATE = 'RMS_GROUP_RESET_USER_UPDATE';

export const RMS_GROUP_RESET_USER_MANAGEMENT =
  'RMS_GROUP_RESET_USER_MANAGEMENT';

export const POST_RMS_GROUP_REQUEST_REQUEST = 'POST_RMS_GROUP_REQUEST_REQUEST';
export const POST_RMS_GROUP_REQUEST_SUCCESS = 'POST_RMS_GROUP_REQUEST_SUCCESS';
export const POST_RMS_GROUP_REQUEST_FAILED = 'POST_RMS_GROUP_REQUEST_FAILED';

export const RMS_FETCH_GROUP_TEMPLATES_REQUEST =
  'RMS_FETCH_GROUP_TEMPLATES_REQUEST';
export const RMS_FETCH_GROUP_TEMPLATES_SUCCESS =
  'RMS_FETCH_GROUP_TEMPLATES_SUCCESS';
export const RMS_FETCH_GROUP_TEMPLATES_FAILED =
  'RMS_FETCH_GROUP_TEMPLATES_FAILED';

export const RMS_SET_SELECTED_GROUP_TEMPLATE =
  'RMS_SET_SELECTED_GROUP_TEMPLATE';

export const RMS_GET_USER_BUDGET_DETAILS_REQUEST =
  'RMS_GET_USER_BUDGET_DETAILS_REQUEST';
export const RMS_GET_USER_BUDGET_DETAILS_SUCCESS =
  'RMS_GET_USER_BUDGET_DETAILS_SUCCESS';
export const RMS_GET_USER_BUDGET_DETAILS_FAILED =
  'RMS_GET_USER_BUDGET_DETAILS_FAILED';

export const RMS_UPDATE_GROUP_GROSS_FEE = 'RMS_UPDATE_GROUP_GROSS_FEE';

export const GET_RMS_GROUP_BOND_DATA_REQUEST =
  'GET_RMS_GROUP_BOND_DATA_REQUEST';
export const GET_RMS_GROUP_BOND_DATA_SUCCESS =
  'GET_RMS_GROUP_BOND_DATA_SUCCESS';
export const GET_RMS_GROUP_BOND_DATA_FAILED = 'GET_RMS_GROUP_BOND_DATA_FAILED';
