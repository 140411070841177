import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import {
  GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS,
  GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS,
  SEARCH_RMS_CONVERTID_USERS_RESET,
  SEARCH_RMS_CONVERTID_USERS_SUCCESS,
  GET_RMS_CONVERTID_CENTER_SUCCESS,
  GET_RMS_CONVERTID_DEPARTMENT_SUCCESS,
  GET_RMS_CONVERTID_DESIGNATION_SUCCESS,
  RESET_ADD_USER_POPUP_DATA,
  RMS_CONVERTID_ADD_REMOVE_SELECTED_USERS,
  RMS_CONVERTID_EXISTING_USER_MANAGEMENT_FAIL,
  RMS_CONVERTID_EXISTING_USER_MANAGEMENT_REQUEST,
  RMS_CONVERTID_EXISTING_USER_MANAGEMENT_SUCCESS,
  RMS_CONVERTID_POST_ADDED_USERS_REQUEST,
  RMS_CONVERTID_POST_ADDED_USERS_SUCCESS,
  RMS_CONVERTID_SEARCH_LOADING_SET,
  RMS_CONVERTID_USER_MANAGEMENT_FAIL,
  RMS_CONVERTID_USER_MANAGEMENT_REQUEST,
  RMS_CONVERTID_USER_MANAGEMENT_SUCCESS,
  GET_RMS_CONVERTID_QUALIFICATION_SUCCESS,
  RMS_CONVERTID_UPDATE_USER_REQUEST,
  RMS_CONVERTID_UPDATE_USER_SUCCESS,
  RMS_CONVERTID_UPDATE_USER_FAILED,
  RMS_CONVERTID_RESET_USER_UPDATE,
  RMS_CONVERTID_RESET_USER_MANAGEMENT,
} from '../actionTypes/convertIdTypes';

const INITIAL_STATE = {
  allCenters: [],
  allDepartments: [],
  allDesignations: [],
  allQualifications: [],
  centers: [],
  departments: [],
  designations: [],
  existUsersLoading: false,
  existingUsers: null,
  qualifications: {},
  saveLoading: false,
  selectedUsers: [],
  successClose: false,
  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: false,
  userData: null,
  userLoading: false,
  users: [],
  usersPage: 0,
  usersTotalRecords: 0,
};

const userManagementReducer = (state = INITIAL_STATE, action) => {
  const {
    data = [],
    page = 0,
    size = 0,
    totalRecords = 0,
  } = action.payload || {};
  switch (action.type) {
    case SEARCH_RMS_CONVERTID_USERS_RESET:
      return {
        ...state,
        users: [],
        usersPage: 0,
        usersTotalRecords: 0,
      };
    case SEARCH_RMS_CONVERTID_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...data],
        usersPage: page || state.usersPage,
        usersTotalRecords: totalRecords || state.totalRecords,
      };

    case GET_RMS_CONVERTID_USER_QUALIFICATIONS_SUCCESS: {
      const updatedQualifications = { ...state.qualifications };
      if (data?.length) {
        data.forEach(qualification => {
          updatedQualifications[qualification._id] = qualification;
        });
      }
      return {
        ...state,
        qualifications: updatedQualifications,
      };
    }
    case GET_RMS_CONVERTID_CENTER_DETAILS_SUCCESS: {
      const updatedCenters = { ...state.centers };
      if (data?.length) {
        data.forEach(center => {
          updatedCenters[center._id] = center;
        });
      }
      return {
        ...state,
        centers: updatedCenters,
      };
    }
    case GET_RMS_CONVERTID_CENTER_SUCCESS: {
      return {
        ...state,
        allCenters: data || [],
      };
    }
    case GET_RMS_CONVERTID_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        allDepartments: data || [],
      };
    }
    case GET_RMS_CONVERTID_DESIGNATION_SUCCESS: {
      return {
        ...state,
        allDesignations: data || [],
      };
    }
    case GET_RMS_CONVERTID_QUALIFICATION_SUCCESS: {
      return {
        ...state,
        allQualifications: data || [],
      };
    }
    case GET_RMS_CONVERTID_DEPARTMENT_DETAILS_SUCCESS: {
      const updatedDepartments = { ...state.departments };
      if (data?.length) {
        data.forEach(department => {
          updatedDepartments[department._id] = department;
        });
      }
      return {
        ...state,
        departments: updatedDepartments,
      };
    }
    case GET_RMS_CONVERTID_DESIGNATION_DETAILS_SUCCESS: {
      const updatedDesignations = { ...state.designations };
      if (data?.length) {
        data.forEach(designation => {
          updatedDesignations[designation._id] = designation;
        });
      }
      return {
        ...state,
        designations: updatedDesignations,
      };
    }
    case RMS_CONVERTID_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        userLoading: true,
        existUsersLoading: true,
      };
    case RMS_CONVERTID_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userData: action?.payload?.data || null,
        existUsersLoading: !!action?.payload?.data,
      };
    case RMS_CONVERTID_USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userLoading: false,
        existUsersLoading: false,
      };
    case RMS_CONVERTID_EXISTING_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        existUsersLoading: true,
      };
    case RMS_CONVERTID_EXISTING_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        existingUsers: getExistingUsersEmail(action?.payload?.data || null),
        existUsersLoading: false,
      };
    case RMS_CONVERTID_EXISTING_USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userLoading: false,
        existUsersLoading: false,
      };
    case RMS_CONVERTID_SEARCH_LOADING_SET:
      return {
        ...state,
        userLoading: true,
        existUsersLoading: true,
      };
    case RMS_CONVERTID_ADD_REMOVE_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: setSelectedUsers(action?.payload, state.selectedUsers),
      };
    case RMS_CONVERTID_POST_ADDED_USERS_REQUEST: {
      return {
        ...state,
        saveLoading: true,
      };
    }
    case RMS_CONVERTID_POST_ADDED_USERS_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        successClose: true,
      };
    }
    case RESET_ADD_USER_POPUP_DATA:
      return {
        ...state,
        userLoading: false,
        userData: null,
        existingUsers: null,
        existUsersLoading: false,
        selectedUsers: [],
        saveLoading: false,
        successClose: false,
      };

    case RMS_CONVERTID_UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserError: false,
      };
    case RMS_CONVERTID_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: true,
      };
    case RMS_CONVERTID_UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: true,
      };

    case RMS_CONVERTID_RESET_USER_UPDATE:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: false,
      };

    case RMS_CONVERTID_RESET_USER_MANAGEMENT:
    case CLEAR_SESSION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

const getExistingUsersEmail = existUsers => {
  let newExistUsers = null;
  if (existUsers?.length) {
    newExistUsers = existUsers.map(data => data?.email);
  }
  return newExistUsers;
};
const setSelectedUsers = (selectedUser, currentData) => {
  const index = currentData?.indexOf(selectedUser);
  if (index > -1) {
    currentData.splice(index, 1);
  } else {
    currentData.push(selectedUser);
  }
  return currentData || [];
};
export default userManagementReducer;
