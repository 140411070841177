import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import useConvertDate from '../../../hooks/useConvertDate';
import NoContent from '../../../../Components/NoContent/NoContent';
import DottedLoader from '../../../../Components/Loader/DottedLoader';
import { capitalizeFirstLetter } from '../../../Common/helper';
import { convertDuration } from '../../../Common/helper';
import { DurationUnits } from '../../../Common/constants';

const Bonds = ({ bonds, bondsLoading, loadNextBondPage }) => {
  const { t } = useTranslation();
  const { convertDateISOToString } = useConvertDate();

  const getFormattedRequestType = requestType => {
    return capitalizeFirstLetter(requestType)?.split('_')?.[0];
  };

  const getBondDurationInMonths = durationInDays => {
    return convertDuration(
      durationInDays,
      DurationUnits.DAYS,
      DurationUnits.MONTHS
    );
  };

  return (
    <div>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Bonds')}</p>
          </div>
        </div>
      </div>
      <div className="RMS__page--body">
        <div className={`RMS__page--table `}>
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('Normal/Group')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Template')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Bond Duration In Months')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('Start Date')}</h4>
            </div>

            <div className="RMS__page--table-column">
              <h4>{t('End Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Bond Status')}</h4>
            </div>
          </div>
          <div className="RMS__page--table-body">
            {!bonds?.length &&
              (bondsLoading ? <DottedLoader /> : <NoContent />)}
            {bonds?.map(
              ({
                requestId,
                requestType,
                bondTemplateName,
                bondDurationInDays,
                startDate,
                endDate,
                bondStatus,
              }) => (
                <>
                  <div className="RMS__page--table-row " key={requestId}>
                    <div className="RMS__page--table-column">
                      <p>{requestId || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{getFormattedRequestType(requestType) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{bondTemplateName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{`${
                        bondDurationInDays
                          ? getBondDurationInMonths(bondDurationInDays)
                          : 0
                      }`}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(startDate) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(endDate) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{capitalizeFirstLetter(bondStatus) || '-'}</p>
                    </div>
                  </div>
                </>
              )
            )}
            <ReactVisibilitySensor
              partialVisibility
              onChange={isVisible =>
                isVisible && bonds?.length && loadNextBondPage()
              }
            >
              <div>&nbsp;</div>
            </ReactVisibilitySensor>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonds;
