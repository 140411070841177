import React from 'react';
import { Route } from 'react-router';
import ManageRequestsContainer from './ManageRequestsContainer';
import { useSelector } from 'react-redux';
import useFetchLoggedInUserDetails from '../../hooks/useFetchLoggedInUserDetails';

import ManageRequestsFormContainer from './ManageRequestFormContainer';

const AdminApplicantManageRequestContainer = () => {
  useFetchLoggedInUserDetails();
  const { landingData } = useSelector(state => state.rmsLandingData);

  return (
    <div>
      <Route
        exact
        path="/rms/manage-request"
        children={({ match }) => (
          <ManageRequestsContainer match={match} landingData={landingData} />
        )}
      />
      <Route
        path="/rms/manage-request/requestform"
        children={({ match }) => <ManageRequestsFormContainer match={match} />}
      />
    </div>
  );
};

export default AdminApplicantManageRequestContainer;
