import React, { useEffect, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import useConvertId from '../../hooks/useConvertId';
import DottedLoader from '../../../Components/Loader/DottedLoader';
import useConvertDate from '../../hooks/useConvertDate';

const ViewApprovalHistoryModal = ({
  onHandleClose,
  ApprovalLevels,
  setSelectedApprovalLevel = () => {},
  getApplicantNames = () => {},
  approverNames,
  approvalHistoryLoading,
  approvalInfo,
  selectedApprovalLevel,
  requestDetailsById,
  getApprovalLevels = () => {},
  getApprovalLevelInfo = () => {},
}) => {
  const {
    approvals,
    eligibleApproverIds,
    rejections,
    minimumNumberOfApprovals,
  } = approvalInfo || {};
  const { convertDateISOToString } = useConvertDate();

  const { t } = useTranslation();

  //dropdownoptions data
  const dropDownOptions = [
    'Level One',
    'Level Two',
    'Level Three',
    'Level Four',
    'Level Five',
    'Level Six',
    'Level Seven',
    'Level Eight',
    'Level Nine',
    'Level Ten',
    'Level Eleven',
    'Level Twelve',
    'Level Thirteen',
    'Level Fourteen',
    'Level Fifteen',
    'Level Sixteen',
    'Level Seventeen',
    'Level Eighteen',
    'Level Nineteen',
    'Level Twenty',
  ];

  const filterDropDown = dropDownOptions.map((option, index) => {
    return {
      label: t(option),
      value: `${index + 1}`,
    };
  });

  const [listOfApproverIds, setListOfApproverIds] = useState([]);
  const [listOfRejectionIds, setListOfRejectionIds] = useState([]);
  const { getFormattedDate } = useConvertId();
  const loading = false;

  {
    /* check if eligible approver Id belong to approver id list, or if it belongs to rejected id list */
  }
  const renderAcceptedOrRejected = approverId => {
    if (!listOfApproverIds.length && !listOfRejectionIds.length) {
      return '-';
    } else {
      return (
        (listOfApproverIds.includes(approverId) && t('Accepted')) ||
        (listOfRejectionIds.includes(approverId) && t('Rejected')) ||
        '-'
      );
    }
  };

  const renderApprovalDate = approverId => {
    return approvals
      ?.map(approval => {
        return (
          approval.performedBy === approverId &&
          convertDateISOToString(approval.performedAt)
        );
      })
      .filter(item => item);
  };

  const renderRejectedDate = approverId => {
    return rejections
      ?.map(rejection => {
        return (
          rejection.performedBy === approverId &&
          getFormattedDate(rejection.performedAt)
        );
      })
      .filter(item => item);
  };

  const renderPerfomedDate = approverId => {
    if (!approvals?.length && !rejections?.length) {
      return '-';
    }
    const rejectedDate = renderRejectedDate(approverId);
    const approvedDate = renderApprovalDate(approverId);
    return (
      (rejectedDate?.length && rejectedDate?.[0]) ||
      (approvedDate?.length && approvedDate?.[0]) ||
      '-'
    );
  };

  //extract Id's only from response data received for approvals and rejections
  useEffect(() => {
    const approvalIds = approvals?.map(item => item.performedBy);
    const rejectionIds = rejections?.map(item => item.performedBy);
    setListOfApproverIds(approvalIds || []);
    setListOfRejectionIds(rejectionIds || []);
  }, [approvals, rejections]);

  //get all approver names from id's
  useEffect(() => {
    getApplicantNames(eligibleApproverIds);
  }, [eligibleApproverIds]);

  useEffect(() => {
    setSelectedApprovalLevel(1);
  }, []);

  //Gets called 1st

  useEffect(() => {
    getApprovalLevels(requestDetailsById?._id);
  }, [requestDetailsById]);

  //Gets called 2nd
  useEffect(() => {
    getApprovalLevelInfo(requestDetailsById?._id, selectedApprovalLevel);
  }, [selectedApprovalLevel, requestDetailsById]);

  return (
    <div className="reel-view--popup RMS__popUp approvalHistory">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Approval History')} </h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onHandleClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <label>{t('Select Level')}</label>

            <ReactDropdown
              // show approval levels from list based on applicant approval level
              options={filterDropDown.slice(0, ApprovalLevels)}
              placeholder={t('Filter By')}
              value={dropDownOptions[0]}
              onChange={option => setSelectedApprovalLevel(option.value)}
            />
            <p>
              -{' '}
              {`${minimumNumberOfApprovals || 0} Approval${
                minimumNumberOfApprovals !== 1 ? 's' : ''
              } ${t('Required')}`}
            </p>
          </div>

          <div className="reel-view--popup-row">
            <div className="RMS__page--table">
              <div className="RMS__page--table-header">
                <div className="RMS__page--table-column">
                  <h4>{t('Name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Action')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Date')} </h4>
                </div>
              </div>
              <div className="RMS__page--table-body">
                {approvalHistoryLoading ? (
                  <DottedLoader />
                ) : eligibleApproverIds?.length ? (
                  eligibleApproverIds?.map((approverId, i) => (
                    <div key={approverId} className="RMS__page--table-row">
                      <div className="RMS__page--table-column">
                        <p>
                          {approverNames?.map(nameDetails => {
                            return (
                              nameDetails._id === approverId && nameDetails.name
                            );
                          }) || '-'}
                        </p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{renderAcceptedOrRejected(approverId)}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{renderPerfomedDate(approverId)}</p>
                      </div>
                    </div>
                  ))
                ) : loading ? (
                  <Loader />
                ) : (
                  <NoContent message={t('Requests not available')} />
                )}
              </div>
            </div>
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`
             btn btn--primary
           `}
              onClick={onHandleClose}
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewApprovalHistoryModal;
