import React from 'react';
import { useTranslation } from 'react-i18next';

const BondConfirmationModal = ({
  onClose,
  onYesClick,
  onNoClick,
  loadingButton,
  errorMessage,
  isGroupRequest,
}) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Bond Details')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            {isGroupRequest ? (
              <div>
                <p>
                  {t(
                    'Please be informed that based on the gross fee of this course request, a bond may be incurred. Please submit the request if you wish to proceed.'
                  )}
                </p>
                <p>{t('Do you want to bond them or not?')}</p>
              </div>
            ) : (
              <div>
                <p>
                  {t(
                    'Please be informed that based on the gross fee of this course request, a bond may be incurred. Please submit the request if you wish to proceed. You may contact HR for more details.'
                  )}
                </p>
                <p>{t('Do you still wish to continue?')}</p>
              </div>
            )}
          </div>
          <div className="reel-view--popup-row submit">
            <span>{errorMessage}</span>
            <button
              className={`btn btn--primary ${
                loadingButton ? 'btn--loader' : ''
              }`}
              onClick={onYesClick}
            >
              {!loadingButton && t('Yes')}
            </button>
            <button className="btn btn--secondary" onClick={onNoClick}>
              {t('No')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BondConfirmationModal;
