import React, { useEffect, useState } from 'react';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { SubsidyFormType } from '../../../../Common/constants';

const CourseFee = ({
  data,
  isEditMode = false,
  updateCourseData = () => {},
}) => {
  const {
    isSubsidized,
    grossFee: initialGrossFee,
    grossFeeWithGst: initialGrossFeeWithGST,
    netFee,
    grantRef: initialGrantRef,
    subsidyForm: initialSubsidyForm,
    subsidyText,
  } = data?.course || {};

  const { t } = useTranslation();

  const [isCourseSubsidized, setIsCourseSubsidized] = useState('');
  const [grossFee, setGrossFee] = useState('');
  const [grossFeeWithGST, setGrossFeeWithGST] = useState('');
  const [netFeeAfterSubsidy, setNetFeeAfterSubsidy] = useState('');
  const [grantRef, setGrantRef] = useState('');
  const [subsidyForm, setSubsidyForm] = useState('');
  const [subsidyFormOtherText, setSubsidyFormOtherText] = useState('');

  useEffect(() => {
    if (data?.course) {
      setGrossFee(initialGrossFee);
      setGrossFeeWithGST(initialGrossFeeWithGST);
      setNetFeeAfterSubsidy(netFee);
      setGrantRef(initialGrantRef);
      setSubsidyForm(initialSubsidyForm);
      setSubsidyFormOtherText(subsidyText);
      setIsCourseSubsidized(isSubsidized ? 'yes' : 'no');
    }
  }, [data]);

  useEffect(() => {
    updateCourseData('subsidized', isCourseSubsidized === 'yes');
  }, [isCourseSubsidized]);

  useEffect(() => {
    updateCourseData('grossFee', parseFloat(grossFee || 0));
  }, [grossFee]);

  useEffect(() => {
    updateCourseData('grossFeeWithGst', parseFloat(grossFeeWithGST || 0));
  }, [grossFeeWithGST]);

  useEffect(() => {
    updateCourseData('netFee', parseFloat(netFeeAfterSubsidy || 0));
  }, [netFeeAfterSubsidy]);

  useEffect(() => {
    updateCourseData('grantRef', grantRef);
  }, [grantRef]);

  useEffect(() => {
    updateCourseData('subsidyForm', subsidyForm);
  }, [subsidyForm]);

  useEffect(() => {
    updateCourseData('subsidyText', subsidyFormOtherText);
  }, [subsidyFormOtherText]);

  return (
    <>
      <div className="RMS__page--divider"></div>
      <div className="RMS__page--body">
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field row">
              <p className="form--field-title required">
                {t('Is This Course Subsidized?')}
              </p>
              <ReactDropdown
                options={[
                  { label: t('Yes'), value: 'yes' },
                  { label: t('No'), value: 'no' },
                ]}
                value={isCourseSubsidized}
                onChange={option => setIsCourseSubsidized(option.value)}
                disabled={!isEditMode}
                placeholder={t('Select')}
              />
            </div>
          </div>
        </div>
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">{t('Gross Fee')}</p>
              <div className="form--field-input">
                <input
                  type="text"
                  placeholder={t('N/A')}
                  value={grossFee}
                  onChange={e =>
                    e.target.validity.valid && setGrossFee(e.target.value)
                  }
                  pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title required">
                {t('Gross Fee(with GST)')}
              </p>
              <div className="form--field-input">
                <input
                  type="text"
                  placeholder={t('N/A')}
                  value={grossFeeWithGST}
                  onChange={e =>
                    e.target.validity.valid &&
                    setGrossFeeWithGST(e.target.value)
                  }
                  pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
          <div className="RMS__page--body-column">
            {isCourseSubsidized === 'yes' && (
              <div className="form--field twoRows">
                <p className="form--field-title required">
                  {t('Net Fee(after Subsidy)')}
                </p>
                <div className="form--field-input">
                  <input
                    type="text"
                    placeholder={t('N/A')}
                    value={netFeeAfterSubsidy}
                    onChange={e =>
                      e.target.validity.valid &&
                      setNetFeeAfterSubsidy(e.target.value)
                    }
                    pattern="^[0-9]\d{0,6}(\.\d{0,2})?$"
                    disabled={!isEditMode}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field twoRows">
              <p className="form--field-title">{t('Grant Ref')}</p>
              <div className="form--field-input">
                <input
                  type="text"
                  placeholder={t('N/A')}
                  value={grantRef || '-'}
                  onChange={e => setGrantRef(e.target.value)}
                  disabled={!isEditMode}
                />
              </div>
            </div>
          </div>
          {isCourseSubsidized === 'yes' && (
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title required">
                  {t('Subsidy Form')}
                </p>
                <ReactDropdown
                  options={[
                    { label: t('SDF'), value: SubsidyFormType.SDF },
                    { label: t('VCF'), value: SubsidyFormType.VCF },
                    {
                      label: t('Others'),
                      value: SubsidyFormType.OTHERS,
                    },
                  ]}
                  value={subsidyForm}
                  onChange={option => setSubsidyForm(option.value)}
                  disabled={!isEditMode}
                  placeholder={t('Select')}
                />
              </div>
            </div>
          )}
          <div className="RMS__page--body-column">
            {subsidyForm === SubsidyFormType.OTHERS &&
              isCourseSubsidized === 'yes' && (
                <div className="form--field twoRows">
                  <p className="form--field-title required">{t('Others')}</p>
                  <div className="form--field-input">
                    <input
                      type="text"
                      placeholder={t('N/A')}
                      value={subsidyFormOtherText}
                      onChange={e => setSubsidyFormOtherText(e.target.value)}
                      disabled={!isEditMode}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseFee;
