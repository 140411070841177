import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupAddUserModal from '../../Components/Modal/GroupAdduserModal';
import {
  RMS_GROUP_ADD_REMOVE_SELECTED_USERS,
  RMS_GROUP_SAVE_SELECTED_USERS,
  RMS_GROUP_SEARCH_LOADING_SET,
} from '../../redux/actionTypes/groupRequestTypes';

const GroupAddUserContainer = ({ onHandleClose, fetchFilterSearch }) => {
  const dispatch = useDispatch();

  const {
    userLoading,
    userData,
    selectedUsers,
    userBudgets,
    userBudgetsLoading,
    grossFee,
  } = useSelector(state => state.rmsGroupRequest);

  const hasSufficientBudget = useCallback(
    userId => {
      if (userBudgets?.[userId]) {
        const { lockedAmount, utilizedAmount, groupBudgetLimit } =
          userBudgets?.[userId] || {};

        return (
          Number(lockedAmount || 0) +
            Number(utilizedAmount || 0) +
            Number(grossFee) <=
          Number(groupBudgetLimit)
        );
      } else {
        return false;
      }
    },
    [userBudgets]
  );

  return (
    <>
      <GroupAddUserModal
        onHandleClose={onHandleClose}
        userData={userData}
        fetchFilterSearch={fetchFilterSearch}
        userLoading={userLoading}
        setSearchLoading={() =>
          dispatch({ type: RMS_GROUP_SEARCH_LOADING_SET })
        }
        triggerAddUserAction={id =>
          dispatch({
            type: RMS_GROUP_ADD_REMOVE_SELECTED_USERS,
            payload: id,
          })
        }
        selectedUsers={selectedUsers}
        saveSelectedUsers={() =>
          dispatch({
            type: RMS_GROUP_SAVE_SELECTED_USERS,
          })
        }
        budgetsLoading={userBudgetsLoading}
        hasSufficientBudget={hasSufficientBudget}
      />
    </>
  );
};

export default GroupAddUserContainer;
