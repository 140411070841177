import React from 'react';
import { useTranslation } from 'react-i18next';

const BrochureAndCourseLink = ({ data }) => {
  const { t } = useTranslation();
  const { brochureUploadPath, courseWebsite } = data || {};

  return (
    <>
      <div className="RMS__page--content">
        <div className="RMS__page--body">
          <div className="RMS__page--body-row twoColumn">
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title required">
                  {t('Upload course brochure / edm / handout')}
                </p>
                <div className="form--field-input uploadIcon">
                  {brochureUploadPath ? (
                    <a
                      className="btn btn--orange"
                      href={brochureUploadPath || '-'}
                      download
                      target="_blank"
                    >
                      {t('Brochure 1')}
                    </a>
                  ) : (
                    <input
                      disabled
                      type="text"
                      placeholder={"-"}
                      value={"-"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="RMS__page--body-column">
              <div className="form--field twoRows">
                <p className="form--field-title">
                  {t('Course website and / or registration link')}
                </p>
                <div className="form--field-input">
                  <input
                    disabled
                    type="text"
                    placeholder={t('N/A')}
                    value={courseWebsite || '-'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrochureAndCourseLink;
