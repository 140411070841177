import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUploadFile from '../../hooks/useUploadFile';
import uploadfile from './../../assets/images/svg-images/icon-upload.svg';
import useConvertDate from '../../hooks/useConvertDate';
import {
  multipleQuestionAnswers,
  feedBackQuestionPriorityOrders,
} from '../../Common/constants';

const FeedBackModal = ({
  onHandleClose,
  feedbackQuestions,
  viewFeedbackData,
  isViewFeedback,
  setUserFeedBack,
  userFeedBackSubmitLoading,
  userFeedBackSubmitErrorMsg,
}) => {
  const { t } = useTranslation();
  const { getCurrentDateObject, convertDateToThemeFormat } = useConvertDate();

  const [uploadFile, fileUploadLink, fileUploadingProgress] = useUploadFile();
  const [titleAndLocation, setTitleAndLocation] = useState();
  const [trainer, setTrainer] = useState();
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const radioOptions = [
    multipleQuestionAnswers.STRONGLY_AGREED,
    multipleQuestionAnswers.AGREED,
    multipleQuestionAnswers.NEUTRAL,
    multipleQuestionAnswers.DISAGREED,
    multipleQuestionAnswers.STRONGLY_DISAGREED,
  ];

  const date = getCurrentDateObject();

  useEffect(() => {
    setQuestionAndAnswer(feedbackQuestions);
  }, [feedbackQuestions]);

  const addAnswersToQuestion = (id, value) => {
    setQuestionAndAnswer(
      questionAndAnswer.map(question =>
        question._id === id ? { ...question, answer: value } : question
      )
    );
  };

  const isChecked = (id, value) => {
    const ans = questionAndAnswer?.find(f => {
      return f._id === id;
    });

    if (ans.answer === value) {
      return true;
    } else return false;
  };

  const handleFileAccept = event => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      uploadFile(file);
    }
  };

  const handleSubmit = () => {
    if (
      date &&
      titleAndLocation &&
      trainer &&
      questionAndAnswer?.every(question => question.answer)
    ) {
      setErrorMessage();

      const response = questionAndAnswer?.map(({ _id, answer }) => {
        return {
          questionId: _id,
          response: answer,
        };
      });

      const feedback = {
        feedBackDate: date,
        feedBackResponses: response,
        trainer: trainer,
        fileUploadPath: fileUploadLink,
        titleAndLocation: titleAndLocation,
      };

      setUserFeedBack(feedback);
    } else {
      setErrorMessage(t('Please fill all the required fields'));
    }
  };

  const getBasicQuestionValue = _id => {
    const question = viewFeedbackData?.data?.find(data => {
      return data.questionId === _id;
    });

    return question?.response || '-';
  };

  const getMultipleQuestionValue = (id, value) => {
    const question = viewFeedbackData?.data?.find(f => {
      return f.questionId === id;
    });

    return question?.response === value;
  };

  return (
    <div className="reel-view--popup RMS__popUp FeedBackModal">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Feedback Form')}</h3>
          <a
            href="javascript:void(0)"
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose()}
          >
            &times;
          </a>
        </div>
        <div className="RMS__page--content">
          <div className="RMS__page--body">
            <div className="RMS__page--body-row">
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className="form--field-title required">{t('Date')}:</p>
                  <div className="form--field-input">
                    <input
                      type="text"
                      readOnly
                      placeholder={t('Type Here')}
                      value={
                        isViewFeedback
                          ? convertDateToThemeFormat(
                              viewFeedbackData?.feedBackDate
                            )
                          : convertDateToThemeFormat(date)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className="form--field-title required">
                    {t('Title and location of training:')}
                    {''}
                    {!isViewFeedback && <span>*</span>}
                  </p>
                  <div className="form--field-input">
                    <input
                      type="text"
                      readOnly={isViewFeedback}
                      placeholder={t('Type Here')}
                      onChange={e => setTitleAndLocation(e.target.value)}
                      value={
                        isViewFeedback
                          ? viewFeedbackData?.titleAndLocation || '-'
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className="form--field-title required">
                    {t('Trainer:')}
                    {!isViewFeedback && <span>*</span>}
                  </p>
                  <div className="form--field-input">
                    <input
                      type="text"
                      readOnly={isViewFeedback}
                      placeholder={t('Type Here')}
                      onChange={e => setTrainer(e.target.value)}
                      value={
                        isViewFeedback ? viewFeedbackData?.trainer || '-' : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="RMS__page--body-row">
              <div className="RMS__page--table">
                <div className="RMS__page--table-header">
                  <div className="RMS__page--table-column"></div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Strongly Agreed')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Agreed')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Neutral')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Disagreed')}</h4>
                  </div>
                  <div className="RMS__page--table-column">
                    <h4>{t('Strongly Disagreed')}</h4>
                  </div>
                </div>
                <div className="RMS__page--table-body">
                  {questionAndAnswer?.map(
                    ({ _id, order, question, feedBackQuestionType }) => {
                      return (
                        feedBackQuestionType ===
                          feedBackQuestionPriorityOrders.SINGLE_ANSWER_MULTIPLE_CHOICE_QUESTION && (
                          <div className="RMS__page--table-row" key={_id}>
                            <div className="RMS__page--table-column">
                              <p>
                                <span className="numbering">
                                  {order}
                                  {'. '}
                                </span>
                                {question}{' '}
                                {!isViewFeedback && (
                                  <span className="required">*</span>
                                )}
                              </p>
                            </div>
                            {radioOptions?.map(option => {
                              return (
                                <div className="RMS__page--table-column">
                                  <input
                                    type="radio"
                                    className="form-input--checkbox"
                                    checked={
                                      !isViewFeedback
                                        ? isChecked(_id, option)
                                        : getMultipleQuestionValue(_id, option)
                                    }
                                  />
                                  <label
                                    onClick={() =>
                                      !isViewFeedback &&
                                      addAnswersToQuestion(_id, option)
                                    }
                                  ></label>
                                </div>
                              );
                            })}
                          </div>
                        )
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            {questionAndAnswer?.map(
              ({ _id, order, question, feedBackQuestionType }) => {
                return (
                  feedBackQuestionType ===
                    feedBackQuestionPriorityOrders.BASIC_QUESTION && (
                    <div className="RMS__page--body-row" key={_id}>
                      <div className="form--field smallTitle textarea">
                        <p className="form--field-title required">
                          <span className="numbering">
                            {order}
                            {'. '}
                          </span>
                          {question}
                          {!isViewFeedback && <span>*</span>}
                        </p>
                        <div className="form--field-input">
                          <textarea
                            readOnly={isViewFeedback}
                            placeholder={t('Type Here')}
                            onChange={e =>
                              addAnswersToQuestion(_id, e.target.value)
                            }
                            value={
                              isViewFeedback ? getBasicQuestionValue(_id) : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
                );
              }
            )}
            <div className="RMS__page--body-row">
              <div className="form--field twoRows smallTitle">
                <p className="form--field-title required">
                  {t('Add Attachment (Certificates,Diploma, etc)')}
                </p>

                <div className="form--field-input uploadIcon">
                  {(!isViewFeedback && (
                    <>
                      <input
                        type="file"
                        name="uploadfile"
                        class="custom-file-input"
                        onChange={handleFileAccept}
                      />
                      <img src={uploadfile} alt="upload-field" />
                      <div
                        className="backgroundFill"
                        style={{
                          width: `${fileUploadingProgress}%`,
                        }}
                      ></div>
                    </>
                  )) || (
                    <>
                      {viewFeedbackData?.fileUploadPath ? (
                        <a
                          className="btn btn--orange"
                          href={viewFeedbackData?.fileUploadPath || '-'}
                          download
                          target="_blank"
                        >
                          {t('Download')}
                        </a>
                      ) : (
                        <input
                          disabled
                          type="text"
                          placeholder={'-'}
                          value={'-'}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="RMS__page--submit">
          <div className="RMS__page--error">
            <div className="RMS__page--error-content">
              {errorMessage ||
                (userFeedBackSubmitErrorMsg &&
                  t('Something Went Wrong, Please Try Again Later.'))}
            </div>
          </div>
          <button
            className={`
            btn btn--primary
            ${userFeedBackSubmitLoading ? 'btn--loader' : ''}
          `}
            onClick={!isViewFeedback ? handleSubmit : onHandleClose}
          >
            {!isViewFeedback ? t('Submit') : t('Cancel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedBackModal;
