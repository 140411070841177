export const RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST =
  'RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_REQUEST';
export const RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_SUCCESS;';
export const RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED =
  'RMS_FETCH_ALL_MANAGE_BONDS_DETAILS_FAILED';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_REQUEST';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_SUCCESS';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_DETAILS_FAILED';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_REQUEST =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_REQUEST';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_SUCCESS';
export const RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_FAILED =
  'RMS_FETCH_ALL_MANAGE_BONDS_ELIGIBLE_APPLICANTS_ID_FAILED';

export const RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_REQUEST =
  'RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_REQUEST';
export const RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_SUCCESS';
export const RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_FAILED =
  'RMS_FETCH_ALL_MANAGE_BONDS_DESIGNATION_NAME_FAILED';

export const RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_REQUEST =
  'RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_REQUEST';
export const RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS =
  'RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_SUCCESS';
export const RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_FAILED =
  'RMS_FETCH_ALL_MANAGE_BONDS_DEPARTMENT_NAME_FAILED';

export const RMS_DELETE_BOND_REQUEST = 'RMS_DELETE_BOND_REQUEST';
export const RMS_DELETE_BOND_SUCCESS = 'RMS_DELETE_BOND_SUCCESS';
export const RMS_DELETE_BOND_FAILED = 'RMS_DELETE_BOND_FAILED';
export const POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST =
  'POST_RMS_MANAGE_BONDS_ADD_APPLICANT_REQUEST';
export const POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS =
  'POST_RMS_MANAGE_BONDS_ADD_APPLICANT_SUCCESS';
export const POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED =
  'POST_RMS_MANAGE_BONDS_ADD_APPLICANT_FAILED';

export const RMS_EDIT_BOND_DETAILS_REQUEST = 'RMS_EDIT_BOND_DETAILS_REQUEST';
export const RMS_EDIT_BOND_DETAILS_SUCCESS = 'RMS_EDIT_BOND_DETAILS_SUCCESS';
export const RMS_EDIT_BOND_DETAILS_FAIL = 'RMS_EDIT_BOND_DETAILS_FAIL';

export const RMS_RESET_ADD_ELIGIBLE_APPLICANT =
  'RMS_RESET_ADD_ELIGIBLE_APPLICANT';

export const RMS_RESET_ADD_APPLICANT_SEARCH = 'RMS_RESET_ADD_APPLICANT_SEARCH';

export const PUT_RMS_BOND_ACTIVE_REQUEST = 'PUT_RMS_BOND_ACTIVE_REQUEST';
export const PUT_RMS_BOND_ACTIVE_SUCCESS = 'PUT_RMS_BOND_ACTIVE_SUCCESS';
export const PUT_RMS_BOND_ACTIVE_FAILED = 'PUT_RMS_BOND_ACTIVE_FAILED';
