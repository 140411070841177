import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDropdown from 'react-dropdown';
import {
  userStatsFilterDropDown,
  userStatsSearchDropDown,
} from '../../../Common/constants';
import Autosuggest from 'react-autosuggest';

const TableHeader = ({
  searchText,
  setSearchText,
  selectedFilterOptions,
  setSelectedFilterOptions,
  isDesignationLoading,
  designations,
  getDesignations,
  setSelectedDesignation,
  selectedSearchFilterOptions,
  setSelectedSearchFilterOptions,
  selectedIds,
  exportReport,
  isRequestS3FileObjectFailed,
  url,
  isFileDownloadStarted,
  resetUserDetailsS3BucketData,
}) => {
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState([]);
  const [isViewSearchDropDown, setIsViewSearchDropDown] = useState(false);
  const [searchFilterOptions, setSearchFilterOptions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [designationSearchText, setDesignationSearchText] = useState('');
  const [isViewAutosuggest, setIsViewAutosuggest] = useState(false);

  const dropDownList = [
    {
      label: t('Name'),
      value: userStatsFilterDropDown.searchByName,
    },
    {
      label: t('Email'),
      value: userStatsFilterDropDown.searchByEmail,
    },
    {
      label: t('Designation'),
      value: userStatsFilterDropDown.searchByDesignation,
    },
    {
      label: t('Bonded'),
      value: userStatsFilterDropDown.searchByBonded,
    },
  ];

  const searchDownList = [
    {
      label: t('Yes'),
      value: userStatsSearchDropDown.searchByYes,
    },
    {
      label: t('No'),
      value: userStatsSearchDropDown.searchByNo,
    },
  ];

  useEffect(() => {
    setFilterOptions(dropDownList);
    setSelectedFilterOptions(userStatsFilterDropDown.searchByName);
    setSearchFilterOptions(searchDownList);
  }, []);

  useEffect(() => {
    if (selectedFilterOptions === userStatsFilterDropDown.searchByBonded) {
      setIsViewSearchDropDown(true);
      setSearchText('');
      setSelectedDesignation();
      setDesignationSearchText('');
    } else {
      setIsViewSearchDropDown(false);
    }

    if (selectedFilterOptions === userStatsFilterDropDown.searchByDesignation) {
      setIsViewAutosuggest(true);
      getDesignations(designationSearchText);
      setSearchText('');
      setSelectedSearchFilterOptions();
    } else {
      setIsViewAutosuggest(false);
    }

    if (
      selectedFilterOptions === userStatsFilterDropDown.searchByName ||
      selectedFilterOptions === userStatsFilterDropDown.searchByEmail
    ) {
      setSelectedDesignation();
      setDesignationSearchText('');
      setSelectedSearchFilterOptions();
    }
  }, [selectedFilterOptions]);

  useEffect(() => {
    isViewSearchDropDown &&
      setSelectedSearchFilterOptions(userStatsSearchDropDown.searchByYes);
  }, [isViewSearchDropDown]);

  useEffect(() => {
    designationSearchText && getDesignations(designationSearchText);
  }, [designationSearchText]);

  useEffect(() => {
    setSuggestions(
      designations?.map(item => ({
        name: item.name,
        id: item._id,
      })) || []
    );
  }, [designations]);

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return <div {...containerProps}>{!isDesignationLoading && children}</div>;
  };
  const renderInputComponent = inputProps => (
    <div className="react-autosuggest__wrapper">
      <input {...inputProps} />
      {isDesignationLoading && designationSearchText && (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );

  return (
    <div className="RMS__page--header">
      <div
        className={`RMS__page--header-row underline ${
          isViewSearchDropDown ? 'bondedFilter' : ''
        } ${isViewAutosuggest ? 'designationFilter' : ''}`}
      >
        <div className="RMS__page--header-left">
          {isViewSearchDropDown ? (
            <ReactDropdown
              options={searchFilterOptions}
              value={selectedSearchFilterOptions}
              onChange={option => setSelectedSearchFilterOptions(option.value)}
              placeholder={t('Select')}
            />
          ) : isViewAutosuggest ? (
            <Autosuggest
              suggestions={suggestions}
              shouldRenderSuggestions={() => true}
              onSuggestionsFetchRequested={({ value }) => {
                setDesignationSearchText(value);
              }}
              onSuggestionSelected={(_, { suggestionIndex }) => {
                setSelectedDesignation(suggestions[suggestionIndex]?.id);
              }}
              getSuggestionValue={suggestion => suggestion.name}
              renderSuggestion={suggestion => <span>{suggestion.name}</span>}
              inputProps={{
                placeholder: t('Search Designation'),
                value: designationSearchText,
                onChange: (_, { newValue }) => {
                  setDesignationSearchText(newValue);
                },
              }}
              renderSuggestionsContainer={renderSuggestionsContainer}
              renderInputComponent={renderInputComponent}
            />
          ) : (
            <input
              type="text"
              placeholder={t('Search')}
              className={`form-input form-input--search`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          )}
        </div>
        <div className="RMS__page--header-right">
          <ReactDropdown
            options={filterOptions}
            value={selectedFilterOptions}
            onChange={option => {
              setSelectedFilterOptions(option.value);
              resetUserDetailsS3BucketData();
            }}
            placeholder={t('Select')}
          />
          <button
            className={`btn btn--primary  btn--marginLeft ${
              url && !isFileDownloadStarted
                ? `btn--loader`
                : !isRequestS3FileObjectFailed && ` btn--export`
            }`}
            disabled={!selectedIds?.length}
            onClick={exportReport}
          >
            {isRequestS3FileObjectFailed ? t('Error! Try again.') : t('Export')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
