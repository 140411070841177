import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import ReactDropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  SuperAdminActionMode,
  SuperAdminRequestFilterOptions,
} from '../../Common/constants';
import DatePicker from './DatePicker';

const TableHeader = ({
  searchText,
  selectedDate,
  selectedDropdown,
  selectedTemplate,
  setSearchText,
  setSelectedDate,
  setSelectedDropdown,
  setSelectedTemplate,
  templates,
  filterMode,
  setFilterMode,
  setSearchTemplateText,
  templatesLoading,
}) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState('');
  const [searchTemplateValue, setSearchTemplateValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTemplateLabel, setSelectedTemplateLabel] = useState('');

  const viewCalenderHandler = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleClickAway = () => {
    setShowDatePicker(!showDatePicker);
  };

  const searchHandler = e => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    setSearchTemplateText(searchTemplateValue);
  }, [searchTemplateValue]);

  useEffect(() => {
    setSuggestions(
      templates?.map(item => {
        return {
          name: item.name,
          id: item._id,
        };
      }) || []
    );

    if (templates?.length && !selectedTemplateLabel) {
      setSelectedTemplateLabel(templates?.[0]?.name);
      setSearchTemplateValue(templates?.[0]?.name);
    }
  }, [templates]);

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return <div {...containerProps}>{!templatesLoading && children}</div>;
  };

  const renderInputComponent = inputProps => (
    <div className="react-autosuggest__wrapper">
      <input {...inputProps} />
      {templatesLoading && searchTemplateValue && (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>
              {t('Requests')}{' '}
              {selectedTemplateLabel
                ? `${t('For')} ${selectedTemplateLabel}`
                : ''}
            </p>
          </div>
          <div className="RMS__page--header-right ">
            <p>{t('Filter')}</p>

            {showDatePicker && (
              <OutsideClickHandler onOutsideClick={handleClickAway}>
                <DatePicker
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                />
              </OutsideClickHandler>
            )}
            <Autosuggest
              suggestions={suggestions}
              shouldRenderSuggestions={() => true}
              onSuggestionsFetchRequested={({ value }) => {
                setSearchTemplateValue(value);
              }}
              onSuggestionSelected={(_, { suggestionIndex }) => {
                setSelectedTemplate(suggestions[suggestionIndex]?.id);
                setSelectedTemplateLabel(suggestions[suggestionIndex]?.name);
              }}
              getSuggestionValue={suggestion => suggestion.name}
              renderSuggestion={suggestion => <span>{suggestion.name}</span>}
              inputProps={{
                placeholder: t('Search templates'),
                value: searchTemplateValue,
                onChange: (_, { newValue }) => {
                  setSearchTemplateValue(newValue);
                },
              }}
              renderSuggestionsContainer={renderSuggestionsContainer}
              renderInputComponent={renderInputComponent}
            />
            <ReactDropdown
              options={[
                { label: t('All'), value: SuperAdminActionMode.ALL_REQUEST },
                { label: t('Approvals'), value: SuperAdminActionMode.APPROVAL },
                {
                  label: t('Past Requests'),
                  value: SuperAdminActionMode.PAST_REQUEST,
                },
              ]}
              value={filterMode}
              onChange={option => setFilterMode(option.value)}
              placeholder={t("Select")}
            />
          </div>
        </div>
        <div className="RMS__page--header-row underline">
          <div className="RMS__page--header-left full-width">
            <input
              type="text"
              placeholder={
                selectedDropdown !== 'dateRange'
                  ? t('Search')
                  : t('Select Date Range')
              }
              className={`form-input form-input--search ${
                selectedDropdown === 'dateRange' && 'date-range'
              }  `}
              value={searchText}
              onClick={() => {
                selectedDropdown === 'dateRange' && viewCalenderHandler();
              }}
              onChange={e => {
                selectedDropdown !== 'dateRange' && searchHandler(e);
              }}
            />
          </div>
          <div className="RMS__page--header-right">
            <ReactDropdown
              options={[
                {
                  label: t('Name'),
                  value: SuperAdminRequestFilterOptions.NAME,
                },
                {
                  label: t('Email'),
                  value: SuperAdminRequestFilterOptions.EMAIL,
                },
                {
                  label: t('Request ID'),
                  value: SuperAdminRequestFilterOptions.REQUEST_ID,
                },
                {
                  label: t('Date Range'),
                  value: SuperAdminRequestFilterOptions.DATE_RANGE,
                },
              ]}
              placeholder={t('Filter By')}
              value={selectedDropdown}
              onChange={option => setSelectedDropdown(option.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

{
}

export default TableHeader;
