import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import Auth from '@aws-amplify/auth';
import { get } from '../api/commonApi';
import { CLEAR_SESSION } from '../redux/actionTypes/auth';

function useApiPromise() {
  const dispatch = useDispatch();
  const valueRef = useRef();

  const accessToken = useSelector(state => state?.auth?.access_token);

  const processPromise = async (method, service, baseUrl, reduxActions) => {
    const promise = await callAPI(method, baseUrl, service);
    requestApiCall(promise, reduxActions);
    valueRef.current = promise;
    return promise;
  };

  const callAPI = (method, baseUrl, service) => {
    switch (method) {
      case 'GET':
      default:
        return get(baseUrl, accessToken, service);
    }
  };

  const requestApiCall = (result, actions) => {
    dispatch({ type: actions.request });
    try {
      if (result.status === 200 || result.status === 201) {
        dispatch({
          type: actions.success,
          payload: result.data,
        });
      } else if (result.status === 202) {
        dispatch({
          type: actions.success,
          payload: result.data,
        });
      } else if (result.status === 204) {
        dispatch({
          type: actions.success,
          payload: [],
        });
      } else {
        if (result.status === 401) {
          Auth.signOut();
          dispatch({
            type: CLEAR_SESSION,
          });
        }
        dispatch({
          type: actions.fail,
          payload:
            result.data &&
            (result.data.details || result.data.message || result.data),
        });
      }
    } catch (error) {
      dispatch({
        type: actions.fail,
        payload: error,
      });
    }
  };
  const getServiceType = portType => {
    switch (portType) {
      case 'isUserService':
        return process.env.REACT_APP_USER_MANAGEMENT_SERVICE;

      case 'isGroupService':
        return process.env.REACT_APP_DIVISION_SERVICE;

      case 'isReelService':
        return process.env.REACT_APP_VIDEO_SERVICE;

      case 'isNotificationService':
        return process.env.REACT_APP_NOTIFICATION_SERVICE;

      case 'isWebsocketService':
        return process.env.REACT_APP_WEBSOCKET_SERVICE;
      case 'isRmsService':
        return process.env.REACT_APP_RMS_SERVICE;

      default:
        return '';
    }
  };

  const submitForm = (url, request, success, fail, method, service) => {
    const reduxActions = { request, success, fail };
    return processPromise(method, getServiceType(service), url, reduxActions);
  };

  return [submitForm];
}

export default useApiPromise;
