import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteModalCommon = ({
  highlightedText,
  messageFirstPart,
  messageSecondPart,
  onCancelClick,
  onClose,
  onOkClick,
  title,
  hideQuestionMark = false,
  errorMessage = '',
  loading = false,
  okButtonText = 'Ok',
  cancelButtonText = 'Cancel',
}) => {
  const { t } = useTranslation();
  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{title}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={onClose}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <p>
              {messageFirstPart}
              {highlightedText && <span>&nbsp;{highlightedText}&nbsp;</span>}
              {messageSecondPart}
              {!hideQuestionMark && '?'}
            </p>
          </div>
          <div className="reel-view--popup-row">
            <span className="error">{errorMessage}</span>
            <button
              className={`btn btn--primary ${loading ? 'btn--loader' : ''}`}
              onClick={onOkClick}
            >
              {!loading && t(okButtonText)}
            </button>
            <button className="btn btn--secondary" onClick={onCancelClick}>
              {t(cancelButtonText)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModalCommon;
