import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDropdown from 'react-dropdown';
import { ActionMode, UserModes } from '../../Common/constants';

const TableHeader = ({
  selectedUser,
  setSelectedUser,
  selectedAdminDropdown,
  setSelectedAdminDropdown,
  userPrivilages,
  searchText,
  setSearchText,
  isFromNotification,
}) => {
  const { t } = useTranslation();
  const [selectUserOptions, setSelectUserOptions] = useState([]);

  const dropDownList = [
    {
      label: 'Admin',
      value: UserModes.ADMIN,
    },
    {
      label: 'User',
      value: UserModes.USER,
    },
  ];

  // show drop down list based on privilage status
  useEffect(() => {
    if (!userPrivilages) return;
    if (userPrivilages === 'allPrivilage') {
      setSelectUserOptions(dropDownList);
    }
    if (userPrivilages === 'admin') {
      setSelectUserOptions([{ ...dropDownList[0] }]);
    }
    if (userPrivilages === 'applicant') {
      setSelectUserOptions([{ ...dropDownList[1] }]);
    }
  }, [userPrivilages]);

  //set Selected user on initial render
  useEffect(() => {
    !isFromNotification
      ? setSelectedUser(selectUserOptions?.[0]?.value)
      : setSelectedUser(UserModes.USER);
  }, [selectUserOptions]);

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Requests')}</p>
          </div>
          <div className="RMS__page--header-right ">
            <p>{t('Mode')}</p>
            <ReactDropdown
              options={selectUserOptions}
              placeholder={t('Select Mode')}
              value={selectedUser}
              onChange={option => setSelectedUser(option.value)}
            />
            {selectedUser === UserModes.ADMIN && (
              <ReactDropdown
                options={[
                  { label: 'Approvals', value: ActionMode.APPROVALS },
                  { label: 'Submitted', value: ActionMode.SUBMITTED },
                  { label: 'Past Requests', value: ActionMode.PAST_REQUESTS },
                ]}
                value={selectedAdminDropdown}
                onChange={option => setSelectedAdminDropdown(option.value)}
                placeholder={t("Select")}
              />
            )}
          </div>
        </div>
        <div className="RMS__page--header-row underline">
          <div className="RMS__page--header-left full-width">
            <input
              type="text"
              placeholder={t('Search')}
              className={`form-input form-input--search`}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
