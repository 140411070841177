import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader/loader';
import useApi from '../../../Hooks/useApi';
import { privileges, Services, TemplateTypes } from '../../Common/constants';
import LandingComponent from '../../Components/Landing/Landing';
import useFetchLoggedInUserDetails from '../../hooks/useFetchLoggedInUserDetails';
import {
  RESET_RMS_SERVICE_STATUS,
  RMS_LOGIN_USER_GROUP_TEMPLATE_FAIL,
  RMS_LOGIN_USER_GROUP_TEMPLATE_REQUEST,
  RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS,
  RMS_LOGIN_USER_TEMPLATE_FAIL,
  RMS_LOGIN_USER_TEMPLATE_REQUEST,
  RMS_LOGIN_USER_TEMPLATE_SUCCESS,
  RMS_SERVICE_STATUS_FAIL,
  RMS_SERVICE_STATUS_REQUEST,
  RMS_SERVICE_STATUS_SUCCESS,
} from '../../redux/actionTypes/landing';
import {
  RMS_FETCH_ACTIVE_FINANCIAL_FAILED,
  RMS_FETCH_ACTIVE_FINANCIAL_REQUEST,
  RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS,
} from '../../redux/actionTypes/superAdminDashboardTypes';

const LandingContainer = ({ history }) => {
  const {
    landingLoading,
    landingData,
    loginApplicantNormalTemplate,
    loginApplicantGroupTemplate,
    isRmsHealthCheckerLoading,
    isRmsHealthStatusSuccess,
  } = useSelector(state => state.rmsLandingData);
  const { activeFinancialDuration } = useSelector(
    state => state.rmsSuperAdminDashboard
  );
  const [getLoginApplicantNormalTemplateApi] = useApi();
  const [getLoginApplicantGroupTemplateApi] = useApi();
  const [getCurrentFinancialDuration] = useApi();
  const [getIsRmsServiceUp] = useApi();
  const dispatch = useDispatch();

  useFetchLoggedInUserDetails();

  const getLoginApplicantNormalTemplate = applicantId => {
    getLoginApplicantNormalTemplateApi(
      `/api/v1/templates?type=${TemplateTypes.NORMAL_TEMPLATE}&applicantId=${applicantId}&page=1&size=1`,
      RMS_LOGIN_USER_TEMPLATE_REQUEST,
      RMS_LOGIN_USER_TEMPLATE_SUCCESS,
      RMS_LOGIN_USER_TEMPLATE_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const getLoginApplicantGroupTemplate = applicantId => {
    getLoginApplicantGroupTemplateApi(
      `/api/v1/templates?type=${TemplateTypes.GROUP_TEMPLATE}&adminId=${applicantId}&page=1&size=1`,
      RMS_LOGIN_USER_GROUP_TEMPLATE_REQUEST,
      RMS_LOGIN_USER_GROUP_TEMPLATE_SUCCESS,
      RMS_LOGIN_USER_GROUP_TEMPLATE_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  const fetchCurrentFinancialDuration = () => {
    getCurrentFinancialDuration(
      `/api/v1/financialDurations?isActive=true`,
      RMS_FETCH_ACTIVE_FINANCIAL_REQUEST,
      RMS_FETCH_ACTIVE_FINANCIAL_SUCCESS,
      RMS_FETCH_ACTIVE_FINANCIAL_FAILED,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };
  const checkRmsServiceUp = () => {
    getIsRmsServiceUp(
      `/actuator/health`,
      RMS_SERVICE_STATUS_REQUEST,
      RMS_SERVICE_STATUS_SUCCESS,
      RMS_SERVICE_STATUS_FAIL,
      {},
      '',
      'GET',
      null,
      Services.isRmsService
    );
  };

  useEffect(() => {
    landingData?.roles.includes(privileges.RMS_APPLICANT) &&
      getLoginApplicantNormalTemplate(landingData?._id);
    if (landingData?.roles.includes(privileges.RMS_APPLICANT)) {
      getLoginApplicantNormalTemplate(landingData?._id);
      fetchCurrentFinancialDuration();
    }

    landingData?.roles.includes(privileges.RMS_ADMIN) &&
      getLoginApplicantGroupTemplate(landingData?._id);
  }, [landingData]);

  useEffect(() => {
    checkRmsServiceUp();
    return () => {
      dispatch({ type: RESET_RMS_SERVICE_STATUS });
    };
  }, []);

  return !landingLoading && !isRmsHealthCheckerLoading ? (
    <LandingComponent
      landingData={landingData}
      isNormalTemplate={!!loginApplicantNormalTemplate}
      isGroupTemplate={!!loginApplicantGroupTemplate}
      activeFinancialDuration={activeFinancialDuration}
      isRmsHealthStatusSuccess={isRmsHealthStatusSuccess}
    />
  ) : (
    <Loader />
  );
};
export default LandingContainer;
