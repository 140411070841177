import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import {
  RESET_ACCESS_CONTROLLER_TYPE,
  RMS_GET_TEMPLATES_FAILED,
  RMS_GET_TEMPLATES_REQUEST,
  RMS_GET_TEMPLATES_SUCCESS,
  RMS_GET_TEMPLATE_ADMIN_USERS_FAILED,
  RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST,
  RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS,
  RMS_GET_TEMPLATE_USERS_FAILED,
  RMS_GET_TEMPLATE_USERS_REQUEST,
  RMS_GET_TEMPLATE_USERS_RESET,
  RMS_GET_TEMPLATE_USERS_SUCCESS,
  RMS_RESET_ACCESS_CONTROL,
  RMS_SAVE_TEMPLATE_USERS_FAILED,
  RMS_SAVE_TEMPLATE_USERS_REQUEST,
  RMS_SAVE_TEMPLATE_USERS_SUCCESS,
} from '../actionTypes/accessControlTypes';

const INITIAL_STATE = {
  templates: [],
  templatesLoading: false,
  templateUsers: [],
  templateUsersLoading: false,
  adminSaveLoading: false,
  adminSaveSuccess: false,
  adminSaveTaskId: '',
  adminSaveError: false,
};

const AccessControlReducer = (state = INITIAL_STATE, action) => {
  const {
    data = [],
    page = 0,
    size = 0,
    totalRecords = 0,
  } = action.payload || {};
  switch (action.type) {
    case RMS_GET_TEMPLATES_REQUEST:
      return {
        ...state,
        templatesLoading: true,
      };
    case RMS_GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templatesLoading: false,
        templates: data,
      };
    case RMS_GET_TEMPLATES_FAILED:
      return {
        ...state,
        templatesLoading: false,
      };
    case RMS_GET_TEMPLATE_ADMIN_USERS_REQUEST:
    case RMS_GET_TEMPLATE_USERS_REQUEST:
      return {
        ...state,
        templateUsersLoading: true,
        templateUsers: [],
      };
    case RMS_GET_TEMPLATE_ADMIN_USERS_SUCCESS:
    case RMS_GET_TEMPLATE_USERS_SUCCESS:
      return {
        ...state,
        templateUsersLoading: false,
        templateUsers: data,
      };

    case RMS_GET_TEMPLATE_USERS_RESET:
      return {
        ...state,
        templateUsers: [],
      };
    case RMS_GET_TEMPLATE_ADMIN_USERS_FAILED:
    case RMS_GET_TEMPLATE_USERS_FAILED:
      return {
        ...state,
        templateUsersLoading: false,
      };

    case RMS_SAVE_TEMPLATE_USERS_REQUEST:
      return {
        ...state,
        adminSaveLoading: true,
        adminSaveSuccess: false,
        adminSaveError: false,
        adminSaveTaskId: '',
      };
    case RMS_SAVE_TEMPLATE_USERS_SUCCESS:
      return {
        ...state,
        adminSaveLoading: false,
        adminSaveSuccess: true,
        adminSaveTaskId: action.payload?._id,
      };

    case RMS_SAVE_TEMPLATE_USERS_FAILED:
      return {
        ...state,
        adminSaveLoading: false,
        adminSaveError: true,
      };

    case RMS_RESET_ACCESS_CONTROL:
    case CLEAR_SESSION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default AccessControlReducer;
