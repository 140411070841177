import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../Hooks/useApi';
import { TemplateTypes } from '../../Common/constants';
import CreateBondTemplate from '../../Components/ManageTemplate/CreateBondTemplate';
import { useLocation } from 'react-router-dom';
import {
  GET_BOND_TEMPLATES_FAIL,
  GET_BOND_TEMPLATES_REQUEST,
  GET_BOND_TEMPLATES_SUCCESS,
  GET_RMS_SELECTED_TEMPLATES_FOR_BOND_FAILED,
  GET_RMS_SELECTED_TEMPLATES_FOR_BOND_REQUEST,
  GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS,
  POST_BOND_TEMPLATE_FAIL,
  POST_BOND_TEMPLATE_REQUEST,
  POST_BOND_TEMPLATE_SUCCESS,
  RESET_RMS_SELECTED_BOND_FOR_TEMPLATE,
  RESET_RMS_TEMPLATE,
} from '../../redux/actionTypes/templateTypes';

const CreateBondTemplateContainer = ({ history, isEdit }) => {
  const dispatch = useDispatch();
  const [fetchTemplates] = useApi();
  const [postBondTemplate] = useApi();
  const [getSelectedTemplateDetailsApi] = useApi();
  const { state } = useLocation();

  const {
    bondTemplates,
    postBondTemplateLoading,
    postBondTemplateSuccess,
    postBondTemplateFailMessage,
    selectedBond,
    bondAttachedTemplates,
  } = useSelector(state => state.templateManagement);

  const getTemplates = () => {
    fetchTemplates(
      `/api/v1/templates?assignedToBondTemplate=false&page=1&size=100`,
      GET_BOND_TEMPLATES_REQUEST,
      GET_BOND_TEMPLATES_SUCCESS,
      GET_BOND_TEMPLATES_FAIL,
      {},
      '',
      'GET',
      null,
      'isRmsService'
    );
  };
  const createBondTemplates = (name, bondBracket, selectedBondTemplates) => {
    let arrangeData = {
      name: name,
      attachedTemplateIds: selectedBondTemplates?.map(data => data?.value),
      bandBrackets: bondBracket,
    };
    if (isEdit) {
      arrangeData._id = selectedBond?._id || '';
    }

    postBondTemplate(
      '/api/v1/bondTemplates',
      POST_BOND_TEMPLATE_REQUEST,
      POST_BOND_TEMPLATE_SUCCESS,
      POST_BOND_TEMPLATE_FAIL,
      arrangeData,
      '',
      isEdit ? 'PUT' : 'POST',
      null,
      'isRmsService'
    );
  };

  const getSelectedTemplateDetails = templateIds => {
    templateIds?.length &&
      getSelectedTemplateDetailsApi(
        `/api/v1/templates?templateIds=${templateIds}&page=1&size=${templateIds.length}`,
        GET_RMS_SELECTED_TEMPLATES_FOR_BOND_REQUEST,
        GET_RMS_SELECTED_TEMPLATES_FOR_BOND_SUCCESS,
        GET_RMS_SELECTED_TEMPLATES_FOR_BOND_FAILED,
        {},
        '',
        'GET',
        null,
        'isRmsService'
      );
  };

  useEffect(() => {
    getTemplates();
    return () => {
      dispatch({ type: RESET_RMS_TEMPLATE });
      dispatch({ type: RESET_RMS_SELECTED_BOND_FOR_TEMPLATE });
    };
  }, []);

  useEffect(() => {
    if (isEdit) {
      //blocking direct access to edit bond template screen

      if (
        !selectedBond ||
        !state ||
        !selectedBond?.attachedTemplateIds?.includes(state?.selectedTemplate)
      ) {
        if (history.length) {
          history.goBack();
        } else {
          history.push(`/rms`);
        }
      } else {
        selectedBond?.attachedTemplateIds &&
          getSelectedTemplateDetails(selectedBond.attachedTemplateIds);
      }
    }
  }, [isEdit, state]);

  return (
    <CreateBondTemplate
      bondTemplates={bondTemplates}
      createBondTemplate={createBondTemplates}
      postBondTemplateLoading={postBondTemplateLoading}
      postBondTemplateSuccess={postBondTemplateSuccess}
      postBondTemplateFailMessage={postBondTemplateFailMessage}
      isEdit={isEdit}
      selectedBond={selectedBond}
      bondAttachedTemplates={bondAttachedTemplates}
    />
  );
};

export default CreateBondTemplateContainer;
