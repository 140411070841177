import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import NoContent from '../../../Components/NoContent/NoContent';
import iconAddButton from '../../assets/images/svg-images/icon-addButton.svg';
import iconDelete from '../../assets/images/svg-images/icon-delete.svg';
import { BudgetTypes } from '../../Common/constants';
import { getArrayIndexUsingKey } from '../../Common/helper';
import AddTemplateUserContainer from '../../Container/AddUserContainer/AddTemplateUserContainer';
import AddBudgetModal from '../Modal/AddBudgetModal';
import DeleteUserModal from '../Modal/DeleteUserModal';
import ApprovalBrackets from './ApprovalBrackets';

const CreateTemplate = ({
  isGroupTemplateType,
  users,
  removeSelectedUsers,
  usersDepartments,
  handleBudgetLimit,
  handleBulkSelect,
  selectedBudgetUsers,
  toggleSelectAllBudgetUsers,
  setBulkBudgetValues,
  bulkRemove,
  createTemplateCallBack,
  createTemplateLoading,
  createTemplateSuccessLoading,
  createTemplateFailed,
  editTemplate,
  getTemplateAdminDetails,
  templateAdmin,
  isCreateTemplate,
  getTemplateData,
  createTemplateFailMessage,
  cancelLastUpdate,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isAddTemplateUsers, setIsAddTemplateUsers] = useState(false);
  const [isBudgetLimitOpen, setIsBudgetLimitOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [budgetModalName, setBudgetModalName] = useState('');
  const [selectedBulkBudgetType, setSelectedBulkBudgetType] = useState(
    BudgetTypes.generalBudget
  );
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [deleteUserPopTitle, setDeleteUserPopTitle] = useState('');
  const [deleteUserPopMsgBodyPartOne, setDeleteUserPopMsgBodyPartOne] =
    useState();
  const [deleteUserPopMsgBodyPartTwo, setDeleteUserPopMsgBodyPartTwo] =
    useState();
  const [deleteUserData, setDeleteUserData] = useState();
  const [isBulkRemove, setIsBulkRemove] = useState(false);
  const [createTemplateClick, setCreateTemplateClick] = useState(true);
  const [templateData, setTemplateData] = useState(null);
  const [approvalBrackets, setApprovalBrackets] = useState(null);

  const validRangeOverLap = data => {
    const length = data.length;
    if (length > 1) {
      for (let i = 0; i < length; i++) {
        for (let u = 0; u < length; u++) {
          if (i == u) {
            // skip if comparing the same range
            continue;
          }
          const rangeOne = data[i];
          const rangeTwo = data[u];
          const rangeOneMin = parseInt(rangeOne.grossStartAmount);
          const rangeTwoMax = parseInt(rangeTwo.grossEndAmount);
          const rangeTwoMin = parseInt(rangeTwo.grossStartAmount);
          const rangeOneMax = parseInt(rangeOne.grossEndAmount);

          if (rangeOneMin >= rangeTwoMin && rangeOneMin <= rangeTwoMax) {
            return false;
          }
          if (rangeOneMax >= rangeTwoMin && rangeOneMax <= rangeTwoMax) {
            return false;
          }
          if (rangeOneMin <= rangeTwoMin && rangeTwoMax <= rangeOneMax) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (!templateName) {
      setErrorMessage(t('Please fill template name.'));
    } else {
      if (createTemplateClick) {
        if (validateUser()) {
          setErrorMessage('');
          createTemplateCallBack(templateName);
        }
      } else {
        const isApprovalGrossAmountSuccess = approvalBrackets?.every(
          approval =>
            (approval.grossStartAmount || approval.grossStartAmount === 0) &&
            approval.grossEndAmount
        );

        const isApproveLevelSuccess = approvalBrackets?.every(data =>
          data?.approvalLevels?.every(
            approveLevel =>
              approveLevel?.minApproval &&
              approveLevel?.notifierIds?.length &&
              approveLevel?.approverIds?.length
          )
        );
        if (isApproveLevelSuccess && isApprovalGrossAmountSuccess) {
          const checkMinGreaterThanMax = approvalBrackets?.every(
            approval =>
              parseInt(approval.grossStartAmount) <
              parseInt(approval.grossEndAmount)
          );
          if (checkMinGreaterThanMax) {
            if (validRangeOverLap(approvalBrackets || [])) {
              if (validateMinimumApproval()) {
                if (validateUser()) {
                  setErrorMessage('');
                  editTemplate(
                    templateData?._id,
                    templateName,
                    approvalBrackets,
                    templateData?.bondTemplateId
                  );
                }
              }
            } else {
              setErrorMessage(
                t('Please remove overlapping approval Gross Amount  brackets.')
              );
            }
          } else {
            setErrorMessage(
              t(
                'Approval bracket minimum gross amounts should less than maximum gross amount.'
              )
            );
          }
        } else {
          setErrorMessage(t('Please fill all approval bracket fields.'));
        }
      }
    }
  };

  const validateUser = () => {
    if (users?.length) {
      if (!isGroupTemplateType) {
        if (
          users?.every(
            user => user.generalBudgetLimit && user.professionalBudgetLimit
          )
        ) {
          return true;
        } else {
          setErrorMessage(t('Please fill all user budget limit fields.'));
          return false;
        }
      } else {
        if (users?.every(user => user.generalBudgetLimit)) {
          return true;
        } else {
          setErrorMessage(t('Please fill all user budget limit fields.'));
          return false;
        }
      }
    } else {
      setErrorMessage(t('No user selected.'));
      return false;
    }
  };
  const validateMinimumApproval = () => {
    const isApproveLevelSuccess = approvalBrackets?.every(data =>
      data?.approvalLevels?.every(
        approveLevel =>
          parseInt(approveLevel?.minApproval) <=
          approveLevel?.approverIds?.length
      )
    );
    if (isApproveLevelSuccess) {
      return true;
    } else {
      setErrorMessage(t('Invalid minimum approval value. please check.'));
      return false;
    }
  };
  useEffect(() => {
    !createTemplateLoading &&
      createTemplateSuccessLoading &&
      history.push(`/rms/manage-template`);
  }, [createTemplateLoading, createTemplateSuccessLoading]);

  useEffect(() => {
    setCreateTemplateClick(!location?.state?.templateData);
    setTemplateData(location?.state?.templateData || null);
  }, [location]);
  useEffect(() => {
    if (templateData) {
      getTemplateData(templateData);
      setTemplateName(templateData?.name);
      templateData?.rmsAdminIds?.length &&
        getTemplateAdminDetails(templateData.rmsAdminIds);
    }
  }, [templateData]);

  useEffect(() => {
    isCreateTemplate(createTemplateClick);
  }, [createTemplateClick]);
  useEffect(() => {
    setSelectAll(false);
  }, [isAddTemplateUsers]);

  return (
    <div className="RMS__page createTemplate">
      <div className="container">
        <div className="RMS__page--wrapper">
          <div className="RMS__page--title">
            <h2>
              {isGroupTemplateType
                ? createTemplateClick
                  ? t('Create Group Template')
                  : t('Edit Group Template')
                : createTemplateClick
                ? t('Create Normal Template')
                : t('Edit Normal Template')}
            </h2>
          </div>
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <p>{t('Basic Info')}</p>
                </div>
              </div>
            </div>
            <div className="RMS__page--body">
              <div className="RMS__page--body-row">
                <div className="form--field row">
                  <p className="form--field-title">{t('Template Name')} :</p>
                  <div className="form--field-input">
                    <input
                      type="text"
                      placeholder={t('Template Name')}
                      onChange={e => {
                        setTemplateName(e.target.value);
                      }}
                      value={templateName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ApprovalBrackets
            createTemplateClick={createTemplateClick}
            templateAdmin={templateAdmin}
            getArrayBracket={array => setApprovalBrackets(array)}
            templateData={templateData}
          />
          <div className="RMS__page--content">
            <div className="RMS__page--header">
              <div className="RMS__page--header-row">
                <div className="RMS__page--header-left">
                  <p>{t('Add Users')}</p>
                </div>
              </div>
            </div>
            <div className="RMS__page--table addUsers">
              <div
                className={`RMS__page--table-header ${
                  !isGroupTemplateType ? 'normal' : 'group'
                }`}
              >
                <div className="RMS__page--table-column">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      className="form-input--checkbox"
                      name="selectAll"
                      checked={selectAll}
                    />
                    <label
                      htmlFor="selectAll"
                      onClick={() => {
                        setSelectAll(!selectAll);
                        toggleSelectAllBudgetUsers(!selectAll);
                      }}
                    ></label>
                  </div>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Username')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Email')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Department Id')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('General Budget Limit')}</h4>
                </div>
                {!isGroupTemplateType && (
                  <div className="RMS__page--table-column">
                    <h4>{t('Prof Budget Limit')}</h4>
                  </div>
                )}

                <div className="RMS__page--table-column">
                  <h4></h4>
                </div>
              </div>

              <div
                className={`RMS__page--table-body ${
                  !isGroupTemplateType ? 'normal' : 'group'
                }`}
              >
                {users?.length ? (
                  users.map(data => (
                    <div key={data?.email} className="RMS__page--table-row">
                      <div className="RMS__page--table-column">
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            className="form-input--checkbox"
                            name={data?.email}
                            checked={
                              selectAll ||
                              selectedBudgetUsers?.includes(data?.email)
                            }
                          />
                          <label
                            htmlFor={data?.email}
                            onClick={() =>
                              !selectAll && handleBulkSelect(data?.email)
                            }
                          ></label>
                        </div>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{data?.name || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{data?.email || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>
                          {' '}
                          {usersDepartments?.[
                            getArrayIndexUsingKey(
                              usersDepartments,
                              '_id',
                              data?.departmentId
                            )
                          ]?.name || '-'}
                        </p>
                      </div>
                      <div className="RMS__page--table-column">
                        <input
                          type="text"
                          placeholder="Type"
                          value={data?.generalBudgetLimit || ''}
                          onChange={e =>
                            e.target.validity.valid &&
                            handleBudgetLimit(
                              e.target.value,
                              data?.email,
                              BudgetTypes.generalBudget
                            )
                          }
                          pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
                        />
                      </div>
                      {!isGroupTemplateType && (
                        <div className="RMS__page--table-column">
                          <input
                            type="text"
                            placeholder="Type"
                            value={data?.professionalBudgetLimit || ''}
                            onChange={e =>
                              e.target.validity.valid &&
                              handleBudgetLimit(
                                e.target.value,
                                data?.email,
                                BudgetTypes.proffBudget
                              )
                            }
                            pattern="^[1-9]\d{0,6}(\.\d{0,2})?$"
                          />
                        </div>
                      )}

                      <div className="RMS__page--table-column">
                        <img
                          src={iconDelete}
                          alt="delete-data"
                          onClick={() => {
                            setIsBulkRemove(false);
                            setDeleteUserPopTitle(t('Delete a user'));
                            setDeleteUserPopMsgBodyPartOne(
                              t('Are you sure you want to delete this user?')
                            );
                            setDeleteUserPopMsgBodyPartTwo(
                              t('is part of this template!')
                            );
                            setDeleteUserData(data);
                            setIsUserDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <NoContent />
                )}
              </div>
            </div>
            <div className="RMS__page--submit">
              {users?.length ? (
                <>
                  <button
                    className="btn btn--secondary btn--marginRight"
                    onClick={() => {
                      selectedBudgetUsers?.length && setIsBudgetLimitOpen(true);
                      setSelectedBulkBudgetType(BudgetTypes.generalBudget);
                      setBudgetModalName(t('Set General Budget Limit'));
                    }}
                  >
                    {t('Set General Budget')}
                  </button>
                  {!isGroupTemplateType && (
                    <button
                      className="btn btn--secondary btn--marginRight"
                      onClick={() => {
                        selectedBudgetUsers?.length &&
                          setIsBudgetLimitOpen(true);
                        setSelectedBulkBudgetType(BudgetTypes.proffBudget);
                        setBudgetModalName(t('Set Proff. Budget Limit'));
                      }}
                    >
                      {t('Set Proff. Budget')}
                    </button>
                  )}
                </>
              ) : null}

              <button
                className="btn btn--add"
                onClick={() => setIsAddTemplateUsers(true)}
              >
                <img src={iconAddButton} alt="add-data" />
              </button>

              {isAddTemplateUsers && (
                <AddTemplateUserContainer
                  isGroupTemplateType={isGroupTemplateType}
                  onHandleClose={(isCancelled, backUpData) => {
                    isCancelled && cancelLastUpdate(backUpData);
                    setIsAddTemplateUsers(false);
                  }}
                  templateId={templateData?._id || ''}
                />
              )}

              {isBudgetLimitOpen && (
                <AddBudgetModal
                  onHandleClose={() => {
                    setIsBudgetLimitOpen(false);
                  }}
                  setBudgetValues={value =>
                    setBulkBudgetValues(selectedBulkBudgetType, value)
                  }
                  header={budgetModalName}
                />
              )}

              {isUserDeleteModalOpen && (
                <DeleteUserModal
                  onHandleClose={() => setIsUserDeleteModalOpen(false)}
                  header={deleteUserPopTitle}
                  msgPartOne={deleteUserPopMsgBodyPartOne}
                  msgPartTwo={deleteUserPopMsgBodyPartTwo}
                  deleteUserData={deleteUserData}
                  bulkRemove={bulkRemove}
                  setSelectAll={setSelectAll}
                  removeSelectedUsers={data => {
                    removeSelectedUsers(data);
                    setIsUserDeleteModalOpen(false);
                  }}
                  isBulkRemove={isBulkRemove}
                />
              )}
            </div>
          </div>
          <div className="RMS__page--submit overall">
            <div className="RMS__page--error">
              <div className="RMS__page--error-content">
                {errorMessage || t(createTemplateFailMessage)}
              </div>
            </div>

            <button
              className="btn btn--secondary btn--marginRight"
              onClick={() => history.push(`/rms/manage-template`)}
            >
              {t('Back')}
            </button>
            <button
              className={`btn btn--primary ${
                createTemplateLoading && 'btn--loader'
              }`}
              onClick={() => handleSubmit()}
            >
              {!createTemplateLoading && t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
