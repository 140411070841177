export const GET_RMS_LOGGED_IN_USER_DETAILS_REQUEST =
  'GET_RMS_LOGGED_IN_USER_DETAILS_REQUEST';
export const GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS =
  'GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS';
export const GET_RMS_LOGGED_IN_USER_DETAILS_FAILED =
  'GET_RMS_LOGGED_IN_USER_DETAILS_FAILED';

export const POST_RMS_NORMAL_REQUEST_REQUEST =
  'POST_RMS_NORMAL_REQUEST_REQUEST';
export const POST_RMS_NORMAL_REQUEST_SUCCESS =
  'POST_RMS_NORMAL_REQUEST_SUCCESS';
export const POST_RMS_NORMAL_REQUEST_FAILED = 'POST_RMS_NORMAL_REQUEST_FAILED';

export const RESET_NORMAL_REQUEST_DATA = 'RESET_NORMAL_REQUEST_DATA';

export const GET_RMS_NORMAL_BOND_DATA_REQUEST =
  'GET_RMS_NORMAL_BOND_DATA_REQUEST';
export const GET_RMS_NORMAL_BOND_DATA_SUCCESS =
  'GET_RMS_NORMAL_BOND_DATA_SUCCESS';
export const GET_RMS_NORMAL_BOND_DATA_FAILED =
  'GET_RMS_NORMAL_BOND_DATA_FAILED';
