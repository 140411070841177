import Auth from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router';
import useApi from '../../../Hooks/useApi';
import {
  RequestSubmissionErrors,
  RequestType,
  Services,
} from '../../Common/constants';
import BondConfirmationModal from '../../Components/Modal/BondConfirmationModal';
import BudgetCheckModal from '../../Components/Modal/BudgetCheckModal';
import TrainingFormCourseDetails from '../../Components/TrainingForm/CommonComponents/TrainingFormCourseDetails';
import TrainingFormOtherDetails from '../../Components/TrainingForm/CommonComponents/TrainingFormOtherDetails';
import TrainingFormPersonalDetails from '../../Components/TrainingForm/TrainingFormPersonalDetails';
import { fileUploadPath } from '../../Common/constants';
import {
  GET_RMS_LOGGED_IN_USER_DETAILS_FAILED,
  GET_RMS_LOGGED_IN_USER_DETAILS_REQUEST,
  GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS,
  GET_RMS_NORMAL_BOND_DATA_FAILED,
  GET_RMS_NORMAL_BOND_DATA_REQUEST,
  GET_RMS_NORMAL_BOND_DATA_SUCCESS,
  POST_RMS_NORMAL_REQUEST_FAILED,
  POST_RMS_NORMAL_REQUEST_REQUEST,
  POST_RMS_NORMAL_REQUEST_SUCCESS,
  RESET_NORMAL_REQUEST_DATA,
} from '../../redux/actionTypes/normalRequestTypes';
import {
  GET_RMS_CENTER_DETAILS_FAILED,
  GET_RMS_CENTER_DETAILS_REQUEST,
  GET_RMS_CENTER_DETAILS_SUCCESS,
  GET_RMS_DEPARTMENT_DETAILS_FAILED,
  GET_RMS_DEPARTMENT_DETAILS_REQUEST,
  GET_RMS_DEPARTMENT_DETAILS_SUCCESS,
  GET_RMS_DESIGNATION_DETAILS_FAILED,
  GET_RMS_DESIGNATION_DETAILS_REQUEST,
  GET_RMS_DESIGNATION_DETAILS_SUCCESS,
  GET_RMS_USER_QUALIFICATIONS_FAILED,
  GET_RMS_USER_QUALIFICATIONS_REQUEST,
  GET_RMS_USER_QUALIFICATIONS_SUCCESS,
} from '../../redux/actionTypes/userManagementTypes';

import {
  RMS_GET_CLOUD_STORAGE_DATA_REQUEST,
  RMS_GET_CLOUD_STORAGE_DATA_SUCCESS,
  RMS_GET_CLOUD_STORAGE_DATA__FAILED,
} from '../../redux/actionTypes/commonTypes';

const NormalRequestContainer = () => {
  const [getLoggedInUserDetailsApi] = useApi();
  const [getCenterDetailsApi] = useApi();
  const [getDepartmentDetailsApi] = useApi();
  const [getDesignationDetailsApi] = useApi();
  const [getUserQualificationsApi] = useApi();
  const [submitNormalRequestApi] = useApi();
  const [getBondDataApi] = useApi();
  const [getCloudStorageDataApi] = useApi();

  const [postData, setPostData] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showBudgetCheckPopup, setShowBudgetCheckPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bondBracketAvailable, setBondBracketAvailable] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const history = useHistory();
  const { params, path, url } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    userDetails,
    normalRequestSubmitLoading,
    normalRequestSubmitError,
    normalRequestSubmitErrorMessage,
    normalRequestSubmitSuccess,
    bondDataLoading,
    bondDataSuccess,
    bondData,
  } = useSelector(state => state.rmsNormalRequest);

  const { departments, designations, qualifications, centers } = useSelector(
    state => state.rmsUserManagement
  );

  const getLoggedInUserDetails = email => {
    email &&
      getLoggedInUserDetailsApi(
        `/api/v1/users?emailList=${email}`,
        GET_RMS_LOGGED_IN_USER_DETAILS_REQUEST,
        GET_RMS_LOGGED_IN_USER_DETAILS_SUCCESS,
        GET_RMS_LOGGED_IN_USER_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };

  const getCloudStorageData = () => {
    getCloudStorageDataApi(
      `/api/v1/cloud-storage/path/${fileUploadPath.REQUEST_FORM}`,
      RMS_GET_CLOUD_STORAGE_DATA_REQUEST,
      RMS_GET_CLOUD_STORAGE_DATA_SUCCESS,
      RMS_GET_CLOUD_STORAGE_DATA__FAILED,
      {},
      {},
      'GET',
      false,
      'isRmsService'
    );
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        getLoggedInUserDetails(user.attributes.email || '');
      })
      .catch(e => console.error(e));

    getCloudStorageData();

    return () => {
      dispatch({
        type: RESET_NORMAL_REQUEST_DATA,
      });
    };
  }, []);

  useEffect(() => {
    if (userDetails) {
      const { centerId, departmentId, designationId, qualificationId } =
        userDetails;

      centerId && getCenterDetails([centerId]);
      departmentId && getDepartmentDetails([departmentId]);
      designationId && getDesignationDetails([designationId]);
      qualificationId && getQualificationDetails([qualificationId]);
    }
  }, [userDetails]);

  const getQualificationDetails = qualificationIds => {
    qualificationIds?.length &&
      getUserQualificationsApi(
        `/api/v1/qualifications?qualificationIds=${qualificationIds}&page=1&size=${qualificationIds.length}`,
        GET_RMS_USER_QUALIFICATIONS_REQUEST,
        GET_RMS_USER_QUALIFICATIONS_SUCCESS,
        GET_RMS_USER_QUALIFICATIONS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getCenterDetails = centerIds => {
    centerIds?.length &&
      getCenterDetailsApi(
        `/api/v1/centers?centerIds=${centerIds}&page=1&size=${centerIds.length}`,
        GET_RMS_CENTER_DETAILS_REQUEST,
        GET_RMS_CENTER_DETAILS_SUCCESS,
        GET_RMS_CENTER_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDepartmentDetails = departmentIds => {
    departmentIds?.length &&
      getDepartmentDetailsApi(
        `/api/v1/departments?departmentIds=${departmentIds}&page=1&size=${departmentIds.length}`,
        GET_RMS_DEPARTMENT_DETAILS_REQUEST,
        GET_RMS_DEPARTMENT_DETAILS_SUCCESS,
        GET_RMS_DEPARTMENT_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };
  const getDesignationDetails = designationIds => {
    designationIds?.length &&
      getDesignationDetailsApi(
        `/api/v1/designations?designationIds=${designationIds}&page=1&size=${designationIds.length}`,
        GET_RMS_DESIGNATION_DETAILS_REQUEST,
        GET_RMS_DESIGNATION_DETAILS_SUCCESS,
        GET_RMS_DESIGNATION_DETAILS_FAILED,
        {},
        {},
        'GET',
        false,
        'isRmsService'
      );
  };

  useEffect(() => {
    const { grossFee } = postData?.course || {};
    if (grossFee) {
      getBondDetails(grossFee);
    }
  }, [postData?.course]);

  useEffect(() => {
    if (!bondDataLoading && bondDataSuccess) {
      if (Object.keys(bondData || {})?.length) {
        //bond exist
        setBondBracketAvailable(true);
      } else {
        //no bonds
        setBondBracketAvailable(false);
      }
    }
  }, [bondDataLoading]);

  const gotoNextRoute = nextRoute => {
    if (!nextRoute) return;
    history.push(`${path}/${nextRoute}`);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onNoClick = () => {
    setShowConfirmationPopup(false);
    history.push('/rms');
  };

  const updatePostData = (nextRoute, data = {}) => {
    setPostData(existingData => {
      return {
        ...existingData,
        ...data,
      };
    });
    if (nextRoute) {
      gotoNextRoute(nextRoute);
    } else {
      //last form
      if (bondBracketAvailable) {
        setShowConfirmationPopup(true);
      } else {
        setSubmitClicked(true);
      }
    }
  };

  const isRequiredKeysExists = () => {
    return ['course', 'examination'].every(key =>
      Object.keys(postData).includes(key)
    );
  };

  useEffect(() => {
    if (submitClicked && isRequiredKeysExists()) {
      submitNormalRequest();
      setSubmitClicked(false);
    }
  }, [submitClicked, postData]);

  const submitNormalRequest = () => {
    if (!isRequiredKeysExists()) {
      //course details and other details not added
      return;
    }

    const formattedReqBody = {
      ...postData,
      applicantId: userDetails._id,
      type: RequestType.NORMAL_REQUEST,
    };
    submitNormalRequestApi(
      `/api/v1/trainingRequests`,
      POST_RMS_NORMAL_REQUEST_REQUEST,
      POST_RMS_NORMAL_REQUEST_SUCCESS,
      POST_RMS_NORMAL_REQUEST_FAILED,
      formattedReqBody,
      {},
      'POST',
      false,
      'isRmsService'
    );
  };

  const getBondDetails = grossFee => {
    userDetails?._id &&
      grossFee &&
      getBondDataApi(
        `/api/v1/bandBrackets/applicableBandBracket?applicantId=${userDetails?._id}&grossFee=${grossFee}`,
        GET_RMS_NORMAL_BOND_DATA_REQUEST,
        GET_RMS_NORMAL_BOND_DATA_SUCCESS,
        GET_RMS_NORMAL_BOND_DATA_FAILED,
        {},
        {},
        'GET',
        false,
        Services.isRmsService
      );
  };

  useEffect(() => {
    if (normalRequestSubmitError && normalRequestSubmitErrorMessage) {
      if (
        normalRequestSubmitErrorMessage ===
        RequestSubmissionErrors.INSUFFICIENT_BUDGET
      ) {
        setShowConfirmationPopup(false);
        setShowBudgetCheckPopup(true);
        setErrorMessage('');
      } else if (
        normalRequestSubmitErrorMessage ===
        RequestSubmissionErrors.PRICE_BRACKET_NOT_DEFINED
      ) {
        setErrorMessage(t('Price bracket not defined for the given gross fee'));
      } else {
        setErrorMessage(t('Error while creating request. Please try again.'));
      }
    } else if (normalRequestSubmitError) {
      setErrorMessage(t('Error while creating request. Please try again.'));
    } else {
      setErrorMessage('');
    }
  }, [normalRequestSubmitError]);

  const onBudgetCheckOkClick = () => {
    setShowBudgetCheckPopup(false);
  };

  useEffect(() => {
    if (
      !normalRequestSubmitLoading &&
      !normalRequestSubmitError &&
      normalRequestSubmitSuccess
    ) {
      //request save success
      history.push('/rms');
    }
  }, [
    normalRequestSubmitLoading,
    normalRequestSubmitError,
    normalRequestSubmitSuccess,
  ]);

  return (
    <div>
      <Route
        exact
        path="/rms/normal-request"
        children={({ match }) => (
          <TrainingFormPersonalDetails
            onSave={updatePostData}
            onBack={onBackClick}
            userDetails={userDetails}
            departments={departments}
            designations={designations}
            qualifications={qualifications}
            centers={centers}
            match={match}
          />
        )}
      />
      <Route
        path="/rms/normal-request/course"
        children={({ match }) => (
          <TrainingFormCourseDetails
            onBack={onBackClick}
            onSave={updatePostData}
            match={match}
          />
        )}
      />
      <Route
        path="/rms/normal-request/other"
        children={({ match }) => (
          <TrainingFormOtherDetails
            onSave={updatePostData}
            onBack={onBackClick}
            match={match}
            loadingButton={normalRequestSubmitLoading}
            submitErrorMessage={errorMessage}
          />
        )}
      />
      {showConfirmationPopup && (
        <BondConfirmationModal
          onClose={() => setShowConfirmationPopup(false)}
          onNoClick={onNoClick}
          onYesClick={submitNormalRequest}
          loadingButton={normalRequestSubmitLoading}
          errorMessage={errorMessage}
        />
      )}
      {showBudgetCheckPopup && (
        <BudgetCheckModal
          onClose={() => setShowBudgetCheckPopup(false)}
          onOkClick={onBudgetCheckOkClick}
          userName={userDetails?.name || ''}
        />
      )}
    </div>
  );
};

export default NormalRequestContainer;
