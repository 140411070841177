import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DeleteTemplateModal = ({
  onHandleClose,
  deleteTemplateData,
  deleteTemplate,
  deleteTemplateLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="reel-view--popup RMS__popUp textMessage">
      <div className="reel-view--popup-container">
        <div className="reel-view--popup-header noBorder">
          <h3>{t('Delete template')}</h3>
          <a
            className="btn-close closemodale"
            aria-hidden="true"
            onClick={() => onHandleClose()}
          >
            &times;
          </a>
        </div>

        <div className="reel-view--popup-body">
          <div className="reel-view--popup-row">
            <p>
              {t('Are you sure you want to delete')}{' '}
              {deleteTemplateData ? (
                <span>{deleteTemplateData.name} </span>
              ) : (
                ''
              )}{' '}
              ?
            </p>
          </div>
          <div className="reel-view--popup-row">
            <button
              className={`btn btn--primary ${
                deleteTemplateLoading && 'btn--loader'
              }`}
              disabled={deleteTemplateLoading}
              onClick={() => {
                deleteTemplate(deleteTemplateData);
              }}
            >
              {!deleteTemplateLoading && t('Yes')}
            </button>
            <button
              className="btn btn--secondary"
              onClick={() => {
                onHandleClose();
              }}
            >
              {t('No')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTemplateModal;
