import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../../Components/Loader/DottedLoader';
import NoContent from '../../../../Components/NoContent/NoContent';
import { RequestType } from '../../../Common/constants';
import useConvertDate from '../../../hooks/useConvertDate';
import { CourseTypes } from '../../../Common/constants';

const Requests = ({ requests, loading, loadNextRequestPage, userId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { convertDateISOToString } = useConvertDate();
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const viewRequestForm = trainingRequestId => {
    history.push({
      pathname: `/rms/super-admin-dashboard/reporting/requestform`,
      state: { trainingRequestId },
    });
  };

  useEffect(() => {
    setSelectedUserIds(userId);
  }, [userId]);

  const redirectToArchivalPage = () => {
    selectedUserIds?.length &&
      history.push({
        pathname: `/rms/archival-training-request`,
        state: { userIds: selectedUserIds },
      });
  };

  return (
    <>
      <div className="RMS__page--header">
        <div className="RMS__page--header-row">
          <div className="RMS__page--header-left">
            <p>{t('Requests')}</p>
          </div>
          <div className="RMS__page--header-right">
            <button
              className="btn btn--primary btn--tableButton btn--whiteText"
              onClick={() => redirectToArchivalPage()}
            >
              {t('Archival Records')}
            </button>
          </div>
        </div>
      </div>
      <div className="RMS__page--body">
        <div className="RMS__page--table">
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Normal/Group')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Gross Fee')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Net Fee')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Training Type')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Institution')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Start Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('End Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Status')}</h4>
            </div>
          </div>

          <div className="RMS__page--table-body">
            {requests?.length ? (
              requests?.map(
                ({
                  _id,
                  requestId,
                  type,
                  requestStatus,
                  course: {
                    grossFee,
                    netFee,
                    courseType,
                    institutionName,
                    startDate,
                    endDate,
                  },
                }) => (
                  <div
                    key={_id}
                    className="RMS__page--table-row clickable-row"
                    onClick={() => {
                      viewRequestForm(_id);
                    }}
                  >
                    <div className="RMS__page--table-column">
                      <p>{requestId || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {type === RequestType.NORMAL_REQUEST
                          ? 'Normal'
                          : RequestType.GROUP_REQUEST
                          ? 'Group'
                          : '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{grossFee?.toString() || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{netFee?.toString() || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>
                        {courseType === CourseTypes.GENERAL
                          ? 'General'
                          : CourseTypes.PROFESSIONAL
                          ? 'Professional'
                          : '-'}
                      </p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{institutionName || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(startDate) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{convertDateISOToString(endDate) || '-'}</p>
                    </div>
                    <div className="RMS__page--table-column">
                      <p>{requestStatus || '-'}</p>
                    </div>
                  </div>
                )
              )
            ) : loading ? (
              <Loader />
            ) : (
              <NoContent message={t('Requests not available')} />
            )}
          </div>
          <ReactVisibilitySensor
            partialVisibility
            onChange={isVisible => {
              if (isVisible) {
                loadNextRequestPage();
              }
            }}
          >
            <div>&nbsp;</div>
          </ReactVisibilitySensor>
        </div>
      </div>
    </>
  );
};

export default Requests;
