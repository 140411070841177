import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import {
  GET_RMS_CENTER_DETAILS_SUCCESS,
  GET_RMS_DEPARTMENT_DETAILS_SUCCESS,
  GET_RMS_DESIGNATION_DETAILS_SUCCESS,
  GET_RMS_USER_QUALIFICATIONS_SUCCESS,
  SEARCH_RMS_USERS_RESET,
  SEARCH_RMS_USERS_SUCCESS,
  GET_RMS_CENTER_SUCCESS,
  GET_RMS_DEPARTMENT_SUCCESS,
  GET_RMS_DESIGNATION_SUCCESS,
  RESET_ADD_USER_POPUP_DATA,
  RMS_ADD_REMOVE_SELECTED_USERS,
  RMS_EXISTING_USER_MANAGEMENT_FAIL,
  RMS_EXISTING_USER_MANAGEMENT_REQUEST,
  RMS_EXISTING_USER_MANAGEMENT_SUCCESS,
  RMS_POST_ADDED_USERS_REQUEST,
  RMS_POST_ADDED_USERS_SUCCESS,
  RMS_SEARCH_LOADING_SET,
  RMS_USER_MANAGEMENT_FAIL,
  RMS_USER_MANAGEMENT_REQUEST,
  RMS_USER_MANAGEMENT_SUCCESS,
  GET_RMS_QUALIFICATION_SUCCESS,
  RMS_UPDATE_USER_REQUEST,
  RMS_UPDATE_USER_SUCCESS,
  RMS_UPDATE_USER_FAILED,
  RMS_RESET_USER_UPDATE,
  RMS_RESET_USER_MANAGEMENT,
  RMS_DELETE_USER_REQUEST,
  RMS_DELETE_USER_SUCCESS,
  RMS_DELETE_USER_FAILED,
  RMS_RESET_ERROR_MESSAGE,
  GET_RMS_COMPANY_SUCCESS,
  GET_RMS_COMPANY_DETAILS_SUCCESS,
  RMS_DESIGNATION_RESET,
  RMS_QUALIFICATION_RESET,
  RMS_CENTER_RESET,
  RMS_DEPARTMENT_RESET,
} from '../actionTypes/userManagementTypes';
const INITIAL_STATE = {
  allCenters: [],
  allDepartments: [],
  allDesignations: [],
  allQualifications: [],
  centers: [],
  deleteUserError: false,
  deleteUserErrorMessage: '',
  deleteUserLoading: false,
  deleteUserSuccess: false,
  departments: [],
  designations: [],
  existUsersLoading: false,
  existingUsers: null,
  qualifications: {},
  saveLoading: false,
  selectedUsers: [],
  successClose: false,
  updateUserError: false,
  updateUserErrorMsg: '',
  updateUserLoading: false,
  updateUserSuccess: false,
  userData: null,
  userLoading: false,
  users: [],
  usersPage: 0,
  usersTotalRecords: 0,
  allCompanies: [],
  companies: [],
  designationPage: 0,
  designationTotalRecord: 0,
  qualificationPage: 0,
  qualificationTotalRecord: 0,
  centerPage: 0,
  centerTotalRecord: 0,
  departmentPage: 0,
  departmentTotalRecord: 0,
};

const userManagementReducer = (state = INITIAL_STATE, action) => {
  const {
    data = [],
    page = 0,
    size = 0,
    totalRecords = 0,
  } = action.payload || {};
  switch (action.type) {
    case SEARCH_RMS_USERS_RESET:
      return {
        ...state,
        users: [],
        usersPage: 0,
        usersTotalRecords: 0,
      };
    case SEARCH_RMS_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...data],
        usersPage: page || state.usersPage,
        usersTotalRecords: totalRecords || state.totalRecords,
      };

    case GET_RMS_USER_QUALIFICATIONS_SUCCESS: {
      const updatedQualifications = { ...state.qualifications };
      if (data?.length) {
        data.forEach(qualification => {
          updatedQualifications[qualification._id] = qualification;
        });
      }
      return {
        ...state,
        qualifications: updatedQualifications,
      };
    }
    case GET_RMS_CENTER_DETAILS_SUCCESS: {
      const updatedCenters = { ...state.centers };
      if (data?.length) {
        data.forEach(center => {
          updatedCenters[center._id] = center;
        });
      }
      return {
        ...state,
        centers: updatedCenters,
      };
    }
    case GET_RMS_CENTER_SUCCESS: {
      return {
        ...state,
        allCenters: [...state.allCenters, ...data],
        centerPage: page || state.centerPage,
        centerTotalRecord: totalRecords || state.centerTotalRecord,
      };
    }
    case GET_RMS_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        allDepartments: [...state.allDepartments, ...data],
        departmentPage: page || state.departmentPage,
        departmentTotalRecord: totalRecords || state.departmentTotalRecord,
      };
    }
    case GET_RMS_DESIGNATION_SUCCESS: {
      return {
        ...state,
        allDesignations: [...state.allDesignations, ...data],
        designationPage: page || state.designationPage,
        designationTotalRecord: totalRecords || state.designationTotalRecord,
      };
    }
    case GET_RMS_QUALIFICATION_SUCCESS: {
      return {
        ...state,
        allQualifications: [...state.allQualifications, ...data],
        qualificationPage: page || state.qualificationPage,
        qualificationTotalRecord:
          totalRecords || state.qualificationTotalRecord,
      };
    }
    case GET_RMS_DEPARTMENT_DETAILS_SUCCESS: {
      const updatedDepartments = { ...state.departments };
      if (data?.length) {
        data.forEach(department => {
          updatedDepartments[department._id] = department;
        });
      }
      return {
        ...state,
        departments: updatedDepartments,
      };
    }
    case GET_RMS_DESIGNATION_DETAILS_SUCCESS: {
      const updatedDesignations = { ...state.designations };
      if (data?.length) {
        data.forEach(designation => {
          updatedDesignations[designation._id] = designation;
        });
      }
      return {
        ...state,
        designations: updatedDesignations,
      };
    }
    case RMS_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        userLoading: true,
        existUsersLoading: true,
      };
    case RMS_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userData: action?.payload?.data || null,
        existUsersLoading: !!action?.payload?.data,
      };
    case RMS_USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userLoading: false,
        existUsersLoading: false,
      };
    case RMS_EXISTING_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        existUsersLoading: true,
      };
    case RMS_EXISTING_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        existingUsers: getExistingUsersEmail(action?.payload?.data || null),
        existUsersLoading: false,
      };
    case RMS_EXISTING_USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userLoading: false,
        existUsersLoading: false,
      };
    case RMS_SEARCH_LOADING_SET:
      return {
        ...state,
        userLoading: true,
        existUsersLoading: true,
      };
    case RMS_ADD_REMOVE_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: setSelectedUsers(action?.payload, state.selectedUsers),
      };
    case RMS_POST_ADDED_USERS_REQUEST: {
      return {
        ...state,
        saveLoading: true,
      };
    }
    case RMS_POST_ADDED_USERS_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        successClose: true,
      };
    }
    case RESET_ADD_USER_POPUP_DATA:
      return {
        ...state,
        userLoading: false,
        userData: null,
        existingUsers: null,
        existUsersLoading: false,
        selectedUsers: [],
        saveLoading: false,
        successClose: false,
      };

    case RMS_UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserError: false,
        updateUserErrorMsg: '',
      };
    case RMS_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: true,
      };
    case RMS_UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: true,
        updateUserErrorMsg: action?.payload || '',
      };

    case RMS_RESET_USER_UPDATE:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: false,
      };

    case RMS_DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserError: false,
        deleteUserSuccess: false,
        deleteUserErrorMessage: '',
      };
    case RMS_DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: true,
      };
    case RMS_DELETE_USER_FAILED:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: true,
        deleteUserErrorMessage: action.payload || '',
      };
    case RMS_RESET_ERROR_MESSAGE:
      return {
        ...state,
        updateUserErrorMsg: '',
      };

    case GET_RMS_COMPANY_SUCCESS: {
      return {
        ...state,
        allCompanies: data || [],
      };
    }

    case GET_RMS_COMPANY_DETAILS_SUCCESS: {
      const updatedCompanies = { ...state.companies };
      if (data?.length) {
        data.forEach(company => {
          updatedCompanies[company?._id] = company;
        });
      }
      return {
        ...state,
        companies: updatedCompanies,
      };
    }
    case RMS_DESIGNATION_RESET:
      return {
        ...state,
        allDesignations: [],
        designationPage: 0,
        designationTotalRecord: 0,
      };
    case RMS_QUALIFICATION_RESET:
      return {
        ...state,
        allQualifications: [],
        qualificationPage: 0,
        qualificationTotalRecord: 0,
      };
    case RMS_CENTER_RESET:
      return {
        ...state,
        allCenters: [],
        centerPage: 0,
        centerTotalRecord: 0,
      };
    case RMS_DEPARTMENT_RESET:
      return {
        ...state,
        allDepartments: [],
        departmentPage: 0,
        departmentTotalRecord: 0,
      };
    case RMS_RESET_USER_MANAGEMENT:
    case CLEAR_SESSION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

const getExistingUsersEmail = existUsers => {
  let newExistUsers = null;
  if (existUsers?.length) {
    newExistUsers = existUsers.map(data => data?.email);
  }
  return newExistUsers;
};
const setSelectedUsers = (selectedUser, currentData) => {
  const index = currentData?.indexOf(selectedUser);
  if (index > -1) {
    currentData.splice(index, 1);
  } else {
    currentData.push(selectedUser);
  }
  return currentData || [];
};
export default userManagementReducer;
