export const RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST =
  'RMS_GET_ARCHIVAL_TRAINING_DATA_REQUEST';
export const RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS =
  'RMS_GET_ARCHIVAL_TRAINING_DATA_SUCCESS';
export const RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED =
  'RMS_GET_ARCHIVAL_TRAINING_DATA_FAILED';

export const RMS_RESET_ARCHIVAL_TRAINING_DATA =
  'RMS_RESET_ARCHIVAL_TRAINING_DATA';

export const RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST =
  'RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_REQUEST';
export const RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS =
  'RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_SUCCESS';
export const RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED =
  'RMS_FETCH_ARCHIVAL_TRAINING_S3_FILE_FAILED';

export const RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA =
  'RMS_RESET_ARCHIVAL_TRAINING_S3_BUCKET_DATA';
