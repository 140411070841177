import React from 'react';
import { useTranslation } from 'react-i18next';
import useConvertDate from '../../../../hooks/useConvertDate';

const Assessments = ({ data }) => {
  const { t } = useTranslation();
  const { examination } = data || {};
  const {
    resetTimeFromIsoString,
    removeDateFromIsoString,
    convertDateISOToString,
    convertDateISOToTimeString,
  } = useConvertDate();

  return (
    <>
      <div className="RMS__page--body">
        <div className="RMS__page--body-row">
          <div className="RMS__page--body-column">
            <div className="form--field row">
              <p className={`form--field-title `}>{t('Type of Assessments')}</p>
              <div className="form--field-input">
                <input
                  disabled
                  type="text"
                  placeholder={t('N/A')}
                  value={examination?.typeOfAssessment || 'No'}
                />
              </div>
            </div>
          </div>
        </div>
        {examination?.scheduledExams?.map(({ startDate, location }, i) => (
          <div key={i}>
            <div className="RMS__page--body-row">
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className={`form--field-title }`}>
                    {t('Date of the exam')}
                  </p>
                  <div className="form--field-input">
                    <input
                      disabled
                      type="text"
                      placeholder={t('N/A')}
                      value={convertDateISOToString(
                        resetTimeFromIsoString(startDate)
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className={`form--field-title `}>{t('Time of Exam')}</p>
                  <div className="form--field-input">
                    <input
                      disabled
                      type="text"
                      placeholder={t('N/A')}
                      value={convertDateISOToTimeString(startDate)}
                    />
                  </div>
                </div>
              </div>
              <div className="RMS__page--body-column">
                <div className="form--field twoRows">
                  <p className={'form--field-title'}>{t('Location of Exam')}</p>
                  <div className="form--field-input">
                    <input
                      disabled
                      type="text"
                      placeholder={t('N/A')}
                      value={location}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="RMS__page--divider" />
          </div>
        ))}
      </div>
    </>
  );
};

export default Assessments;
