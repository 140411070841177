import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Loader from '../../../Components/Loader/DottedLoader';
import NoContent from '../../../Components/NoContent/NoContent';
import { ActionMode, RequestStatus, UserModes } from '../../Common/constants';
import FeedbackContainer from '../../Container/FeedBackContainer/FeedBackContainer';
import { formatText } from '../../Common/helper';
import useConvertId from '../../hooks/useConvertId';
import EditAndDeleteIconBtn from '../ManageRequets/EditAndDeleteIconBtn';
import useConvertDate from '../../hooks/useConvertDate';

const TableGrid = ({
  userMode,
  selectedAdminDropdown,
  onDelete,
  requests,
  loadNextPage = () => {},
  userDetails,
  onRemoveRow,
  requestLoading = false,
  feedbackRequest,
}) => {
  const { convertDateISOToString } = useConvertDate();
  const { t } = useTranslation();
  const { getFormattedDate } = useConvertId();
  const history = useHistory();
  const [isFeedback, setIsFeedback] = useState(false);
  const [requestIdForFeedBack, setRequestIdForFeedBack] = useState();
  const [applicantId, setApplicantId] = useState();
  const [isViewFeedback, setIsViewFeedback] = useState(false);

  const userOrSubmittedSelected =
    userMode === UserModes.USER ||
    selectedAdminDropdown === ActionMode.SUBMITTED;

  const adminAndApprovalSelected =
    selectedAdminDropdown === ActionMode.APPROVALS &&
    userMode === UserModes.ADMIN;

  const adminAndPastRequest =
    selectedAdminDropdown === ActionMode.PAST_REQUESTS &&
    userMode === UserModes.ADMIN;

  const viewRequestForm = trainingRequestId => {
    history.push({
      pathname: `/rms/manage-request/requestform`,
      state: { trainingRequestId },
    });
  };

  const viewFeedbackCheck = id => {
    const isView = feedbackRequest?.find(data => {
      return data.trainingRequestId === id;
    });
    if (isView) {
      return true;
    } else {
      return false;
    }
  };

  const clickOnViewFeedBack = (id, applicantId) => {
    setIsViewFeedback(true);
    setIsFeedback(true);
    setRequestIdForFeedBack(id);
    setApplicantId(applicantId);
  };

  const clickOnWriteFeedBack = (id, applicantId) => {
    setIsFeedback(true);
    setIsViewFeedback(false);
    setRequestIdForFeedBack(id);
    setApplicantId(applicantId);
  };

  return (
    <>
      <div className="RMS__page--body">
        <div
          className={`RMS__page--table ${
            (selectedAdminDropdown === ActionMode.APPROVALS && 'approvals') ||
            (selectedAdminDropdown === ActionMode.PAST_REQUESTS &&
              'pastRequest') ||
            (selectedAdminDropdown === ActionMode.SUBMITTED && 'submitted')
          }`}
        >
          <div className="RMS__page--table-header">
            <div className="RMS__page--table-column">
              <h4>{t('Request ID')}</h4>
            </div>
            {userMode !== UserModes.USER && (
              <>
                <div className="RMS__page--table-column">
                  <h4>{t('Name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Course name')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Institution')}</h4>
                </div>
                <div className="RMS__page--table-column">
                  <h4>{t('Gross Fee')}</h4>
                </div>
              </>
            )}
            <div className="RMS__page--table-column">
              <h4>{t('Training Type')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Date Submitted')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Training Start Date')}</h4>
            </div>
            <div className="RMS__page--table-column">
              <h4>{t('Status')}</h4>
            </div>
            {(selectedAdminDropdown !== ActionMode.APPROVALS ||
              userMode === UserModes.USER) && (
              <div className="RMS__page--table-column">
                <h4>{t('Approval/Rejected Date')}</h4>
              </div>
            )}
            <div className="RMS__page--table-column"></div>
          </div>
          <div className="RMS__page--table-body">
            {requests?.length ? (
              <>
                {requests.map(
                  (
                    {
                      requestId,
                      applicantId,
                      type,
                      createdAt,
                      course,
                      requestStatus,
                      approvedOrRejectedAt,
                      _id,
                      pendingApprovalLevelId,
                    },
                    i
                  ) => (
                    <div
                      key={_id}
                      className="RMS__page--table-row"
                      onClick={() => viewRequestForm(_id)}
                    >
                      <div className="RMS__page--table-column">
                        <p>{requestId || '-'}</p>
                      </div>
                      {userMode !== UserModes.USER && (
                        <>
                          <div className="RMS__page--table-column">
                            <p>{userDetails[applicantId] || '-'}</p>
                          </div>
                          <div className="RMS__page--table-column">
                            <p>{course?.title || '-'}</p>
                          </div>
                          <div className="RMS__page--table-column">
                            <p>{course?.institutionName || '-'}</p>
                          </div>
                          <div className="RMS__page--table-column">
                            <p>{course?.grossFee?.toString() || '-'}</p>
                          </div>
                        </>
                      )}
                      <div className="RMS__page--table-column">
                        <p>{formatText(type) || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{convertDateISOToString(createdAt) || '-'}</p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>
                          {convertDateISOToString(course?.startDate) || '-'}
                        </p>
                      </div>
                      <div className="RMS__page--table-column">
                        <p>{requestStatus || '-'}</p>
                      </div>
                      {(selectedAdminDropdown !== ActionMode.APPROVALS ||
                        userMode === UserModes.USER) && (
                        <div className="RMS__page--table-column">
                          <p>
                            {convertDateISOToString(approvedOrRejectedAt) ||
                              '-'}
                          </p>
                        </div>
                      )}
                      <div className="RMS__page--table-column">
                        {userOrSubmittedSelected && (
                          <div>
                            {(requestStatus
                              ?.toLowerCase()
                              ?.includes(
                                RequestStatus.PENDING.toLowerCase()
                              ) && (
                              <EditAndDeleteIconBtn
                                deleteHandler={() => onDelete(requestId, _id)}
                              />
                            )) ||
                              (requestStatus?.toLowerCase() ===
                                RequestStatus.COMPLETED.toLowerCase() &&
                                userMode === UserModes.USER &&
                                !viewFeedbackCheck(_id) && (
                                  <button
                                    className="btn btn--secondary btn--thinButton btn--tableButton"
                                    onClick={e => {
                                      e.stopPropagation();
                                      clickOnWriteFeedBack(_id, applicantId);
                                    }}
                                  >
                                    {t('Write Feedback')}
                                  </button>
                                )) ||
                              (viewFeedbackCheck(_id) && (
                                <button
                                  className="btn btn--secondary btn--thinButton btn--tableButton"
                                  onClick={e => {
                                    e.stopPropagation();
                                    clickOnViewFeedBack(_id, applicantId);
                                  }}
                                >
                                  {t('View Feedback')}
                                </button>
                              ))}
                          </div>
                        )}
                        {adminAndApprovalSelected && (
                          <>
                            <button
                              className="btn btn--primary btn--thinButton btn--marginRight"
                              onClick={e => {
                                e.stopPropagation();
                                onRemoveRow(true, _id, pendingApprovalLevelId);
                              }}
                            >
                              {t('Accept')}
                            </button>
                            <button
                              className="btn btn--secondary btn--thinButton"
                              onClick={e => {
                                e.stopPropagation();
                                onRemoveRow(false, _id, pendingApprovalLevelId);
                              }}
                            >
                              {t('Reject')}
                            </button>
                          </>
                        )}
                        {adminAndPastRequest && viewFeedbackCheck(_id) && (
                          <div>
                            <button
                              className="btn btn--secondary btn--thinButton"
                              onClick={e => {
                                e.stopPropagation();
                                clickOnViewFeedBack(_id, applicantId);
                              }}
                            >
                              {t('View Feedback')}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible) {
                      loadNextPage();
                    }
                  }}
                >
                  <div>&nbsp;</div>
                </ReactVisibilitySensor>
              </>
            ) : requestLoading ? (
              <Loader />
            ) : (
              <NoContent message={t('Requests not available')} />
            )}
          </div>
        </div>
        {isFeedback && (
          <FeedbackContainer
            onHandleClose={() => setIsFeedback(false)}
            requestId={requestIdForFeedBack}
            applicantId={applicantId}
            isViewFeedback={isViewFeedback}
          />
        )}
      </div>
    </>
  );
};

export default TableGrid;
