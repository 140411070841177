import React from 'react';
import {
  PrimaryBtn,
  SecondaryBtn,
} from '../../../CommonComponents/Button/Button';
import { useHistory } from 'react-router';

const PageSubmit = ({ onSubmitClick, loading = false, errorMessage = '' }) => {
  const history = useHistory();
  const onBackHandler = () => {
    history.goBack();
  };

  return (
    <div>
      <div className="RMS__page--submit overall">
        <div className="RMS__page--error">
          <div className="RMS__page--error-content">{errorMessage}</div>
        </div>
        {onSubmitClick && (
          <PrimaryBtn
            text="Update"
            onClick={onSubmitClick}
            extraClassNames={`btn--marginRight ${loading ? `btn--loader` : ''}`}
          />
        )}
        <SecondaryBtn text="Back" onClick={onBackHandler} />
      </div>
    </div>
  );
};

export default PageSubmit;
