import moment from 'moment';
import { useSelector } from 'react-redux';

const useConvertDate = () => {
  const {
    themeInfo: { dateFormat },
  } = useSelector(state => state.auth);

  const addLeadingZeros = num => {
    try {
      const number = parseInt(num);
      return number < 10 ? `0${number}` : `${number}`;
    } catch {
      return '00';
    }
  };

  const convertDateToThemeFormat = dateObject => {
    if (!dateObject?.date || !dateFormat) {
      return '-';
    }

    return dateFormat
      .replace('DD', addLeadingZeros(dateObject.date))
      .replace('MM', addLeadingZeros(dateObject.month))
      .replace('YYYY', addLeadingZeros(dateObject.year));
  };

  //expected output => {date: today's date, month: today's month, year: today's year }
  const getCurrentDateObject = () => {
    return {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
  };

  const getDateObjectFromISOString = dateIsoString => {
    return {
      date: new Date(dateIsoString).getDate(),
      month: new Date(dateIsoString).getMonth() + 1,
      year: new Date(dateIsoString).getFullYear(),
    };
  };

  const convertDateISOToString = dateIsoString => {
    if (dateIsoString && moment(dateIsoString).isValid() && dateFormat) {
      return moment(dateIsoString).format(dateFormat);
    } else {
      return '';
    }
  };
  const convertDateISOToTimeString = dateIsoString => {
    if (dateIsoString && moment(dateIsoString).isValid()) {
      return moment(dateIsoString).format('LT');
    } else {
      return '';
    }
  };

  const combineDateIsoStringAndTime = (dateIsoString, time) => {
    const date = new Date(dateIsoString);
    const [hours, mins] = time.split(':');
    date.setHours(hours, mins, 0);
    return date;
  };

  const resetTimeFromIsoString = dateIsoString => {
    const date = new Date(dateIsoString);
    date.setHours(0, 0, 0);
    return date;
  };

  const removeDateFromIsoString = dateIsoString => {
    const hours = new Date(dateIsoString)
      .getHours()
      .toString()
      .padStart(2, '0');
    const mins = new Date(dateIsoString)
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hours}:${mins}`;
  };

  const convertDateObjectToJSDate = dateObj => {
    const { date, month, year } = dateObj || {};

    if (!date || !month || !year) return new Date();
    return new Date(year, month - 1, date);
  };

  return {
    convertDateToThemeFormat,
    getCurrentDateObject,
    getDateObjectFromISOString,
    convertDateISOToString,
    convertDateISOToTimeString,
    combineDateIsoStringAndTime,
    resetTimeFromIsoString,
    removeDateFromIsoString,
    convertDateObjectToJSDate,
  };
};

export default useConvertDate;
