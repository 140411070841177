import { CLEAR_SESSION } from '../../../redux/actionTypes/auth';
import { avoidedDuplicationData } from '../../Common/helper';
import {
  GET_RMS_GROUP_BOND_DATA_FAILED,
  GET_RMS_GROUP_BOND_DATA_REQUEST,
  GET_RMS_GROUP_BOND_DATA_SUCCESS,
  POST_RMS_GROUP_REQUEST_FAILED,
  POST_RMS_GROUP_REQUEST_REQUEST,
  POST_RMS_GROUP_REQUEST_SUCCESS,
  REMOVE_GROUP_SAVED_APPLICANT,
  RESET_GROUP_ADD_USER_POPUP_DATA,
  RMS_FETCH_GROUP_TEMPLATES_FAILED,
  RMS_FETCH_GROUP_TEMPLATES_REQUEST,
  RMS_FETCH_GROUP_TEMPLATES_SUCCESS,
  RMS_GET_USER_BUDGET_DETAILS_FAILED,
  RMS_GET_USER_BUDGET_DETAILS_REQUEST,
  RMS_GET_USER_BUDGET_DETAILS_SUCCESS,
  RMS_GROUP_ADD_REMOVE_SELECTED_USERS,
  RMS_GROUP_RESET_USER_MANAGEMENT,
  RMS_GROUP_RESET_USER_UPDATE,
  RMS_GROUP_SAVE_SELECTED_USERS,
  RMS_GROUP_SEARCH_LOADING_SET,
  RMS_GROUP_UPDATE_USER_FAILED,
  RMS_GROUP_UPDATE_USER_REQUEST,
  RMS_GROUP_UPDATE_USER_SUCCESS,
  RMS_GROUP_USER_MANAGEMENT_FAIL,
  RMS_GROUP_USER_MANAGEMENT_REQUEST,
  RMS_GROUP_USER_MANAGEMENT_SUCCESS,
  RMS_SET_SELECTED_GROUP_TEMPLATE,
  RMS_UPDATE_GROUP_GROSS_FEE,
  SEARCH_RMS_GROUP_USERS_RESET,
  SEARCH_RMS_GROUP_USERS_SUCCESS,
} from '../actionTypes/groupRequestTypes';

const INITIAL_STATE = {
  allUsers: [],
  bondData: null,
  bondDataError: false,
  bondDataLoading: false,
  bondDataSuccess: false,
  grossFee: 0,
  groupRequestSubmitError: false,
  groupRequestSubmitErrorMessage: '',
  groupRequestSubmitLoading: false,
  groupRequestSubmitResponse: null,
  groupRequestSubmitSuccess: false,
  groupTemplates: [],
  groupTemplatesLoading: false,
  qualifications: {},
  saveLoading: false,
  savedUsers: [],
  selectedUsers: [],
  successClose: false,
  templateId: null,
  updateUserError: false,
  updateUserLoading: false,
  updateUserSuccess: false,
  userBudgets: null,
  userBudgetsLoading: false,
  userData: null,
  userLoading: false,
  users: [],
  usersPage: 0,
  usersTotalRecords: 0,
};

const groupRequestReducer = (state = INITIAL_STATE, action) => {
  const { data = [], page = 0, totalRecords = 0 } = action.payload || {};

  switch (action.type) {
    case SEARCH_RMS_GROUP_USERS_RESET:
      return {
        ...state,
        users: [],
        usersPage: 0,
        usersTotalRecords: 0,
      };
    case SEARCH_RMS_GROUP_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...(data || [])],
        usersPage: page || state.usersPage,
        usersTotalRecords: totalRecords || state.totalRecords,
      };
    case REMOVE_GROUP_SAVED_APPLICANT:
      return {
        ...state,
        savedUsers: action?.payload || [],
        selectedUsers: action?.payload || [],
      };

    case RMS_GROUP_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case RMS_GROUP_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userData: action?.payload?.data || null,
        allUsers: avoidedDuplicationData(
          state?.allUsers || [],
          action?.payload?.data,
          '_id'
        ),
      };
    case RMS_GROUP_USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userLoading: false,
      };

    case RMS_FETCH_GROUP_TEMPLATES_REQUEST:
      return {
        ...state,
        groupTemplatesLoading: true,
      };
    case RMS_FETCH_GROUP_TEMPLATES_SUCCESS:
      return {
        ...state,
        groupTemplates: data || [],
        groupTemplatesLoading: false,
      };
    case RMS_FETCH_GROUP_TEMPLATES_FAILED:
      return {
        ...state,
        groupTemplatesLoading: false,
      };

    case RMS_SET_SELECTED_GROUP_TEMPLATE:
      return {
        ...state,
        templateId: action.payload || '',
      };

    case RMS_GROUP_SEARCH_LOADING_SET:
      return {
        ...state,
        userLoading: true,
      };
    case RMS_GROUP_ADD_REMOVE_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: setSelectedUsers(action?.payload, state.selectedUsers),
      };
    case RMS_GROUP_SAVE_SELECTED_USERS: {
      const customSelectedUsers = [...state.selectedUsers];
      return {
        ...state,
        savedUsers: customSelectedUsers,
      };
    }

    case RESET_GROUP_ADD_USER_POPUP_DATA:
      return {
        ...state,
        userLoading: false,
        userData: null,
        selectedUsers: [],
        savedUsers: [],
        saveLoading: false,
        successClose: false,
      };

    case RMS_GROUP_UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserError: false,
      };
    case RMS_GROUP_UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: true,
      };
    case RMS_GROUP_UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: true,
      };

    case RMS_GROUP_RESET_USER_UPDATE:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserError: false,
      };

    case POST_RMS_GROUP_REQUEST_REQUEST:
      return {
        ...state,
        groupRequestSubmitLoading: true,
        groupRequestSubmitSuccess: false,
        groupRequestSubmitError: false,
        groupRequestSubmitErrorMessage: '',
        groupRequestSubmitResponse: null,
      };
    case POST_RMS_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groupRequestSubmitLoading: false,
        groupRequestSubmitSuccess: true,
        groupRequestSubmitResponse: action?.payload,
      };
    case POST_RMS_GROUP_REQUEST_FAILED:
      return {
        ...state,
        groupRequestSubmitLoading: false,
        groupRequestSubmitError: true,
        groupRequestSubmitErrorMessage: action.payload || '',
      };

    case RMS_GET_USER_BUDGET_DETAILS_REQUEST:
      return {
        ...state,
        userBudgetsLoading: true,
      };
    case RMS_GET_USER_BUDGET_DETAILS_SUCCESS:
      return {
        ...state,
        userBudgets: appendBudgetDetails(
          state.userBudgets || {},
          action?.payload?.data || []
        ),
        userBudgetsLoading: false,
      };
    case RMS_GET_USER_BUDGET_DETAILS_FAILED:
      return {
        ...state,
        userBudgetsLoading: false,
      };

    case RMS_UPDATE_GROUP_GROSS_FEE:
      return {
        ...state,
        grossFee: action.payload,
      };

    case GET_RMS_GROUP_BOND_DATA_REQUEST:
      return {
        ...state,
        bondDataLoading: true,
        bondDataSuccess: false,
        bondDataError: false,
        bondData: null,
      };
    case GET_RMS_GROUP_BOND_DATA_SUCCESS:
      return {
        ...state,
        bondDataLoading: false,
        bondDataSuccess: true,
        bondData: action?.payload,
      };
    case GET_RMS_GROUP_BOND_DATA_FAILED:
      return {
        ...state,
        bondDataLoading: false,
        bondDataError: true,
      };

    case RMS_GROUP_RESET_USER_MANAGEMENT:
    case CLEAR_SESSION:
      return INITIAL_STATE;

    default:
      return state;
  }
};

const appendBudgetDetails = (currentData = {}, newData = []) => {
  let updatedBudgets = { ...currentData };

  newData.forEach(budgetData => {
    updatedBudgets[budgetData.userId] = budgetData.groupBudget;
  });

  return updatedBudgets;
};

const setSelectedUsers = (selectedUser, existingData) => {
  const currentData = [...existingData];
  const index = currentData?.indexOf(selectedUser);
  if (index > -1) {
    currentData.splice(index, 1);
  } else {
    currentData.push(selectedUser);
  }
  return currentData || [];
};

export default groupRequestReducer;
