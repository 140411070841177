import React from 'react';

const RmsPageWrapper = ({ children }) => {
  return (
    <>
      <div className="RMS__page trainingForm form2">
        <div className="container">
          <div className="RMS__page--wrapper">{children}</div>
        </div>
      </div>
    </>
  );
};

export default RmsPageWrapper;
