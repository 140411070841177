import React from 'react';
import RMSPageTitle from '../CommonComponents/RMSPageTitle';
import useConvertId from '../../hooks/useConvertId';
import TableGrid from './TableGrid';
import TableHeader from './TableHeader';
import ReactVisibilitySensor from 'react-visibility-sensor';

const ManageRequests = ({
  match,
  searchText,
  setSearchText,
  selectedDate,
  setSelectedDate,
  selectedTemplate,
  setSelectedTemplate,
  selectedDropdown,
  setSelectedDropdown,
  allTemplateRequests,
  selectedTemplateRequests,
  loading,
  loadNextRequestPage,
  feedbackRequest,
  acceptOrRejectRequest,
  filterMode,
  setFilterMode,
  setSearchTemplateText,
  allTemplateRequestsLoading,
}) => {
  const { getFormattedDate } = useConvertId();

  if (!match) {
    return null;
  }

  return (
    <div>
      <div className="RMS__page superAdmin manageRequests">
        <div className="container">
          <div className="RMS__page--wrapper">
            <RMSPageTitle titleText="Manage Requests" />
            <div className="RMS__page--content">
              <TableHeader
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                searchText={searchText}
                setSearchText={setSearchText}
                templates={allTemplateRequests}
                templatesLoading={allTemplateRequestsLoading}
                setSelectedDropdown={setSelectedDropdown}
                selectedDropdown={selectedDropdown}
                filterMode={filterMode}
                setFilterMode={setFilterMode}
                setSearchTemplateText={setSearchTemplateText}
              />
              <TableGrid
                requests={selectedTemplateRequests}
                loading={loading}
                feedbackRequest={feedbackRequest}
                acceptOrRejectRequest={acceptOrRejectRequest}
                filterMode={filterMode}
              />
            </div>
            <ReactVisibilitySensor
              partialVisibility
              onChange={isVisible => {
                if (isVisible) {
                  loadNextRequestPage();
                }
              }}
            >
              <div>&nbsp;</div>
            </ReactVisibilitySensor>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageRequests;
